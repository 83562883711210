import {
  BaseInfoWrapper,
  ContentWrapper,
  CusButton,
  IdCardInfo,
  Wrap,
} from "../../style";
import { Checkbox, Form, Input, message } from "antd";
import FileUpload from "@/components/file-upload";
import BaseFooter from "@/layouts/base-footer";
import CusHeader from "@/layouts/cus-header";
import PersionCommon from "../../components/common";
import type { FormInstance } from "antd/es/form";
import { Title } from "@/pages/server/server-center/style";
import { useEffect, useRef, useState } from "react";
import GrantModal from "./components/grant-modal";
import { useSelector, history, useParams } from "umi";
import { createAuth, getUserAuthedInfo } from "@/api/auth";
import Agreement from "@/components/agreement";
import { AccountAuditVo } from "@/models/auth";
import { ContentText } from "./components/content";
interface BossAuthProps {}
const BossAuth: React.FC<BossAuthProps> = () => {
  const [demoList] = useState([
    { icon: require("@/assets/images/persion/id_card_1.png"), text: "标准" },
    {
      icon: require("@/assets/images/persion/id_card_2.png"),
      text: "边框缺失",
    },
    {
      icon: require("@/assets/images/persion/id_card_3.png"),
      text: "照片模糊",
    },
    {
      icon: require("@/assets/images/persion/id_card_4.png"),
      text: "闪光强烈",
    },
  ]);
  const { user } = useSelector((state: any) => state.user);
  const formRef: any = useRef<FormInstance>();
  const param = useParams<any>();
  const [visible, setVisible] = useState(false);
  const [info, setInfo] = useState<AccountAuditVo>({} as AccountAuditVo);
  const onFinish = (values: any) => {
    const params = {
      ...values,
      ...values.idCard,
      roleType: 1,
      accountId: user.id,
      sourceClient: 3,
    };
    delete params.idCard;
    createAuth(params).then((res: any) => {
      if (res.code == 1) {
        message.success("提交成功");
        history.replace("/persion/base/" + new Date().getTime());
      }
    });
  };
  useEffect(() => {
    if (param.id == 1) {
      getUserAuthedInfo(user.id).then((res: any) => {
        if (res.code == 1) {
          setInfo(res.data);
          formRef.current?.setFieldsValue({
            auditName: res.data.auditName,
            idCardNo: res.data.idCardNo,
            idCardFront: res.data.idCardFront,
            idCardBack: res.data.idCardBack,
          });
        }
      });
    }
  }, []);
  return (
    <>
      <CusHeader title="个人中心" showSearch={false}></CusHeader>
      <ContentWrapper>
        <Wrap>
          <PersionCommon></PersionCommon>
          <BaseInfoWrapper style={{ flex: 1 }}>
            <Title style={{ marginBottom: "24px" }}>企业认证</Title>
            <Form
              labelCol={{ span: 7 }}
              wrapperCol={{ span: 20 }}
              ref={formRef}
              onFinish={onFinish}
            >
              <Form.Item
                label="企业名称"
                name="auditName"
                wrapperCol={{ span: 16 }}
                rules={[
                  {
                    validator: (_: any, value: any) => {
                      if (value) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(new Error("请输入企业名称"));
                      }
                      //
                    },
                    validateTrigger: ["blur"],
                  },
                ]}
                initialValue={info?.auditName}
              >
                <Input placeholder="请输入" style={{ width: "224px" }} />
              </Form.Item>
              <Form.Item
                label="营业执照"
                name="businessLicense"
                rules={[
                  {
                    validator: (_: any, value: any) => {
                      if (value) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(new Error("请上传营业执照"));
                      }
                    },
                  },
                ]}
                initialValue={info.businessLicense}
              >
                <>
                  <FileUpload
                    noCrope
                    value={info?.businessLicense}
                    height={145}
                    width={224}
                    onChange={(value) =>
                      formRef.current?.setFieldsValue({
                        businessLicense: value,
                      })
                    }
                  />
                  <span className="tip">
                    *请确保上传的照片边框完整、字体清晰、亮度均匀
                  </span>
                </>
              </Form.Item>
              <Form.Item
                label="法人身份证照片"
                name="idCard"
                rules={[
                  {
                    validator: (_: any, value: any) => {
                      if (value && value.idCardFront && value.idCardBack) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          new Error("请上传法人身份证照片")
                        );
                      }
                      //
                    },
                  },
                ]}
              >
                <div style={{ display: "flex" }}>
                  <FileUpload
                    noCrope
                    height={148}
                    width={176}
                    value={info?.idCardFront}
                    defaultUrl={require("@/assets/images/persion/zhengmian.png")}
                    onChange={(value) => {
                      formRef.current?.setFieldsValue({
                        idCard: {
                          ...formRef.current?.getFieldValue("idCard"),
                          idCardFront: value,
                        },
                      });
                    }}
                  />
                  <FileUpload
                    noCrope
                    height={148}
                    width={176}
                    value={info?.idCardBack}
                    defaultUrl={require("@/assets/images/persion/fanmian.png")}
                    onChange={(value) =>
                      formRef.current?.setFieldsValue({
                        idCard: {
                          ...formRef.current?.getFieldValue("idCard"),
                          idCardBack: value,
                        },
                      })
                    }
                  />
                </div>
                <IdCardInfo>
                  <span className="idcard-info-title">拍摄示例</span>
                  <div className="idcard-info-list">
                    {demoList.map((item, index) => (
                      <div key={index} className="idcard-item">
                        <img
                          src={item.icon}
                          className="idcard-info-icon"
                          alt="idcard-info-icon"
                        />
                        <span className="idcard-info-text">{item.text}</span>
                      </div>
                    ))}
                  </div>
                </IdCardInfo>
              </Form.Item>
              <Form.Item
                label="授权书"
                name="warrantPic"
                rules={[
                  {
                    validator: (_: any, value: any) => {
                      if (value) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(new Error("请上传授权书"));
                      }
                      //
                    },
                  },
                ]}
              >
                <>
                  <FileUpload
                    noCrope
                    height={145}
                    width={224}
                    value={info?.warrantPic}
                    onChange={(value) =>
                      formRef.current?.setFieldsValue({
                        warrantPic: value,
                      })
                    }
                  />
                  <div className="tip business">
                    <span
                      className="download"
                      onClick={async () => {
                        if (formRef.current?.getFieldValue("auditName")) {
                          setVisible(true);
                        } else {
                          message.error("请先填写企业名称");
                        }
                      }}
                    >
                      点击生成并下载授权书
                    </span>
                    ，填写完成后拍照上传，注意授权书填写完整，拍摄清晰，使用手写签名和实体公章；
                  </div>
                </>
              </Form.Item>
              <Form.Item
                wrapperCol={{ offset: 7 }}
                name="agree"
                rules={[
                  {
                    validator: (_: any, value: any) => {
                      if (value) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          new Error("请勾选并同意《认证服务协议》")
                        );
                      }
                      //
                    },
                  },
                ]}
              >
                <Checkbox
                  onChange={(e) => {
                    formRef.current?.setFieldsValue({
                      agree: e.target.checked,
                    });
                  }}
                >
                  <span className="agree-item">
                    <span>阅读并同意</span>
                    <Agreement
                      title="《认证服务协议》"
                      content={() => <ContentText />}
                    ></Agreement>
                  </span>
                </Checkbox>
                <div className="agree-info">
                  <span>
                    1、认领提交的申请信息，只作为审核使用，不会公开展示;
                  </span>
                  <span>2、支持jpg/jpeg/png/bmp格式，图片大小不超过4MB;</span>
                  <span>3、营业执照和法定代表人信息必须与工商信息一致;</span>
                  <span>4、上传图片为实物图片或复印件加盖红色公章;</span>
                  <span>5、保证上传图片清晰，并且露出四个角;</span>
                  <span>6、上传图片如果不够清晰，会审核不通过</span>
                </div>
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 7, span: 12 }}>
                <CusButton
                  type="primary"
                  width={224}
                  height={32}
                  style={{ marginTop: "70px" }}
                  htmlType="submit"
                >
                  提交申请
                </CusButton>
              </Form.Item>
            </Form>
          </BaseInfoWrapper>
        </Wrap>
      </ContentWrapper>
      <BaseFooter />
      <GrantModal
        companyName={formRef.current?.getFieldValue("auditName")}
        visibleModal={visible}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
      />
    </>
  );
};

export default BossAuth;
