import styled from "styled-components";
export const CodeInputWrapper = styled.div<{ width?: number; height?: number }>`
  display: flex;
  width: ${(props) => (props.width ? props.width + "px" : "370px")};
  align-items: center;
  .ant-input-affix-wrapper,
  .ant-input {
    margin-right: 14px;
  }
`;
