import { Title } from "@/styles/common-style";
import {
  ExpertItemContainer,
  ExpertPagination,
  ExpertSenior,
  ExpertSeniorList,
  SeniorTabPane,
  SeniorTabs,
} from "../style";
import { Row } from "antd";
import { useEffect, useState } from "react";
import CardItem from "./card-item";
import { getExpert, getExpertList } from "@/utils/api";
import { CasePagination } from "@/pages/case-center/style";
interface ExpertListProps {
  searchList: any;
  warnning: boolean;
}
interface ConsultantType {
  id: number;
  title: string;
  expertName: string;
  expertType: 2;
  expertAvatar: string;
  introduction: string;
  sort: 6;
  createTime: string;
  updateTime: string;
}
export type ConsultantListType = ConsultantType[];
const ExpertList: React.FC<ExpertListProps> = ({ searchList, warnning }) => {
  let [defaultdata, setDefaultdata] = useState([]);
  const [seniorData, setSensiorData] = useState<any>([]);
  const [expertData, setExpertData] = useState<any>([]);
  const [current, setCurrent] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [activeKey, setActiveKey] = useState<string>("1");
  const [consultant, setConsultant] = useState<ConsultantType>(
    {} as ConsultantType
  );
  const [tabs] = useState<any>([
    { title: "中国服装流行趋势研究与发布中心", key: "1" },
    { title: "智能可穿戴研发中心", key: "2" },
    { title: "中国服装数字化转型创新中心", key: "3" },
  ]);
  const getExpertInfo = (params: any, cb: any) => {
    getExpertList(params).then((res: any) => {
      if (res.code === 1) {
        params.expertType == 2 && setConsultant(res.data[0]);
        if (params.expertType == 1) {
          cb(
            res.data.sort(
              (a: { sort: number }, b: { sort: number }) => b.sort - a.sort
            )
          );
        } else {
          console.log(res.data);
          cb(res.data);
        }
        params.expertType > 2 && res.page && setTotal(res.page.count);
      }
    });
  };

  const onChange = (key: any) => {
    setActiveKey(key);
    setTotal(0);
    setCurrentPage(1);
    setDefaultdata([]);
    defaultdata = [];
    getExpertInfo({ expertType: +key + 2, index: 1, size: 16 }, setDefaultdata);
  };
  useEffect(() => {
    try {
      getExpertInfo({ expertType: 3, index: 1, size: 16 }, setDefaultdata);
      getExpertInfo({ expertType: 2, index: 1, size: 4 }, setSensiorData);
      getExpertInfo({ expertType: 1 }, setExpertData);
    } catch (error) {
      console.error(error);
    }
  }, []);
  return (
    <>
      {warnning || (
        <ExpertItemContainer bgImage={expertData[0]?.expertAvatar}>
          <Title style={{ marginBottom: "35px" }}>首席科学家</Title>
          <Row>
            <div className="img">
              {/* <img src={expertData[0]?.expertAvatar} /> */}
            </div>
            <div className="expert-info">
              <div className="watter-meno">
                <img
                  src={require("@/assets/images/expert/watter.png")}
                  alt=""
                  className="watter"
                />
              </div>
              <div className="name">{expertData[0]?.expertName}</div>
              <div className="export-info-nick">{expertData[0]?.title}</div>
              <div className="export-info-intro">
                {expertData[0]?.introduction}
              </div>
              <div className="watter-meno text-align footer-img">
                <img
                  src={require("@/assets/images/expert/watter.png")}
                  alt=""
                  className="watter"
                />
              </div>
            </div>
          </Row>
        </ExpertItemContainer>
      )}
      {warnning || (
        <ExpertSenior>
          <div className="senior-container">
            <div className="senior-centent">
              <Title className="senior-title">高级顾问</Title>
              <div className="senior-name">{consultant.expertName}</div>
              <div className="export-info-nick">{consultant.title}</div>
              <div className="senior-info">{consultant.introduction}</div>
              <div className="senior-img-list">
                {seniorData &&
                  seniorData.map((item: ConsultantType, index: any) => {
                    return (
                      <div
                        className={
                          current === index
                            ? "senior-img  senior-img-active"
                            : "senior-img"
                        }
                        onClick={() => {
                          setCurrent(index);
                          setConsultant(item);
                        }}
                        key={index}
                      >
                        <img src={item.expertAvatar} />
                      </div>
                    );
                  })}
              </div>
            </div>
            <div
              className="img-avatar"
              style={{
                width: "295px",
                height: " 482px",
                borderRadius: "2px",
                backgroundImage: `url(${consultant.expertAvatar})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            ></div>
          </div>
        </ExpertSenior>
      )}
      <ExpertSeniorList style={{ textAlign: "center" }}>
        {warnning ? (
          !searchList && searchList.length == 0 ? (
            <div
              style={{
                fontSize: "30px",
                textAlign: "center",
                marginTop: "300px",
                color: "white",
                display: "inline-block",
              }}
            >
              没找到您所查询的专家哦！！！
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "1200px",
                margin: "auto",
              }}
            >
              {searchList.map((it: any, index: number) => (
                <CardItem
                  key={index}
                  data={{
                    expertName: it.expertName,
                    expertAvatar: it.expertAvatar,
                    introduction: it.introduction,
                    title: it.title,
                  }}
                />
              ))}{" "}
            </div>
          )
        ) : (
          <div
            style={{ width: "1200px", margin: "0 auto", paddingBottom: 100 }}
          >
            <SeniorTabs
              activeKey={activeKey}
              onChange={onChange}
              centered
              animated
            >
              {tabs &&
                tabs.map((item: any) => (
                  <SeniorTabPane tab={item.title} key={item.key}>
                    {" "}
                  </SeniorTabPane>
                ))}
            </SeniorTabs>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {defaultdata &&
                defaultdata.map((it: any, index: number) => (
                  <CardItem
                    key={index}
                    data={{
                      title: it.title,
                      expertName: it.expertName,
                      expertAvatar: it.expertAvatar,
                      introduction: it.introduction,
                    }}
                  />
                ))}
            </div>
            <ExpertPagination
              defaultCurrent={1}
              current={currentPage}
              total={total}
              hideOnSinglePage
              pageSize={16}
              showSizeChanger={false}
              onChange={(page, pageSize) => {
                setCurrentPage(page);
                getExpertInfo(
                  { expertType: +activeKey + 2, index: page, size: 16 },
                  setDefaultdata
                );
              }}
            />
          </div>
        )}
      </ExpertSeniorList>
    </>
  );
};

export default ExpertList;
