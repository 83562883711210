// @ts-nocheck
import React from 'react';
import { ApplyPluginsType } from '/Users/zfkc/Desktop/web/forbes-brain/node_modules/.pnpm/@umijs+runtime@3.5.41_react@16.14.0/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": require('@/pages/base-home').default,
    "exact": true
  },
  {
    "path": "/home",
    "component": require('@/pages/base-home').default,
    "exact": true
  },
  {
    "path": "/real-list",
    "component": require('@/pages/real-info-list').default,
    "exact": true
  },
  {
    "path": "/trains",
    "component": require('@/pages/trains').default,
    "exact": true
  },
  {
    "path": "/ecological",
    "component": require('@/pages/ecological').default,
    "exact": true
  },
  {
    "path": "/atlas",
    "component": require('@/pages/atlas').default,
    "exact": true
  },
  {
    "path": "/data-cockpit",
    "component": require('@/pages/data-cockpit').default,
    "exact": true
  },
  {
    "path": "/trains/trains-details/:id",
    "component": require('@/pages/metting-details').default,
    "exact": true
  },
  {
    "path": "/real-detail/:id",
    "component": require('@/pages/real-time-detail').default,
    "exact": true
  },
  {
    "path": "/persion/base/:id",
    "component": require('@/pages/persion').default,
    "exact": true
  },
  {
    "path": "/persion/security/:id",
    "component": require('@/pages/persion/base-security').default,
    "exact": true
  },
  {
    "path": "/persion/phone/:id",
    "component": require('@/pages/persion/edit-phone').default,
    "exact": true
  },
  {
    "path": "/persion/order/:id",
    "component": require('@/pages/persion/sam-order').default,
    "exact": true
  },
  {
    "path": "/persion/auth/:id",
    "component": require('@/pages/persion/auth').default,
    "exact": true
  },
  {
    "path": "/persion/auth/user-auth/:id",
    "component": require('@/pages/persion/auth/user-auth').default,
    "exact": true
  },
  {
    "path": "/persion/auth/boss-auth/:id",
    "component": require('@/pages/persion/auth/boss-auth').default,
    "exact": true
  },
  {
    "path": "/persion/my-collect/:id",
    "component": require('@/pages/persion/my-collect').default,
    "exact": true
  },
  {
    "path": "/persion/reset/:id",
    "component": require('@/pages/persion/edit-password').default,
    "exact": true
  },
  {
    "path": "/persion/auth/user-authed/:id",
    "component": require('@/pages/persion/auth/user-authed').default,
    "exact": true
  },
  {
    "path": "/persion/auth/boss-authed/:id",
    "component": require('@/pages/persion/auth/boss-authed').default,
    "exact": true
  },
  {
    "path": "/persion/my-order/:id",
    "component": require('@/pages/persion/my-order').default,
    "exact": true
  },
  {
    "path": "/persion/my-download/:id",
    "component": require('@/pages/persion/my-download').default,
    "exact": true
  },
  {
    "path": "/server/center",
    "component": require('@/pages/server/server-center').default,
    "exact": true
  },
  {
    "path": "/server/all-service",
    "component": require('@/pages/server/all-service').default,
    "exact": true
  },
  {
    "path": "/server/server-detail/:id",
    "component": require('@/pages/server/server-detail').default,
    "exact": true
  },
  {
    "path": "/economic",
    "component": require('@/pages/economic').default,
    "exact": true
  },
  {
    "path": "/economic/economic-detail/:id",
    "component": require('@/pages/economic/economic-detail').default,
    "exact": true
  },
  {
    "path": "/solution",
    "component": require('@/pages/solution').default,
    "exact": true
  },
  {
    "path": "/login",
    "component": require('@/pages/login').default,
    "exact": true
  },
  {
    "path": "/regist",
    "component": require('@/pages/regist').default,
    "exact": true
  },
  {
    "path": "/case-center",
    "component": require('@/pages/case-center').default,
    "exact": true
  },
  {
    "path": "/case/case-detail/:id",
    "component": require('@/pages/case-detail').default,
    "exact": true
  },
  {
    "path": "/expert",
    "component": require('@/pages/expert').default,
    "exact": true
  },
  {
    "path": "/policy/policy-list",
    "component": require('@/pages/policy/policy-list').default,
    "exact": true
  },
  {
    "path": "/policy/policy-detail/:id",
    "component": require('@/pages/policy/policy-detail').default,
    "exact": true
  },
  {
    "path": "/goods-order/:id",
    "component": require('@/pages/knowledge-map/order').default,
    "exact": true
  },
  {
    "path": "/knowledge",
    "component": require('@/pages/knowledge-map').default,
    "exact": true
  },
  {
    "path": "/knowledge/knowledge-detail/:id",
    "component": require('@/pages/knowledge-map/knowledge-detail').default,
    "exact": true
  },
  {
    "path": "/order-platform",
    "component": require('@/pages/order-platform').default,
    "exact": true
  },
  {
    "path": "/d-center",
    "component": require('@/pages/d-center').default,
    "exact": true
  },
  {
    "path": "/capacity-center",
    "component": require('@/pages/capacity-center').default,
    "exact": true
  },
  {
    "path": "/data-assets",
    "component": require('@/pages/data-assets').default,
    "exact": true
  },
  {
    "path": "/maintain",
    "component": require('@/pages/maintain').default,
    "exact": true
  },
  {
    "path": "/engineer-certification",
    "component": require('@/pages/engineer-certification').default,
    "exact": true
  },
  {
    "path": "/more-trains",
    "component": require('@/pages/more-trains').default,
    "exact": true
  },
  {
    "path": "/more-consult",
    "component": require('@/pages/more-consulting').default,
    "exact": true
  },
  {
    "path": "/h5-pay/:id/:orderNo",
    "component": require('@/pages/h5-pay').default,
    "exact": true
  },
  {
    "path": "/h5/info/:id",
    "component": require('@/pages/h5/info').default,
    "exact": true
  },
  {
    "path": "/h5/form/:id",
    "component": require('@/pages/h5/sub-form').default,
    "exact": true
  },
  {
    "path": "/to-pay",
    "component": require('@/pages/to-pay').default,
    "exact": true
  },
  {
    "path": "/visitor",
    "component": require('@/pages/h5/visitor').default,
    "exact": true
  },
  {
    "path": "/visitor/:id",
    "component": require('@/pages/h5/visitor').default,
    "exact": true
  },
  {
    "path": "/digital",
    "component": require('@/pages/digital').default,
    "exact": true
  },
  {
    "path": "/company-plan",
    "component": require('@/pages/company-plan').default,
    "exact": true
  },
  {
    "path": "/two-pants",
    "component": require('@/pages/two-pants').default,
    "exact": true
  },
  {
    "path": "/implementation",
    "component": require('@/pages/implementation').default,
    "exact": true
  },
  {
    "path": "/industrial-internet",
    "component": require('@/pages/industrial-internet').default,
    "exact": true
  },
  {
    "path": "/industry-park",
    "component": require('@/pages/industry-park').default,
    "exact": true
  },
  {
    "path": "/knitting-factory",
    "component": require('@/pages/knitting-factory').default,
    "exact": true
  },
  {
    "path": "/two-area",
    "component": require('@/pages/two-area').default,
    "exact": true
  },
  {
    "path": "/two-collect",
    "component": require('@/pages/two-collect').default,
    "exact": true
  },
  {
    "path": "/clothing",
    "component": require('@/pages/clothing').default,
    "exact": true
  },
  {
    "path": "/knitting",
    "component": require('@/pages/knitting').default,
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
