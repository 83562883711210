import { Title } from "@/styles/common-style";
import { Train3Wrapper } from "../style";

interface Train3Props {}
export const Train3: React.FC<Train3Props> = () => {
  const train3Data = [
    {
      title: "专家团队支持",
      info: "依托于行业顶层资源，拥有一批即懂技术又懂业务的，有丰富经验的专家；",
      bg: require("@/assets/images/more-consulting/icon1.png"),
    },
    {
      title: "前沿科技支持",
      info: "依托中国服装智能制造技术创新战略联盟，有丰富的的科技企业技术资源；",
      bg: require("@/assets/images/more-consulting/icon2.png"),
    },
    {
      title: "大型项目经验",
      info: "拥有服务过国内龙头服装企业的项目经验，在行业内拥有高知名度 ",
      bg: require("@/assets/images/more-consulting/icon3.png"),
    },
  ];
  return (
    <Train3Wrapper>
      <Title
        fz={36}
        textAlign="center"
        fw={600}
        color="#000"
        style={{
          paddingTop: 30,
          marginBottom: 30,
          fontFamily: " PingFangSC, PingFangSC-Semibold",
        }}
      >
        优势
      </Title>
      <div className="train3-list">
        {train3Data.map((item: any, index: number) => (
          <div className="train3-list-item" key={index}>
            <img src={item.bg} alt="" className="img" />
            <div className="item-title">{item.title}</div>
            <div className="item-info">{item.info}</div>
          </div>
        ))}
      </div>
    </Train3Wrapper>
  );
};
