import { Title } from "@/styles/common-style";
import { JoinUsWrapper } from "../style";

interface JoinUsProps {}
export const JoinUs: React.FC<JoinUsProps> = () => {
  const data = [
    {
      name: "涨知识",
      icon: require("@/assets/images/engineer/icon1.png"),
      info: ["服装相关领域的前沿知识与", "信息的公益分享服务"],
    },
    {
      name: "好就业",
      icon: require("@/assets/images/engineer/icon2.png"),
      info: ["服装相关领域的前沿知识与信息", "的公益分享服务"],
    },
    {
      name: "真薪资",
      icon: require("@/assets/images/engineer/icon3.png"),
      info: ["为服装高级定制店铺或企业的", "量体师进行专业培训服务"],
    },
  ];
  const items = [
    { title: "招生对象", info: "版师、工艺师和新媒体运营", color: "#f3f7fe" },
    {
      title: "报名须知",
      info: "需满足同一职业连续工作一年以上或累计工作二年以上的且经过初级培训结业。",
      color: "#e8edff",
    },
  ];
  return (
    <JoinUsWrapper>
      <Title
        fz={36}
        fw={600}
        color="#000"
        textAlign="center"
        className="join-title"
      >
        加入我们
      </Title>
      <div className="info">
        快来加入我们吧，我们为你精心准备了初中高级全段位的职业技能认定，让你在行业中拥有权威的官方认定，无论是找工作还是工资水平，都能高人一等！
      </div>
      <div className="join-list">
        {data.map((item, index) => (
          <div className="join-list-item" key="index">
            <div className="title-info">
              <div className="title">{item.name}</div>
              <img src={item.icon} alt="" className="icon" />
            </div>
            {item.info.map((it, key) => (
              <div className="info-item" key={key}>
                {it}
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="list-items">
        {items.map((item: any, index) => (
          <div
            className="item"
            key={index}
            style={{ backgroundColor: item.color }}
          >
            <div className="title">{item.title}</div>
            <div className="infos">{item.info}</div>
          </div>
        ))}
      </div>
    </JoinUsWrapper>
  );
};
