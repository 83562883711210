import { Title } from "@/styles/common-style";
import { ContactUs } from "@/utils/common";
import { useEffect, useState } from "react";
import { useSelector, history } from "umi";
import { ConsulteCateWrapper } from "../style";
import { Row } from "antd";
import { RightOutlined } from "@ant-design/icons";
interface TrainsProps {}
interface TrainsVo {
  metadataName: string;
  introduction: string;
  // type: string;
}
const Trains: React.FC<TrainsProps> = (props) => {
  const [consulte, setConsulte] = useState<TrainsVo>();
  const [serverCate, setServerCate] = useState<any>();
  const [trans, setTrans] = useState<TrainsVo[]>();
  const trains: TrainsVo[] = [
    {
      metadataName: "海螺公益培训",
      introduction: "服装相关领域的前沿知识与信息的公益分享服务。",
    },
    {
      metadataName: "版师培训",
      introduction:
        "呈现国内天花板级的立体裁剪与平面制版的教育体系，结合服装人体工学，深度剖析立体裁剪与平面制版的理论精髓，严谨的理论体系结合最新的流行趋势，增强版师举一反三的造型能力，拓展制版技术的极限",
    },
    {
      metadataName: "流行趋势培训",
      introduction:
        "掌握商品数据企划技能并制作OTB企划书、流行企划书，季节流行趋势分析与品牌企划、商品企划、设计企划、面料企划、营销企划、买手模式搭建、买手技能及工作流程介绍、店铺风格区域规划、陈列搭配实操、组货前中后组合技术等。",
    },
    {
      metadataName: "精益数字化转型培训",
      introduction:
        "提供基于精益生产体系的数字化转型方法培训及实践经验分享，帮助企业提升精益管理水平，以适应数字化生产模式，进一步提升生产效率，缩短生产周期，降低生产成本。内容包括精益生产方法体系培训、精益咨询、精益实践导入，数字化转型系统规划方法、数字化车间及智能工厂建设方法及实践",
    },
    {
      metadataName: "着装顾问",
      introduction: "为服装高级定制店铺或企业的量体师进行专业培训服务",
    },
  ];
  const setPay = (data: any) => {
    switch (data.priceType) {
      case 2:
        return "按需定价";
      case 1:
        return "免费";
      case 3:
        return `${data.price}${data.priceCompany == 1 ? "元/年" : "元/次"}`;
      default:
        return "免费";
    }
  };
  const { serverCates } = useSelector((state: any) => state.server);

  useEffect(() => {
    let item = serverCates.filter((item: any) => item.id === 436)[0];
    setServerCate(item);
    setConsulte(trains[0]);
    setTrans(trains.slice(1, trains.length));
  }, [serverCates]);
  return (
    <>
      {serverCate && (
        <ConsulteCateWrapper all>
          <Row
            align="middle"
            justify="space-between"
            style={{ width: 1200, margin: "0 auto  20px" }}
          >
            <Title>{serverCate?.metadataName}</Title>
            <div
              onClick={() => history.push("/more-trains")}
              style={{ cursor: "pointer" }}
            >
              查看更多 <RightOutlined />
            </div>
          </Row>
          <div className="wrapper">
            <div className="consulte-info">
              {consulte && (
                <div
                  className="info"
                  // onClick={() => ContactUs(consulte.metadataName)}
                >
                  <div className="title">{consulte.metadataName}</div>
                  <div className="intro">{consulte?.introduction}</div>
                  {/* <div className='btn'>{setPay(consulte)}</div> */}
                </div>
              )}
            </div>
            <div className="right-info">
              {trans &&
                trans.map((item: any, index) => (
                  <div
                    key={index}
                    className="item"
                    // onClick={() => ContactUs(item.metadataName)}
                  >
                    <div
                      className="item-wrapper"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <div
                        className="title"
                        style={{ alignItems: "flex-start", width: "100%" }}
                      >
                        {item.metadataName}
                      </div>
                      <div
                        className="intro"
                        style={{ alignItems: "flex-start", width: "100%" }}
                      >
                        {item?.introduction}
                      </div>
                    </div>

                    {/* <div className='btn'>{setPay(item)}</div> */}
                  </div>
                ))}
            </div>
          </div>
        </ConsulteCateWrapper>
      )}
    </>
  );
};

export default Trains;
