import React, { useEffect, useState } from "react";
import { H5Wrapper, H5Content } from "../style";
// import {
//   Form,
//   Input,
//   Select,
//   DatePicker,
//   InputNumber,
//   Radio,
//   Button,
//   message,
//   Space,
//   Row,
// } from "antd"
import {
  Button,
  Input,
  Radio,
  Form,
  Picker,
  DatetimePicker,
  Toast,
  Uploader,
  UploaderValueItem,
} from "react-vant";
import { Plus } from "@react-vant/icons";
import moment from "moment";
import { createVisitor, getCommonByName, queryById } from "../api";
import _ from "lodash";
import { Typography } from "antd";
import { downloadFile, upload } from "@/utils/common";
import { useParams } from "umi";
import { sendSms } from "@/api/login";
type AvatarUploaderProps = {
  value?: string;
  maxCount: number;
  onChange?: (val: string) => void;
};

const FileUploader = ({ maxCount = 1, ...props }: AvatarUploaderProps) => {
  const [tasks, setTasks] = React.useState<UploaderValueItem[]>([]);
  const idCountRef = React.useRef(0);

  React.useEffect(() => {
    if (props.value === undefined) return;
    if (!tasks[0] || props.value !== tasks[0].url) {
      setTasks([{ url: props.value, id: idCountRef.current++ }]);
    }
  }, [props.value]);

  const innerChange = (items: UploaderValueItem[]) => {
    setTasks(items);
    const currentUrl: any = items[0]?.data;
    props.onChange?.(currentUrl);
  };

  return (
    <Uploader
      value={tasks}
      accept="*"
      upload={upload}
      uploadIcon={<Plus />}
      maxCount={maxCount}
      onChange={innerChange}
    />
  );
};

interface Iprops {}
const Index: React.FC<Iprops> = () => {
  const { Paragraph } = Typography;
  const [form] = Form.useForm();
  let [num, setNum] = useState<number>(60);
  let [pColumns, setPColumns] = useState<any>([]);
  const [minDate, setMinDate] = useState<any>(new Date());
  const [data, setData] = useState<any>({
    tableSignFlag: 1,
    welcomingSpeechFlag: 1,
  });
  const params = useParams<{ id: string }>();
  const onFinish = (values: any) => {
    createVisitor({
      ...values,
      receptionContactsNum: values?.receptionContactsNum
        ? Number(values?.receptionContactsNum)
        : null,
      visitingDateTime: moment(values.visitingDateTime).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
    }).then((res: any) => {
      if (res.code === 1) {
        Toast.success(res?.data);
      }
    });
  };
  useEffect(() => {
    getCommonByName({
      model: "dfib",
      shortName: "VisitorStaffValueEnum",
    }).then((res) => {
      if (res.data) {
        setPColumns([
          ...res.data.map((item: any) => ({
            ...item,
            value: item.code,
            text: item.name,
          })),
        ]);
      }
    });
  }, []);
  const init = () => {
    if (params?.id) {
      queryById(params?.id).then((res: any) => {
        form.setFieldsValue({
          ...res?.data,
          receptionContactsNum: res?.data?.receptionContactsNum
            ? res?.data?.receptionContactsNum + ""
            : null,
        });
        setData({ ...res?.data });
      });
    }
  };
  const getCode = _.throttle(
    () => {
      let phone = form.getFieldValue("visitingContactPhoneNumber");
      let params = {
        mobile: phone,
        smsType: 7,
      };
      sendSms(params).then((res: any) => {
        if (res.code == 1) {
          Toast.success("验证码发送成功");
        }
      });
      let timer: any = null;
      clearInterval(timer);
      num = 60;
      timer = setInterval(() => {
        if (num == 0) {
          clearInterval(timer);
          num = 60;
        } else {
          num--;
        }
        setNum(num);
      }, 1000);
    },
    60000,
    {
      leading: true,
      trailing: false,
    }
  );
  useEffect(() => {
    init();
  }, [params.id]);
  useEffect(() => {
    document.title = "参观预约申请表";
  }, []);
  return (
    <H5Wrapper style={{ marginTop: 0 }}>
      {/* <NavBar title='参观预约申请表' leftArrow={false} fixed /> */}
      <H5Content>
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={{
            receptionContactsNum: "4",
          }}
          footer={
            params?.id ? (
              <></>
            ) : (
              <div style={{ margin: "16px 16px 0" }}>
                <Button round nativeType="submit" type="primary" block>
                  提交
                </Button>
              </div>
            )
          }
        >
          <Form.Item
            name="visitingApplyName"
            label="参观申请人"
            disabled={params?.id ? true : false}
            rules={[{ required: true, message: "请输入参观申请人" }]}
          >
            <Input placeholder="请输入参观申请人" />
          </Form.Item>
          <Form.Item
            name="visitingUnit"
            label="参观单位"
            disabled={params?.id ? true : false}
            rules={[{ required: true, message: "请输入参观单位" }]}
          >
            <Input placeholder="请输入参观单位" />
          </Form.Item>
          <Form.Item
            name="visitingType"
            label="参观单位类型"
            disabled={params?.id ? true : false}
            trigger="onConfirm"
            rules={[{ required: true, message: "请选择参观单位类型" }]}
            onClick={(_, action: any) => {
              params?.id ? null : action.current?.open();
            }}
          >
            <Picker
              popup
              columns={[
                {
                  text: "政府",
                  value: 1,
                },
                {
                  text: "协会",
                  value: 2,
                },
                // {
                //   text: "品牌企业",
                //   value: 3,
                // },
                {
                  text: "纺织服装企业",
                  value: 4,
                },
                {
                  text: "面辅料企业",
                  value: 5,
                },
                {
                  text: "软硬件企业",
                  value: 6,
                },
                {
                  text: "教育机构",
                  value: 7,
                },
                {
                  text: "地产",
                  value: 8,
                },
                {
                  text: "其他",
                  value: 9,
                },
              ]}
            >
              {(val, selectRow: any) => selectRow?.text || "请选择参观单位类型"}
            </Picker>
          </Form.Item>

          <Form.Item
            name="visitingContacts"
            label="参观团队联络人"
            disabled={params?.id ? true : false}
            rules={[{ required: true, message: "请输入参观团队联络人" }]}
          >
            <Input placeholder="请输入参观团队联络人" />
          </Form.Item>
          <Form.Item
            disabled={params?.id ? true : false}
            name="visitingContactTitle"
            label="联络人职务"
            // rules={[{ required: true, message: "请输入联络人职务" }]}
          >
            <Input placeholder="请输入联络人职务" />
          </Form.Item>
          <Form.Item
            disabled={params?.id ? true : false}
            name="visitingContactPhoneNumber"
            label="联络人手机号"
            rules={[{ required: true, message: "请输入联络人手机号" }]}
          >
            <Input placeholder="请输入联络人手机号" type="tel" />
          </Form.Item>
          {!params?.id && (
            <Form.Item
              // disabled={params?.id ? true : false}
              name="smsCode"
              label="验证码"
              rules={[{ required: true, message: "验证码" }]}
            >
              <Input
                placeholder="验证码"
                suffix={
                  <Button
                    size="small"
                    type="primary"
                    disabled={num >= 60 ? false : true}
                    onClick={() => {
                      form
                        .validateFields(["visitingContactPhoneNumber"])
                        .then((values: any) => {
                          getCode();
                        });
                    }}
                  >
                    {num >= 60 ? "获取验证码" : num + "秒后重发"}
                  </Button>
                }
              />
            </Form.Item>
          )}
          <Form.Item
            disabled={params?.id ? true : false}
            name="visitingDateTime"
            label="来访时间"
            trigger="onChange"
            rules={[{ required: true, message: "请选择来访时间" }]}
            onClick={(_, action: any) => {
              params?.id ? null : action.current?.open();
            }}
          >
            <DatetimePicker
              popup
              minDate={minDate}
              title="选择时间年月日时分"
              type="datetime"
              // onChange={(value: any) => {
              //   if (new Date(value).getDay() === 1) {
              //     Toast.info({ message: "当前选择的日期不支持预约" });
              //     form.setFieldsValue({ visitingDateTime: "" });
              //   }
              // }}
              onConfirm={(value: any) => {
                console.log("====================================");
                console.log(value);
                console.log("====================================");
                form.setFieldsValue({ visitingDateTime: value });
              }}
            >
              {(val: Date) => {
                return val && new Date(val).getDay() !== 1
                  ? moment(val).format("YYYY-MM-DD HH:mm:ss")
                  : "请选择来访时间";
              }}
            </DatetimePicker>
          </Form.Item>

          <Form.Item
            name="receptionContactsNum"
            label="研究院接待人"
            disabled={params?.id ? true : false}
            trigger="onConfirm"
            rules={[{ required: true, message: "请选择研究院接待人" }]}
            onClick={(_, action: any) => {
              params?.id ? null : action.current?.open();
            }}
          >
            <Picker popup columns={pColumns || []}>
              {(val, selectRow: any) => selectRow?.text || "请选择研究院接待人"}
            </Picker>
          </Form.Item>
          <Form.Item
            name="visitingNum"
            label="参观人数"
            disabled={params?.id ? true : false}
            rules={[{ required: true, message: "请输入参观人数" }]}
          >
            <Input placeholder="请输入参观人数" type="digit" />
          </Form.Item>
          <Form.Item
            // disabled={params?.id ? true : false}
            name="visitingName"
            label="参观人员名单(可上传word、excel、pdf、jpg、png等各类格式的参观人员文件)"
            // rules={[{ required: true, message: "请上传参观人员名单" }]}
          >
            {params.id ? (
              data.visitingName ? (
                <a
                  href="javascript:void;"
                  onClick={() => {
                    let arr = data.visitingName.split("/");
                    let fileName = arr[arr.length - 1];
                    downloadFile(data.visitingName, fileName);
                  }}
                >
                  <Paragraph
                    copyable={
                      data.visitingName
                        ? {
                            text: data.visitingName,
                            tooltips: ["复制", "复制下载链接成功"],
                          }
                        : false
                    }
                  >
                    参观人员名单下载
                  </Paragraph>
                </a>
              ) : (
                "无"
              )
            ) : (
              <FileUploader maxCount={1} />
            )}
          </Form.Item>
          <Form.Item
            name="needGuideType"
            label="讲解员"
            disabled={params?.id ? true : false}
          >
            <Radio.Group iconSize={16}>
              <Radio name={1} checkedColor="#1848fd">
                需要
              </Radio>
              <Radio name={0} checkedColor="#1848fd">
                不需要
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="welcomingSpeechFlag"
            label="大屏欢迎词"
            disabled={params?.id ? true : false}
            // rules={[{ required: true, message: "请选择是否需要大屏欢迎词" }]}
          >
            <Radio.Group iconSize={16}>
              <Radio name={1} checkedColor="#1848fd">
                需要
              </Radio>
              <Radio name={0} checkedColor="#1848fd">
                不需要
              </Radio>
            </Radio.Group>
          </Form.Item>

          {data?.welcomingSpeechFlag ? (
            <Form.Item
              name="welcomingSpeech"
              label=""
              disabled={params?.id ? true : false}
            >
              <Input.TextArea
                rows={4}
                autoSize
                placeholder="请输入大屏欢迎词"
              />
            </Form.Item>
          ) : (
            ""
          )}
          <Form.Item
            name="meeting"
            label="现场会议交流"
            disabled={params?.id ? true : false}
            // rules={[{ required: true, message: "请选择是否需要现场会议交流" }]}
          >
            <Radio.Group iconSize={16}>
              <Radio name={1} checkedColor="#1848fd">
                需要
              </Radio>
              <Radio name={0} checkedColor="#1848fd">
                不需要
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="tableSignFlag"
            label="现场会议桌签"
            disabled={params?.id ? true : false}
            // rules={[{ required: true, message: "请选择是否需要现场会议桌签" }]}
          >
            <Radio.Group iconSize={16}>
              <Radio name={1} checkedColor="#1848fd">
                需要
              </Radio>
              <Radio name={0} checkedColor="#1848fd">
                不需要
              </Radio>
            </Radio.Group>
          </Form.Item>
          {data?.tableSignFlag ? (
            <Form.Item
              name="tableSign"
              label=""
              disabled={params?.id ? true : false}
            >
              <Input.TextArea
                rows={4}
                autoSize
                placeholder="请输入桌签人员名单姓名"
              />
            </Form.Item>
          ) : (
            ""
          )}
          <Form.Item
            name="licensePlate"
            label="车牌"
            disabled={params?.id ? true : false}
          >
            <Input placeholder="请输入车牌" />
          </Form.Item>
          <Form.Item
            name="remarks"
            label="备注"
            disabled={params?.id ? true : false}
          >
            <Input.TextArea rows={4} autoSize placeholder="请输入备注内容" />
          </Form.Item>
        </Form>
      </H5Content>
    </H5Wrapper>
  );
};

export default Index;
