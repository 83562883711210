import { List, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { history, ServerDetailVo } from "umi";
// import InfiniteScroll from "react-infinite-scroll-component"
import {
  ContentContainer,
  ListWrapper,
  SelectorArea,
  ServicesListWrapper,
  TitleWrapper,
} from "../style";
import { ContentInfo } from "./info-item";
import SortItems from "./sort-item";
// import moment from "moment"
// import { payWays } from "@/utils/common"
export interface CusListVo {
  image: string;
  type: number;

  description: string;
  content: () =>
    | React.ReactNode
    | {
        children: React.ReactNode;
        props: any;
      };
}
interface ServiceListProps {
  onItemClick?: (value: any) => void;
  sortby1?: (value: string) => void;
  sortby2?: (value: string) => void;
  sortby3?: (value: string) => void;
  serverList: ServerDetailVo[];
  loadMoreData: (value: any) => void;
  total?: number;
  loading?: boolean;
  currentPage?: number;
}
const ServiceList: React.FC<ServiceListProps> = ({
  onItemClick,
  sortby1,
  sortby2,
  sortby3,
  loadMoreData,
  total = 0,
  currentPage,
  serverList,
  loading,
}) => {
  const [current, setCurrent] = useState(1);
  const [data, setData] = useState<ServerDetailVo[]>(serverList || []);
  useEffect(() => {
    setData(serverList);
  }, [serverList]);

  useEffect(() => {
    if (currentPage) {
      setCurrent(currentPage);
    }
  }, [currentPage]);
  return (
    <ServicesListWrapper>
      <SelectorArea>
        <SortItems
          key={1}
          nosort
          selector={{ name: "默认", value: "" }}
          onChange={(value: any) => {
            sortby1?.("");
          }}
        />
        <SortItems
          key={2}
          nosort
          selector={{ name: "推荐", value: "1" }}
          onChange={(value: any) => {
            sortby2?.("1");
          }}
        />
        <SortItems
          key={3}
          nosort
          selector={{ name: "最新", value: "2" }}
          onChange={(value: any) => {
            sortby3?.("0");
          }}
        />
      </SelectorArea>
      <ListWrapper>
        {/* <InfiniteScroll
          dataLength={data.length}
          next={loadMoreData}
          hasMore={hasMore}
          loader={<></>}
          endMessage={<Divider plain>我是有底线的</Divider>}
        > */}
        <List
          size="large"
          grid={{ gutter: 16, column: 4 }}
          dataSource={data}
          footer={false}
          loading={loading}
          pagination={
            data.length > 0 && {
              current: current,
              total: total,
              pageSize: 20,
              showSizeChanger: false,
              onChange: (page) => {
                setCurrent(page);
                loadMoreData(page);
              },
            }
          }
          renderItem={(item: any) => (
            <ContentInfo item={item} key={item.id} url={item.annex} />
          )}
        />
        {/* </InfiniteScroll> */}
      </ListWrapper>
    </ServicesListWrapper>
  );
};

export default ServiceList;
