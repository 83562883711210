import { message } from "antd";
import { getUserInfo, updateUserInfo } from "@/api/user";
import { getPageQuery, GetUserInfo, SetUserInfo } from "@/utils/common";
import type { Effect, Reducer } from "umi";

export interface UserInfoVo {
  avatar?: string;
  birthday?: string;
  gender?: number;
  id: number;
  mobile?: string;
  nickName?: string;
}
export interface UsersSliceState {
  user: UserInfoVo;
}

interface UsersSliceType {
  namespace: "user";
  state: UsersSliceState;
  effects: {
    getUser: Effect;
    updateUser: Effect;
  };
  reducers: {
    saveUser: Reducer<UsersSliceState>;
  };
}

const UsersSlice: UsersSliceType = {
  // 跟着类型写就完事儿
  namespace: "user",
  state: {
    user: GetUserInfo() || null,
  },
  effects: {
    *getUser({ payload }: any, { call, put }: any) {
      try {
        const accountId = GetUserInfo()?.account_id;
        const res = yield call(getUserInfo, payload ? payload : accountId);
        const params = getPageQuery();
        if (res.code === 1) {
          SetUserInfo({ account_id: res.data.id, ...res.data });
          yield put({
            type: "saveUser",
            payload: res.data,
          });
        }
      } catch (error) {}
    },
    *updateUser({ payload }: any, { call, put }: any) {
      try {
        const user = GetUserInfo();
        const res = yield call(updateUserInfo, { id: user.id, ...payload });
        if (res.code === 1) {
          message.success("更新成功");
          yield put({ type: "getUser" });
          // SetUserInfo({ account_id: res.data.id, ...res.data })
          // yield put({
          //   type: "saveUser",
          //   payload: res.data,
          // })
        }
      } catch (error) {}
    },
  },
  reducers: {
    saveUser(state: any, { payload }: any) {
      state.user = { ...payload };
      return {
        ...state,
      };
    },
  },
};

export default UsersSlice;
