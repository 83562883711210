import styled from "styled-components";
import CaseDetailArrow from "@/assets/images/case/case-detail-arrow.png";
export const CaseDetailWrapper = styled.div`
  .header-container {
    width: 100%;
    min-width: 1440px;
    height: 610px;
    position: relative;
    img {
      width: 100%;
      height: 400px;
    }
    .case-card {
      display: flex;
      position: absolute;
      top: 200px;
      left: 50%;
      margin-left: -600px;
      width: 1200px;
      /* height: 320px; */
      padding: 37px 57px 30px 30px;
      box-sizing: border-box;
      background: #ffffff;
      border-radius: 5px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
      .img {
        margin-top: 10px;
        margin-right: 39px;
        width: 130px;
        min-height: 50px;
        max-height: 100px;
      }
      .case-card-title {
        margin-bottom: 20px;
        font-size: 30px;
        line-height: 42px;
        font-family: PingFangSC, PingFangSC-Semibold;
      }
      .des {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        margin-bottom: 28px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #666666;
      }
      .case-title {
        margin-bottom: 16px;
        font-size: 24px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        color: #000000;
      }
    }
    .case-card-content {
      width: 910px;
    }
    .percent-list {
      display: flex;
      justify-content: space-between;
      padding: 0 55px;
      font-size: 30px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #000000;
      .value {
      }
      .label {
        font-size: 14px;
      }
    }
  }
`;

export const CaseInfoWrapper = styled.div`
  margin: 0 auto;
  width: 1200px;
  .case-title {
    margin-bottom: 28px;
    position: relative;
    padding-left: 30px;
    &::before {
      position: absolute;
      content: "";
      top: 2px;
      left: 0;
      width: 10px;
      height: 49px;
      background: #1848fd;
    }
  }
  .case-intro {
    margin-bottom: 104px;
    .case-intro-content {
      display: flex;
      align-items: center;
      background: #141561;
      border-radius: 2px;
      justify-content: space-between;
    }

    .case-img {
      width: 50%;
      height: 452px;
      border-radius: 0 0 2px 2px;
    }
    .right-content {
      margin-right: 36px;
      padding: 35px 26px 30px 36px;
      width: 530px;
      height: 385px;
      box-sizing: border-box;
      background: rgba(234, 241, 251, 0.16);
      border-radius: 2px;
      backdrop-filter: blur(4px);
    }
    .right-content-item {
      color: #ffffff;
      &.mab26 {
        margin-bottom: 26px;
      }
      .right-content-item-title {
        position: relative;
        margin-bottom: 12px;
        font-size: 24px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        &::before {
          content: "";
          position: absolute;
          left: 64px;
          top: 12px;
          width: 92px;
          height: 15px;
          background: url(${CaseDetailArrow}) no-repeat center;
          background-size: 100%;
        }
      }
      .right-content-item-intro {
        display: -webkit-box;
        word-break: break-all;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
        font-size: 14px;
        font-family: Alibaba, Alibaba-Regular;
        font-weight: 400;
      }
    }
  }
  .case-solute {
    margin-bottom: 213px;
    .case-title {
      margin-bottom: 38px;
    }
    .case-content {
      padding: 45px 40px;
      background: #f8f8f8;
      border-radius: 2px;
      font-size: 24px;
      font-family: Alibaba, Alibaba-Regular;
      font-weight: 400;
      text-align: left;
      color: #666666;
    }
  }
`;
