import { useEffect, useState } from "react";
import { CusButton, EditInfoWrapper } from "../style";

import { replaceId } from "@/utils/common";
interface EditInfoProps {
  data: listVo[];
}
interface listVo {
  icon: string;
  title: string;
  info: string;
  show?: boolean;
  onClick?: () => void;
  renderRight?: () =>
    | React.ReactNode
    | {
        children: React.ReactNode;
        props: any;
      };
}
const EditInfo: React.FC<EditInfoProps> = ({ data }) => {
  const [list, setlist] = useState<listVo[]>();
  useEffect(() => {
    if (Array.isArray(data)) {
      setlist(data);
    }
  }, [data]);
  return (
    <EditInfoWrapper style={{ width: "815px" }}>
      {list &&
        list.map((item, index) => {
          return (
            item.show && (
              <div
                className="edit-info-item"
                key={index}
                onClick={() => item.onClick?.()}
              >
                <div className="edit-info-container">
                  <div className="edit-info-icon">
                    <img src={item.icon} />
                  </div>
                  <div className="edit-info-wrap">
                    <div className="edit-info-title">{item.title}</div>
                    <div className="edit-info-info">{item.info}</div>
                  </div>
                </div>
                <div className="edit-info-render">{item.renderRight?.()}</div>
              </div>
            )
          );
        })}
    </EditInfoWrapper>
  );
};

export default EditInfo;
