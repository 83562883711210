import { Modal, message } from "antd";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { CusUplpad } from "./style";
import ImgCrop from "antd-img-crop";
import { GetToken } from "@/utils/common";
interface uploadProps {
  maxCount?: number;
  onChange?: (value: any) => void;
  value?: any;
  noCrope?: boolean; // 裁剪图片
  defaultUrl?: string;
  width?: number;
  height?: number;
}
function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
const FileUpload: React.FC<uploadProps> = ({
  maxCount = 1,
  onChange,
  value,
  defaultUrl = "",
  height,
  width,
  noCrope = true,
}) => {
  const [preview, setPreview] = useState(false);
  const [img, setImg] = useState("");
  const [title, setTitle] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>(
    value
      ? [
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            url: value,
          },
        ]
      : []
  );
  useEffect(() => {
    if (value) {
      setFileList([
        {
          uid: "-1",
          name: "image.png",
          status: "done",
          url: value,
        },
      ]);
      onChange?.(value);
    }
  }, [value]);
  const onRemove = (file: any) => {};
  const beforeUpload = async (file: RcFile, FileList: RcFile[]) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif" ||
      file.type === "image/webp";
    if (!isJpgOrPng) {
      message.error("图片格式不对");
    }

    const isLt4M = file.size / 1024 / 1024 < 4;
    if (!isLt4M) {
      message.error("图片尺寸不对");
    }
    return isJpgOrPng && isLt4M;
    // const flag = await checkImage(file, 114, 114);
    // } else {
    //   if (!isJpgOrPng) {
    //     setShowPreview(false);
    //   } else {
    //     setShowPreview(true);
    //   }
    // }
  };
  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    console.log("newFileList", newFileList);
    const list = newFileList.filter((item: any) => item.status === "done");
    if (Array.isArray(list) && list.length > 0 && list[0].response?.data) {
      onChange?.(list[0].response.data);
    } else {
      onChange?.("");
    }
  };
  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setImg(file.url || file.preview);
    setTitle("预览");
    setPreview(true);
  };
  return (
    <>
      {noCrope ? (
        <CusUplpad
          width={width}
          height={height}
          url={defaultUrl}
          action={process.env.BASE_URL + "/account/file/upload/file"}
          listType="picture-card"
          fileList={fileList}
          onChange={handleChange}
          onPreview={handlePreview}
          beforeUpload={beforeUpload}
          onRemove={onRemove}
          maxCount={maxCount}
          headers={{ Authorization: `bearer ${GetToken()}` }}
          showUploadList={{ showPreviewIcon: false }}
        >
          {fileList.length < maxCount ? (
            defaultUrl ? (
              <></>
            ) : (
              <PlusOutlined style={{ fontSize: "28px", color: "#999" }} />
            )
          ) : (
            ""
          )}
        </CusUplpad>
      ) : (
        <ImgCrop rotate>
          <CusUplpad
            width={width}
            height={height}
            action={process.env.BASE_URL + "/account/file/upload/file"}
            listType="picture-card"
            fileList={fileList}
            onChange={handleChange}
            onPreview={handlePreview}
            beforeUpload={beforeUpload}
            onRemove={onRemove}
            headers={{ Authorization: `bearer ${GetToken()}` }}
            maxCount={maxCount}
          >
            {fileList.length < maxCount ? (
              defaultUrl ? (
                <></>
              ) : (
                <PlusOutlined style={{ fontSize: "28px", color: "#999" }} />
              )
            ) : (
              ""
            )}
          </CusUplpad>
        </ImgCrop>
      )}

      <Modal
        visible={preview}
        title={title}
        footer={null}
        onCancel={() => setPreview(false)}
      >
        <img alt="二维码" style={{ width: "100%" }} src={img} />
      </Modal>
    </>
  );
};

export default FileUpload;
