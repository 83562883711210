import { NavLeftWrapper } from "../style";
import { Input } from "antd";
import CusCard from "./cus-card/cus-card";
import { useDispatch, useSelector } from "umi";
import { useState } from "react";
interface NavLeftProps {
  showSearch: boolean;
}

const { Search } = Input;
const NavLeft: React.FC<NavLeftProps> = ({ showSearch = true }) => {
  const dispatch = useDispatch();
  const [val, setValue] = useState<any>(52);
  const onSearch = (value: any) => {
    if (value) {
      setValue(4);
    } else {
      setValue(1);
    }

    dispatch({ type: "park/savebackNumber", payload: value ? 4 : 0 });
    dispatch({ type: "park/savesearch", payload: value ? value : null });
  };
  return (
    <>
      <NavLeftWrapper>
        {/* {showSearch && (
          <Search
            placeholder="请输入"
            allowClear
            enterButton
            size="large"
            onSearch={onSearch}
          />
        )} */}

        <CusCard val={val} />
        {/** 城市搜索**/}
      </NavLeftWrapper>
    </>
  );
};

export default NavLeft;
