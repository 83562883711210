import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "umi";
import { CusTabPane, CusTabs } from "../../style";
import ResultList from "../result-list/index1";

interface ParkCompanyListProps {}

export const ParkCompanyList: React.FC<ParkCompanyListProps> = () => {
  const [tabs] = useState([
    { title: "工业园区", key: "1" },
    { title: "企业", key: "2" },
  ]);
  const [currentId, setCurrentId] = useState<string>("1");
  const { parkInfo } = useSelector((state: any) => state.park);
  // const dispatch = useDispatch()

  return (
    <CusTabs
      onChange={(key) => {
        setCurrentId(key);
      }}
    >
      {tabs &&
        tabs.map((item) => (
          <CusTabPane tab={item.title} key={item.key}>
            <ResultList
              key={currentId}
              currentId={currentId}
              height={600}
              onItemClick={(value, index) => {
                // itemClick?.(value, currentId == "2" ? 0 : index)
              }}
            ></ResultList>
          </CusTabPane>
        ))}
    </CusTabs>
  );
};
