import request from "@/utils/request";
//临平站-查询龙头企业
export const getheadCompany = () => {
  return request("/business/company/headCompany", {
    method: "get",
    requestType: "json",
  });
};
//临平站-查询优选服务供应商
export const getheadSupplier = () => {
  return request("/business/service/supplier/headSupplier", {
    method: "get",
    requestType: "json",
  });
};

//business/company/scaleCompany

export const getScaleCompany = () => {
  return request("/business/company/scaleCompany", {
    method: "get",
    requestType: "json",
  });
};
