import { BtnWrapper, Header } from "./style";
import { Row, Input } from "antd";
import { history, useSelector } from "umi";
import { CusButton } from "@/styles/common-style";
import "../quill.snow.css";
import { HeaderTitle } from "../header-title";
import HeaderLogin from "../header-login";
import { GlobalStyle } from "@/styles/global";
interface CusHeaderProps {
  title: string;
  showSearch?: boolean;
  type?: string;
  placeholder?: string;
  val?: any;
  search?: (value: string) => void;
}
const CusHeader: React.FC<CusHeaderProps> = ({
  title = "",
  showSearch,
  search,
  type = "",
  val = "",
  placeholder = "请输入你需要的服务",
}) => {
  const user = useSelector((state: any) => state.login.state);
  const onSearch = (value: any, event: any) => {
    search?.(value);
  };
  return (
    <Header>
      <GlobalStyle />
      <Row>
        <HeaderTitle hasSelect={false} />
        <div className="header-center">
          <div className="header-title">{title}</div>
          {showSearch && (
            <div className="header-search">
              <Input.Search
                size="large"
                defaultValue={val}
                placeholder={placeholder}
                enterButton="搜索"
                onSearch={onSearch}
              />
            </div>
          )}
        </div>
      </Row>
      {/**按钮区域**/}
      {type === "regist" ? (
        <BtnWrapper className="regist">
          <span className="regist-text">已有账号？</span>
          <CusButton
            type="link"
            onClick={() => {
              history.push("/login");
            }}
          >
            请登录
          </CusButton>
        </BtnWrapper>
      ) : (
        <BtnWrapper>
          {/* {!user?.isLogin ? (
           
          ) : (
            <>
              <Button
                type='primary'
                onClick={() => {
                  history.push("/regist")
                }}
              >
                注册
              </Button>
              <Button
                onClick={() => {
                  history.push("/login");
                }}
              >
                登录
              </Button>
            </>
          )} */}
          <HeaderLogin />
        </BtnWrapper>
      )}
    </Header>
  );
};

export default CusHeader;
