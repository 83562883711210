import ShowModal from "@/components/showModal";
import { Title } from "@/styles/common-style";
import { Row, Button } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import { useState } from "react";
import { CatesListWrapper, CusCard } from "../style";
import { history } from "umi";
import { ContactUs } from "@/utils/common";
interface CatesItemProps {
  item: any;
}
const CatesItem: React.FC<CatesItemProps> = ({ item }) => {
  const [visible, setVisible] = useState(false);
  const [img, setImg] = useState("");
  return (
    <>
      <CusCard
        hoverable
        onClick={() => {
          // item.img && setVisible(true);
          // item.img && setImg(item.img);
          item.path && history.push(item.path);
        }}
      >
        <div className="card-img">
          <img src={item.icon} alt={item.icon} />
        </div>
        <div className="card-content">
          <Title fz={16} fw={500}>
            {item.title}
          </Title>
          <Title className="sub-title" fz={14} fw={400}>
            {item.intro}
          </Title>
        </div>
      </CusCard>
      {/* <ShowModal
        onCancel={() => {
          setVisible(false);
        }}
        visibleModal={visible}
        img={img}
      /> */}
    </>
  );
};
interface CatesListProps {
  data: any;
}
const CatesList: React.FC<CatesListProps> = ({ data }) => {
  return (
    <>
      <Row className="product-server-right-row" justify="space-between">
        <Title fw={500} fz={24} className="product-server-right-title">
          {data.label}
        </Title>
        <Button
          type="link"
          onClick={() => {
            if (data.path) {
              window.open(process.env.LOCATION_URL + "#" + data.path);
            }
          }}
        >
          查看更多
          <DoubleRightOutlined />
        </Button>
      </Row>
      <div className="product-server-right-info">{data.info}</div>
      <CatesListWrapper>
        {data.item &&
          data.item.map((item: any, index: number) => (
            <CatesItem item={item} key={index} />
          ))}
      </CatesListWrapper>
    </>
  );
};

export default CatesList;
