import styled from "styled-components";
import footer_bg from "@/assets/images/data-assets/footer_bg.png";
export const DataAssetsWrapper = styled.div``;

export const DataInfoWrapper = styled.div`
  width: 1200px;
  margin: 96px auto 106px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .icon {
    margin-bottom: 14px;
    width: 70px;
    height: 64px;
  }
  .card-intro {
    font-family: Alibaba, Alibaba-Regular;
    font-weight: 400;
    text-align: center;
    color: #666666;
  }
`;

export const DataDescWrapper = styled.div`
  width: 1200px;
  margin: 0 auto 90px;
  .data-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

export const DataDescItem = styled.div<{ bg?: string }>`
  position: relative;
  width: 590px;
  height: 320px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  padding: 56px 40px 28px 52px;
  box-sizing: border-box;
  /* padding-left: 190px; */
  /* align-items: center; */
  /* justify-content: center; */
  background: url(${(props) => props.bg}) no-repeat center;
  background-size: 100%;
  .desc {
    font-size: 18px;
    font-family: Alibaba, Alibaba-Regular;
    font-weight: 400;
    text-align: left;
    color: #666666;
    line-height: 32px;
  }
  .look-more {
    position: absolute;
    bottom: 28px;
    right: 40px;
    width: 100px;
    height: 40px;
    background: #3460f2;
    border-radius: 2px;
    box-shadow: 0px 2px 7px 2px rgba(27, 71, 215, 0.3);
  }
`;

export const ProductInfoWrapper = styled.div`
  width: 1100px;
  margin: 0 auto 90px;
  .title-info {
    margin-bottom: 10px;
    width: 182px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 30px;
      height: 30px;
    }
  }
  .product-list {
    display: flex;
    flex-wrap: wrap;
  }
  .product-item {
    width: 500px;
    height: 100px;
    margin-right: 86px;
    margin-bottom: 30px;
    &:nth-child(2n) {
      margin-right: 0;
    }
    .product-item-desc {
      font-size: 18px;
      font-family: Alibaba, Alibaba-Regular;
      font-weight: 400;
      line-height: 36px;
      color: #666666;
    }
  }
`;

export const ApplyWrapper = styled.div`
  width: 100%;
  min-width: 1440px;
  height: 182px;
  background: url(${footer_bg}) no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .ant-btn {
    margin-top: 34px;
    width: 149px;
    height: 40px;
    background: #3460f2;
    border-radius: 2px;
    box-shadow: 0px 2px 7px 2px rgba(27, 71, 215, 0.3);
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
  }
`;
