import { useState } from "react";
import { ServerInfoVo, useSelector } from "umi";
import { TagSelectorContainer } from "../style";
import TagList from "./tag-list";
interface TagSelectorProps {
  serverClick?: (value: string) => void;
  subserverClick?: (value: string) => void;
  payCateClick?: (value: string) => void;
  priceCateClick?: (value: string) => void;
}
const TagSelector: React.FC<TagSelectorProps> = ({
  serverClick,
  subserverClick,
  payCateClick,
  priceCateClick,
}) => {
  const [showService, setShowService] = useState<boolean>(false);
  const { knowledgeCates } = useSelector((state: any) => state.knowledge);
  const [childList, setChildList] = useState<ServerInfoVo[]>([]);
  const [val, setVal] = useState<string>("0");

  return (
    <TagSelectorContainer>
      <div className="tag-area">
        <TagList
          title="知识分类"
          data={knowledgeCates}
          tagClick={(value: string) => {
            if (value === "-1") {
              setShowService(false);
            } else {
              setShowService(true);
              setVal(value);
              setChildList(
                knowledgeCates.filter(
                  (item: ServerInfoVo) => item.id === value
                )[0].childCateList
              );
            }
            serverClick?.(value);
          }}
        />
      </div>
      {showService && (
        <div className="tag-area">
          <TagList
            key={childList[0]?.id || 0}
            title="二级分类"
            data={childList}
            tagClick={(value: string) => {
              subserverClick?.(value == "-1" ? val : value);
            }}
          />
        </div>
      )}
      {/* <div className="tag-area">
        <TagList
          title="交付方式"
          data={payWays}
          tagClick={(value: string) => {
            payCateClick?.(value);
          }}
        />
      </div>
      <div className="tag-area">
        <TagList
          title="价格"
          tagClick={(value: string) => {
            priceCateClick?.(value);
          }}
        />
      </div> */}
    </TagSelectorContainer>
  );
};

export default TagSelector;
