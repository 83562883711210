import { useEffect, useState } from "react";
import { Tabs, Button } from "antd";
import { CateWrapper, CusTabWrapper, ListCardWrapper } from "../style";
// import { CusTag } from "@/styles/common-style";
interface CatesProps {
  title: string;
  bgColor?: string;
  data?: any;
  type?: number;
  tabs: any;
  TabClick?: (value: number) => void;
  itemClick?: (value: any) => void;
}
export interface CatesList {
  introduction: ReactNode;
  serviceName: string;
  serviceCateId: number;
  serviceCateName: string;
  serviceSupplierId: number;
  serviceSupplierName: string;
  versionName: string;
  serviceWebUrl: string;
  brief: string;
  poster: string;
  priceType: number;
  price: number;
  deliveryMethodName: string;
  priceCompany: number;
  deliveryMethod: number;
}
interface ListCardProps {
  data: CatesList[];
  bgColor?: string;
  type?: number;
  itemClick: (value: any) => void;
}
export interface DataSource {
  serviceName: string;
  serviceCateId: number;
  serviceCateName: string;
  serviceSupplierId: number;
  serviceSupplierName: string;
  versionName: string;
  serviceWebUrl: string;
  brief: string;
  poster: string;
  priceType: number;
  price: number;
  deliveryMethodName: string;
  priceCompany: number;
  deliveryMethod: number;
}
const getItemType = (data: any) => {
  // switch (data.priceType) {
  // case 2:
  //   return "询价";
  // case 1:
  //   return "免费";
  // case 3:
  //   return data.price + (data.priceCompany == 1 ? "元/年" : "元/次");
  // default:
  return "";
  // }
};
const ListCard: React.FC<ListCardProps> = ({
  data,
  bgColor,
  type,
  itemClick,
}) => {
  const [tags, setTags] = useState<any>([
    { title: "精确长久" },

    { title: "稳定" },
    { title: "吸附能力强" },
    { title: "真空吸附方式" },
  ]);
  return (
    <>
      {data && data.length > 0 ? (
        <ListCardWrapper>
          {/**图片引导**/}
          <>
            <div className="img-container">
              {data[0] && (
                <div onClick={() => itemClick?.(data[0])}>
                  <img
                    src={
                      bgColor
                        ? require("@/assets/images/server/bg2.png")
                        : require("@/assets/images/server/bg1.png")
                    }
                  />
                  <div className="cate-info">
                    <div className="cate-title ">{data[0].serviceName}</div>
                    <div className="cate-intro "> {data[0].introduction}</div>
                    <div className="cate-type">{getItemType(data[0])}</div>
                    {/* {tags &&
              tags.map((tag: any, index: number) => (
                <CusTag Color='#1848fd' bgColor='#fff' key={index}>
                  {tag.title}
                </CusTag>
              ))} */}
                    <Button className="look-more">查看更多</Button>
                  </div>
                </div>
              )}
            </div>
            {data && (
              <CateWrapper
                bgColor={bgColor}
                len={data.slice(1).length > 7 ? 7 : data.slice(1).length}
                type={type}
              >
                {data &&
                  data.length > 0 &&
                  data[1] &&
                  (data.slice(1).length > 7
                    ? data.slice(1).slice(0, 7)
                    : data.slice(1)
                  ).map((item, index) => {
                    return (
                      <div
                        className="item-cate"
                        key={index}
                        onClick={() => itemClick?.(item)}
                      >
                        <div>
                          <div className="cate-title cate-sub-title  ">
                            {item.serviceName}
                          </div>
                          <div
                            className={
                              data.slice(1).length == 3
                                ? "cate-intro "
                                : "cate-intro truncate "
                            }
                          >
                            {item.introduction}
                          </div>
                        </div>
                        <div className="cate-type">{getItemType(item)}</div>
                      </div>
                    );
                  })}
              </CateWrapper>
            )}
          </>
        </ListCardWrapper>
      ) : (
        <div style={{ color: "#999" }}>暂无数据</div>
      )}
    </>
  );
};

const Cates: React.FC<CatesProps> = ({
  title,
  data,
  bgColor,
  type,
  tabs,
  TabClick,
  itemClick,
}) => {
  const [dataSource, setDataSource] = useState<DataSource[]>([]);
  useEffect(() => {}, []);
  const { TabPane } = Tabs;
  const onTabClick = (key: any) => {
    TabClick?.(tabs[key]);
  };
  useEffect(() => {
    if (Array.isArray(data)) {
      setDataSource(data);
    }
  }, [data]);
  return (
    <CusTabWrapper>
      <div className="title">{title}</div>
      <Tabs defaultActiveKey="0" animated onTabClick={onTabClick}>
        {tabs &&
          tabs.map((item: any, index: number) => (
            <TabPane tab={item.metadataName} key={index}>
              <ListCard
                data={dataSource}
                itemClick={(value) => itemClick?.(value)}
                bgColor={bgColor}
                type={type}
              />
            </TabPane>
          ))}
      </Tabs>
    </CusTabWrapper>
  );
};

export default Cates;
