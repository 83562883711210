import BaseFooter from "@/layouts/base-footer";
import CusHeader from "@/layouts/cus-header";
import BaseInfo from "./base-info";
import PersionCommon from "./components/common";
import { ContentWrapper, Wrap } from "./style";

interface PersonProps {}
const Person: React.FC<PersonProps> = () => {
  return (
    <>
      <CusHeader title="个人中心" showSearch={false}></CusHeader>
      <ContentWrapper>
        <Wrap>
          <PersionCommon></PersionCommon>
          <BaseInfo />
        </Wrap>
      </ContentWrapper>
      <BaseFooter />
    </>
  );
};

export default Person;
