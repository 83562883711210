// server_detail

import { CollectCreate, getProductDetail } from "@/api/server";
import BaseFooter from "@/layouts/base-footer";
import CusHeader from "@/layouts/cus-header";
import { useEffect } from "react";
import { history } from "umi";
import { useDispatch, useParams, useSelector } from "umi";
import ServerContent from "./components/content";
import HeaderInfo from "./components/header-info";
import { ServerDetailContainer } from "./style";

interface ServerDetailProps {}
const ServerDetail: React.FC<ServerDetailProps> = () => {
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.user);
  useEffect(() => {
    const p = {
      id: params.id,
      accountId: user?.id || 0,
    };
    if (params.id) {
      getProductDetail(p).then((res: any) => {
        if (res.code == 1) {
          dispatch({
            type: "server/saveDetails",
            payload: res.data,
          });
        }
      });
    }
  }, []);

  return (
    <>
      <CusHeader
        title={"服务中心"}
        showSearch
        search={(value) =>
          history.push({
            pathname: "/server/all-service",
            state: {
              keyword: value,
            },
          })
        }
      />
      <ServerDetailContainer>
        <HeaderInfo />
        <ServerContent />
      </ServerDetailContainer>
      <BaseFooter />
    </>
  );
};

export default ServerDetail;
