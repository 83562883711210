import styled from "styled-components";
import { Row, Tabs, Card, Pagination } from "antd";

const { TabPane } = Tabs;
const { Meta } = Card;
export const CaseWrapper = styled.div`
  .header-container {
    position: relative;
    width: 100%;
    min-width: 1440px;
    /* height: 400px; */
    img {
      width: 100%;
      height: 100%;
    }
    .container-info {
      width: 1200px;
      position: absolute;
      top: 50%;
      margin-top: -92px;
      left: 50%;
      margin-left: -600px;
      .title {
        font-family: "RuiZiRuiXianNuFangHeiJian";
        font-size: 80px;
        font-weight: 400;
        line-height: 1;
        color: #ffffff;
      }
      .sub-info {
        font-size: 36px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        color: #ffffff;
        letter-spacing: 10px;
      }
      .desc {
        font-size: 18px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
        color: #ffffff;
        margin-bottom: 20px;
      }
    }
  }
`;

export const CaseListWrapper = styled.div`
  margin: 0 auto;
  width: 1200px;
  .ant-divider-horizontal.ant-divider-with-text::before,
  .ant-divider-horizontal.ant-divider-with-text::after {
    transform: translateY(0%);
  }
`;

export const CaseTabs = styled(Tabs)`
  margin-top: 64px;
  margin-bottom: 220px;
  .ant-tabs-nav-wrap {
    /* background-color: #eaf1fb; */
    height: 300px;
  }
  &.ant-tabs-left > .ant-tabs-content-holder {
    border: none;
  }
  &.ant-tabs-top > .ant-tabs-nav::before {
    border: none;
  }
  &.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
    border-bottom-color: #f0f0f0;
  }
  & .ant-tabs-nav .ant-tabs-tab {
    display: flex;
    margin: 0 !important;
    align-items: center;
    /* flex: 1; */
    height: 60px;
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: center;
    background-color: #eaf1fb;
    color: #333333;
    .ant-tabs-tab-btn {
      margin: 0 auto;
    }
    &.ant-tabs-tab-active {
      background: #1848fd;
    }

    &:last-child {
      margin: 0;
    }
  }
  .ant-tabs-ink-bar {
    background-color: transparent;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-size: 24px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    color: #ffffff;
  }
  .ant-tabs-nav {
    .ant-tabs-nav-list {
      padding: 0;
      /* width: 1200px; */
      width: 220px;
      height: 300px;
    }
    .ant-tabs-tab {
      height: 60px;
    }
  }
`;

export const CasePane = styled(TabPane)`
  .no-data {
    display: flex;
    flex: 1;
    height: 300px;
    align-items: center;
    justify-content: center;
  }
`;

export const CaseCard = styled(Card)`
  width: 222px;
  height: 270px;
  margin-right: 16px;
  margin-bottom: 34px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  &:nth-child(4n) {
    margin-right: 0;
  }
  .ant-card-cover {
    img {
      width: 222px;
      height: 166px;
    }
  }
  .ant-card-body {
    padding: 12px 17px 0 17px;
  }
  &:hover .ant-card-meta-description {
    color: #1848fd;
  }
`;
export const CaseMeta = styled(Meta)`
  .ant-card-meta-description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    padding-right: 6px;
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    color: #000000;
  }
`;

export const CasePagination = styled(Pagination)`
  text-align: center;
  .ant-pagination-item {
    width: 32px;
    height: 32px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    a {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
    }
  }
  .ant-pagination-item-active {
    background: #1848fd;
    border-radius: 2px;
    a {
      color: #ffffff;
    }
  }
`;
