import { base_image_oss } from "@/utils/common";
import { BannarWrap } from "./style";

// bannae_logo.png
interface BannarProps {}
export const Bannar: React.FC<BannarProps> = () => {
  return (
    <BannarWrap>
      <img
        src={base_image_oss + "home/bannar_logo.png"}
        alt=""
        onClick={() => {
          window.open("https://www.kornit.com.cn/");
        }}
      />
    </BannarWrap>
  );
};
