import { Title } from "@/styles/common-style";

export const Provicy = () => {
  return (
    <div className="content-wrapper">
      {/* <Title textAlign='center'>橙织数字时尚产业创新平台隐私政策</Title> */}
      <p> 协议版本：v1.0</p>
      <p> 发布日期：2022-07-07</p>
      <p>生效日期：2022-07-07  </p>
      <p>
        橙织数字时尚产业创新平台（以下简称“我们”）非常重视您的信任，并深知个人信息对于您的重要性，我们将按照法律法规的规定，保护您的个人信息安全。{" "}
        <b>
          我们将恪守以下原则，保护您的个人信息安全：权责一致原则、目的明确原则、选择同意原则、最小必要原则、确保安全原则、主体参与原则、公开透明原则等。
          【特别提示】请您仔细阅读我们的《橙织数字时尚产业创新平台隐私政策》（尤其是加粗或划线的内容）并确定了解我们对您个人信息的处理规则。阅读过程中，如您有任何疑问，可及时与我们联系（联系方式以隐私政策里约定的为准）。如您选择不同意协议中的任何条款，您应立即停止访问本平台。
          橙织数字时尚产业创新平台非常重视用户的隐私和个人信息保护。您在使用我们的产品与/或服务时，我们可能会收集和使用您的相关信息。我们希望通过《橙织数字时尚产业创新平台隐私政策》（以下简称“本隐私政策”）向您说明在您使用我们的产品与/或服务时，我们如何收集、使用、保存、共享和转让这些信息，以及我们为您提供的访问、更新、控制和保护这些信息的方式。
          为了保护橙织数字时尚产业创新平台用户隐私权，优化用户体验，橙织数字时尚产业创新平台制定本隐私政策。本隐私政策旨在帮助您了解我们会收集哪些数据、为什么收集这些数据，会利用这些数据做些什么及如何保护这些数据。我们希望您在使用橙织数字时尚产业创新平台服务前仔细阅读并明确您已经充分理解、接受本隐私政策的内容，希望您可以根据自己的理解做出合适的选择。您同意隐私政策仅代表您已了解本平台提供的功能，以及功能运行所需的必要个人信息，并不代表您已同意我们可以收集非必要个人信息，非必要个人信息会根据您使用过程中的授权情况单独征求您的同意。在我们更新本隐私政策后，您继续使用我们的产品和/或服务，即意味着您同意本隐私政策（含更新版本）内容，并且同意我们按照本隐私政策收集、使用、保存和共享您的相关信息。此外，当我们将您的个人信息用于本隐私政策未涵盖的用途时，我们会事先征求您的同意。请您特别注意：本隐私政策适用于我们以平台、客户端、小程序以及随技术发展出现的新形态向您提供的各项产品和服务。
          下文将帮您详细了解我们如何收集、使用、存储、委托处理、共享、转让（如适用）、公开披露与保护您的个人信息。并且帮您了解访问、删除、更正、撤回授权个人信息的方式。
        </b>
      </p>
      <p>  一、我们如何收集和使用您的个人信息</p>
      <p>
         
        在您使用橙织数字时尚产业创新平台提供的服务过程中，我们将收集以下必要的相关个人信息，以及部分您可以选择提供的个人信息。
      </p>
      <p>  1、注册、登录与个人信息维护</p>
      <p>
         
        第一：当您注册、登录本服务时，您需要通过手机号或邮箱、密码创建橙织帐号，并且您可以完善相关的网络身份识别信息（头像、昵称、密码）。如果您仅浏览橙织官网，您不需要注册橙织帐号并提供上述信息。
      </p>
      <p>
         第二：当您代表客户注册本服务时，您可能还需提供企业/组织主体证照等基本信息，以便我们帮助客户完成认证。您确认已获得这类客户的充分授权签署并同意本隐私政策的全部内容。
      </p>
      <p>
         
        第三：当您进行个人信息维护时，您可能需要提供个人电话号码、住址、邮箱地址、所在行业、职位、公司名称、公司地址、公司规模，但是此类信息并非必填项。
      </p>
      <p>  2、短信、邮件通知</p>
      <p>
         您在使用我们的服务时，我们可能使用您的信息向您的设备发送短信、电子邮件、新闻或推送通知。如您不希望收到这些信息，可以按照我们的相关提示，在设备上选择取消订阅。
      </p>
      <p>
          3、用户评价
        在您进行用户评价内容填写时，我们会收集您的ip地址信息、手机号。
      </p>
      <p> 4、解决方案咨询留言</p>
      <p>
         
        当您需要咨询解决方案时，您需要提供联系人姓名、联系手机号、公司名称、所属行业、联系邮箱地址、国家省市区相关的信息。
      </p>
      <p>  5、文档下载</p>
      <p>
         当您进行文档下载时，我们会获取您的姓名、个人电话号码、公司名称相关的信息。
      </p>
      <p>  6、用户登录日志</p>
      <p>
         为了更好的向您提供服务，我们会收集您的用户登录日志，包括：数据来源、用户名、手机号信息。
      </p>
      <p>
          7、建议反馈 当您提供建议反馈时，我们会收集您的手机信息。 8、供需发布
      </p>
      <p>
         当您作为企业进行供需发布时，我们会收集您的联系人姓名、联系手机号、公司名称、所属行业信息。
      </p>
      <p> 9、为您提供安全保障</p>
      <p>
         为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或橙织相关协议规则的情况，我们可能使用或整合您的用户信息、消费信息、设备信息、有关网络日志以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
      </p>
      <p>  12、其他用途</p>
      <p>
         我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。
      </p>
      <p>  13、征得授权同意的例外</p>
      <p>
         根据相关法律法规的规定，在以下情形中，我们可以在不征得您的授权同意的情况下收集、使用一些必要的个人信息：
      </p>
      <p>  （1）为订立、履行您作为一方当事人的合同所必需；</p>
      <p> （2）为履行法定义务所必需；</p>
      <p>
         
        （3）为应对突发公共卫生事件，或者紧急情况下为保护个人的生命健康和财产安全所必需；
      </p>
      <p>
         （4）为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；
      </p>
      <p> （5）在合理的范围内处理您自行公开或者其他已经合法公开的个人信息；</p>
      <p> （6）法律、行政法规规定的其他情形。</p>
      <p>
         
        根据法律规定，共享、转让经去匿名化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
      </p>
      <p>
         <b>二、我们如何使用Cookie和同类技术</b>
      </p>
      <p>
         为确保平台正常运转，我们会在您的计算机或移动设备上存储名为cookie的数据文件。Cookie通常包含用户身份标识符、城市名称以及一些字符。Cookie主要的功能是便于您使用平台产品和服务，以及帮助平台统计访问数据等。
        我们不会将cookie用于本《橙织数字时尚产业创新平台隐私政策》所述目的之外的任何用途。您可以根据自己的偏好管理或删除cookie。有关详情，可以浏览aboutcookies.org平台。您可以清除计算机上保存的所有cookie，大部分网络浏览器都设有阻止cookie的功能。如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置，您可能无法使用由我们提供的、依赖于Cookie的服务或相应功能。您可以通过更改您的浏览器设置限制我们对Cookie的使用。以Chrome浏览器为例，您可以在Chrome浏览器右上方的下拉菜单的“浏览器设置”中，通过（“【设置】-【高级】-【清除浏览数据】”），选择“【清除您的Cookie】”。
      </p>
      <p>
        <b> 三、我们如何委托处理、共享、转让、公开披露您的信息</b>
      </p>
      <p>1、委托处理</p>
      <p>
         本业务功能中某些具体的模块或功能由外部供应商提供。例如我们会聘请服务提供商来协助我们提供客户支持。
        对我们委托处理个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的要求、个人信息保护政策以及其他任何相关的保密和安全措施来处理个人信息。
      </p>
      <p>  2、共享</p>
      <p>
        <b>
           我们承诺对您的信息进行严格保密，我们不会与任何公司、组织或个人共享您的信息，但以下情况除外：
        </b>
      </p>
      <p>
         （1）在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息；
      </p>
      <p>
         （2）共享的个人信息是去匿名化化处理后的脱敏信息，且共享第三方无法重新识别此类信息的自然人主体；
      </p>
      <p>
         （3）在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息；
      </p>
      <p>
         
        （3）为了保护信息主体或其他个人生命、财产或社会公众的利益免遭受损害而有必要提供您的信息给第三方；
      </p>
      <p>
         
        <b>
          （4）与我们关联的公司共享。您的信息可能会在我们的关联公司之间共享。我们只会共享必要的信息，且这种共享受本政策的约束。关联公司如要改变信息的处理目的，将再次征求您的授权同意；
        </b>
      </p>
      <p>
         （5）与授权合作伙伴共享：仅为实现本隐私权政策中声明的目的，我们的某些服务将由我们和授权合作伙伴共同提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。例如安排合作伙伴提供服务。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于与产品或服务无关的其他用途。
      </p>
      <p> 目前，我们的授权合作伙伴包括以下类型：</p>
      <p>
         
        ·广告、分析服务类的授权合作伙伴。除非得到您的许可，否则我们不会将您的个人身份信息（指可以识别您身份的信息，例如姓名或电子邮箱，通过这些信息可以联系到您或识别您的身份）与提供广告、分析服务的合作伙伴共享。我们会向这些合作伙伴提供有关其广告覆盖面和有效性的信息，而不会提供您的个人身份信息，或者我们将这些信息进行汇总，以便它不会识别您个人。例如，只有在广告主同意遵守我们的广告发布准则后，我们才可能会告诉广告主他们广告的效果如何，或者有多少人看了他们广告或在看到广告后安装了应用，或者向这些合作伙伴提供不能识别个人身份的统计信息，帮助他们了解其受众或顾客。
      </p>
      <p>
         
        ·供应商、服务提供商和其他合作伙伴。我们将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括提供技术基础设施服务、分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务、支付便利或进行学术研究和调查。
      </p>
      <p> 3、转让</p>
      <p>
         我们不会将您的个人信息转让给除橙织数字时尚产业创新平台的关联公司外的任何公司、组织和个人，但以下情形除外：
      </p>
      <p> （1）事先获得您的单独同意；</p>
      <p>  （2）满足法律法规、法律程序的要求或强制性的政府要求或司法裁定；</p>
      <p>
         
        （3）随着我们业务的持续发展，我们或我们的关联公司有可能涉及合并、分立、清算、资产或业务的收购或出售等交易，您的信息有可能作为此类交易的一部分而被转移，我们将确保信息在转移时的保密性，并要求新的持有您信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
      </p>
      <p>  4、公开披露</p>
      <p>
         
        我们公开披露您的个人信息会采用符合行业内标准的安全保护措施。我们仅会在以下情形下，公开披露您的个人信息：
      </p>
      <p> （1）获得您的单独同意；</p>
      <p>
          （2）基于法律法规、法律程序、诉讼或政府主管部门强制要求下。
        5、例外情形
      </p>
      <p>
         
        <b>
          在以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：
        </b>
      </p>
      <p> （1）为订立、履行您作为一方当事人的合同所必需；</p>
      <p>  （2）为履行法定义务所必需；</p>
      <p>
         （3）为应对突发公共卫生事件，或者紧急情况下为保护个人的生命健康和财产安全所必需；
      </p>
      <p>
         
        （4）为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；
      </p>
      <p>  （5）在合理的范围内处理您自行公开或者其他已经合法公开的个人信息；</p>
      <p>  （6）法律、行政法规规定的其他情形；</p>
      <p>
         
        （7）根据法律规定，共享、转让经去匿名化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
      </p>
      <p>
          <b>四、我们如何保护您的个人信息</b>
      </p>
      <p> 1、信息存储</p>
      <p>
         
        我们仅在实现处理目的的最小必要范围内留存您的个人信息。您在使用橙织数字时尚产业创新平台产品及服务期间，我们将持续为您保存您的个人信息。如果您注销账户或主动删除上述信息，我们将依据《个人信息保护法》等相关法律法规的规定删除您的信息，但法律、行政法规规定的保存期限未届满，或者删除个人信息从技术上难以实现的除外。在您注销帐户或主动删除上述信息后，我们不会再对您的个人信息进行商业化使用，但我们可能会对您的个人信息进行删除或匿名化处理后使用。
      </p>
      <p>  2、存储地点</p>
      <p>
          原则上，
        <b>
          我们在中华人民共和国境内收集和产生的个人信息仅储存于中华人民共和国境内。如需跨境传输，我们将会获得您的单独同意。此类管辖区可能设有不同的数据保护法，在此类情况下，我们会确保您的个人信息得到在中华人民共和国境内足够同等的保护。例如，我们会请求您对跨境转移个人信息的单独同意，或者在跨境数据转移之前实施数据去标识化匿名化等安全举措。我们将根据法律、法规和相关监管要求进行个人信息保护影响评估，并且完成通过国家网信部门组织的安全评估和/或按照国家网信部门的规定经专业机构进行个人信息保护认证和/或按照国家网信部门制定的标准合同与境外接收方订立合同，约定我们与境外接收方的权利和义务。
        </b>
      </p>
      <p>  3、保护措施</p>
      <p>
         
        （1）我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，我们的网络服务采取了传输层安全协议等加密技术，确保您的数据在网络传输过程的安全；我们采用加密技术对数据的存储进行加密；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息。
         
      </p>
      <p>
         （2）我们从组织建设、制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性。
        <span style={{ color: "red" }}>
          目前，橙织数字时尚产业创新平台已经通过了国家信息安全等级保护（三级）的备案和测评；我们通过了国际权威的ISO
          27001信息安全管理体系认证、CSA
          STAR云计算安全评估认证和ISO 29151个人隐私数据保护体系认证；
        </span>
        我们会举办安全培训课程，加强员工对于保护个人信息重要性的认识。
      </p>
      <p>
         
        <b>
          （3）我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本《橙织数字时尚产业创新平台隐私政策》所述目的所需的期限内保留您的个人信息
        </b>
        （除非法律有强制的存留要求）。
      </p>
      <p>
         
        （4）使用橙织数字时尚产业创新平台服务时，我们强烈建议您不要使用非橙织数字时尚产业创新平台推荐的通信方式发送您的信息。您可以通过我们的服务建立联系和相互分享。当您通过我们的服务创建交流、分享时，您可以自主选择沟通、分享的对象，作为能够看到您的联络方式、交流信息或分享内容等相关信息的第三方。
      </p>
      <p> 4、安全事件响应</p>
      <p>
         
        （1）在使用橙织数字时尚产业创新平台服务进行沟通时，请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。
        <b>
          如您发现自己的个人信息尤其是您的账户或密码发生泄露，请您立即通过本隐私政策公布的联系方式联络我们，以便我们根据您的申请采取相应措施。
        </b>
        请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息，如您在沟通时选择上传包含个人信息的图片。请使用复杂密码，协助我们保证您的账号安全。我们将尽力保障您发送给我们的任何信息的安全性。
      </p>
      <p>
         
        （2）我们会制定网络安全事件应急预案，及时处置系统漏洞、计算机病毒、网络攻击、网络侵入等安全风险，在发生危害网络安全的事件时，我们会立即启动应急预案，采取相应的补救措施，并按照规定向有关主管部门报告。
      </p>
      <p>
         （3）个人信息泄露、毁损、丢失属于公司级特大安全事件，我们会负责定期组织工作组成员进行安全预案演练，防止此类安全事件发生。若一旦不幸发生，我们将按照最高优先级启动应急预案，组成应急响应小组，在最短时间内追溯原因并减少损失。
      </p>
      <p>
         
        （4）在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时通过软件内部提醒、邮件、短信或电话等方式向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处理措施、您可自主防范和降低的风险的建议、对您的补救措施等。我们将及时将事件相关情况通过消息推送、平台公告等方式告知您，难以逐一告知时我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
      </p>
      <p> 5、例外情况</p>
      <p>
         
        当我们的产品或服务发生停止运营的情形时，我们将按照法律规定向用户进行通知，通知方式包括但不限于消息推送、平台公告等，并在合理期限内删除或匿名化处理您的个人信息。
        互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
      </p>
      <p>
         <b>五、您的权利</b>
      </p>
      <p>
         
        按照中华人民共和国相关的法律、法规、标准，我们保障您对自己的个人信息行使以下权利：
      </p>
      <p>  1、访问、复制和更正您的个人信息</p>
      <p>
        <span style={{ color: "red" }}>
           您可以点击右上角用户中心-账号中心，进行个人信息查询、修改。
        </span>
      </p>
      <p> 2、删除您的个人信息或注销您的帐号</p>
      <p>
         
        您可以通过本《橙织数字时尚产业创新平台隐私政策》公布的联系方式，申请删除您的个人信息或注销您的帐号；
      </p>
      <p>
        <b> 在以下情形中，您可以向我们提出删除个人信息的请求：</b>
      </p>{" "}
      <p>
        <b>例如:</b>
      </p>
      <p>
         <b> ①如果我们处理个人信息的行为违反法律法规；</b>
      </p>
      <p>
        <b>
          ②如果我们处理个人信息的目的已实现、无法实现或者实现处理目的不再必要；
        </b>
      </p>
      <p>
        <b>  ③如果我们收集、使用您的个人信息，却未征得您的明确同意；</b>
      </p>
      <p>
         <b>④如果您撤回您的授权同意；</b>
      </p>
      <p>
          <b>⑤如果我们处理个人信息的行为严重违反了与您的约定；</b>
      </p>
      <p>
          <b>⑥如果您不再使用我们的产品或服务，或您主动注销了账号；</b>
      </p>
      <p>
         <b>⑦如果我们永久不再为您提供产品或服务；</b>{" "}
      </p>
      <p>
          <b>⑧如果您的个人信息保存期限届满；</b>
      </p>
      <p>
          <b>⑨法律、行政法规规定的其他情形。</b>
      </p>
      <p>
         
        <b>
          {" "}
          例外情况：在法律、行政法规规定的保存期限未届满或删除个人信息从技术上难以实现的，我们将无法完全删除您的个人信息。但我们将停止除存储和采取必要的安全保护措施之外的个人信息处理。
        </b>
      </p>
      <p>
         若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，要求其及时删除，除非法律法规另有规定，或这些主体获得您的独立授权。
      </p>
      <p>
         
        当您从我们的服务中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。
      </p>
      <p>  3、撤回或变更您的信息</p>
      <p>
         您可以在设备本身操作系统中关闭地理位置信息、通讯录、摄像头、麦克风、相册权限、日历权限，改变同意范围或撤回您的授权。
      </p>
      <p>
         
        如果您想要撤回同意、删除您的信息，或是您发现我们违反法律、行政法规的规定或者双方的约定收集、使用其个人信息，您想要向我们提出的请求，您可以通过本《橙织数字时尚产业创新平台隐私政策》公布的联系方式与我们取得联系，我们将在验证您身份后的三十日内回复并进行相关操作。
      </p>
      <p>  4、响应您的上述请求</p>
      <p>
         为了保障安全，您需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。对于合理的请求，我们会积极响应；但对于多次重复、超出合理限度的请求，我们将视情况予以拒绝。
      </p>
      <p>  5、提前获知产品和服务停止运营</p>
      <p>
         若因特殊原因导致橙织数字时尚产业创新平台被迫停止运营，我们将按照法律法规的要求，在产品和/或服务的主页面或站内信或向您发送电子邮件或其他合适的能触达您的方式通知您，并将停止对您个人信息的收集，同时会按照法律规定对所持有的您的个人信息进行删除或匿名化处理等。
      </p>
      <p> 6、响应请求的例外</p>
      <p>  在以下情形中，按照法律法规要求，我们将无法响应您的上述请求：</p>
      <p>  （1）与国家安全、国防安全直接相关的；</p>
      <p>  （2）与公共安全、公共卫生、重大公共利益直接相关的；</p>
      <p> （3）与犯罪侦查、起诉、审判和执行判决等直接相关的；</p>
      <p>
         
        （4）证据表明您可能存在明显恶意、滥用权力及占用平台资源、辱骂客服人员等相关情形的（如您的请求将危害公共安全）；
      </p>
      <p>
         
        （5）您和其他人合法权益，或您的请求超出了一般技术手段和商业成本可覆盖的范围）；
      </p>
      <p>
         
        （6）响应个人信息主体的请求将导致您或其他个人、组织的合法权益受到严重损害的；
      </p>
      <p>  （7）涉及商业秘密的。</p>
      <p>
        <b>六、我们如何处理未成年人的个人信息</b>
      </p>
      <p>
         如果没有父母或监护人的同意，未成年人不得创建自己的用户账户。如您为未成年人的，建议您请您的父母或监护人仔细阅读本隐私权政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。
      </p>
      <p>
         
        对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。
      </p>
      <p>
          <b>七、本政策的适用及更新</b>
      </p>
      <p>
         
        <b>
          我们保留不时更新或修改本政策的权利。我们可能适时修订本政策内容。如该等修订会导致您在本政策项下权利的重大变更，我们将在变更生效前，通过系统推送、电子邮件、短信、公告等方式通知您。若您不同意该等修订应立即停止使用橙织数字时尚产业创新平台的产品和服务，或通过本政策公布的联系方式联系我们要求注销您的帐号。若您继续使用我们的产品和服务，即表示您同意接受修订后的本政策的约束。
        </b>
      </p>
      <p>本政策所指的重大变更包括但不限于：</p>
      <p>
         
        （1）我们的服务模式发生重大变化。如处理信息的目的、处理的信息类型、信息的使用方式等；
      </p>
      <p>
         
        （2）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
      </p>
      <p>  （3）信息共享、转让或公开披露的主要对象发生变化；</p>
      <p> （4）您参与信息处理方面的权利及其行使方式发生重大变化；</p>
      <p>
          （5）我们负责处理信息安全的责任部门、联络方式及投诉渠道发生变化时；
      </p>
      <p> （6）信息安全影响评估报告表明存在高风险时。 </p>
      <p>
        <b>八、如何联系我们 </b> 
      </p>
      <p>
        如果您对本《橙织数字时尚产业创新平台隐私政策》及我们对您的个人信息的处理有任何疑问、意见、建议，请通过
        <b>联系方式：400-1086-621</b>
        与我们联系。一般情况下，我们会在15日内对您的请求予以答复。
      </p>
    </div>
  );
};
