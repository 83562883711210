import styled from "styled-components";
import { Card } from "antd";
import home_server_bg from "@/assets/images/home/home_server_bg.png";
export const ProductServicesWrapper = styled.div`
  width: 100%;
  min-width: 1440px;
  margin-top: 106px;
  margin-bottom: 118px;
  .product-title {
    margin-bottom: 12px;
  }
  .product-sub-title {
    margin-bottom: 36px;
    font-family: PingFangSC, PingFangSC-Regular;
  }
  .product-server-info {
    width: 100%;
    padding-top: 66px;
    min-width: 1440px;
    height: 586px;
    background: #edf1f8 url(${home_server_bg}) no-repeat center;
    background-size: 100%;
  }
  .product-server-content {
    display: flex;
    margin: 0 auto;
    width: 1200px;
    height: 468px;
  }
  .product-server-left {
    display: flex;
    flex-direction: column;
    .product-server-item {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;
      width: 256px;
      height: 76px;
      border-left: 8px solid #b4c3fa;
      background-color: #fff;
      .product-server-sub {
        font-family: PingFangSC, PingFangSC-Regular;
      }
      &.product-server-actie-item {
        border-left: 8px solid #1848fd;
        .title {
          color: #1848fd;
        }
      }
    }
  }

  .product-server-right {
    margin-left: 78px;
    width: 868px;
    .product-server-right-row {
      margin-bottom: 26px;
    }
    .product-server-right-title {
      font-family: PingFangSC, PingFangSC-Medium;
    }
    .product-server-right-info {
      margin-bottom: 40px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #000000;
    }
  }
`;
export const CusCard = styled(Card)`
  margin-right: 12px;
  margin-bottom: 20px;
  width: 280px;
  height: 112px;
  &:nth-child(3n) {
    margin-right: 0;
  }
  .ant-card-body {
    display: flex;
    align-items: center;
    padding: 3px;
  }
  .card-img {
    margin-right: 18px;
    width: 80px;
    height: 104px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f7ff;
    border-radius: 3px;
    img {
      width: 40px;
      height: 40px;
    }
  }
  .sub-title {
    font-family: PingFangSC, PingFangSC-Regular;
    color: #666;
  }
`;

export const CatesListWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
