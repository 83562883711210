import { getProductList } from "@/api/server";
import BaseFooter from "@/layouts/base-footer";
import CusHeader from "@/layouts/cus-header";
import { useLocation } from "umi";
import { useEffect, useState } from "react";
import { ServerDetailVo, useDispatch } from "umi";
import ServiceList from "./components/service-list";
import TagSelector from "./components/tag-select";
import { AllServicesWrapper } from "./style";

interface AllServices {}
interface ServiceVo {
  sortTime?: string | null | undefined;
  sortPrice?: string | null | undefined;
  multipleOrder?: string | null | undefined;
  priceRange: number | null;
  priceType?: number | null;
  serviceName?: string;
  index: number;
  size: number;
  deliveryMethod?: number | null;
  sortOrder?: number | null;
  serviceCateId?: number | null;
}
const AllServices: React.FC<AllServices> = () => {
  const dispatch = useDispatch();
  const { state, ...location }: any = useLocation<{
    keyword: string;
    serviceName: string;
  }>();
  const [request, setRequest] = useState<ServiceVo>({
    index: 0,
    size: 10,
    serviceName: state?.keyword,
    serviceCateId: state?.serviceCateId,
  } as ServiceVo);
  let index = 1;
  const [hasMore, setHasMore] = useState(false);
  const [serverList, setServerList] = useState<ServerDetailVo[]>([]);

  useEffect(() => {
    dispatch({
      type: "server/getServerCastes",
      payload: {},
    });
  }, []);
  // useEffect(() => {
  //   if (params.keyword) {
  //     request.serviceName = params?.keyword
  //   }
  //   if (params.serviceName) {
  //     request.serviceName = params?.serviceName
  //   }
  //   setRequest({ ...request })
  // }, [params])
  const getList = (i: any) => {
    request.index = i;
    getProductList(request).then((res: any) => {
      if (res.code === 1) {
        setServerList([...serverList, ...res.data]);
        res.page &&
          setHasMore(res.page.count > [...serverList, ...res.data].length);
      }
    });
  };
  const loadMoreData = () => {
    index++;
    getList(index);
  };
  useEffect(() => {
    if (Object.keys(request).length > 0) {
      getList(1);
    }
  }, [request]);
  return (
    <>
      <CusHeader
        title={"服务中心"}
        showSearch
        search={(value) => {
          setServerList([]);
          request.index = 1;
          request.serviceName = value;
          setRequest({ ...request });
        }}
      />
      <AllServicesWrapper>
        <TagSelector
          serverClick={(value) => {
            setServerList([]);
            request.index = 1;
            request.serviceCateId = value == "-1" ? null : Number(value);
            setRequest({ ...request });
          }}
          subserverClick={(value) => {
            setServerList([]);
            request.serviceCateId = value == "-1" ? null : Number(value);
            setRequest({ ...request });
          }}
          payCateClick={(value) => {
            setServerList([]);
            request.index = 1;
            request.deliveryMethod = value == "-1" ? null : Number(value);
            setRequest({ ...request });
          }}
          priceCateClick={(value) => {
            setServerList([]);
            request.index = 1;
            request.priceRange = value == "-1" ? null : Number(value);
            setRequest({ ...request });
          }}
        />
        <ServiceList
          serverList={serverList}
          loadMoreData={loadMoreData}
          hasMore={hasMore}
          sortby1={(value) => {
            setServerList([]);
            request.index = 1;
            request.multipleOrder = "0";
            request.sortPrice = null;
            request.sortTime = null;
            setRequest({ ...request });
          }}
          sortby2={(value) => {
            setServerList([]);
            request.index = 1;
            request.sortPrice = value;
            request.multipleOrder = null;
            setRequest({ ...request });
          }}
          sortby3={(value) => {
            setServerList([]);
            request.index = 1;
            request.sortTime = value;
            request.multipleOrder = null;
            setRequest({ ...request });
          }}
        />
      </AllServicesWrapper>
      <BaseFooter />
    </>
  );
};

export default AllServices;
