import { CusButton, CusInput, Title } from "@/styles/common-style";
import { CusSlider, LoginWrap } from "../style";
import { Form, message, Checkbox } from "antd";
import { useRef, useState } from "react";
import type { FormInstance } from "antd/es/form";
import _ from "lodash";
import Agreement from "@/components/agreement";
import CusCode from "@/components/cus-code";
import { isPhone } from "@/utils/common";
import CusInputNumber from "@/components/cus-input-number";
import { login, sendSms } from "../api";
import encrypt from "@/utils/util_encrypt";

import { useDispatch } from "umi";
import SliderSms from "@/components/slider-sms";
import { ContentText } from "./content";
import { Provicy } from "./provicy";

interface LoginFormProps {}
const LoginForm: React.FC<LoginFormProps> = () => {
  const [text] = useState<string>("获取验证码");
  let [num, setNum] = useState<number>(60);
  const formRef: any = useRef<FormInstance>();
  const dispatch = useDispatch();
  let timer: any = null;
  const onFinish = (values: any) => {
    console.log("1233");
    const sms_encrypt = encrypt(values.sms);
    const { iv: uuid, encrypt: smsCode } = sms_encrypt;
    const params = {
      username: values.phone,
      smsCode,
      grant_type: "sms",
      uuid,
    };
    dispatch({
      type: "login/login",
      payload: { ...params },
    });
  };

  const onFinishFailed = (errorInfo: any) => {};
  const validator = (_: any, value: any) => {
    if (value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("验证码不能为空"));
  };
  const getCode = _.throttle(
    () => {
      let phone = formRef.current?.getFieldValue("phone");
      let params = {
        mobile: phone,
        smsType: 1,
      };
      sendSms(params).then((res: any) => {
        if (res.code == 1) {
          message.success("验证码发送成功");
        }
      });
      clearInterval(timer);
      num = 60;
      timer = setInterval(() => {
        if (num == 0) {
          clearInterval(timer);
          num = 60;
        } else {
          num--;
        }
        setNum(num);
      }, 1000);
    },
    60000,
    {
      leading: true,
      trailing: false,
    }
  );

  return (
    <LoginWrap>
      <div className="login-title">
        <Title className="title">登录</Title>
      </div>
      <Form
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        ref={formRef}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label=""
          name="phone"
          hasFeedback
          rules={[
            {
              validator: (_: any, value: any) => {
                if (value) {
                  if (isPhone(value)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(new Error("手机号格式不正确"));
                  }
                } else {
                  return Promise.reject(new Error("请输入手机号"));
                }
                //
              },
              validateTrigger: ["blur"],
            },
          ]}
        >
          <CusInputNumber
            inputHeight={54}
            inputWidth={374}
            placeholder="请输入手机号码"
          />
        </Form.Item>
        <Form.Item
          label=""
          name="slider"
          rules={[
            {
              validator: (_: any, value: any) => {
                if (value == 1) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(new Error("请托动滑块并移动到最右边"));
                }
                //
              },
            },
          ]}
        >
          <SliderSms />
        </Form.Item>
        <Form.Item
          label=""
          name="sms"
          rules={[{ validator: validator, validateTrigger: ["blur"] }]}
          hasFeedback
        >
          <CusCode
            width={370}
            disabled={num >= 60 ? false : true}
            onCodeClick={() => {
              formRef.current
                ?.validateFields(["phone", "slider"])
                .then((values: any) => {
                  getCode();
                });
            }}
            text={num >= 60 ? text : num + "秒后重发"}
          />
        </Form.Item>

        <Form.Item>
          <CusButton width={370} height={54} type="primary" htmlType="submit">
            登录
          </CusButton>
        </Form.Item>
        <Form.Item
          name="remember"
          valuePropName="checked"
          rules={[
            {
              validator: (_: any, value: any) => {
                if (value) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(new Error("请勾选"));
                }
                //
              },
            },
          ]}
        >
          <Checkbox>
            阅读并同意
            <Agreement
              title="《用户服务协议》"
              content={() => <ContentText />}
            />
            和
            <Agreement
              subTitle="橙织数字时尚产业创新平台隐私政策"
              title="《隐私政策》"
              content={() => <Provicy />}
            />
          </Checkbox>
        </Form.Item>
      </Form>
      {/* <div className="regist">免费注册</div> */}
    </LoginWrap>
  );
};

export default LoginForm;
