import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 95px;
  border-bottom: 1px solid #d8d8d8;
  min-width: 1440px;
  .site-title {
    width: 360px;
    padding-left: 30px;
    padding-right: 40px;
    display: flex;
    font-family: Alibaba, Alibaba-Regular;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #000;
    font-weight: 400;
    .sub-title {
      font-size: 12px;
      color: #333333;
    }
  }
  .header-title {
    padding-left: 48px;
    position: relative;
    font-size: 30px;
    font-weight: 600;
  }
  .header-title:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 1px;
    height: 46px;
    background-color: #d8d8d8;
  }
  .header-center {
    /* width: 1100px; */
    display: flex;
    align-items: center;
    @media screen and (max-width: 1440px) {
      & {
        width: 900px;
      }
    }
  }
  .header-search {
    margin-left: 80px;
    .ant-input-search {
      width: 620px;
    }
    .ant-input,
    .ant-btn {
      height: 50px;
    }
    .ant-input-group-addon .ant-btn {
      width: 110px;
      font-weight: 500;
    }
    .ant-btn-lg {
      font-size: 18px;
    }
  }
  .ant-btn-primary {
    background-color: #1848fd;
  }
`;

export const BtnWrapper = styled.div`
  display: flex;
  padding-right: 30px;
  @media screen and (max-width: 1440px) {
    & {
      padding-right: 20px;
    }
  }
  .ant-btn {
    margin-right: 20px;
    height: 30px;
    font-size: 12px;
    font-weight: 400;
  }
  &.regist {
    .regist-text {
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #333333;
    }
    .ant-btn-link {
      padding: 0;
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #1848fd;
    }
  }
`;
