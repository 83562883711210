import styled from "styled-components";

export const SolutionWrapper = styled.div`
  margin: 0 auto 90px;
  width: 1190px;
  color: #333;
  .solutions-tilte {
    text-align: center;
    font-size: 52px;
    font-weight: 600;
  }
  .solutions-intro {
    margin: 8px 0 50px;
    font-family: Alibaba, Alibaba-Regular;
  }
  .solutions-list {
    display: flex;
  }
  .solutions-item {
    position: relative;
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
  .solutions-item-img {
    width: 283px;
    height: 441px;
  }
  .solutions-item-wrap {
    cursor: pointer;
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    color: #fff;
  }
  .solutions-item-icon {
    width: 70px;
    height: 70px;
  }
  .solutions-item-title {
    margin: 34px 0 14px;
    font-size: 30px;
    font-weight: 600;
  }
  .solutions-item-way {
    font-size: 24px;
    font-weight: 400;
  }
  .solutions-item-card {
    margin: 30px;
    font-size: 18px;
    font-weight: 400;
    padding: 20px 12px 40px;
    background: #253050;
  }
  .solutions-item-card-title {
    font-size: 24px;
    font-weight: 600;
  }
  .solutions-item-card-way {
    display: inline-block;
    font-size: 16px;
    padding-bottom: 10px;
    line-height: 36px;
    border-top: 1px solid #fff;
  }
`;
