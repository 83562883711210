import { bg } from "@/assets/images/login/bg_login.png";
import { ListWrapper } from "@/pages/server/all-service/style";
import styled from "styled-components";

export const PolicyWrapper = styled.div``;

export const PolicyListWrapper = styled(ListWrapper)`
  margin: 0 auto;
  padding-top: 30px;
  width: 1290px;
  .ant-list-item {
    padding: 20px;
    flex-direction: row;
    justify-content: normal;
    align-items: normal;
  }
  .item-content {
    margin-left: 36px;
    position: relative;
    width: 872px;
  }
  .item-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .sc-jqUVSM {
    width: 872px;
    max-height: 47.14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .kdQFqS {
    font-family: PingFangSC, PingFangSC-Medium;
  }
  .item-time {
    font-size: 24px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #999999;
  }
  .infinite-scroll-component__outerdiv {
    width: 1290px;
    overflow: hidden;
  }
  .infinite-scroll-component {
    width: 1307px;
  }
  .item-detail {
    font-size: 24px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    color: #1848fd;
    position: absolute;
    bottom: 39px;
    left: 0;
    user-select: none;
    cursor: pointer;
  }
  .item-product {
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #666666;
    max-height: 105px;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  .item-product::after {
    content: "...";
    width: 1em;
    position: absolute;
    right: 0;
    bottom: 0;
  }
`;

export const Poster = styled.div<{ bgImg?: string }>`
  width: 359px;
  height: 270px;
  border-radius: 5px;
  background-color: #d8d8d8;
  background: url(${(props) => (props.bgImg ? props.bgImg : "")}) no-repeat
    center;
  background-size: cover;
`;
