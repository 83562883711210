import BaseLayout from "@/layouts";
import { FC } from "react";
import { Partner } from "@/pages/order-platform/components/partner";
import { JoinUs } from "@/pages/server/server-center/components/join";

interface IProps {}

const Index: FC<IProps> = (props) => {
  const imageList: any[] = [
    { image: require("@/assets/images/industry-park/01.png") },
    { image: require("@/assets/images/industry-park/02.png") },
  ];
  return (
    <BaseLayout>
      <div>
        {imageList.map((item: any) => (
          <img src={item.image} style={{ width: "100%", minWidth: "1440px" }} />
        ))}
      </div>
      <Partner noDownload title="他们都在这里找到了解决方案" subTitle="" />
      <JoinUs
        title="更多专业定制化解决方案，欢迎垂询"
        btnText="联系我们"
        conactUs
      />
    </BaseLayout>
  );
};

export default Index;
