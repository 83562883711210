import BaseLayout from "@/layouts";
import CaseList from "./components/case-list";
import { CaseWrapper } from "./style";
interface SolutionProps {}
export const CaseCenter: React.FC<SolutionProps> = () => {
  return (
    <BaseLayout>
      <CaseWrapper>
        <div className="header-container">
          <img src={require("@/assets/images/solution/solution-bg.png")} />
          <div className="container-info">
            <div className="title">案例中心</div>
            <div className="sub-info">SOLUTION CENTER</div>
            <div className="desc">
              探究行业共性需求 构造普适而实用的解决方案
            </div>
          </div>
        </div>
        <CaseList></CaseList>
      </CaseWrapper>
    </BaseLayout>
  );
};

export default CaseCenter;
