import { Title } from "@/styles/common-style";
import { Trans3Wrapper } from "../style";
import { Row } from "antd";
interface Trans3Props {}
export const Trans3: React.FC<Trans3Props> = () => {
  return (
    <Trans3Wrapper>
      <div className="info-card">
        <div className="left">
          <Title
            fz={36}
            textAlign="left"
            fw={600}
            color="#000"
            style={{
              marginBottom: 35,
              fontFamily: " PingFangSC, PingFangSC-Semibold",
            }}
          >
            我们的特点
          </Title>
          <Row className="item" align="middle">
            <span className="icon"></span>
            <span className="item-info">
              课程融入市场需求，职场竞争更有优势；
            </span>
          </Row>
          <Row className="item" align="middle">
            <span className="icon"></span>
            <span className="item-info">全程实战，成就实操实力派；</span>
          </Row>
          <Row className="item" align="middle">
            <span className="icon"></span>
            <span className="item-info">
              行业专业老师精心打造，全方位的激发潜能；
            </span>
          </Row>
          <Row>
            <div className="list-item">
              <span className="num-text">
                <span className="num">15</span>年
              </span>
              <span className="list-info">培训经验</span>
            </div>
            <div className="list-item">
              <span className="num-text">
                <span className="num">15</span>个
              </span>
              <span className="list-info">开课城市</span>
            </div>
            <div className="list-item">
              <span className="num-text">
                <span className="num">1000</span>+
              </span>
              <span className="list-info">企业客户与我们合作共赢</span>
            </div>
            <div className="list-item">
              <span className="num-text">
                <span className="num">100000</span>+
              </span>
              <span className="list-info">累计培训人</span>
            </div>
          </Row>
        </div>
        <img
          src={require("@/assets/images/more-trains/bg4.png")}
          className="img"
        />
      </div>
    </Trans3Wrapper>
  );
};
