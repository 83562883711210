import { ContentContainer, TitleWrapper } from "../style";
import { List, Col, Row } from "antd";
import { history } from "umi";
// import { Document, pdfjs } from "react-pdf/dist/esm/entry.webpack"
import { useEffect, useState } from "react";
import no_data from "@/assets/images/knowledge/no_data.png";
interface ContentInfoProps {
  item: any;
  url: string;
}
// const pdfJS = require("pdfjs-dist")
// pdfJS.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry")
export const ContentInfo: React.FC<ContentInfoProps> = ({ item, url }) => {
  const [data, setData] = useState<any>();
  useEffect(() => {
    setData({
      ...item,
      totalPage: item.previewImgNum,
      coverImg: item.coverImg
        ? item.coverImg
        : item?.previewImgName
        ? item.previewImgPrefix + item?.previewImgName?.split(",")[0]
        : null,
    });
  }, [item]);

  return (
    <List.Item
      onClick={() => {
        history.push("/knowledge/knowledge-detail/" + data?.id);
      }}
    >
      <div
        style={{
          width: "100%",
          height: 150,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          style={{
            maxWidth: "100%",
            height: 150,
          }}
          alt="poster"
          src={data?.coverImg || no_data}
        />
      </div>
      <List.Item.Meta
        title={
          <TitleWrapper>
            <div className="title">{data?.title}</div>
            {/* <div className='type'>{getItemType(item)}</div> */}
          </TitleWrapper>
        }
      />
      {/* <div className='ant-list-item-meta-description '>{data?.remark}</div> */}

      {/* {item.content()} */}
      <ContentContainer>
        {/* <Col span={24}>
          交付方式：
          {
            payWays.filter((i) => i.id == item.deliveryMethod)[0]
              .cateName
          }
        </Col> */}
        <Col span={24}>
          <Row>
            <div style={{ marginRight: "20px" }}>
              下载 {data?.downNum + data?.waterSaleNum || 0}
            </div>
            <div>总页数 {data?.totalPage || 0}</div>
          </Row>
        </Col>
      </ContentContainer>
    </List.Item>
  );
};
