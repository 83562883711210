import { Train1Wrapper } from "../style";
import { Title } from "@/styles/common-style";
import { ConsultNow } from "./consult-now";

interface Train1Props {}
export const Train1: React.FC<Train1Props> = () => {
  const train1Data = [
    {
      title: "海螺公益培训",
      info: "服装相关领域的前沿知识与信息的公益分享服务。",
      bg: require("@/assets/images/more-consulting/bg1.png"),
    },
    {
      title: "版师培训",
      info: "呈现国内天花板级的立体裁剪与平面制版的教育体系，结合服装人体工学，深度剖析立体裁剪与平面制版的理论精髓，严谨的理论体系结合最新的流行趋势，增强版师举一反三的造型能力，拓展制版技术的极限。",
      bg: require("@/assets/images/more-consulting/bg2.png"),
    },
    {
      title: "流行趋势培训",
      info: "掌握商品数据企划技能并制作OTB企划书、流行企划书，季节流行趋势分析与品牌企划、商品企划、设计企划、面料企划、营销企划、买手模式搭建、买手技能及工作流程介绍、店铺风格区域规划、陈列搭配实操、组货前中后组合技术等。",
      bg: require("@/assets/images/more-consulting/bg3.png"),
    },
    {
      title: "着装顾问",
      info: "为服装高级定制店铺或企业的量体师进行专业培训服务",
      bg: require("@/assets/images/more-consulting/bg4.png"),
    },
    {
      title: "精益数字化转型培训",
      info: "提供基于精益生产体系的数字化转型方法培训及实践经验分享，帮助企业提升精益管理水平，以适应数字化生产模式，进一步提升生产效率，缩短生产周期，降低生产成本。",
      bg: require("@/assets/images/more-consulting/bg5.png"),
    },
  ];
  return (
    <Train1Wrapper>
      <Title
        fz={36}
        textAlign="center"
        fw={600}
        color="#000"
        style={{
          marginBottom: 56,
          fontFamily: " PingFangSC, PingFangSC-Semibold",
        }}
      >
        培训服务内容
      </Title>
      <div className="train1-list">
        {train1Data.map((item: any, index: number) => {
          return (
            <div className="item" key={index}>
              <img src={item.bg} alt="" className="img" />
              <div className="item-int">
                <div className="item-title">{item.title}</div>
                <div className="item-info">{item.info}</div>
                <ConsultNow
                  name={item.title}
                  style={{
                    position: "absolute",
                    width: "110px",
                    height: "40px",
                    background: " #1848fd",
                    left: "50%",
                    marginLeft: "-55px",
                    bottom: 28,
                    color: "#fff",
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </Train1Wrapper>
  );
};
