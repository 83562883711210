import { payWays } from "@/utils/common";
import { useState } from "react";
import { ServerInfoVo, useSelector } from "umi";
import { TagSelectorContainer } from "../style";
import TagList from "./tag-list";
interface TagSelectorProps {
  serverClick?: (value: string) => void;
  subserverClick?: (value: string) => void;
  payCateClick?: (value: string) => void;
  priceCateClick?: (value: string) => void;
}
const TagSelector: React.FC<TagSelectorProps> = ({
  serverClick,
  subserverClick,
  payCateClick,
  priceCateClick,
}) => {
  const [showService, setShowService] = useState<boolean>(false);
  const { serverCates } = useSelector((state: any) => state.server);
  const [childList, setChildList] = useState<ServerInfoVo[]>([]);
  const [val, setVal] = useState<string>("0");
  console.log(serverCates, "serverCates");
  return (
    <TagSelectorContainer>
      <div className="tag-area">
        <TagList
          title="服务分类"
          data={serverCates.filter(
            (item) => item.id !== 430 && item.id !== 421 && item.id !== 436
          )}
          tagClick={(value: string) => {
            if (value === "-1") {
              setShowService(false);
            } else {
              setShowService(true);
              setVal(value);
              setChildList(
                serverCates.filter((item: ServerInfoVo) => item.id === value)[0]
                  .metadataList
              );
            }
            serverClick?.(value);
          }}
        />
      </div>
      {showService && (
        <div className="tag-area">
          <TagList
            title="二级分类"
            data={childList}
            tagClick={(value: string) => {
              subserverClick?.(value == "-1" ? val : value);
            }}
          />
        </div>
      )}
      <div className="tag-area">
        <TagList
          title="交付方式"
          data={payWays}
          tagClick={(value: string) => {
            payCateClick?.(value);
          }}
        />
      </div>
      <div className="tag-area">
        <TagList
          title="价格"
          tagClick={(value: string) => {
            priceCateClick?.(value);
          }}
        />
      </div>
    </TagSelectorContainer>
  );
};

export default TagSelector;
