import styled from "styled-components";

import { Table } from "antd";

export const AtlasListWrapper = styled.div`
  width: 1440px;
  margin: 0 auto 98px;
  /* padding: 40px 60px 0 60px; */
  background-color: #fff;
`;

export const AtlasListHeader = styled.div`
  display: flex;
  width: 1440px;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  .title {
    padding: 30px;
    padding-left: 50px;
    font-size: 24px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    color: #000000;
    &::before {
      position: absolute;
      content: "";
      left: 30px;
      top: 50%;
      margin-top: -15px;
      width: 6px;
      height: 30px;
      background: #1848fd;
    }
  }
`;

export const CusTable = styled(Table)`
  margin: 0 auto;
  width: 1512px;
  .ant-table-thead > tr > th {
    background-color: #1848fd;
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #fff;
  }
  .ant-table-tbody .ant-table-row {
    background-color: #fff;
    &:nth-of-type(even) {
      background-color: #f7f7f7;
    }
  }
`;
