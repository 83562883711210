import { Title } from "@/styles/common-style";
import { DataDescItem, DataDescWrapper } from "../style";
import { Button } from "antd";
import { ContactUs } from "@/utils/common";
interface DataDescProps {}

export const DataDesc: React.FC<DataDescProps> = () => {
  const OrderDescList = [
    {
      title: "丰富的数据资产类型",
      desc: "平台支持多种数据库、文件等结构化和非结构化物理资源的定义、盘点和规划，从工艺、款式、版型、数字人台及标准工时等方面，全方位的盘点服装行业的各类数据生产要素",
      bg: require("@/assets/images/data-assets/bg1.png"),
    },
    {
      title: "多样的数据服务方式",
      desc: "平台中的数据资源可以快捷开发出多种数据服务类型，包括：数据查询、下载、共享交换、API和敏捷分析，还能提供分析产品授权使用、基于分析模型报表开发、验证接口调用、主题文件批量下载等高附加值服务",
      bg: require("@/assets/images/data-assets/bg2.png"),
    },
    {
      title: "全流程数据安全管控",
      desc: "平台将应用程序和数据部署在处于高安全等级的云主机和云数据库上，保证数据的独立性，有效防止网络恶性攻击。同时元数据的访问拥有严格的角色权限体系，做到人员与系统的双安全保障。消费者获取的数据可依据数据安全中对资产的分级分类要求做相应的脱敏和加密处理",
      bg: require("@/assets/images/data-assets/bg3.png"),
    },
    {
      title: "可视化数据资产服务门户",
      desc: "平台盘点的数据资产能够以数据商品的形式在数据资产门户中对业务用户开放，消费者在门户中可以通过资产分类导航、资产标签、评价等级和关键字检索的方式查找需要的数据资产，浏览数据资产详情并发起数据服务的使用申请",
      bg: require("@/assets/images/data-assets/bg4.png"),
    },
  ];
  const OrderDescInfo = ({ title, desc, bg }: any) => {
    return (
      <DataDescItem bg={bg}>
        <Title fz={24} fw={600} color="#000">
          {title}
        </Title>
        <div className="desc">{desc}</div>
        <Button
          type="primary"
          className="look-more"
          onClick={() => ContactUs("申请试用")}
        >
          申请试用
        </Button>
      </DataDescItem>
    );
  };
  return (
    <DataDescWrapper>
      <Title
        fz={36}
        fw={600}
        color="#000"
        textAlign="center"
        style={{ marginBottom: "55px" }}
      >
        数据资产平台
      </Title>
      <div className="data-list">
        {OrderDescList.map((item, index) => (
          <OrderDescInfo {...item} />
        ))}
      </div>
    </DataDescWrapper>
  );
};
