import Title from "@/components/title";
import { ContactView } from "@/models/ecological";
import { createContact } from "@/utils/api";
import { isPhone } from "@/utils/common";
import { Form, Input, Button, message } from "antd";

const ContactUs: React.FC = () => {
  const [form] = Form.useForm();
  const filedData = [
    {
      name: "companyName",
      label: "公司名称",
      rules: [
        {
          validator: (_: any, value: any) => {
            if (value) {
              return Promise.resolve();
            } else {
              return Promise.reject(new Error("公司名称不能为空"));
            }
            //
          },
        },
      ],
      renderNode: <Input placeholder="请输入" />,
    },
    {
      name: "contact",
      label: "联系人",
      rules: [
        {
          validator: (_: any, value: any) => {
            if (value) {
              return Promise.resolve();
            } else {
              return Promise.reject(new Error("联系人不能为空"));
            }
            //
          },
        },
      ],
      renderNode: <Input placeholder="请输入" />,
    },
    {
      name: "contactPost",
      label: "职位",
      rules: [
        {
          validator: (_: any, value: any) => {
            if (value) {
              return Promise.resolve();
            } else {
              return Promise.reject(new Error("职位不能为空"));
            }
            //
          },
        },
      ],
      renderNode: <Input placeholder="请输入" />,
    },
    {
      name: "contactWay",
      label: "联系方式",
      rules: [
        {
          validator: (_: any, value: any) => {
            if (value) {
              if (isPhone(value)) {
                return Promise.resolve();
              } else {
                return Promise.reject(new Error("手机号格式不正确"));
              }
            } else {
              return Promise.reject(new Error("请输入手机号码"));
            }
            //
          },
        },
      ],
      renderNode: <Input placeholder="请输入手机号码" />,
    },
  ];
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const titleStyle = {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "70px",
  };
  const saveInfo = (values: any) => {
    // form.validateFields().then((values) => {
    // ContactView
    if (Object.keys(values).length > 0) {
      const params: ContactView = {
        ...values,
        dataSource: 1,
      };
      createContact(params).then((res: any) => {
        console.log(res.code);
        if (res.code === 1) {
          message.success("新增成功");
        }
      });
    } else {
      message.error("信息不能为空");
    }
    // })
  };
  return (
    <div className="contact-us">
      <div className="right-form">
        <Title text="立即联系我们" titleStyle={titleStyle} />
        <Form {...layout} form={form} onFinish={(values) => saveInfo(values)}>
          {filedData.map((item) => (
            <Form.Item
              name={item.name}
              key={item.name}
              label={item.label}
              rules={item.rules}
            >
              {item.renderNode}
            </Form.Item>
          ))}
          <Button
            className="right-form-button"
            type="primary"
            htmlType="submit"
          >
            立即申请
          </Button>
          {/* </Form.Item> */}
        </Form>
      </div>
    </div>
  );
};

export default ContactUs;
