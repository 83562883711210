import { CusButton, Title } from "@/styles/common-style";
import { HeaderInfoWrapper } from "../style";
import { Row, message } from "antd";
// import CusInputNumber from "@/components/cus-input-number"
import { useSelector, history } from "umi";
import { useState } from "react";
import _ from "lodash";
import moment from "moment";
import no_data from "@/assets/images/knowledge/no_data.png";

interface HeaderInfoProps {
  payFunc?: (value: string) => void;
}
const Info: React.FC<HeaderInfoProps> = ({ payFunc }) => {
  const { knowledgeDetail } = useSelector((state: any) => state.knowledge);
  const { user } = useSelector((state: any) => state.user);
  // const getItemType = (data: any) => {
  //   switch (data.priceType) {
  //     case 2:
  //       return "按需定价"
  //     case 1:
  //       return "免费"
  //     case 3:
  //       return `<span className='server-price'>${data.price}</span>${
  //         data.priceCompany == 1 ? "/年" : "/次"
  //       }`
  //     default:
  //       return "免费"
  //   }
  // }
  // const userCollectCreate = _.throttle(
  //   () => {
  //     if (user?.id) {
  //       const params = {
  //         serviceProductId: knowledgeDetail.id,
  //         accountId: user?.id,
  //         clientSource: 1,
  //       }
  //       if (!knowledgeDetail.isCollect) {
  //         CollectCreate(params).then((res: any) => {
  //           if (res.code === 1) {
  //             setIsCollect(1)
  //             message.success("收藏成功")
  //           } else {
  //             setIsCollect(0)
  //           }
  //         })
  //       }
  //     } else {
  //       history.push("/login")
  //     }
  //   },
  //   60000,
  //   {
  //     leading: true,
  //     trailing: false,
  //   }
  // )
  return (
    <>
      {knowledgeDetail && (
        <HeaderInfoWrapper>
          <div className="card-wrapper">
            <div className="left">
              <img className="img" src={knowledgeDetail.coverImg || no_data} />
            </div>
            <div className="right">
              <div className="right-title">{knowledgeDetail.title}</div>
              <div className="right-intro">{knowledgeDetail.remark}</div>
              <div className="info">
                <div className="download-info">
                  下载{" "}
                  {knowledgeDetail.downNum + knowledgeDetail.waterSaleNum || 0}
                </div>
                <div className="total-info">
                  总页数 {knowledgeDetail?.totalPage || 0}页
                </div>
                <div className="time-info">
                  {knowledgeDetail.createTime &&
                    moment(knowledgeDetail.createTime || 0).format(
                      "YYYY-MM-DD HH:mm"
                    )}
                </div>
              </div>
              <Row align="middle" justify="space-between">
                <div className="price-info">
                  价格：
                  <span className="price">
                    ¥{knowledgeDetail.salePrice || 0}
                  </span>
                </div>
                <div
                  className="price-btn"
                  onClick={() =>
                    payFunc?.(
                      knowledgeDetail && knowledgeDetail?.salePrice > 0
                        ? knowledgeDetail.playFlag !== 1
                          ? "2"
                          : "3"
                        : "1"
                    )
                  }
                >
                  {knowledgeDetail && knowledgeDetail?.salePrice > 0
                    ? knowledgeDetail.playFlag !== 1
                      ? "购买下载"
                      : "下载"
                    : "免费下载"}
                </div>
              </Row>
            </div>
          </div>
        </HeaderInfoWrapper>
      )}
    </>
  );
};

export default Info;
