import BaseLayout from "@/layouts";
import { BgImage } from "../order-platform/style";
import { GoTrain } from "./components/go-train";
import { JoinUs } from "./components/join-us";
import { EngineerWrapper } from "./style";

interface EngineerCertificationProps {}
const EngineerCertification: React.FC<EngineerCertificationProps> = () => {
  return (
    <BaseLayout>
      <EngineerWrapper>
        <BgImage bg={require("@/assets/images/engineer/engineer_bg.png")} />
        <JoinUs />
        <GoTrain />
      </EngineerWrapper>
    </BaseLayout>
  );
};

export default EngineerCertification;
