import styled from "styled-components";
import fullscreen from "@/assets/images/park/fullScreen.png";
export const FullscreenWrapper = styled.span<{ bgImage: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .icon {
    width: 28px;
    height: 28px;
    background: url(${(props) => props.bgImage || fullscreen}) no-repeat center;
    background-size: cover;
  }
  .text {
    color: #666;
    font-size: 12px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
  }
`;
