import styled from "styled-components";
import { Row, Tabs } from "antd";
import card_bg from "@/assets/images/solution/card_bg.png";
import case_icon from "@/assets/images/solution/case_icon.png";
import case_item_bg from "@/assets/images/solution/case-item-bg.png";
const { TabPane } = Tabs;
export const SolutionWrapper = styled.div`
  .header-container {
    width: 100%;
    min-width: 1440px;
    /* height: 400px; */
    position: relative;
    img {
      width: 100%;
      min-width: 1440px;
      height: 100%;
    }
    .container-info {
      width: 1200px;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-top: -128px;
      margin-left: -600px;
      .title {
        font-family: "RuiZiRuiXianNuFangHeiJian";
        font-size: 80px;
        font-weight: 400;
        line-height: 1;
        color: #ffffff;
      }
      .sub-info {
        font-size: 36px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        color: #ffffff;
        letter-spacing: 10px;
      }
      .desc {
        font-size: 18px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
        color: #ffffff;
        margin-bottom: 20px;
      }
    }
  }
`;

export const PlansWrapper = styled.div`
  margin: 122px auto 128px;
  width: 1200px;
`;

export const PlansItemContainer = styled.div<{ bgImage?: string }>`
  margin-bottom: 80px;
  width: 1200px;
  padding: 32px 46px 50px 30px;
  box-sizing: border-box;
  display: flex;
  background-color: #999;
  background-image: url(${(props) => (props.bgImage ? props.bgImage : "")});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .left-column {
    width: 214px;
    margin-right: 44px;
    .title {
      font-size: 24px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      color: #000;
    }
    .solgan {
      font-size: 12px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #3360f2;
      line-height: 20px;
    }
    .underline {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        bottom: -26px;
        width: 30px;
        height: 4px;
        background: #1848fd;
      }
    }
  }
  .right-column {
    flex: 1;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #333;
    .ant-row {
      padding-top: 44px;
    }
  }
`;

export const CardWrap = styled.div`
  display: flex;
  align-items: baseline;
  width: 203px;
  height: 90px;
  margin-top: 18px;
  /* justify-content: space-around; */
  background-color: #fff;
  padding: 24px 5px 0 8px;
  /* justify-content */
  margin-right: 12px;
  border-radius: 5px;
  &:nth-child(4n) {
    margin-right: 0;
  }

  .icon {
    width: 12px;
    height: 12px;
  }
  .list-item {
    margin-left: 5px;
    padding: 0 0 0 2px;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    /* text-align: center; */
    color: #333333;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    /* padding-right: 6px; */
  }
  &:hover {
    /* height: 104px; */
    background: url(${case_item_bg}) no-repeat center;
    background-size: cover;
    box-shadow: 0px 0px 11px 2px rgb(0 0 0 / 11%);
  }
`;

export const CusRow = styled(Row)<{ type?: number }>`
  & .card-wrap:nth-child(n + 5) {
    width: ${(props) => (props.type ? "162px" : "203px")};
    margin-right: ${(props) => (props.type ? "10px" : "12px")};
  }
  /* & .card-wrap:hover {
    height: 104px;
    background: url(${case_item_bg}) no-repeat center;
    background-size: 100%;
  } */
`;

export const CasedemoWrapper = styled.div`
  margin: 0 auto;
  width: 1200px;
`;

export const CusCaseTabs = styled(Tabs)`
  &.ant-tabs-top > .ant-tabs-nav::before {
    border: none;
  }
  &.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
    border-bottom-color: #f0f0f0;
  }
  &.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    display: flex;
    align-items: center;
    width: 275px;
    height: 88px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 2px 7px 2px rgba(0, 0, 0, 0.13);
    .ant-tabs-tab-btn {
      margin: 0 auto;
    }

    &.ant-tabs-tab-active {
      background: #1848fd;
    }
  }

  .ant-tabs-nav {
    .ant-tabs-nav-list {
      padding: 8px;
      width: 1200px;
      justify-content: space-between;
    }
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab:last-child {
    margin: 0;
  }
  .ant-tabs-tabpane {
    margin-bottom: 104px;
    width: 100%;
    height: 440px;
    background: url(${card_bg}) no-repeat center;
    background-size: 100% 100%;
    .ant-row {
      cursor: pointer;
      align-items: center;
      justify-content: space-between;
      padding: 46px 95px 48px 72px;
    }
    .left-tab {
      width: 330px;
      .left-title {
        position: relative;
        font-size: 30px;
        color: #fff;
        line-height: 42px;
        padding-bottom: 10px;
        margin-bottom: 56px;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 40px;
          height: 4px;
          background-color: #fff;
        }
      }
      .left-list {
        margin-left: -40px;
        display: flex;
        flex-wrap: wrap;
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
        line-height: 22px;
        .left-list-icon {
          margin-bottom: 9px;
          width: 47px;
          height: 47px;
        }
        .list-item {
          /* flex: 1; */
          width: 160px;
          margin-right: 10px;
          margin-bottom: 20px;
          &:nth-child(2n) {
            margin-right: 0px;
          }
        }
      }
    }
    .right-tab {
      padding: 20px 80px 20px 80px;
      display: flex;
      flex-direction: column;
      width: 648px;
      height: 345px;
      background: rgba(255, 255, 255, 0.47);
      border: 1px solid #ffffff;
      border-radius: 10px;
      backdrop-filter: blur(4px);
      .cate-title {
        margin-bottom: 10px;
        position: relative;
        padding-left: 45px;
        font-size: 24px;
        line-height: 33px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        color: #ffffff;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 9px;
          width: 40px;
          height: 18px;
          background: url(${case_icon}) no-repeat center;
          background-size: 40px 18px;
        }
      }
      .cate-sub {
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        height: 98px;
        overflow: hidden;
        color: #333333;
        line-height: 20px;
      }
    }
    .mab36 {
      margin-bottom: 16px;
    }
  }
`;
export const CusCaseCard = styled.div`
  width: 200px;
  height: 60px;
  img {
    width: 100%;
    height: 100%;
  }
`;
export const CusCasePane = styled(TabPane)``;
export const Join = styled.div`
  position: relative;
`;
