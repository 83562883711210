import { useEffect, useState } from "react";
import { CaseListWrapper, CasePane, CaseTabs } from "../style";
import CaseItem from "./case-item";
import { getCase } from "@/utils/api";
import { Divider, Spin, Empty } from "antd";
import { NavigationWithAuth } from "@/utils/common";
import InfiniteScroll from "react-infinite-scroll-component";
interface CaseListProps {}
export interface dataType {
  id: number;
  title: string;
  releaseTime: string;
  poster: string;
  introduction: string;
  exhibitionClient: string;
  exhibitionClientStr: string;
  top: number;
  sort: number;
  caseEffect: string;
  caseBackdrop: string;
  caseDemand: string;
  caseDetails: string;
  caseType: number;
  createTime: string;
  updateTime: string;
}
const CaseList: React.FC<CaseListProps> = () => {
  let [data, setData] = useState<any>([]);
  const [current, setCurrent] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [hasMore, setHasMore] = useState(false);
  let [index, setIndex] = useState(1);
  const tabs = [
    {
      title: "所有案例",
      key: 0,
    },
    {
      title: "服装服务案例",
      key: 1,
    },
    {
      title: "科技服务案例",
      key: 2,
    },
    {
      title: "产业服务案例",
      key: 3,
    },
    {
      title: "政府服务案例",
      key: 4,
    },
  ];
  const getAllcaseList = (params: any) => {
    getCase(params).then((res: any) => {
      if (res.code === 1) {
        let d: any = [];
        d = [...data, ...res.data];

        res.page && setHasMore(res.page.count > d.length);
        setData([...d]);
        setTotal(res.page ? res.page.count : 0);
      }
    });
  };
  useEffect(() => {
    getAllcaseList({ index: 1, size: 8, exhibitionClient: 1 });
  }, []);
  const onChange = (key: string) => {
    setCurrent(Number(key));
    data = [];
    setData([]);
    index = 1;
    setIndex(1);
    getAllcaseList({
      caseType: Number(key) ? Number(key) : null,
      index: index,
      size: 8,
      exhibitionClient: 1,
    });
  };
  const fetchData = () => {
    if (index * 8 >= total) {
      return false;
    }
    index++;

    setTimeout(() => {
      setIndex(index);
      getAllcaseList({
        index: index,
        size: 8,
        exhibitionClient: 1,
        caseType: current ? current : null,
      });
    }, 200);
  };
  return (
    <CaseListWrapper>
      <CaseTabs
        defaultActiveKey={"0"}
        onChange={onChange}
        // animated
        tabPosition="left"
      >
        {tabs &&
          tabs.map((tab) => (
            <CasePane tab={tab.title} key={tab.key} id="scrollableDiv">
              <InfiniteScroll
                className="pane-content"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
                scrollableTarget="scrollableDiv"
                dataLength={data.length} //This is important field to render the next data
                next={fetchData}
                hasMore={hasMore}
                loader={<></>}
                endMessage={data.length > 8 && <Divider>我是有底线的</Divider>}
                refreshFunction={() => {}}
                pullDownToRefreshThreshold={50}
              >
                {data && data.length > 0 ? (
                  data.map((item: dataType, index: number) => (
                    <CaseItem
                      data={item}
                      key={index}
                      onClick={() => {
                        NavigationWithAuth(`/case/case-detail/${item.id}`);
                        // history.push(`/case/case-detail/${item.id}`)
                      }}
                    />
                  ))
                ) : (
                  <div
                    style={{
                      display: "flex",
                      width: 956,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </div>
                )}
              </InfiniteScroll>
            </CasePane>
          ))}
      </CaseTabs>
    </CaseListWrapper>
  );
};

export default CaseList;
