import CusStep from "@/components/cus-steps";
import CusHeader from "@/layouts/cus-header";
import { useRef, useState } from "react";
import { CusButton } from "../persion/style";
import { RegistWrap } from "./style";
import { Form } from "antd";
import CusCode from "@/components/cus-code";
import type { FormInstance } from "antd/es/form";
import _ from "lodash";
import { CusInput, CusPassword } from "@/styles/common-style";
import { isPhone } from "@/utils/common";
interface RegistProps {}
const Regist: React.FC<RegistProps> = () => {
  const [currentId, setCurrentId] = useState<number>(0);
  const [text] = useState<string>("获取验证码");
  const formRef: any = useRef<FormInstance>();
  const formRef2: any = useRef<FormInstance>();
  let [num, setNum] = useState<number>(60);
  let timer: any = null;
  const onFinish = (values: any) => {
    setCurrentId(1);
    setNum(60);
    clearInterval(timer);
  };
  const onFinish2 = (values: any) => {
    setCurrentId(2);
    setNum(60);
    clearInterval(timer);
  };
  const validator = (_: any, value: any) => {
    if (value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("验证码不能为空"));
  };
  const getCode = _.throttle(
    () => {
      clearInterval(timer);
      num = 60;
      timer = setInterval(() => {
        if (num == 0) {
          clearInterval(timer);
          num = 60;
        } else {
          num--;
        }
        setNum(num);
      }, 1000);
    },
    60000,
    {
      leading: true,
      trailing: false,
    }
  );

  const steps = [
    {
      id: 1,
      title: "验证身份",
      content: () => {
        return (
          <Form
            name="basic"
            ref={formRef}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label=""
              name="phone"
              rules={[
                {
                  validator: (_: any, value: any) => {
                    if (value) {
                      if (isPhone(value)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(new Error("手机号格式不正确"));
                      }
                    } else {
                      return Promise.reject(new Error("请输入手机号"));
                    }
                    //
                  },
                },
              ]}
            >
              <CusInput placeholder="请输入手机号码" />
            </Form.Item>
            <Form.Item
              label=""
              name="newcode"
              rules={[{ validator: validator }]}
            >
              <CusCode
                width={370}
                placeholder="请输入短信验证码"
                disabled={num >= 60 ? false : true}
                onCodeClick={() => {
                  formRef.current
                    ?.validateFields(["phone"])
                    .then((values: any) => {
                      if (values.phone && isPhone(values.phone)) {
                        getCode();
                      }
                    });
                }}
                text={num >= 60 ? text : num + "秒"}
              />
            </Form.Item>
            <Form.Item>
              <CusButton
                type="primary"
                htmlType="submit"
                width={370}
                height={54}
              >
                下一步
              </CusButton>
            </Form.Item>
          </Form>
        );
      },
    },
    {
      id: 2,
      title: "完善账号信息",
      content: () => {
        return (
          <Form
            ref={formRef2}
            className="cus-form"
            onFinish={onFinish2}
            autoComplete="off"
          >
            <Form.Item label="登录密码" name="password">
              <CusPassword width={257} placeholder="请输入" />
            </Form.Item>
            <Form.Item label="密码确认" name="comfirmpassword">
              <CusPassword width={257} placeholder="请输入" />
            </Form.Item>
            <Form.Item label="公司名称" name="comfirmpassword">
              <CusInput width={257} placeholder="请输入" />
            </Form.Item>
            <Form.Item label="品牌名称" name="comfirmpassword">
              <CusInput width={257} placeholder="请输入" />
            </Form.Item>
            <Form.Item label="联系人" name="comfirmpassword">
              <CusInput width={257} placeholder="请输入" />
            </Form.Item>
            <Form.Item>
              <CusButton
                type="primary"
                htmlType="submit"
                width={257}
                height={54}
              >
                下一步
              </CusButton>
            </Form.Item>
          </Form>
        );
      },
    },
    {
      id: 3,
      title: "注册成功",
      content: () => {
        return (
          <div className="container">
            <span className="title">注册成功</span>
            <img src={require("@/assets/images/persion/success.png")} />
          </div>
        );
      },
    },
  ];
  return (
    <>
      <CusHeader title="注册" showSearch={false} type="regist" />
      <RegistWrap>
        <CusStep steps={steps} current={currentId} width={30} height={30} />
      </RegistWrap>
    </>
  );
};

export default Regist;
