import { ItemVo } from "../models/plans-items";
import { PlansWrapper } from "../style";
import PlansItem from "./plans-item";

interface PlansProps {}
const Plans: React.FC<PlansProps> = () => {
  const planData: ItemVo = {
    title: "数字化转型服务解决方案",
    info: "以数据驱动企业内各环节的高效协同，打通上下游间的物流和信息流，帮助企业降低运营成本，提升管理水平，依托丰富的全产业链资源及实战专家团队，快速助力企业数字化转型升级。",
    solgan: "DIGITAL TRANSFORMATION SERVICE SOLUTIONS",
    list: [
      {
        title: (
          <>
            <div>企业数字化转型</div>
            <div>顶层规划</div>
          </>
        ),
        path: "/digital",
      },
      {
        title: (
          <>
            <div>智能工厂咨询</div>
            <div>规划及实施</div>
          </>
        ),
        path: "/knitting-factory",
      },
      {
        title: (
          <>
            <div>智能制造项目</div>
            <div>招投标起草及咨询</div>
          </>
        ),
        type: true,
        // path: "/digital",
      },
      {
        title: (
          <>
            <div>智能制造项目</div>
            <div>软硬件总包及监理</div>
          </>
        ),
        type: true,
        // path: "/digital",
      },
      {
        title: "精益生产管理咨询及实施",
        path: "/implementation",
      },
      {
        title: "企业MES、WMS、PLM等工业软件规划",
        path: "/company-plan",
      },
      {
        title: (
          <>
            <div>智能针织无人工厂</div>
            <div>解决方案</div>
          </>
        ),
        path: "/knitting",
      },
      {
        title: (
          <>
            <div>“三衣两裤”智能制造</div>
            <div>解决方案</div>
          </>
        ),
        path: "/two-pants",
      },
    ],
  };
  const planData1: ItemVo = {
    title: "科技企业服务解决方案",
    info: "总结当下行业痛点，积极探寻突破方向，通过整合优质的市场资源，为行业发展的转型升级输出资源、标准、技术及专业人才。",
    solgan: "TECHNOLOGY ENTERPRISE SERVICE SOLUTIONS",
    list: [
      {
        title: (
          <>
            <div>数字化需求</div>
            <div>精准对接服务</div>
          </>
        ),
        type: true,
      },
      {
        title: <div>优秀解决方案供应商推介</div>,
        path: "/clothing",
      },
      {
        title: <div>智能制造标准制定及发布</div>,
        type: true,
      },
      {
        title: <div>数字化转型标准制定及发布</div>,
        type: true,
      },
      {
        title: (
          <>
            <div>中国服装工业互联网</div>
            <div>平台生态合作伙伴</div>
          </>
        ),
        type: true,
      },
      {
        title: <div>《中国服装数字化供应商选型目录》评选</div>,
        path: "https://mp.weixin.qq.com/s/qPZ-frUhWXPV7CkKQX0U2w",
      },
      {
        title: <div>中国服装数智化转型万里行高峰论坛</div>,
        path: "/clothing",
      },
      {
        title: (
          <>
            <div>时尚产业专业人才</div>
            <div>培训及认定</div>
          </>
        ),
        path: "/more-trains",
      },
    ],
  };
  const planData2: ItemVo = {
    title: "政府及产业服务解决方案",
    info: "产业大数据服务平台，聚焦产业数据集成，助力政府测有效把控当地产业发展态势，促进技术融合、产业融合、数据融合，推进决策科学化、治理精准化、服务高效化，构建区域内良性产业发展生态链。",
    solgan: "GOVERNMENT AND INDUSTRIAL SERVICE SOLUTIONS",
    list: [
      {
        title: <div>数字化快反工厂等级认定</div>,
        path: "/maintain",
      },
      {
        title: (
          <>
            <div>服装工业园数字化</div>
            <div>规划及咨询</div>
          </>
        ),
        path: "/industry-park",
      },
      {
        title: (
          <>
            <div>服装产业集群数字化</div>
            <div>规划及咨询</div>
          </>
        ),
        path: "/two-collect",
      },
      {
        title: (
          <>
            <div>服装工业互联网平台</div>
            <div>规划及咨询</div>
          </>
        ),
        path: "/industrial-internet",
      },
      {
        title: <div>地方智改数转评估诊断项目</div>,
        type: true,
      },
      {
        title: (
          <>
            <div>国家、省、市地方</div>
            <div>数字化标杆项目申报</div>
          </>
        ),
        type: true,
      },
      {
        title: <div>《区域服装产业规划&发展报告》</div>,
        path: "/two-area",
      },
      // {
      //   title: (
      //     <>
      //       <div>区域数字化转型</div>
      //       <div>高峰论坛</div>
      //     </>
      //   ),
      // },
      // {
      //   title: (
      //     <>
      //       <div>区域服装行业职业</div>
      //       <div>技能等级认证</div>
      //     </>
      //   ),
      // },
      // {
      //   title: (
      //     <>
      //       <div>区域产业链大数据</div>
      //       <div>管理平台</div>
      //     </>
      //   ),
      // },
    ],
  };
  return (
    <PlansWrapper>
      <PlansItem
        data={planData}
        bgImage={require("@/assets/images/solution/list_bg1.png")}
      ></PlansItem>
      <PlansItem
        data={planData1}
        bgImage={require("@/assets/images/solution/list_bg2.png")}
      ></PlansItem>
      <PlansItem
        data={planData2}
        // type={5}
        bgImage={require("@/assets/images/solution/list_bg3.png")}
      ></PlansItem>
    </PlansWrapper>
  );
};

export default Plans;
