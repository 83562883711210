export default class ClusterBubble extends TMap.DOMOverlay {
  constructor(options) {
    super(options);
  }
  // 初始化：获取配置参数
  onInit(options = {}) {
    Object.assign(this, { ...options });
    // console.log(this, "this", options)
  }
  createDOM() {
    this.onClick = () => {
      let center = this.position;
      let zoom = this.map.getZoom();
      if (zoom < 13) {
        this.map.setCenter(center);
        this.map.setZoom(13);
      } else if (zoom >= 13 && zoom < 15) {
        this.map.setCenter(center);
        this.map.setZoom(15);
      }
      // DOMOverlay继承自EventEmitter，可以使用emit触发事件
      this.emit("click");
    };
    var dom = document.createElement("div");

    this.destory = () => {
      dom.remove();
      this.emit("destory");
    };
    dom.classList.add("my-overlay");
    // dom.setAttribute("id", "my-overlay") // 覆盖物类名
    dom.innerText = this.content;
    dom.setAttribute(
      "title",
      `${this.properties.city}共${this.properties.count}个`
    );
    dom.innerHTML = `<div class="count" >${this.content}</div>`;
    // dom.style.cssText = [
    //   "width: " + (40 + parseInt(this.content) * 2) + "px;",
    //   "height: " + (40 + parseInt(this.content) * 2) + "px;",
    //   "line-height: " + (40 + parseInt(this.content) * 2) + "px;",
    // ].join(" ")

    // 监听点击事件，实现zoomOnClick
    this.onClick = this.onClick.bind(this);
    // pc端注册click事件，移动端注册touchend事件
    dom.addEventListener("click", this.onClick);
    dom.addEventListener("destory", this.onClick);
    return dom;
  }

  updateDOM() {
    if (!this.map) {
      return;
    }
    // 经纬度坐标转容器像素坐标
    let pixel = this.map.projectToContainer(this.position);

    // 使文本框中心点对齐经纬度坐标点
    let left = pixel.getX() - this.dom.clientWidth / 2 + "px";
    let top = pixel.getY() - this.dom.clientHeight / 2 + "px";
    this.dom.style.transform = `translate(${left}, ${top})`;
    this.emit("dom_updated");
  }
  onDestroy() {
    this.dom.removeEventListener("click", this.onClick);
    this.dom.removeEventListener("destory", this.onClick);
    this.removeAllListeners();
    this.emit("onDestroy");
  }
}
