import { Title } from "@/styles/common-style";
import { Train2Wrapper } from "../style";

interface Train2Props {}
export const Train2: React.FC<Train2Props> = () => {
  const train2Data = [
    {
      title: "现场培训中心",
      info: "超大的培训中心，讲师现场为您讲",
      bg: require("@/assets/images/more-trains/bg1.png"),
    },
    {
      title: "虚拟课堂",
      info: "无论在家中还是在办公室，都能让您舒适的展开学习",
      bg: require("@/assets/images/more-trains/bg2.png"),
    },
    {
      title: "视频课程",
      info: "在家中、办公室或路上，随时随地观看学习。",
      bg: require("@/assets/images/more-trains/bg3.png"),
    },
  ];
  return (
    <Train2Wrapper>
      <Title
        fz={36}
        textAlign="center"
        fw={600}
        color="#000"
        style={{
          marginBottom: 54,
          marginTop: 46,
          fontFamily: " PingFangSC, PingFangSC-Semibold",
        }}
      >
        培训方式
      </Title>
      <div className="train2-list">
        {train2Data.map((item: any, index: number) => (
          <div
            className="train2-list-item"
            key={index}
            // style={{
            //   background: `url(${item.bg}) no-repeat center`,
            //   backgroundSize: "100%",
            // }}
          >
            <div className="item-title">{item.title}</div>
            <div className="item-info">{item.info}</div>
            <img src={item.bg} alt="" className="item-img" />
            {/* <ConsultNow
              name={item.title}
              style={{
                position: "absolute",
                left: index == 0 || index == 1 || index == 2 ? 38 : 30,
                bottom: 20,
              }}
            /> */}
          </div>
        ))}
      </div>
    </Train2Wrapper>
  );
};
