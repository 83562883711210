import BaseLayout from "@/layouts";
import { BannarInfo, Title } from "@/styles/common-style";
import { PolicyListWrapper, PolicyWrapper, Poster } from "./style";
import { Divider, List, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { history } from "umi";
import moment from "moment";
import { getPolicyList } from "@/utils/api";
interface DataType {
  id: number;
  title: string;
  releaseTime: string;
  poster: string;
  introduction: string;
  exhibitionPlatform: string;
  exhibitionPlatformStr: string;
  sort: number;
  enclosure: string;
  detail: string;
  createTime: string;
  updateTime: string;
}
interface PolicyListProps {}

const PolicyList: React.FC<PolicyListProps> = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DataType[]>([]);
  const [count, setCount] = useState<number>(0);
  let [indexCount, setIndexCount] = useState(1);
  const loadMoreData = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    indexCount++;
    setIndexCount(indexCount);
    // });

    getPolicyList({
      index: indexCount,
      size: 10,
    })
      .then((res: any) => {
        setLoading(false);
        setData([...data, ...res.data]);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    window.scroll(0, 0);
    getPolicyList({
      index: indexCount,
      size: 10,
    }).then((res: any) => {
      if (res.code === 1) {
        setData(res.data);
        setCount(res.page.count);
      }
    });
  }, []);
  return (
    <BaseLayout>
      <PolicyWrapper>
        <BannarInfo bgImage={require("@/assets/images/policy/policy_bg.png")} />
        <PolicyListWrapper>
          <InfiniteScroll
            dataLength={data.length}
            next={loadMoreData}
            hasMore={data.length < count}
            loader={<></>}
            endMessage={<Divider plain>我是有底线的</Divider>}
          >
            <List
              dataSource={data}
              renderItem={(item) => (
                <List.Item
                  key={item.id}
                  onClick={() => {
                    history.push({
                      pathname: `/policy/policy-detail/${item.id}`,
                      search: "?isProlicy=true",
                    });
                  }}
                >
                  <Poster bgImg={item.poster} />
                  <div className="item-content">
                    <Title fz={30} fw={500} color="#333" className="item-title">
                      {item.title}
                    </Title>
                    <div className="item-time">
                      {moment(item.releaseTime).format("YYYY-MM-DD hh:mm")}
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.introduction,
                      }}
                      className="item-product ql-editor"
                    ></div>
                    <div className="item-detail">
                      <span>查看详情</span> &gt;{" "}
                    </div>
                  </div>
                </List.Item>
              )}
            />
          </InfiniteScroll>
        </PolicyListWrapper>
      </PolicyWrapper>
    </BaseLayout>
  );
};

export default PolicyList;
