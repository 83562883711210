import { Title } from "@/styles/common-style";
import { PartnerWrapper } from "../style";

interface PartnerProps {
  noDownload?: boolean;
  url?: string;
  title?: string;
  subTitle?: string;
}
export const Partner: React.FC<PartnerProps> = ({
  noDownload = false,
  title = "他们都在这里找到了合作伙伴",
  subTitle = "让服装生产简单高效",
  url,
}) => {
  let iconlist = [
    { img: require("@/assets/images/order-platform/icon/icon1.png") },
    { img: require("@/assets/images/order-platform/icon/icon2.png") },
    { img: require("@/assets/images/order-platform/icon/icon3.png") },
    { img: require("@/assets/images/order-platform/icon/icon4.png") },
    { img: require("@/assets/images/order-platform/icon/icon5.png") },
    { img: require("@/assets/images/order-platform/icon/icon6.png") },
    { img: require("@/assets/images/order-platform/icon/icon7.png") },
    { img: require("@/assets/images/order-platform/icon/icon8.png") },
    { img: require("@/assets/images/order-platform/icon/icon9.png") },
    { img: require("@/assets/images/order-platform/icon/icon10.png") },
    { img: require("@/assets/images/order-platform/icon/icon11.png") },
    { img: require("@/assets/images/order-platform/icon/icon12.png") },
    { img: require("@/assets/images/order-platform/icon/icon13.png") },
    { img: require("@/assets/images/order-platform/icon/icon14.png") },
    { img: require("@/assets/images/order-platform/icon/icon15.png") },
    { img: require("@/assets/images/order-platform/icon/icon16.png") },
  ];
  return (
    <PartnerWrapper>
      <Title fz={36} fw={600} color="#000" textAlign="center">
        {title}
      </Title>
      {subTitle && <div className="sub-title">{subTitle}</div>}
      <div className="content-logo">
        {iconlist.map((icon, index) => (
          <div className="icon-wrap" key={index}>
            <img src={icon.img} className="icon" alt={icon.img} key={index} />
          </div>
        ))}
      </div>
      {!noDownload && (
        <div className="download-logo">
          <img
            src={
              url || require("@/assets/images/order-platform/order-dowload.png")
            }
            alt=""
          />
        </div>
      )}
    </PartnerWrapper>
  );
};
