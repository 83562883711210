import styled from "styled-components";

export const DeveloperCenterWrapper = styled.div``;

export const DCenterInfoWrapper = styled.div`
  margin: 108px auto 128px;
  width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .card {
    width: 231px;
    height: 285px;
    padding: 40px 0 30px;
    box-sizing: border-box;
    background: #edf1f8;
    border-radius: 10px;
  }
  .icon {
    margin: 0 auto 35px;
    width: 84px;
    height: 84px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .intro {
    margin-top: 12px;
    text-align: center;
    font-size: 18px;
    font-family: Alibaba, Alibaba-Regular;
    font-weight: 400;
    color: #666666;
  }
`;
