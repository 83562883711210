import styled from "styled-components";

export const MoreTrainsWrapper = styled.div``;

export const Train1Wrapper = styled.div`
  width: 100%;
  min-width: 1440px;
  padding: 84px 0 84px 0;
  background: #fff;
  .train1-list {
    margin: 0 auto;
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .item {
    width: 230px;
    height: 562px;

    border-top: none;
    position: relative;
    margin-right: 8px;
    margin-bottom: 10px;
    .img {
      width: 100%;
      height: 150px;
    }
  }
  .item-int {
    width: 230px;
    height: 412px;
    padding: 22px 15px 0 15px;
    box-sizing: border-box;
    border: 1px solid #d8d8d8;
  }
  .item-title {
    text-align: center;
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    color: #000000;
  }
  .item-info {
    margin-top: 8px;
    font-size: 18px;
    font-family: Alibaba, Alibaba-Regular;
    font-weight: 400;
    color: #666666;
    line-height: 26px;
  }

  .bg1 {
    /* background: ; */
  }
`;
export const Train2Wrapper = styled.div`
  width: 100%;
  min-width: 1440px;
  background: #edf1f8;
  padding: 40px 0 64px 0;
  .train2-list {
    margin: 0 auto;
    width: 1200px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .train2-list-item {
      position: relative;
      width: 400px;
      height: 468px;

      /* padding-left: 30px;?/ */
      padding: 44px 35px 35px 35px;
      background-color: #fff;
      box-sizing: border-box;
    }
  }
  .item-title {
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    color: #000000;
  }
  .item-info {
    height: 80px;
    line-height: 36px;
    margin-top: 8px;
    font-size: 18px;
    font-family: Alibaba, Alibaba-Regular;
    font-weight: 400;
    color: #666666;
    /* line-height: 30px; */
  }
  .item-img {
    margin-top: 30px;
    width: 328px;
    height: 236px;
  }
`;

export const ConsultNowWrapper = styled.div`
  cursor: pointer;
  width: 90px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #1848fd;
  font-size: 18px;
  font-family: Alibaba, Alibaba-Regular;
  font-weight: 400;
  color: #1848fd;
`;

export const Trans3Wrapper = styled.div`
  width: 100%;
  min-width: 1440px;
  background: #ffffff;
  padding: 94px 0 42px 0;
  .info-card {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 0 auto;
    width: 1200px;
    height: 450px;
    padding: 46px 64px 54px 62px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 2px 7px 2px rgba(0, 0, 0, 0.21);
    .ant-row.item {
      margin-bottom: 20px;
      .icon {
        margin-right: 15px;
        width: 15px;
        height: 15px;
        background: #1848fd;
        border-radius: 50%;
      }
      .item-info {
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        font-size: 24px;
        color: #000;
      }
    }
    .list-item {
      display: flex;
      flex-direction: column;
      margin-right: 50px;
      &:last-child {
        margin-right: 0;
      }
      .num-text {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: center;
        color: #ff3b30;
        .num {
          font-size: 30px;
        }
      }
      .list-info {
        font-size: 18px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: center;
        color: #666666;
      }
    }
    .img {
      width: 369px;
      height: 263px;
    }
  }
`;
