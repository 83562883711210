import home_hover_bg from "@/assets/images/home/home-hover-bg.png";
import styled from "styled-components";
import { Tabs } from "antd";
import server_bg from "@/assets/images/home/server_bg.png";
const { TabPane } = Tabs;
export const SeverWrapper = styled.div`
  min-width: 1440px;
  margin-bottom: 133px;
  .server-title {
    margin-bottom: 48px;
  }
`;
export const SeverTabs = styled(Tabs)<{ noBorder?: boolean }>`
  .ant-tabs-nav {
    margin-bottom: 38px;
  }
  .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    text-align: center;
    color: #666666;
  }
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #333333;
  }
  &.ant-tabs-top > .ant-tabs-nav::before {
    border-bottom-color: ${(props) =>
      props.noBorder ? "transparent" : "f0f0f0"};
  }
  .ant-tabs-content-holder {
    width: 1200px;
    margin: 0 auto;
  }
  .ant-tabs-nav .ant-tabs-ink-bar {
    height: 5px;
    background: #1848fd;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 100px;
  }
  .server-content {
    display: flex;
    flex: 1;
    align-items: center;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    .server-item {
      position: relative;
      width: 290px;
      height: 300px;
      padding: 21px;
      box-sizing: border-box;
      margin-bottom: 10px;
      margin-right: 13px;
      background: #eff5ff;
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:hover {
        background: url(${home_hover_bg}) no-repeat center;
        background-size: cover;
      }
      .img-wrap {
        margin-right: 18px;
        width: 63px;
        height: 63px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        .server-logo {
          width: 100%;
          max-height: 100%;
        }
      }
      .header-title {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        color: #000000;
      }
      .server-items {
        display: flex;
        margin-bottom: 16px;
        /* align-items: center; */
      }
      .content-info {
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
        color: #333333;
      }
      .look-more {
        position: absolute;
        bottom: 30px;
        font-size: 16px;
        width: 100px;
        height: 40px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        background: #3460f2;
        border-radius: 2px;
        box-shadow: 0px 2px 7px 2px rgba(27, 71, 215, 0.3);
      }
    }
  }
  .look-more {
    height: 60px;
    background-color: #eeeeef;
    text-align: center;
    line-height: 60px;
    font-size: 21px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #1848fd;
  }
`;
export const SeverTabPane = styled(TabPane)``;
export const SeverTab = styled(TabPane)`
  .server-content {
    display: flex;
    flex: 1;
    border: 1px solid #d8d8d8;
    border-bottom: none;
    flex-wrap: wrap;
    .server-item {
      display: flex;
      width: calc(50% - 7px);
      height: 142px;
      padding: 16px 26px;
      box-sizing: border-box;
      /* align-items: center; */
      border-right: 1px solid #d8d8d8;
      border-bottom: 1px solid #d8d8d8;
      /* &:last-child {
        border-bottom: none;
      } */
      &:nth-child(even) {
        border-right: none;
      }
    }

    .img-wrap {
      margin-right: 25px;
      width: 98px;
      height: 98px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      .server-logo {
        width: 78px;
        max-height: 78px;
      }
    }
    .server-info {
      flex: 1;
      .header-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .ant-btn {
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          color: #1848fd;
        }
      }
      .header-title {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: center;
        color: #000000;
      }
      .content-info {
        margin-top: 10px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        color: #333333;
      }
    }
    /* .left {
      margin-right: 33px;
      width: 270px;
      display: flex;
      align-items: center;
      flex-direction: column;
    } */
    .right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 862px;
      height: 345px;
      padding: 36px 40px 31px 40px;
      box-sizing: border-box;
      background: url(${server_bg}) no-repeat center;
      background-size: 100%;
      .right-img {
        width: 379px;
        height: 270px;
      }
      .right-content-info {
        width: 370px;
        height: 270px;
        padding: 26px 20px 0 24px;
        box-sizing: border-box;
        background-color: #fff;
      }
      .right-title {
        line-height: 42px;
        margin-bottom: 29px;
        position: relative;
        &::after {
          position: absolute;
          content: "";
          left: 0;
          bottom: -10px;
          width: 30px;
          height: 4px;
          background: #1848fd;
        }
      }
    }
  }
`;

export const ServerCard = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 19px;
  width: 270px;
  height: 100px;
  padding: 20px;
  background: linear-gradient(180deg, #f8f8ff, #ffffff);
  border: 2px solid #ffffff;
  border-radius: 2px;
  box-shadow: 0px 9px 7px 0px rgba(0, 0, 0, 0.12);
  .img {
    width: 34px;
    height: 34px;
  }
  .left-card-title {
    margin-left: 24px;
    color: #333333;
  }
  &.left-card-active .left-card-title {
    color: #1848fd;
  }
`;
