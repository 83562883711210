import { CusButton } from "@/styles/common-style";
import LoginForm from "./components/login-form";
import { LoginContainer, LoginHeader, LoginWrapper } from "./style";
import { history } from "umi";
import { GetIsLinpin, GetToken, GetUserInfo } from "@/utils/common";
import { useEffect, useState } from "react";
import { HeaderTitle } from "@/layouts/header-title";
interface LoginProps {}
const Login: React.FC<LoginProps> = () => {
  const token = GetToken();
  let id = GetUserInfo()?.account_id;
  useEffect(() => {
    if (token && id) {
      history.replace("/");
    }
  }, [token]);

  return (
    <LoginWrapper>
      <LoginHeader>
        <HeaderTitle hasSelect={false} />
        <CusButton
          type="primary"
          style={{ fontSize: "12px" }}
          onClick={() => history.push("/")}
        >
          回到首页
        </CusButton>
      </LoginHeader>
      <LoginContainer>
        <img
          src={require("@/assets/images/login/login_wrap.png")}
          className="login-img"
          alt="login"
        />
        <LoginForm />
      </LoginContainer>
    </LoginWrapper>
  );
};

export default Login;
