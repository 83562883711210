import { Row, Col } from "antd";
import Title from "@/components/title";
import { AdvantageCardItem } from "@/models/ecological";

const titleStyle = {
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "44px",
} as any;

const ADVANTAGECARD: AdvantageCardItem[] = [
  {
    key: "1",
    title: "开放共享",
    icon: require("@/assets/images/icon1.png"),
    desc:
      "开放、协同、共赢的生态体系，帮助合作伙伴快速融入最具活力的中国数字时尚产业生态圈",
  },
  {
    key: "2",
    title: "专业领域",
    icon: require("@/assets/images/icon2.png"),
    desc:
      "深耕服装行业智能制造，帮助合作伙伴构建产业智能化解决方案，建立业界领先的竞争优势",
  },
  {
    key: "3",
    title: "接入生态",
    icon: require("@/assets/images/icon3.png"),
    desc: "打造接入生态，支持无线和有线接入平台能力，实现数据的快速对接",
  },
  {
    key: "4",
    title: "数据生态",
    icon: require("@/assets/images/icon4.png"),
    desc:
      "打造数据模型生态，允许第三方专家和厂商开发、分享数据模型，实现数据有效分析",
  },
  {
    key: "5",
    title: "应用生态",
    icon: require("@/assets/images/icon5.png"),
    desc:
      "打造服装行业数字化应用生态，支持多场景业务的数据应用能力，构建第三方专家及厂商共享应用模式",
  },
  {
    key: "6",
    title: "移动生态",
    icon: require("@/assets/images/icon6.png"),
    desc:
      "打造基于不同技术框架的移动应用生态，从不同的用户类型，不同的行业，以及专业程度来进行构建",
  },
  {
    key: "7",
    title: "市场营销",
    icon: require("@/assets/images/icon7.png"),
    desc:
      "与伙伴联合开展系列市场营销活动，如行业沙龙、技术Open Day、高端客户论坛、行业展会、新媒体推广等",
  },
  {
    key: "8",
    title: "培训赋能",
    icon: require("@/assets/images/icon8.png"),
    desc:
      "采用线上线下有机结合的方式，向伙伴提供各项技术培训、技术认证，帮助伙伴提升技术应用和技术服务能力",
  },
];
const AdvantageCard: React.FC = () => {
  return (
    <Row className="co-card-container">
      {ADVANTAGECARD.map((item) => (
        <Col className="card-item" key={item.key}>
          <div className="card-header">
            <span className="card-title">{item.title}</span>
            <img className="card-icon" src={item.icon} alt="icon" />
          </div>
          <div className="card-content">{item.desc}</div>
        </Col>
      ))}
    </Row>
  );
};

const CoAdvantage: React.FC = () => {
  return (
    <div className="co-advantage">
      <Title text="生态优势" titleStyle={titleStyle} />
      <AdvantageCard />
    </div>
  );
};

export default CoAdvantage;
