import { CusButton, CusInput } from "@/styles/common-style";
import { useState } from "react";
import { CSSProperties } from "styled-components";
import { CodeInputWrapper } from "./style";

interface CusCodeProps {
  disabled?: boolean;
  text: string;
  width?: number; // 整个验证码框的宽度
  inputWidth?: number;
  inputHeight?: number;
  buttonWidth?: number;
  BtnStyle?: CSSProperties;
  onChange?: (value: string) => void;
  onCodeClick?: () => void;
  placeholder?: string;
}
const CusCode: React.FC<CusCodeProps> = ({
  onCodeClick,
  disabled,
  text = "获取验证码",
  inputWidth = 240,
  inputHeight = 54,
  buttonWidth = 116,
  BtnStyle,
  width = 370,
  onChange,
  placeholder = "",
}) => {
  const [value, setValue] = useState("");
  return (
    <CodeInputWrapper width={width}>
      <CusInput
        placeholder={placeholder ? placeholder : "请输入验证码"}
        maxLength={6}
        width={inputWidth}
        height={inputHeight}
        value={value}
        onChange={(e) => {
          setValue(e.target.value.replace(/[^\d]/g, ""));
          onChange?.(e.target.value.replace(/[^\d]/g, ""));
        }}
      />
      <CusButton
        style={{ fontSize: "12px", ...BtnStyle }}
        width={buttonWidth}
        height={inputHeight}
        disabled={disabled}
        onClick={() => {
          onCodeClick?.();
        }}
      >
        {text}
      </CusButton>
    </CodeInputWrapper>
  );
};

export default CusCode;
