import BaseFooter from "@/layouts/base-footer";
import CusHeader from "@/layouts/cus-header";
import { useLocation } from "umi";
import { useEffect, useState } from "react";
import { ServerDetailVo, useDispatch } from "umi";
import ServiceList from "./components/service-list";
import TagSelector from "./components/tag-select";
import { AllServicesWrapper } from "./style";
import { getKnowledgeList } from "@/api/knowledge";
interface KnowledgeMap {}
interface ServiceVo {
  recommend?: string | null | undefined;
  sortPrice?: string | null | undefined;
  multipleOrder?: string | null | undefined;
  priceRange: number | null;
  priceType?: number | null;
  cateSpgId?: number | null;
  title?: string;
  index: number;
  size: number;
  userRole?: number;
  deliveryMethod?: number | null;
  sortOrder?: number | null;
  cateId?: number | null;
}
const AllServices: React.FC<KnowledgeMap> = () => {
  const dispatch = useDispatch();
  const { state, ...location }: any = useLocation<{
    keyword: string;
    title: string;
  }>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [request, setRequest] = useState<ServiceVo>({
    index: 1,
    size: 20,
    userRole: 3,
    title: state?.keyword,
    cateId: state?.cateId,
  } as ServiceVo);
  const [serverList, setServerList] = useState<ServerDetailVo[]>([]);
  useEffect(() => {
    dispatch({
      type: "knowledge/getKnowledgeCastes",
      payload: {},
    });
  }, []);
  const getList = (i: any) => {
    request.index = i;
    setLoading(true);
    getKnowledgeList(request)
      .then((res: any) => {
        if (res.code === 1) {
          setServerList(res.data);
          // setHasMore(res.page.count > [...serverList, ...res.data].length)
          setTotal(res.page?.count || 0);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const loadMoreData = (value: any) => {
    getList(value);
  };
  useEffect(() => {
    if (Object.keys(request).length > 0) {
      getList(1);
    }
  }, [request]);
  return (
    <>
      <CusHeader
        title={"知识图谱"}
        showSearch
        val={state?.keyword}
        placeholder="请输入您需要的知识标题"
        search={(value) => {
          request.title = value;
          setServerList([]);
          request.index = 1;

          setRequest({ ...request });
        }}
      />
      <AllServicesWrapper>
        <TagSelector
          serverClick={(value) => {
            setServerList([]);
            request.index = 1;
            request.cateId = value == "-1" ? null : Number(value);
            setRequest({ ...request });
          }}
          subserverClick={(value) => {
            setServerList([]);
            setCurrentPage(1);
            request.index = 1;
            request.cateId = value == "-1" ? null : Number(value);
            setRequest({ ...request });
          }}
          // payCateClick={(value) => {
          //   setServerList([])
          //   request.index = 1
          //   setCurrentPage(1)
          //   request.deliveryMethod = value == "-1" ? null : Number(value)
          //   setRequest({ ...request })
          // }}
          // priceCateClick={(value) => {
          //   setServerList([])
          //   setCurrentPage(1)
          //   request.index = 1
          //   request.priceRange = value == "-1" ? null : Number(value)
          //   setRequest({ ...request })
          // }}
        />
        <ServiceList
          serverList={serverList}
          loading={loading}
          loadMoreData={loadMoreData}
          currentPage={request.index}
          total={total}
          sortby1={(value) => {
            setServerList([]);
            request.index = 1;
            request.cateSpgId = null;
            setRequest({ ...request });
          }}
          sortby2={(value) => {
            setServerList([]);
            request.index = 1;

            request.cateSpgId = -1;
            setRequest({ ...request });
          }}
          sortby3={(value) => {
            setServerList([]);
            request.index = 1;
            request.cateSpgId = 0;
            setRequest({ ...request });
          }}
        />
      </AllServicesWrapper>
      <BaseFooter />
    </>
  );
};

export default AllServices;
