import { ListProps } from '@/models/tab';
import { useEffect, useState } from 'react';

import './index.less';
interface CusTabProps {
  list: ListProps[];
  defaultKey?: string;
  onTabClick?: (item: ListProps) => void;
}
const CusTab: React.FC<CusTabProps> = ({ list, onTabClick, defaultKey }) => {
  const [itemKey, setItemKey] = useState<string>('1');
  useEffect(() => {
    if (defaultKey) {
      setItemKey(defaultKey);
    }
  }, [defaultKey]);
  const tabClick = (item: ListProps) => {
    onTabClick && onTabClick(item);
    setItemKey(item.key);
  };
  return (
    <div className="cus-tab-container">
      {list &&
        list.length > 0 &&
        list.map((item) => {
          return (
            <div
              key={item.key}
              onClick={() => tabClick(item)}
              className={
                itemKey === item.key
                  ? 'cus-tab-item cus-tab-item-active'
                  : 'cus-tab-item'
              }
            >
              {item.title}
            </div>
          );
        })}
    </div>
  );
};

export default CusTab;
