import { CardWrapper, FinancialWrapper } from "../style";
import { useEffect, useState } from "react";
import { Title } from "@/styles/common-style";
import { ContactUs } from "@/utils/common";
import { useSelector } from "umi";
interface CardListVo {
  img: string;
  metadataName: string;
  id: string | number;
  introduction: string;
}
const FinancialCate: React.FC = () => {
  const { serverCates } = useSelector((state: any) => state.server);
  const [cardList, setcCrdList] = useState<CardListVo[]>([
    {
      img: require("@/assets/images/server/icon_1.png"),
      id: new Date().getTime(),
      metadataName: "贷款",
      introduction:
        "应付特如其来的财务需要，平台提供的一站式贷款服务均能为您度身订造合适的贷款计划，而且申请手续简便，批核灵活，令您借贷倍感轻松简易",
    },
    {
      img: require("@/assets/images/server/icon_2.png"),
      id: new Date().getTime(),
      metadataName: "融资租凭",
      introduction:
        "线上与线下服务并重，标准化与个性化需求互补”，专注于提供资本与项目的精准对接，提全方位的企业解决方案",
    },
    {
      img: require("@/assets/images/server/icon_3.png"),
      id: new Date().getTime(),
      metadataName: "保险",
      introduction:
        "化解企业经营风险，提高企业风险管理能力，为您的企业发展保驾护航。",
    },
  ]);
  const [serverCate, setServerCate] = useState<any>();
  useEffect(() => {
    let item = serverCates.filter((item: any) => item.id === 416)[0];
    setServerCate(item);
  }, []);
  return (
    <FinancialWrapper>
      <Title style={{ width: "1200px", margin: "0 auto 20px" }}>
        {serverCate?.metadataName}
      </Title>
      <div className="card-wrapper">
        {cardList.map((item: any, index) => (
          <CardWrapper
            key={index}
            onClick={() => {
              ContactUs("金融服务");
            }}
          >
            <div className="content">
              <img src={item.img} className="card-img" />
              <div className="info">
                <div className="title">{item.metadataName}</div>
                <div className="intro">{item.introduction}</div>
              </div>
            </div>
            {/* <div className="card-footer">了解详情</div> */}
          </CardWrapper>
        ))}
      </div>
    </FinancialWrapper>
  );
};

export default FinancialCate;
