import request from "@/utils/request";

// 创建咨询
export const createConsult = (params: any) => {
  return request("/business/show/consult/create", {
    method: "POST",
    requestType: "json",
    data: params,
  });
};

// 展示设备详情

export const getDetail = (id: any) => {
  return request(`/business/show/device/scanQrCode/${id}`, {
    method: "get",
    requestType: "json",
  });
};

// vistor
export const createVisitor = (params: any) => {
  return request(`/business/visitorApplication/create`, {
    method: "POST",
    requestType: "json",
    data: params,
  });
};

//枚举
export const getCommonByName = (params: any) => {
  return request(`/setting/app/getCommonByName`, {
    method: "POST",
    requestType: "json",
    data: params,
  });
};

///business/visitorApplication/queryById/

export const queryById = (id: any) => {
  return request(`/business/visitorApplication/queryById/${id}`, {
    method: "get",
    requestType: "json",
  });
};
