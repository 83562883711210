import styled from "styled-components";
import { Swiper } from "swiper/react/swiper-react";

export const HomeSliderWrapper = styled(Swiper)`
  width: 100%;
  min-width: 1440px;
  height: auto;
  .slider-img {
    width: 100%;
    height: 100%;
  }
  .slide-wrap {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1300px;
    height: 100%;
    margin-left: -650px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
  }
  .slide-title {
    font-size: 100px;
    font-family: "RuiZiRuiXianNuFangHeiJian";
    font-weight: 400;
  }
  .slide-cneter {
    font-size: 100px;
    font-family: "RuiZiRuiXianNuFangHeiJian";
    text-align: center;
  }
  .slide-subtitle {
    font-size: 24px;
    font-family: Alibaba, Alibaba-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    line-height: 1;
    letter-spacing: 30px;
  }
  .slide-sub-center {
    text-align: center;
    font-size: 40px;
  }
  .swiper-pagination-bullets {
    bottom: 36px;
  }
  .swiper-pagination-bullet {
    width: 40px;
    height: 6px;
    border-radius: 2px;
  }
  .swiper-pagination-bullet-active {
    background: #d8d8d8;
  }
`;
