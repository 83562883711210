import { OrderDescItem, OrderDescWrapper } from "@/pages/order-platform/style";
import { Title } from "@/styles/common-style";

interface OrderDescProps {}
export const OrderDesc: React.FC<OrderDescProps> = () => {
  const OrderDescList = [
    {
      title: "海量产能资源",
      desc:
        "10万+企业数据库 构建中国服装产能地图,覆盖中国62个服装产业集群优质工厂",
      bg: require("@/assets/images/order-platform/order_desc_1.png"),
    },
    {
      title: "权威等级认定",
      desc: "中国服装数字化快反工厂等级权威认定,共建中国优质制造可信体系",
      bg: require("@/assets/images/order-platform/order_desc_2.png"),
    },
    {
      title: "精准匹配工厂",
      desc: "地区、规模、类型等多角度精准筛选,快速匹配工厂资源",
      bg: require("@/assets/images/order-platform/order_desc_3.png"),
    },
    {
      title: "担保交易 安全保障",
      desc: "平台实名认证安全可靠 靠谱第三方支付担保",
      bg: require("@/assets/images/order-platform/order_desc_4.png"),
    },
  ];
  const OrderDescInfo = ({ title, desc, bg, index }: any) => {
    return (
      <OrderDescItem bg={bg}>
        <Title fz={24} fw={600} color="#000">
          {title}
        </Title>
        <div className="desc">
          {desc.split(",").map((item: any, index: number) => (
            <div key="index">{item}</div>
          ))}
        </div>
      </OrderDescItem>
    );
  };
  return (
    <OrderDescWrapper>
      <Title
        fz={36}
        fw={600}
        color="#000"
        textAlign="center"
        style={{ marginBottom: 44 }}
      >
        产能中心
      </Title>
      <div className="order-desc">
        {OrderDescList.map((item, index) => (
          <OrderDescInfo key={index} {...item} index={index} />
        ))}
      </div>
    </OrderDescWrapper>
  );
};
