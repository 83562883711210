import { Row } from "antd";
interface BannarInfoProps {}
const BannarInfo: React.FC<BannarInfoProps> = () => {
  return (
    <div className="bannar-info">
      <img
        src={require("@/assets/images/trans/info.png")}
        className="bannar-info-img"
        alt="会议及培训"
      />
      <Row className="bannar-wrap">
        <Row className="bannar-info-title animated fadeInLeft">会议及培训</Row>
        <Row className="bannar-info-subtitle animated fadeInLeft">
          Meetings and training
        </Row>
        <Row className="bannar-info-describe animated fadeInLeft">
          汇聚产业峰会·引领行业大赛·传播专业培训
        </Row>
      </Row>
    </div>
  );
};

export default BannarInfo;
