import { GetTreeData } from "@/api/park";
import { TreeSelect } from "antd";
import { useEffect, useState } from "react";
interface ServeCateListProps {
  onChange?: (value: any) => void;
  value?: string;
  placeholder?: string;
  width?: number;
}
const ServeCateList: React.FC<ServeCateListProps> = ({
  onChange,
  // value = undefined,
  width = 150,
  ...props
}) => {
  const [treeData, setTreeData] = useState<any>();
  const [value, setValue] = useState<any>();
  const getTreedata = () => {
    GetTreeData({}).then((res: any) => {
      if (res.code === 1) {
        setTreeData([
          { id: 0, metadataName: "全部类型" },
          ...res.data.metadataList,
        ]);
      }
    });
  };
  useEffect(() => {
    getTreedata();
  }, []);

  const onChangeValue = (value: any, label: string, extra: any) => {
    setValue(value);
    onChange?.({ companyTypeId: value == 0 ? null : value });
  };
  // useEffect(() => {
  //   if (props.value) {
  //     setValue(props.value)
  //   }
  // }, [props.value])
  return (
    <TreeSelect
      allowClear
      treeDataSimpleMode
      style={{ width: `${width}px` }}
      value={value}
      // multiple
      defaultValue={props.value}
      dropdownStyle={{ maxHeight: 400, overflow: "auto", zIndex: 999999999 }}
      placeholder={"全部类型"}
      onChange={onChangeValue}
      fieldNames={{
        label: "metadataName",
        value: "id",
        children: "metadataList",
      }}
      // loadData={this.onLoadData}
      treeData={treeData}
    />
  );
};

export default ServeCateList;
