import styled from "styled-components";

export const EngineerWrapper = styled.div``;

export const JoinUsWrapper = styled.div`
  margin: 85px auto 95px;
  width: 1200px;
  .join-title {
    position: relative;
    font-family: PingFangSC, PingFangSC-Semibold;
    margin-bottom: 45px;
    &::before {
      position: absolute;
      content: "";
      left: 50%;
      bottom: -4px;
      margin-left: -40px;
      width: 80px;
      height: 5px;
      background-color: #1848fd;
    }
  }
  .info {
    margin: 0 auto 20px;
    width: 1100px;
    font-size: 18px;
    font-family: Alibaba, Alibaba-Regular;
    font-weight: 400;
    text-align: left;
    color: #666666;
  }
  .join-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .join-list-item {
      width: 390px;
      height: 166px;
      padding: 14px 20px 22px 30px;
      background: #ffffff;
      box-shadow: 0px 2px 7px 2px rgba(0, 0, 0, 0.15);
      .title-info {
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title {
          font-size: 18px;
          font-family: PingFangSC, PingFangSC-Semibold;
          color: #000000;
        }
        .icon {
          width: 50px;
          height: 50px;
        }
      }
      .info-item {
        font-size: 18px;
        font-family: Alibaba, Alibaba-Regular;
        font-weight: 400;
        text-align: left;
        line-height: 36px;
        color: #666666;
      }
    }
  }
  .list-items {
    margin-top: 43px;
    display: flex;
    flex-direction: column;
    .item {
      width: 1200px;
      height: 102px;
      display: flex;
      align-items: center;
      padding-left: 54px;
      box-sizing: border-box;
      .title {
        width: 120px;
        font-size: 24px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        color: #000000;
      }
      .infos {
        padding-left: 30px;
        font-family: Alibaba, Alibaba-Regular;
        font-weight: 400;
        text-align: left;
        color: #666666;
      }
    }
  }
`;

export const GoTrainWrapper = styled.div`
  width: 100%;
  min-width: 1440px;
  position: relative;
  min-height: 220px;
  .footer-content {
    position: absolute;
    top: 0;
    padding-top: 44px;
    left: 50%;
    width: 1200px;
    height: 220px;
    margin-left: -600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .info {
      margin-bottom: 30px;
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #000000;
    }
    .join-us-btn {
    }
  }
`;
