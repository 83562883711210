import { Title } from "@/styles/common-style";
import { OrderProcessWrapper } from "../style";
import { Row, Button } from "antd";
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react/swiper-react";
import { CaretRightOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";

import { ContactUs } from "@/utils/common";
interface OrderProcessProps {}
interface CoProcesItem {
  key: string;
  title: string;
  desc: string;
  icon: string;
}

export const OrderProcess: React.FC<OrderProcessProps> = () => {
  const CoProcessList: CoProcesItem[] = [
    {
      key: "1",
      title: "提出申请",
      icon: require("@/assets/images/step1.png"),
      desc: "提交公司、产品相关资料",
    },
    {
      key: "2",
      title: "在线报价",
      icon: require("@/assets/images/step4.png"),
      desc: "在线报价、洽谈合作信息",
    },
    {
      key: "3",
      title: "达成合作",
      icon: require("@/assets/images/step2.png"),
      desc: "下单交易达成合作",
    },
    {
      key: "4",
      title: "确认完成",
      icon: require("@/assets/images/step3.png"),
      desc: "合作达成、确认订单完成",
    },
  ];
  const [orderList] = useState<any>([
    {
      logo: require("@/assets/images/order-platform/order_bg1.png"),
      title: "跨境瑜伽裤产品采购",
      num: 500,
    },
    {
      logo: require("@/assets/images/order-platform/order_bg2.png"),
      title: "羊羔绒布料大小颗粒绒面料服装鞋材绒布 环保羊羔绒面料",
      num: 2000,
    },
    {
      logo: require("@/assets/images/order-platform/order_bg3.png"),
      title: "全棉人字斜 16*12/108*56 提花布 漂白半漂黑色",
      num: 2000,
    },
    {
      logo: require("@/assets/images/order-platform/order_bg4.png"),
      title: " 32支精梳全棉大卫衣 350g针织卫衣毛圈布",
      num: 1000,
    },
    {
      logo: require("@/assets/images/order-platform/order_bg5.png"),
      title: "晚礼服面料",
      num: 500,
    },
    {
      logo: require("@/assets/images/order-platform/order_bg6.png"),
      title: "主播找简约气质通勤女装",
      num: 500,
    },
    {
      logo: require("@/assets/images/order-platform/order_bg7.png"),
      title: "牛仔外套",
      num: 500,
    },
    {
      logo: require("@/assets/images/order-platform/order_bg8.png"),
      title: "时尚卫衣",
      num: 500,
    },
  ]);
  // useEffect(() => {
  //   getpartant({ index: 1, size: 40 }).then((res: any) => {
  //     if (res.data) {
  //       setOrderList(
  //         res.data.map((item: any) => {
  //           return {
  //             logo: item.logUrl,
  //             key: item.id,
  //           };
  //         })
  //       );
  //     }
  //   });
  // }, []);
  return (
    <OrderProcessWrapper>
      <Title
        fz={36}
        fw={600}
        color="#000"
        textAlign="center"
        style={{ marginBottom: 15 }}
      >
        订单交易流程
      </Title>
      <Row className="process-container" align="middle">
        {CoProcessList.map((item) => (
          <div key={item.key} className="process-item">
            <img className="process-item-icon" src={item.icon} alt="icon" />
            <Title
              fz={24}
              fw={600}
              color="#000"
              textAlign="center"
              className="process-item-title"
            >
              {item.title}
            </Title>
            <div className="process-item-desc">{item.desc}</div>
          </div>
        ))}
      </Row>
      <div className="order-slider">
        <div className="swiper-prev">
          <img
            src={require("@/assets/images/order-platform/arrow_left.png")}
            alt=""
          />
        </div>
        <Swiper
          autoplay
          loop
          centeredSlides
          spaceBetween={10}
          slidesPerView={3.75}
          className="slider-comtainer"
          modules={[Autoplay, Navigation]}
          navigation={{
            nextEl: ".swiper-next",
            prevEl: ".swiper-prev",
          }}
        >
          {orderList &&
            orderList.length > 0 &&
            orderList.map((item: any, index: number) => (
              <SwiperSlide key={index} className="order-item">
                <div className="img-container">
                  <img src={item.logo} alt="logo" />
                </div>
                <div className="order-item-info">
                  <div className="order-goods">{item.title} </div>
                  <div className="goods-num">{item.num}件</div>
                  <Button
                    className="order-btn"
                    onClick={() => {
                      ContactUs("申请接单");
                    }}
                  >
                    申请接单
                    <CaretRightOutlined style={{ color: "#1848fd" }} />
                  </Button>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
        <div className="swiper-next">
          <img
            src={require("@/assets/images/order-platform/arrow_right.png")}
            alt=""
          />
        </div>
      </div>
    </OrderProcessWrapper>
  );
};
