import { useEffect, useState } from "react";
import { H5Wrapper } from "../style";
import { Button } from "antd";
import { useParams, history } from "umi";
import { getDetail } from "../api";
interface InfoProps {}
const Info: React.FC<InfoProps> = () => {
  const params = useParams<{ id?: string }>();
  const [info, setInfo] = useState({} as any);
  useEffect(() => {
    document.title = info?.name;
  }, [info]);
  useEffect(() => {
    if (params.id) {
      getDetail(params.id).then((res: any) => {
        if (res.code == 1) {
          setInfo(res.data);
        }
      });
    }
  }, [params?.id]);
  return (
    <H5Wrapper>
      {info?.name ? (
        <>
          {" "}
          <div className="info-title">{info?.name}</div>
          <div
            className="html ql-editor"
            dangerouslySetInnerHTML={{ __html: info?.introduction }}
          ></div>
          <div className="concat-wrap">
            <Button
              type="primary"
              className="concat"
              onClick={() => {
                history.push(`/h5/form/${params?.id}`);
              }}
            >
              我要咨询
            </Button>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "bold",
              height: "calc(100vh - 30px)",
            }}
          >
            设备信息不存在
          </div>
        </>
      )}
    </H5Wrapper>
  );
};

export default Info;
