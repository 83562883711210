import { CaseCard, CaseMeta } from "../style";
import { dataType } from "./case-list";

interface CaseItemProps {
  data: dataType;
  onClick?: () => void;
}
const CaseItem: React.FC<CaseItemProps> = ({ onClick, data }) => {
  return (
    <CaseCard
      hoverable
      onClick={(e) => {
        onClick?.();
      }}
      cover={<img alt="example" src={data?.poster} />}
    >
      <CaseMeta description={data?.title} />
    </CaseCard>
  );
};

export default CaseItem;
