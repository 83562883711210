import { ContactUs } from "@/utils/common";
import { CSSProperties } from "react";
import { ConsultNowWrapper } from "../style";

interface ConsultNowProps {
  style?: CSSProperties;
  name: string;
}
export const ConsultNow: React.FC<ConsultNowProps> = ({ style, name }) => {
  return (
    <ConsultNowWrapper style={{ ...style }} onClick={() => ContactUs(name)}>
      立即报名
    </ConsultNowWrapper>
  );
};
