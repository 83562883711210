import { CusButton } from "@/styles/common-style";
import {
  CardHeaderWrapper,
  CityHeader,
  ParDetail,
  ParkWrapper,
} from "../../style";
import { LeftOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "umi";
interface CardHeaderProps {
  type?: number; // 1 城市地址 2 园区分类 3 园区详情
  // goBack?: (value: number | undefined) => void //goBack 返回
}
const CardHeader: React.FC<CardHeaderProps> = ({ type = 1 }) => {
  const { parkInfo, backInfo, industrialInfo, companyInfo, fullscreen, pre } =
    useSelector((state: { park: any }) => state.park);
  const dispatch = useDispatch();
  console.log(companyInfo, "companyInfo", backInfo, { pre });
  return (
    <CardHeaderWrapper>
      {type === 6 && (
        <CityHeader>
          <CusButton
            icon={<LeftOutlined />}
            className="card-back"
            type="primary"
            width={116}
            height={43}
            onClick={() =>
              dispatch({ type: "park/savebackNumber", payload: 4 })
            }
          >
            返回
          </CusButton>
        </CityHeader>
      )}
      {type == 1 && (
        <CityHeader>
          <CusButton
            icon={<LeftOutlined />}
            className="card-back"
            type="primary"
            width={116}
            height={43}
            onClick={() =>
              pre && pre.length > 1 && !parkInfo?.id
                ? dispatch({
                    type: "park/savebackNumber",
                    payload: pre[pre.length - 1],
                  })
                : dispatch({ type: "park/savebackNumber", payload: 0 })
            }
          >
            返回
          </CusButton>
          <div className="card-header-city">
            {(parkInfo &&
              [
                parkInfo.province,
                parkInfo.city,
                parkInfo.area,
                parkInfo.contact,
              ]
                .filter((it) => it !== "")
                .join("")) ||
              "-"}
          </div>
          <div className="card-header-name">{parkInfo?.clusterName}</div>
          <div className="card-header-info">{parkInfo?.clusterDetail}</div>
        </CityHeader>
      )}
      {type == 2 && (
        <ParDetail>
          <CusButton
            icon={<LeftOutlined />}
            className="card-back"
            type="primary"
            width={116}
            height={43}
            onClick={() =>
              pre && pre.length > 1 && !parkInfo?.id
                ? dispatch({
                    type: "park/savebackNumber",
                    payload: pre[pre.length - 1],
                  })
                : dispatch({ type: "park/savebackNumber", payload: 1 })
            }
          >
            返回
          </CusButton>
          <div className="img-poster1">
            <img
              src={
                industrialInfo &&
                industrialInfo.parkPic &&
                industrialInfo?.parkPic.split(",")[0]
              }
              alt=""
            />
          </div>
          <div className="card-park-name">{industrialInfo?.parkName}</div>
          <div className="card-header-info">
            {industrialInfo?.parkIntroduction}
          </div>
        </ParDetail>
      )}
      {type == 3 && (
        <ParkWrapper>
          <CusButton
            icon={<LeftOutlined />}
            className="card-back"
            type="primary"
            width={116}
            height={43}
            onClick={() => {
              console.log(pre, "pre");
              (pre && pre.length > 1) || !parkInfo?.id
                ? dispatch({
                    type: "park/savebackNumber",
                    payload: pre[pre.length - 1],
                  })
                : dispatch({ type: "park/savebackNumber", payload: 1 });
            }}
          >
            返回
          </CusButton>
          <div className="img-poster1">
            {companyInfo && companyInfo?.companyPic && (
              <img
                src={
                  companyInfo &&
                  companyInfo.companyPic &&
                  companyInfo?.companyPic.split(",")[0]
                }
                alt=""
              />
            )}
          </div>
          <div className="card-header-park">{companyInfo?.companyName}</div>
          <div className="card-header-address">
            {(companyInfo &&
              [
                companyInfo.province,
                companyInfo.city,
                companyInfo.area,
                companyInfo.contact,
              ]
                .filter((it) => it !== "")
                .join("")) ||
              "-"}
          </div>
          <div className="card-header-info">
            {companyInfo?.companyIntroduction}
          </div>
        </ParkWrapper>
      )}
      {/* {(type + "").includes("5") && fullscreen && (
        <ParkWrapper>
          <CusButton
            icon={<LeftOutlined />}
            className="card-back"
            type="primary"
            width={116}
            height={43}
            onClick={() =>
              dispatch({ type: "park/savebackNumber", payload: 4 })
            }
          >
            返回
          </CusButton>
        </ParkWrapper>
      )} */}
    </CardHeaderWrapper>
  );
};

export default CardHeader;
