import { Title } from "@/styles/common-style";
import { useEffect, useState } from "react";
import { useSelector, history } from "umi";
import { CertificationWrapper, LookMoreBtn } from "../style";
import ShowModal from "@/components/showModal";
interface CertificationProps {}
interface CertificatVo {
  cateName: string;
  icon: string;
  intro: string;
  id?: string;
  img: string;
}
export const Certification: React.FC<CertificationProps> = (props) => {
  const { serverCates } = useSelector((state: any) => state.server);
  const icon = [
    require("@/assets/images/server/auth_icon.png"),
    require("@/assets/images/server/auth_user_icon.png"),
  ];
  const Img = [
    require("@/assets/images/order.jpg"),
    require("@/assets/images/server/gj.jpg"),
  ];
  const [visible, setVisible] = useState<boolean>(false);
  const [imgs, setImgs] = useState<string>("");
  const [serverCate, setServerCate] = useState<any>();
  const [certificat, setCertificat] = useState<CertificatVo[]>([]);
  useEffect(() => {
    let item = serverCates.filter((item: any) => item.id === 430)[0];
    setServerCate(item);
    if (item) {
      setCertificat([
        {
          cateName: "中国服装数字化快反工厂等级认定",
          icon: icon[0],
          img: Img[0],
          intro:
            "由中国服装协会组织开展的，运用规范、统一的评价方法，对服装工厂数字化快反能力进行定量和定性分析，从而对服装工厂的数字化快反能力等级作出真实、客观、公正的综合认定，建立服装行业供应链等级划分标准。",
        },
        {
          cateName: "专业人才认定",
          icon: icon[1],
          img: Img[1],

          intro:
            "包含量体着装顾问认证、服装类专业技能等级认证及时尚人才标准体系认证。",
        },
      ]);
    }
  }, []);
  return (
    <>
      {serverCate && (
        <CertificationWrapper>
          <Title style={{ width: "1200px", margin: "0 auto 20px" }}>
            {serverCate?.metadataName}
          </Title>
          <div className="certificat-info">
            {certificat.map((item: any, index) => (
              <div
                className="certificat-item"
                key={index}
                onClick={() => {
                  if (index === 0) {
                    history.push("/maintain");
                  } else {
                    history.push("/engineer-certification");
                  }
                }}
              >
                <div className="certificat-wrap">
                  <div className="title">{item.cateName}</div>
                  <img src={item.icon} className="icon" />
                </div>
                <div className="intro">{item.intro}</div>
                <LookMoreBtn style={{ marginTop: "20px" }}>
                  查看更多
                </LookMoreBtn>
              </div>
            ))}
          </div>
        </CertificationWrapper>
      )}
      <ShowModal
        visibleModal={visible}
        img={imgs}
        onCancel={() => setVisible(false)}
      />
    </>
  );
};

export default Certification;
