import styled from "styled-components";
import { Dropdown, Menu, Row } from "antd";

export const HeaderTitleWrapper = styled(Row)<{ hasBorder?: boolean }>`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* width: 320px; */
  line-height: 1;
  .base-menu {
    height: 100%;
  }
  .base-left-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 34px;
    position: relative;
    font-size: 16px;
    font-family: Alibaba, Alibaba-Regular;
    font-weight: 400;
    text-align: center;
    padding-right: 14px;
    margin-right: 14px;
    color: #000;
    &::before {
      position: absolute;
      content: "";
      right: 0;
      top: 50%;
      margin-top: -24px;
      width: 1px;
      height: 56px;
      background-color: ${(props) =>
        props.hasBorder ? "#d8d8d8" : "transparent"};
      /* top */
    }
  }
  .base-left-solgan {
    font-size: 12px;
    font-family: PingFangSC, PingFangSC-Regular !important;
    color: #333;
    font-weight: 400;
  }
  .base-logo {
    width: 80px;
    height: 49px;
  }
  .ant-dropdown-trigger {
    color: #3360f2;
    height: 60px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-size: 14px;
    line-height: 60px;
  }
`;
export const CusDropdown = styled(Dropdown)`
  /* .ant-dropdown-trigger {
    color: #3360f2;
  } */
`;

export const CusMenu = styled(Menu)``;
