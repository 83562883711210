import { Row } from "antd";
import { useEffect, useState } from "react";

import Title from "@/components/title";

interface CoProcesItem {
  key: string;
  title: string;
  desc: string;
  icon: string;
}
const titleStyle = {
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "44px",
} as any;
const CoProcessList: CoProcesItem[] = [
  {
    key: "1",
    title: "提出申请",
    icon: require("@/assets/images/step1.png"),
    desc: "提交公司、产品相关资料",
  },
  {
    key: "2",
    title: "资料审核",
    icon: require("@/assets/images/step4.png"),
    desc: "审核公司资质、产品信息",
  },
  {
    key: "3",
    title: "协议签订",
    icon: require("@/assets/images/step2.png"),
    desc: "洽谈、签订相关合作协议",
  },
  {
    key: "4",
    title: "运营管理",
    icon: require("@/assets/images/step3.png"),
    desc: "对优质产品进行宣传推广",
  },
];

const CoProcess = () => {
  return (
    <div className="co-process">
      <Title text={"合作流程"} titleStyle={titleStyle} />
      <Row className="co-process-container" align="middle">
        {CoProcessList.map((item) => (
          <div key={item.key} className="co-process-item">
            <img className="co-process-item-icon" src={item.icon} alt="icon" />
            <span className="co-process-item-title">{item.title}</span>
            <span className="co-process-item-desc">{item.desc}</span>
          </div>
        ))}
      </Row>
    </div>
  );
};

export default CoProcess;
