import { Title } from "@/pages/server/server-center/style";
import { BaseInfoWrapper, CusButton } from "../style";
import { DatePicker, Form, Input, Select } from "antd";
import FileUpload from "@/components/file-upload";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "umi";
import type { FormInstance } from "antd/es/form";
import moment from "moment";
interface BaseInfoProps {}
const BaseInfo: React.FC<BaseInfoProps> = () => {
  const { Option } = Select;
  const { user } = useSelector((state: any) => state.user);
  const formRef: any = useRef<FormInstance>();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "user/getUser",
    });
  }, []);
  return (
    <BaseInfoWrapper>
      <Title style={{ marginBottom: "70px" }}>基本信息</Title>
      {user && (
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 12 }}
          ref={formRef}
          onFinish={(values) =>
            dispatch({
              type: "user/updateUser",
              payload: {
                ...values,
                birthday: values.birthday
                  ? moment(values.birthday).format("YYYY-MM-DD") + "T12:00:00"
                  : "",
              },
            })
          }
        >
          <Form.Item label="头像" name="avatar" initialValue={user?.avatar}>
            <>
              <FileUpload
                height={70}
                width={70}
                noCrope={false}
                value={user?.avatar}
                onChange={(value) => {
                  formRef.current.setFieldsValue({ avatar: value });
                }}
              />
              <span className="tip">更换头像</span>
            </>
          </Form.Item>
          <Form.Item label="昵称" name="nickName" initialValue={user?.nickName}>
            <Input placeholder="请输入" style={{ width: "220px" }} />
          </Form.Item>
          <Form.Item label="性别" name="gender" initialValue={user?.gender}>
            <Select placeholder="请选择" allowClear style={{ width: "220px" }}>
              <Option value={1}>男</Option>
              <Option value={2}>女</Option>
              <Option value={0}>未知</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="生日"
            name="birthday"
            initialValue={
              user.birthday
                ? moment(new Date(user.birthday)).add(8, "hour")
                : ""
            }
          >
            <DatePicker
              placeholder="请选择"
              picker="date"
              style={{ width: "220px" }}
            ></DatePicker>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 4, span: 12 }}>
            <CusButton type="primary" width={224} height={32} htmlType="submit">
              保存
            </CusButton>
          </Form.Item>
        </Form>
      )}
    </BaseInfoWrapper>
  );
};

export default BaseInfo;
