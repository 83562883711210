import styled from "styled-components";
import economicbg from "@/assets/images/economic/economic-bg.png";

export const EconomicWrapper = styled.div`
  width: 100%;
  min-width: 1440px;
  height: 1075px;
  background-image: url(${economicbg});
  background-size: 100% 100%;
  .title {
    padding: 146px 0 128px;
    font-size: 60px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    letter-spacing: 6.43px;
  }
  .swiper-slide {
    width: 351px;
  }
  .swiper-image {
    width: 351px;
    height: 544px;
  }
  .swiper-button-next:after,
  .swiper-button-prev:after {
    content: "";
  }
  .swiper-button-next,
  .swiper-button-prev {
    img {
      width: 100px;
    }
  }
  .swiper-button-prev {
    left: 200px;
  }
  .swiper-button-next {
    right: 200px;
  }
`;
