import { getProductList } from "@/api/server";
import { useEffect, useState } from "react";
import { useSelector } from "umi";
import { DigitalCateWrapper } from "../style";
import Cates, { DataSource } from "./cates";
import { history } from "umi";
interface DigitalCateProps {}
const DigitalCate: React.FC<DigitalCateProps> = (props) => {
  const [dataSource, setDataSource] = useState<DataSource[]>([]);
  const { serverCates } = useSelector((state: any) => state.server);
  const [serverCate, setServerCate] = useState<any>();
  const getList = (id: number) => {
    const params = {
      index: 1,
      size: 10,
      serviceCateId: id,
    };
    getProductList(params).then((res: any) => {
      if (res.code === 1) {
        setDataSource(res.data);
      }
    });
  };
  const itemClick = (value: any) => {
    history.push("/server/server-detail/" + value.id);
  };
  useEffect(() => {
    let item = serverCates.filter((item: any) => item.id === 396)[0];
    setServerCate(item);
    if (item && item.childCateList) {
      getList(item.childCateList[0].id);
    }
  }, [serverCates]);
  return (
    <DigitalCateWrapper>
      {serverCate && (
        <Cates
          itemClick={(value) => itemClick(value)}
          title={serverCate?.metadataName}
          tabs={serverCate.metadataList || null}
          data={dataSource}
          type={1}
          TabClick={(value: any) => {
            getList(value.id);
          }}
        />
      )}
    </DigitalCateWrapper>
  );
};

export default DigitalCate;
