import BaseLayout from "@/layouts";
import { BgImage } from "../order-platform/style";
import { ConsultNow } from "./components/consult-now";
import { Train1 } from "./components/train1";
import { Train2 } from "./components/train2";
import { Trans3 } from "./components/trans3";
import { MoreTrainsWrapper } from "./style";

interface MoreTrainsProps {}
const MoreConsulting: React.FC<MoreTrainsProps> = () => {
  return (
    <BaseLayout>
      <MoreTrainsWrapper>
        <BgImage bg={require("@/assets/images/more-trains/main_bg.png")} />
        <Train1 />
        <Train2 />
        <Trans3 />
        <ConsultNow
          name={"培训服务内容"}
          style={{
            margin: "0 auto 42px",
            width: 285,
            height: 56,
            background: "#1848fd",
            color: "#fff",
          }}
        />
      </MoreTrainsWrapper>
    </BaseLayout>
  );
};

export default MoreConsulting;
