import styled from "styled-components";
import listbg from "@/assets/images/home/list-bg.png";
import { List } from "antd";
export const HeaderListWrapper = styled.div`
  width: 100%;
  min-width: 1440px;
  padding: 67px 0 56px;
  background-color: #edf1f8;
  .list-content {
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    width: 1288px;
    height: 604px;
    padding: 32px 75px 55px 24px;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 2px;
  }
  .list-left {
    width: 312px;
    .list-left-wrap {
      margin-top: 12px;
      width: 312px;
      overflow: auto;
      height: 472px;
      padding: 23px 20px;
      background: url(${listbg}) no-repeat center;
      background-size: 100%;
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #ffffff;

      &::-webkit-scrollbar {
        display: none;
      }
      &::-webkit-scrollbar-thumb {
        width: 3px;
        height: 8px;
        background-color: #666;
      }
      .list-left-item {
        margin-bottom: 29px;
      }
    }
  }
  .list-right {
    width: 813px;

    .list-right-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
`;

export const CusList = styled(List)`
  margin-top: 12px;
  width: 100%;
  height: 472px;
  background: #f8f8f8;
  border-radius: 2px;
  .ant-list-item {
    display: flex;
    margin: 0 21px;
    padding: 14px 0;
  }
  .list-title {
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 550px;
  }
  .list-time {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #666666;
  }
`;
