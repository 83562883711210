import request from "@/utils/request";

export const getProductTree = (params: any) => {
  return request(
    "/business/metadata/queryServiceByIdList", //"/business/service/productCate/tree",
    {
      method: "get",
      requestType: "json",
    }
  );
};

// /business/service/product/list
//查询产品服务列表
export const getProductList = (params: any) => {
  return request("/business/service/product/list", {
    method: "post",
    requestType: "json",
    data: params,
  });
};
//查询 产业服务详情
export const getProductDetail = ({ id, accountId }: any) => {
  return request(
    "/business/service/product/queryById/" + id + "/" + accountId,
    {
      method: "get",
      requestType: "json",
    }
  );
};

// 收藏服务列表
// ;/account/Ccceeeillorstv / create

export const CollectCreate = (params: any) => {
  return request("/account/serviceCollect/create", {
    method: "post",
    requestType: "json",
    data: params,
  });
};
