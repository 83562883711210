import BaseLayout from "@/layouts";
import { Title } from "@/styles/common-style";
import { getCaseDetailById } from "@/utils/api";
import { useEffect, useState } from "react";
import { ErrorShowType } from "umi";
import { CaseWrapper } from "../case-center/style";
import CaseInfo from "./conponents/case-info";
import { CaseDetailWrapper } from "./style";
interface SolutionProps {
  match: any;
}
interface CaseDetailInfo {
  id: number;
  title: string;
  releaseTime: string;
  poster: string;
  introduction: string;
  exhibitionClient: string;
  exhibitionClientStr: string;
  top: number;
  sort: number;
  caseEffect: string;
  caseBackdrop: string;
  caseDemand: string;
  caseDetails: string;
  caseType: number;
  createTime: string;
  updateTime: string;
}
interface EffectivenessType {
  dataDimension: string;
  dataExhibition: string;
}
type EffectivenessListType = EffectivenessType[];
export const CaseDetail: React.FC<SolutionProps> = (props) => {
  const [data, setData] = useState<CaseDetailInfo>({} as CaseDetailInfo);
  const [
    EffectivenessList,
    setEffectivenessList,
  ] = useState<EffectivenessListType>([] as EffectivenessListType);
  const getCaseDetailInfo = () => {
    const query = {
      id: props.match.params.id,
    };
    getCaseDetailById(query).then((res: any) => {
      if (res.code === 1) {
        setData(res.data);
        res.data.caseEffect &&
          setEffectivenessList(JSON.parse(res.data.caseEffect));
      }
    });
  };
  useEffect(() => {
    getCaseDetailInfo();
  }, []);
  return (
    <BaseLayout>
      <CaseDetailWrapper>
        <div className="header-container">
          <img src={require("@/assets/images/case/case-detail-bg.png")} />
          <div className="case-card">
            <img src={data.poster} className="img" alt="icon" />
            <div className="case-card-content">
              <Title className="case-card-title">{data.title}</Title>
              <div className="des">{data.introduction}</div>
              <div className="case-title">方案成效</div>
              <div className="percent-list" style={{ width: "920px" }}>
                {EffectivenessList.length === 0 ? (
                  <div>暂无</div>
                ) : (
                  EffectivenessList.map((item, index) => (
                    <div className="percent-item" key={index}>
                      <div className="value">{item.dataExhibition}</div>
                      <div className="label">{item.dataDimension}</div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
        <CaseInfo data={data} />
      </CaseDetailWrapper>
    </BaseLayout>
  );
};

export default CaseDetail;
