import { SeniorCard, SeniorMeta } from "../style";
interface CardItemProps {
  data: {
    title: string;
    expertName: string;
    expertAvatar: string;
    introduction: string;
  };
}
const CardItem: React.FC<CardItemProps> = ({ data }) => {
  return (
    <SeniorCard
      hoverable
      cover={<img alt="example" src={data?.expertAvatar} />}
      style={{ marginTop: "20px" }}
    >
      <SeniorMeta
        title={data?.expertName}
        description={
          <>
            <div className="info">{data?.title}</div>
            <div className="icon">
              <img src={require("@/assets/images/expert/arrow_right.png")} />
            </div>
          </>
        }
      />
    </SeniorCard>
  );
};

export default CardItem;
