import styled from "styled-components";

export const ServerDetailContainer = styled.div`
  width: 100%;
  min-width: 1440px;
  .server-content {
    margin: 0 auto 170px;
    width: 1200px;
    .title {
      margin-bottom: 30px;
      font-size: 36px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #000000;
    }
    .html {
      img {
        width: 100%;
      }
      font-size: 24px;
      font-family: Alibaba, Alibaba-Regular;
      font-weight: 400;
      text-align: left;
      color: #666666;
    }
  }
`;

export const HeaderInfoWrapper = styled.div`
  position: relative;
  height: 500px;
  padding-top: 158px;
  .img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    height: 400px;
    z-index: -1;
  }
  .card-wrapper {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 1200px;
    height: 322px;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    .card-left {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 36px 75px 36px 50px;
      height: 100%;
      .server-logo {
        margin-right: 50px;
        max-height: 300px;
        width: 140px;
      }
      .server-title {
        font-size: 30px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      .server-info {
        width: 565px;
        margin-bottom: 32px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #666666;
      }
      .server-item {
        cursor: pointer;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
      }
      .server-label {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        color: #000000;
      }
      .type {
        font-size: 14px;
      }
      .ant-input {
        margin-right: 10px;
      }
    }

    .card-right {
      padding: 49px 54px 36px 25px;
      width: 320px;
      height: 100%;
      background: #eaf1fb;
      .server-company {
        margin-bottom: 50px;
        font-size: 18px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        color: #000;
      }
      .server-company-cate {
        margin-bottom: 10px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #000;
      }
    }
  }
  .server-btn {
    margin-left: 190px;
  }
  .server-btn .ant-btn-primary {
    margin-right: 55px;
  }
`;
