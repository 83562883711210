import { getheadCompany, getheadSupplier } from "@/api/home";
import { CusButton } from "@/styles/common-style";
import { useEffect, useState } from "react";
import { history } from "umi";
import { SeverTab, SeverTabs, SeverWrapper } from "../style";
import { CaretRightOutlined } from "@ant-design/icons";
interface ServerPartProps {}
type TabsItem = {
  title: string;
  key: string;
};
type TabsContentType = {
  supplierName: string;
  logUrl: string;
  id: number;
  companyName: string;
  companyTypeId: string;
  companyTypeName: string;
  parkId: number;
  parkName: string;
  companyPic: string;
  companyIntroduction: string;
  province: string;
  provinceCode: string;
  city: string;
  cityCode: string;
  area: string;
  areaCode: string;
  address: string;
  longitude: string;
  latitude: string;
  clusterId: number;
  industrialNodeId: number;
  createTime: string;
  updateTime: string;
};
const ServiceProvider: React.FC<ServerPartProps> = () => {
  const [tabs] = useState<TabsItem[]>([
    {
      title: "优质服务商",
      key: "0",
    },
  ]);
  const [tabsContent, setTabsContent] = useState<TabsContentType[]>([]);

  const [currentId, setCurrentId] = useState<string>("0");
  const [currentTab, setCurrentTab] = useState<TabsContentType>(
    {} as TabsContentType
  );
  const [currentTabId, setCurrentTabId] = useState<number>(0);
  const getSupplierListInfo = () => {
    getheadSupplier().then((res: any) => {
      if (res.code == 1) {
        setTabsContent(res.data.length > 8 ? res.data.slice(0, 8) : res.data);
        // setCurrentTab(res.data[0])
      }
    });
  };
  useEffect(() => {
    getSupplierListInfo();
  }, []);
  return (
    <SeverWrapper style={{ marginTop: 50 }}>
      <SeverTabs
        centered
        noBorder
        activeKey={currentId}
        onChange={(key) => {
          setCurrentId(key);
        }}
      >
        {tabs &&
          tabs.map((item: any, index: number) => (
            <SeverTab tab={item.title} key={index}>
              <div className="server-content">
                {tabsContent &&
                  tabsContent.map((it: any, key: number) => (
                    <div className="server-item">
                      <div className="img-wrap">
                        <img
                          src={it?.companyPic || it.logUrl}
                          alt=""
                          className="server-logo"
                        />
                      </div>
                      <div className="server-info">
                        <div className="header-info">
                          <div className="header-title">
                            {it.companyName || it.supplierName}
                          </div>
                          <CusButton type="link">
                            了解更多
                            <CaretRightOutlined style={{ color: "#1848fd" }} />
                          </CusButton>
                        </div>
                        <div className="content-info">
                          {it.companyIntroduction || it.brief}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              <div
                className="look-more"
                onClick={() => history.push("/server/center")}
              >
                查看更多 &raquo;
              </div>
            </SeverTab>
          ))}
      </SeverTabs>
    </SeverWrapper>
  );
};

export default ServiceProvider;
