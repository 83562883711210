import styled from "styled-components";
import { Dropdown } from "antd";
export const LoginWrapper = styled.div`
  margin-right: 36px;
  @media screen and (max-width: 1610px) {
    & {
      margin-right: 2px;
    }
  }
`;

export const CusDropdown = styled(Dropdown)`
  height: 58px;
`;
