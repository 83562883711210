import styled from "styled-components";
import { Row } from "antd";

export const TagListWrapper = styled(Row)`
  margin: 0 auto;
  width: 1200px;
  .tag-label {
    width: 80px;
    height: 60px;
    line-height: 60px;
    font-size: 18px;
    margin-right: 20px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    color: #000;
  }
  .tag-list {
    width: 1034px;
  }
  .tag-item {
    cursor: pointer;
    margin-right: 20px;
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    height: 60px;
    line-height: 60px;
    color: #666;
    .tag-name {
      padding: 2px 4px;
      font-weight: 500;
      border: 1px solid transparent;
    }
  }
  .tag-active-item .tag-name {
    border: 1px solid #1848fd;
    border-radius: 3px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #1848fd;
  }
`;
