import styled from "styled-components";

export const BannarWrap = styled.div`
  margin: 0 auto 90px;
  width: 1200px;
  img {
    width: 100%;
    /* height: 100%; */
  }
`;
