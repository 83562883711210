import { Modal } from "antd";
import { useEffect, useState } from "react";
interface ShowModalProp {
  visibleModal: boolean;
  img: string;
  onCancel: () => void;
}

const ShowModal: React.FC<ShowModalProp> = ({
  visibleModal,
  img,
  onCancel,
}) => {
  return (
    <>
      {visibleModal && (
        <Modal
          width={400}
          title="扫一扫进入了解详情"
          visible={visibleModal}
          onCancel={onCancel}
          footer={false}
        >
          <img src={img} className="modal-img" alt="二维码" />
        </Modal>
      )}
    </>
  );
};

export default ShowModal;
