import { history, useHistory } from "umi";
import { Layout } from "antd";
import "swiper/swiper-bundle.css";
import "@/styles/animate.min.css";
import "./index.less";
import BaseHeader from "./base-header";
import BaseFooter from "./base-footer";
import "./quill.snow.css";
import { useEffect, useState } from "react";
interface layoutProps {
  children: any;
  footer?: boolean;
}
const { Header, Content } = Layout;
const BaseLayout: React.FC<layoutProps> = ({ children, footer = true }) => {
  const { location } = useHistory();
  const [style, setStyle] = useState<any>({});
  useEffect(() => {
    if (location.pathname.includes("atlas")) {
      setStyle({ backgroundColor: "#f4f4f" });
    } else {
      setStyle({ backgroundColor: "#fff" });
    }
  }, [location.pathname]);
  return (
    <>
      <Layout className="layout" id="layout" style={{ ...style }}>
        <Header>
          <BaseHeader />
        </Header>
        <Content className="content">{children}</Content>
        {footer && <BaseFooter />}
      </Layout>
    </>
  );
};

export default BaseLayout;
