import styled from "styled-components";
import step_bg from "@/assets/images/maintain/step_bg.png";
export const MaintainWrapper = styled.div``;

export const SpecificationWrapper = styled.div`
  margin: 74px auto;
  width: 1200px;
  .list {
    display: flex;
    align-items: center;
    justify-content: space-around;
    .list-item {
      padding: 20px 30px;
      margin-right: 30px;
      margin-bottom: 26px;
      width: 380px;
      height: 195px;
      background: #ffffff;
      border-radius: 5px;
      box-shadow: 0px 2px 7px 2px rgba(0, 0, 0, 0.17);
    }
    .title-info {
      display: flex;
      align-items: center;
    }
    .info {
      margin-top: 10px;
      font-size: 18px;
      font-family: Alibaba, Alibaba-Regular;
      font-weight: 400;
      text-align: left;
      color: #666666;
      line-height: 36px;
    }
    &.list1 {
      justify-content: center;
    }
  }
`;

export const BenefitsWrapper = styled.div`
  width: 100%;
  min-width: 1440px;
  height: 556px;
  padding-top: 30px;
  padding-bottom: 58px;
  margin-bottom: 78px;
  box-sizing: border-box;
  @media (min-width: 2000px) {
    & {
      height: 750px;
    }
  }
  background: url(${step_bg}) no-repeat center;
  background-size: cover;
  .ben-list {
    margin: 0 auto 78px;
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ben-list-item {
      position: relative;
      width: 230px;
    }
    .ben-list-item-icon {
      position: absolute;
      width: 94px;
      height: 94px;
      left: 50%;
      margin-left: -47px;
    }
    .info {
      padding-top: 70px;
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 48px;
      width: 230px;
      height: 330px;
      box-sizing: border-box;
      background-color: #fff;
      .title {
        font-size: 24px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: center;
        color: #000000;
      }
      .info-item {
        margin-top: 37px;
        font-size: 18px;
        font-family: Alibaba, Alibaba-Regular;
        font-weight: 400;
        text-align: left;
        color: #666666;
        line-height: 33px;
      }
    }
  }
`;

export const DiagnosisWrapper = styled.div`
  margin: 0 auto 116px;
  width: 1200px;
  .diag-list {
    display: flex;
    align-items: center;
  }
  .diag-list-item {
    width: 400px;
    height: 510px;
    border: 1px solid #d8d8d8;
    border-right: none;
    padding: 44px 56px 28px 56px;
    box-sizing: border-box;
    &:last-child {
      border-right: 1px solid #d8d8d8;
    }
    .title {
      font-size: 24px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      text-align: center;
      color: #000000;
    }
    .info {
      height: 144px;
      font-size: 18px;
      font-family: Alibaba, Alibaba-Regular;
      font-weight: 400;
      text-align: left;
      color: #666666;
      line-height: 30px;
    }
    .img {
      margin-top: 20px;
      width: 289px;
      height: 207px;
    }
  }
`;

export const ProcessWrapper = styled.div`
  width: 100%;
  min-width: 1440px;
  margin-bottom: 108px;
  .info {
    width: 100%;
    min-width: 1440px;
    position: relative;
    .line {
      position: absolute;
      width: 100%;
      z-index: 0;
      top: 18px;
      min-width: 1440px;
      height: 2px;
      background-color: #d8d8d8;
    }
    .step-list {
      position: relative;
      z-index: 1;
      width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .step-list-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .icon {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        font-size: 18px;
        background: #1848fd;
        border-radius: 50%;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        color: #ffffff;
      }
      .text {
        font-size: 18px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        color: #999999;
      }
    }
  }
  .btn-au {
    margin-top: 70px;
    width: 100%;
    min-width: 1440px;
    text-align: center;
    .step-primary {
      width: 250px;
      height: 48px;
      background: #1848fd;
    }
  }
`;
