import BaseFooter from "@/layouts/base-footer";
import CusHeader from "@/layouts/cus-header";
import CateList from "./components/cate-list";
import Certification from "./components/certification";
import CoPartner from "./components/co-partner";
import ConsulteCate from "./components/consulte-cate";
import DigitalCate from "./components/digital-cate";
import FinancialCate from "./components/financial-cate";
import ManufactureCate from "./components/manufacture-cate";
import Trains from "./components/trains";

import { useDispatch, history, useSelector } from "umi";
import { JoinUs } from "./components/join";
import { useEffect, useState } from "react";
interface ServerCenterProps {}
const ServerCenter: React.FC<ServerCenterProps> = () => {
  const dispatch = useDispatch();
  const { serverCates } = useSelector((state: any) => state.server);

  useEffect(() => {
    dispatch({
      type: "server/getServerCastes",
      payload: {},
    });
  }, []);
  console.log(serverCates, "serverCates");
  return (
    <>
      <CusHeader
        title="服务中心"
        showSearch
        search={(value) =>
          history.push({
            pathname: "/server/all-service",
            state: {
              keyword: value,
            },
          })
        }
      ></CusHeader>
      <CateList />
      {serverCates &&
        serverCates.length > 0 &&
        serverCates.map((item: any, index: number) => (
          <div key={index}>
            {item.id == 396 && <DigitalCate />}
            {item.id == 404 && <ManufactureCate />}
            {item.id == 416 && <FinancialCate />}
            {item.id == 421 && <ConsulteCate />}
            {item.id == 430 && <Certification />}
            {item.id == 436 && <Trains />}
          </div>
        ))}
      <CoPartner />
      <JoinUs />
      <BaseFooter />
    </>
  );
};

export default ServerCenter;
