import { MapContainer, MapWrapper, UseMapWrapper } from "../../style";
import NavLeft from "../nav-left";
import NavRight from "../nav-right";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "umi";
interface AtlasMapProps {}
import Dot from "./dot.js";
import ClusterBubble from "./ClusterBubble.js";
import { getParkCount, getClusterAreaList, getCompanyCount } from "@/api/park";
const AtlasMap: React.FC<AtlasMapProps> = ({}) => {
  const useMapRef = useRef(null);
  const {
    parkInfo,
    mapCenter,
    fullscreen,
    clusterList,
    industrialInfo,
    companyInfo,
    backInfo,
    parks,
    parkSearchInfo,
  } = useSelector((state: any) => state.park);
  let [map, setMap] = useState<any>("");
  const dispatch = useDispatch();
  let colorList = [
    "rgba(122,75,250,.3)",
    "rgba(51,51,51,1)",
    "rgba(241,124,22,1)",
    "rgba(61,88,249,.3)",
    "rgba(194,249,2,1)",
    "rgba(226,79,0,1)",
    "rgba(0,173,249,.3)",
    "rgba(34,228,178,1)",
    "rgba(232,250,64,1)",
    "rgba(212,65,20,1)",
    "rgba(248,212,50,1)",
    "rgba(41,53,174,1)",
    "rgba(20,227,23,1)",
    "rgba(243,165,37,1)",
    "rgba(220,62,246,1)",
  ];
  const [showLeft, setShowLeft] = useState<boolean>(false);
  // const [markerLayer, setmarkerLayer] = useState<any>("")
  const TMap: any = (window as any).TMap;
  let district = new TMap.service.District({
    // 新建一个行政区划类
    polygon: 2, // 返回行政区划边界的类型
  }) as any;
  let [infoWindow, setInfoWindow] = useState<any>();
  const [donutlist, setDonutList] = useState<any>();
  const [donuts, setDonuts] = useState<any>([]);
  const [makers, setmakers] = useState<any>([]);
  const ipLocation = new TMap.service.IPLocation();
  const geocoder = new TMap.service.Geocoder();
  // const { city, area, provice } = parkSearchInfo
  const fitBounds = (latLngList: any[]) => {
    // 由多边形顶点坐标数组计算能完整呈现该多边形的最小矩形范围
    if (latLngList.length === 0) {
      return null;
    }
    var boundsN = latLngList[0].lat;
    var boundsS = boundsN;
    var boundsW = latLngList[0].lng;
    var boundsE = boundsW;
    latLngList.forEach((point) => {
      point.lat > boundsN && (boundsN = point.lat);
      point.lat < boundsS && (boundsS = point.lat);
      point.lng > boundsE && (boundsE = point.lng);
      point.lng < boundsW && (boundsW = point.lng);
    });
    return new TMap.LatLngBounds(
      new TMap.LatLng(boundsS, boundsW),
      new TMap.LatLng(boundsN, boundsE)
    );
  };

  const drawPolygon = (placeId: any, polygonArray: any, data: any) => {
    const polygons = new TMap.MultiPolygon({
      map: map,
      geometries: [],
      styles: {
        polygon: new TMap.PolygonStyle({
          width: 6,
          borderColor:
            colorList[Math.floor(Math.random() * colorList.length + 1) - 1],
        }),
      },
    });
    let bounds: any[] = [];
    let newGeometries =
      polygonArray &&
      polygonArray.length > 0 &&
      polygonArray.map((polygon: any[], index: any) => {
        bounds.push(fitBounds(polygon));
        return {
          id: `${placeId}_${index}`,
          styleId: "polygon",
          paths: polygon,
          properties: {
            data: data,
          },
        };
      });
    bounds =
      bounds &&
      bounds.length > 0 &&
      bounds.reduce((a, b) => {
        return fitBounds([
          a.getNorthEast(),
          a.getSouthWest(),
          b.getNorthEast(),
          b.getSouthWest(),
        ]);
      });
    polygons.updateGeometries(newGeometries);
    // 阴影区域点事件
    polygons.on("click", (res: any) => {
      var res = res && res.geometry;
      dispatch({
        type: "park/saveParkInfo",
        payload: res.properties.data,
      });
      if (res.properties?.contact) {
        map.setZoom(14);
      } else if (res.properties?.area) {
        map.setZoom(13);
      } else if (res.properties?.city) {
        map.setZoom(12);
      } else if (res.properties?.provice) {
        map.setZoom(10);
      }
      dispatch({ type: "park/savebackNumber", payload: 1 });
      map.setCenter({
        lat: Number(res.properties.data.latitude),
        lng: Number(res.properties.data.longitude),
      });
    });
    // map.fitBounds(bounds)
  };
  const getArea = async () => {
    let res: any = await (parkSearchInfo.displayType == 1
      ? getClusterAreaList
      : parkSearchInfo.displayType == 2
      ? getParkCount
      : getCompanyCount)({
      ...parkSearchInfo,
    });
    if (res.code == 1 && res.data) {
      if (map) {
        if (res.data && res.data.length > 0) {
          setDonutList(res.data);
        }
      }
    }
  };
  // 绘制统计节点
  const darwCluster = () => {
    // 绘制前，先进行销毁操作
    donuts &&
      donuts.forEach((donut: any, index: any) => {
        donut && donut.destory();
      });
    donutlist.forEach((item: any, index: number) => {
      if (item.lat && item.lon) {
        donuts.push(
          new ClusterBubble({
            id: index,
            map: map,
            properties: {
              ...item,
            },
            content: item.count > 999 ? "999+" : item.count + "",
            position: new TMap.LatLng(Number(item.lat), Number(item.lon)),
          })
        );
      }
    });
    if (parkSearchInfo.displayType == 2 || parkSearchInfo.displayType == 3) {
      let overlay = document?.getElementsByClassName("my-overlay");
      Array.from(overlay).forEach((ele: any, i: number) => {
        ele.classList.add("my-overlay1");
      });
    }
    setDonuts(donuts);
    donuts.forEach((donut: any, index: any) => {
      donut.on("click", () => {
        dispatch({
          type: "park/saveParkSearchInfo",
          payload: {
            ...parkSearchInfo,
            area: null,
            areaCode: null,
            province: null,
            provinceCode: null,
            city: donut.properties.city,
            cityCode: donut.properties.cityCode,
          },
        });
        map.setZoom(11);
        setShowLeft(true);
        dispatch({
          type: "park/savebackNumber",
          payload:
            parkSearchInfo.displayType == 1
              ? 0
              : parkSearchInfo.displayType == 2
              ? 53
              : 52,
        });
      });
    });
  };
  useEffect(() => {
    map && donutlist && darwCluster();
  }, [donutlist, parkSearchInfo]);
  const initMap = () => {
    //初始化地图
    map = new TMap.Map(useMapRef.current, {
      center: new TMap.LatLng(30.43758, 120.2817), //设置中心点坐标
      zoom: 10,
      viewMode: "2D",
    });
    setMap(map);
    infoWindow = new TMap.InfoWindow({
      map: map,
      position: new TMap.LatLng(30.43758, 120.2817), //设置信息框位置
      offset: { x: 0, y: -20 },
      zIndex: 999,
    });
    setInfoWindow(infoWindow);
    infoWindow.close();
    if (!mapCenter) {
      ipLocation.locate({}).then((result2: { result: any }) => {
        // 未给定ip地址则默认使用请求端的ip
        console.log({ result2 });
        var res = result2.result;
        map.setCenter(res.location);
        map.setZoom(13);
      });
    } else {
      map.setCenter(mapCenter);
    }
    // let marker = new TMap.MultiMarker({
    //   id: "marker",
    //   map: map,
    //   styles: {
    //     marker: new TMap.MarkerStyle({
    //       width: 30, // 样式宽
    //       height: 30, // 样式高
    //       fontWeight: 500,
    //       size: 18,
    //       anchor: { x: 0, y: 0 },
    //       color: "#fff",
    //       src: require("@/assets/images/park/icon_address.png"),
    //     }),
    //     highlight: new TMap.MarkerStyle({
    //       width: 30, // 样式宽
    //       height: 30, // 样式高
    //       fontWeight: 500,
    //       size: 18,
    //       color: "#fff",
    //       anchor: { x: 0, y: 0 },
    //       src: require("@/assets/images/park/icon_address.png"),
    //       // src: require("@/assets/images/park/map-marker.png"),
    //     }),
    //   },
    //   geometries: [],
    // })
    // setmarkerLayer(marker)
    //监听地图变化
    map.on("idle", function (event: any) {
      let overlay = document?.getElementsByClassName("my-overlay");
      let dots = document?.getElementsByClassName("overlay");
      if (overlay.length > 0) {
        if (map.getZoom() > 9) {
          Array.from(overlay).forEach((ele: any, i: number) => {
            ele.classList.add("my-overlay1");
          });
        } else {
          infoWindow.close();
          Array.from(overlay).forEach((ele: any, i: number) => {
            ele.classList.remove("my-overlay1");
          });
        }
      }
      if (dots.length > 0) {
        if (map.getZoom() > 9) {
          Array.from(dots).forEach((ele: any, i: number) => {
            ele.classList.remove("overlay1");
          });
        } else {
          infoWindow.close();
          Array.from(dots).forEach((ele: any, i: number) => {
            ele.classList.add("overlay1");
            ele.firstChild.classList.remove("active");
          });
        }
      }
    });
    // map.on("mousemove", (evt: any) => {
    //   console.log("mousemove", evt)
    //   geocoder.getAddress({ location: evt.latLng }).then((res: any) => {
    //     console.log("geocoder", res)
    //   })
    // })
  };
  useEffect(() => {
    console.log(backInfo, "backInfo");
    if (backInfo == 0 && map) {
      map.setZoom(5);
    }
    if ((backInfo + "").includes("5") && map) {
      map.setZoom(11);
    }
  }, [backInfo]);

  // // 获取市集群统计点
  useEffect(() => {
    getArea();
  }, [parkSearchInfo]);

  // 绘制节点
  const drawNode = (data: any): void => {
    // 绘制前，先进行销毁操作
    makers &&
      makers.length > 0 &&
      makers.forEach((donut: any, index: any) => {
        console.log({ donut });
        // donut.destory()
        donut && donut.destory();
      });
    const maker = data.map((item: any, index: number) => {
      if (item.latitude && item.longitude) {
        return new Dot({
          map: map,
          id: item.id,
          position: new TMap.LatLng(
            Number(item.latitude),
            Number(item.longitude)
          ),
          properties: {
            ...item,
            title: item.companyName ? item.companyName : item.parkName,
            id: item.id,
          },
          content: index + 1,
        });
      }
    });
    setmakers(maker);
    maker &&
      maker.length > 0 &&
      maker.forEach((item: any, index: number) => {
        if (item) {
          item.on("click", (e) => {
            map.setZoom(16);
            let dots: any = Array.from(
              document?.getElementsByClassName("overlay")
            );
            dots.forEach((ele: any, i: number) => {
              ele.firstChild.classList.remove("active");
              dots[index].firstChild.classList.add("active");
            });
            infoWindow && infoWindow.close();
            infoWindow.setPosition(item.position); //设置信息窗位置
            infoWindow.setContent(`<h3 >${item.properties?.title}</h3>`);
            infoWindow.open();
            setShowLeft(true);
            dispatch({
              type: !item?.properties.companyName
                ? "park/saveIndustrialInfo"
                : "park/saveCompanyInfo",
              payload: item?.properties,
            });
            dispatch({
              type: "park/savebackNumber",
              payload: !item?.properties.companyName ? 2 : 3,
            });
          });
        }
      });
  };

  //获取当前地址
  const getAddressMap = (data: any) => {
    data.provinceCode =
      data?.provinceCode == "8200001"
        ? "820000"
        : data?.provinceCode == "8100001"
        ? "810000"
        : data?.provinceCode;
    data.cityCode =
      data?.cityCode == "110100" ||
      data?.cityCode == "310100" ||
      data?.cityCode == "7100001" ||
      data?.cityCode == "820000" ||
      data?.cityCode == "810000" ||
      data?.cityCode == "120100" ||
      data?.cityCode == "500100"
        ? ""
        : data?.cityCode;
    data.areaCode = data?.areaCode == "7100002" ? "" : data?.areaCode;
    let address = [
      ...new Set(
        [data?.provinceCode, data?.cityCode, data?.areaCode].filter(
          (item) => item
        )
      ),
    ];
    console.log(address, "address");
    if (address && address.length > 0) {
      district
        .search({
          keyword: address[address.length - 1],
        })
        .then((result) => {
          result.result.forEach((level) => {
            level.forEach((place) => {
              var bounds: any[] = [];
              place.polygon.map((polygon: any[], index: any) => {
                bounds.push(fitBounds(polygon)); // 计算能完整呈现行政区边界的最小矩形范围
              });
              bounds = bounds.reduce((a, b) => {
                return fitBounds([
                  a.getNorthEast(),
                  a.getSouthWest(),
                  b.getNorthEast(),
                  b.getSouthWest(),
                ]);
              });
              map.fitBounds(bounds);
              level[0].location && map.setCenter(level[0].location);
              map.setZoom(11);
            });
          });
        });
    } else {
      map.setZoom(6);
    }
  };
  //
  const openInfoWindow = (data: any) => {
    let dots: any = Array.from(document?.getElementsByClassName("overlay"));
    dots.forEach((ele: any, i: number) => {
      ele.firstChild.classList.remove("active");
    });

    makers &&
      makers.map((item: any, index: number) => {
        console.log(item, data);
        if (item?.properties?.title == data.title) {
          infoWindow && infoWindow.close();
          infoWindow.setPosition(item.position); //设置信息窗位置
          infoWindow.setContent(`<h3 >${item.properties?.title}</h3>`);
          infoWindow.open();
          dots[index].firstChild.classList.add("active");
        }
      });
  };
  useEffect(() => {
    if (map) {
      if (companyInfo?.contact) {
        map.setZoom(18);
      } else if (companyInfo?.area) {
        map.setZoom(16);
      } else if (companyInfo?.city) {
        map.setZoom(12);
      } else if (companyInfo?.province) {
        map.setZoom(10);
      }
      openInfoWindow({ ...companyInfo, title: companyInfo?.companyName });
    }
  }, [companyInfo]);
  useEffect(() => {
    if (map) {
      if (industrialInfo?.contact) {
        map.setZoom(16);
      } else if (industrialInfo?.area) {
        map.setZoom(14);
      } else if (industrialInfo?.city) {
        map.setZoom(12);
      } else if (industrialInfo?.province) {
        map.setZoom(10);
      }
      openInfoWindow({ ...industrialInfo, title: industrialInfo?.parkName });
    }
  }, [industrialInfo]);
  useEffect(() => {
    if (mapCenter && map) {
      map.setZoom(13);
      map.setCenter(mapCenter);
    }
  }, [mapCenter]);
  useEffect(() => {
    if (map) {
      if (parkInfo?.contact) {
        map.setZoom(14);
      } else if (parkInfo?.area) {
        map.setZoom(13);
      } else if (parkInfo?.city) {
        map.setZoom(12);
      } else if (parkInfo?.province) {
        map.setZoom(10);
      }
      openInfoWindow({ ...parkInfo, title: parkInfo?.parkName });
    }
  }, [parkInfo]);
  useEffect(() => {
    if (map) {
      getAddressMap(parkSearchInfo);
    }
  }, [parkSearchInfo]);
  // //园区
  useEffect(() => {
    if (parks && map && parkSearchInfo.displayType != 1) {
      // markerLayer && markerLayer.setGeometries([])
      drawNode(parks);
    }
    return () => {
      // markerLayer && markerLayer.setGeometries([])
      infoWindow && infoWindow.close();
    };
  }, [parks, parkSearchInfo]);

  // //绘制集群区域
  const drawMapArea = async () => {
    makers &&
      makers.length > 0 &&
      makers.forEach((donut: any, index: any) => {
        donut?.destory();
      });
    donuts &&
      donuts.forEach((donut: any, index: any) => {
        donut?.destory();
      });
    let provices = await district.getChildren().then((result: any) => {
      result.result[0] = result.result[0].map(
        (item: { id: any; fullname: any }) => {
          return {
            value: item.id,
            label: item.fullname,
            ...item,
          };
        }
      );
      return result.result[0];
    });

    clusterList?.data?.map((item: any) => {
      //绘制省集群
      if (item.province && !item.city) {
        let provice = provices.filter((it: any) => {
          return it.label == item.province;
        })[0];
        drawPolygon(provice.id, provice.polygon, item);
      } else if (item.province && item.city && !item.area) {
        // let provice = provices.filter((it: any) => {
        //   return it.label == item.province
        // })[0]
        district.search({ keyword: item.cityCode }).then((res: any) => {
          const { result } = res;
          let city = result[0];
          city && drawPolygon(city.id, city.polygon, item);
        });
      } else {
        if (!item.areaCode) {
          return;
        }
        district.search({ keyword: item.areaCode }).then((res: any) => {
          const { result } = res;
          let area = result && result.length > 0 && result[0][0];
          if (area) {
            //   district.getChildren({ id: city.id }).then((res: any) => {
            //     const { result } = res
            //     let area = result[0].filter(
            //       (it: any) => it.fullname === item.area
            //     )[0]
            //     if (area && area.id && area.polygon) {
            item.latitude = area.location.lat;
            item.longitude = area.location.lng;
            drawPolygon(area.id, area.polygon, item);
            //     }
            //   })
          }
        });
      }
    });
  };

  useEffect(() => {
    if (clusterList && Array.isArray(clusterList.data) && map) {
      drawMapArea();
    }
  }, [clusterList]);
  useEffect(() => {
    if (mapCenter && map) {
      map.setCenter(mapCenter);
    }
    if (mapCenter?.value && map) {
      map.setZoom(mapCenter.value === 2 ? 12 : 10);
    }
  }, [mapCenter]);
  // 激活企业 弹窗

  useEffect(() => {
    if (useMapRef.current && TMap) {
      initMap();
    }
    return () => {
      map && map.destroy();
    };
  }, []);
  return (
    <MapContainer fullscreen={fullscreen}>
      <MapWrapper>
        <UseMapWrapper ref={useMapRef} />
        <NavLeft showSearch={fullscreen} />
        {fullscreen && <NavRight />}
      </MapWrapper>
    </MapContainer>
  );
};

export default AtlasMap;
