import { Modal } from "antd";
import styled from "styled-components";

export const AgreementModal = styled(Modal)`
  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-modal-title {
    text-align: center;
    font-size: 24px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    color: #000000;
  }
  .content-wrapper {
    height: 600px;
    overflow: auto;
    padding: 10px;
    &::-webkit-scrollbar {
      width: 4px;
      height: 2px;
      scrollbar-arrow-color: #666;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.2);
      scrollbar-arrow-color: #666;
    }
    /*滚动条里面轨道*/
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }
  }
`;
