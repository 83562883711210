import BaseLayout from "@/layouts";
import { Divider, List, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { getRealInfoList } from "@/utils/api";
import moment from "moment";
import { history } from "umi";
import InfiniteScroll from "react-infinite-scroll-component";
import { PolicyListWrapper, PolicyWrapper, Poster } from "./style";
import { BannarInfo, Title } from "@/styles/common-style";
const RealInfoList: React.FC = () => {
  const [loading, setloading] = useState(false);
  let [list, setlist] = useState<any[]>([]);
  let [data, setdata] = useState<any[]>([]);
  let [count, setCount] = useState(0);
  let [indexCount, setIndexCount] = useState(1);
  useEffect(() => {
    window.scroll(0, 0);
    getRealInfoList({
      index: indexCount,
      size: 10,
    }).then((res: any) => {
      if (res.code === 1) {
        setdata(res.data);
        setCount(res.page.count);
      }
    });
  }, []);
  const onLoadMore = () => {
    // setloading(true)
    // list = data.concat(
    //   [...new Array(1)].map(() => ({ loading: true, releaseTime: "" }))
    // )
    if (loading) {
      return;
    }
    setloading(true);
    indexCount++;
    setIndexCount(indexCount);
    // });
    getRealInfoList({
      index: indexCount,
      size: 10,
    })
      .then((res) => {
        const Resdata = data.concat(res.data);
        setloading(false);
        setdata(Resdata);
      })
      .catch(() => {
        setloading(false);
      });
  };

  return (
    <BaseLayout>
      <PolicyWrapper>
        <BannarInfo bgImage={require("@/assets/images/real-info-bg.png")} />
        <PolicyListWrapper>
          <InfiniteScroll
            dataLength={data.length}
            next={onLoadMore}
            hasMore={data.length < count}
            loader={<></>}
            endMessage={<Divider plain>我是有底线的</Divider>}
          >
            <List
              dataSource={data}
              renderItem={(item) => (
                <List.Item
                  key={item.email}
                  onClick={() =>
                    history.push({
                      pathname: `/policy/policy-detail/${item.id}`,
                      search: "?isProlicy=false",
                    })
                  }
                >
                  <Poster bgImg={item.poster} />
                  <div className="item-content">
                    <Title
                      fz={30}
                      fw={500}
                      color="#333"
                      textAlign="left"
                      className="item-title"
                    >
                      {item.title}
                    </Title>
                    <div className="item-time">
                      {moment(item.releaseTime).format("YYYY-MM-DD hh:mm")}
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.introduction,
                      }}
                      className="item-product ql-editor"
                    ></div>
                    <div className="item-detail">
                      <span>查看详情</span> &gt;{" "}
                    </div>
                  </div>
                </List.Item>
              )}
            />
          </InfiniteScroll>
        </PolicyListWrapper>
      </PolicyWrapper>
    </BaseLayout>
  );
};

export default RealInfoList;
