import styled from "styled-components";
import { Row } from "antd";

export const AllServicesWrapper = styled.div`
  width: 100%;
  min-width: 1440px;
`;

export const TagSelectorContainer = styled.div`
  /* height: 60px; */
  background: #f5fafd;
  .tag-area {
    width: 100%;
    min-width: 1440px;
    border-bottom: 1px solid #d8d8d8;
  }
`;

export const SelectorArea = styled(Row)`
  padding-left: 36px;
  margin-top: 42px;
  background-color: #eeeeef;
  height: 73px;
  .ant-row {
    align-items: center;
    cursor: pointer;
  }
  .tag-name {
    margin-right: 8px;
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    color: #000000;
  }
  .carete {
    width: 30px;
    height: 14px;
    position: relative;
    display: flex;
    flex-direction: column;
    .anticon-caret-up {
      position: absolute;
      top: -4px;
      color: #999;
      font-size: 12px;
    }
    .anticon-caret-down {
      position: absolute;
      top: 4px;
      color: #999;
      font-size: 12px;
    }
  }
`;

export const ServicesListWrapper = styled.div`
  margin: 0 auto;
  width: 1200px;
`;
export const ListWrapper = styled.div`
  padding-bottom: 40px;
  .ant-list-item {
    flex-direction: row-reverse;
    padding: 34px 36px;
    box-sizing: border-box;
  }
  .ant-list-vertical .ant-list-item-meta-title {
    margin-bottom: 3px;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin-left: 0;
    margin-right: 26px;
  }

  .ant-list-pagination {
    text-align: center;
  }
  .ant-list-item-meta-description {
    margin-bottom: 42px;
    color: #666;
    width: 873px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    &.ql-editor {
      height: auto;
      img {
        display: none;
      }
    }
  }
  .ant-list-split .ant-list-item {
    border-bottom: 1px solid #d8d8d8;
  }
  .ant-list-split .ant-list-item:last-child {
    border-bottom: none;
  }
`;
export const ContentContainer = styled(Row)`
  margin-top: 20px;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #999999;
  .ant-row-space-between {
    margin-top: 7px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    font-size: 24px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #333333;
  }
  .type {
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #1848fd;
  }
`;
