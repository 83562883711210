import { Title } from "@/styles/common-style";
import { OrderDescItem, OrderDescWrapper } from "../style";

interface OrderDescProps {}
export const OrderDesc: React.FC<OrderDescProps> = () => {
  const OrderDescList = [
    {
      title: "海量订单 每日更新",
      desc: "汇集服装产业订单需求 总有一单适合你",
      bg: require("@/assets/images/order-platform/order_desc_1.png"),
    },
    {
      title: "精准匹配 省时省心",
      desc: "关键词精准匹配 快速在线接单",
      bg: "#e6e9ff",
    },
    {
      title: "订单速递 在线报价",
      desc: "第一时间收到订单消息 一键报价接单",
      bg: "#e6e9ff",
    },
    {
      title: "担保交易 安全保障",
      desc: "平台实名认证安全可靠 靠谱第三方支付担保",
      bg: "#f3f7fe",
    },
  ];
  const OrderDescInfo = ({ title, desc, bg }: any) => {
    return (
      <OrderDescItem bg={bg}>
        <Title fz={24} fw={600} color="#000">
          {title}
        </Title>
        <div className="desc">{desc}</div>
      </OrderDescItem>
    );
  };
  return (
    <OrderDescWrapper>
      <Title
        fz={36}
        fw={600}
        color="#000"
        textAlign="center"
        style={{ marginBottom: 44 }}
      >
        订单平台创新特色
      </Title>
      <div className="order-desc">
        {OrderDescList.map((item, index) => (
          <OrderDescInfo key={index} {...item} />
        ))}
      </div>
    </OrderDescWrapper>
  );
};
