import { H5Wrapper } from "../style";
import { Form, Input, Button, Space, Checkbox, message } from "antd";
import { useLayoutEffect, useState } from "react";
import { createConsult } from "../api";
import { useParams } from "umi";
import { isPhone } from "@/utils/common";
interface SubFormProps {}

const SubForm: React.FC<SubFormProps> = () => {
  const param = useParams<{ id?: string }>();
  // const [demands, setdemand] = useState<string>("")
  const [disabled, setDisabled] = useState<boolean>(true);
  const options = [
    { label: "服装数字化解决方案", value: "1" },
    { label: "智慧零售解决方案", value: "2" },
    { label: "服装供应链", value: "3" },
    { label: "咨询培训", value: "4" },
    { label: "市场推广", value: "5" },
    { label: "其他需求", value: "6" },
  ];
  const onFinish = (values: any) => {
    console.log(values, "values");
    const params = {
      ...values,
      demand: values.demand.join(","),
      sourceDeviceId: param?.id,
    };
    createConsult(params).then((res: any) => {
      if (res.code == 1) {
        message.success("添加成功");
      }
    });
  };
  useLayoutEffect(() => {
    document.title = "中国服装科创研究院需求统计表";
  }, [param?.id]);
  const onChange = (value: any) => {
    console.log(value, "value");
    // setdemand(value.join(","))
    if (value.join(",").includes("6")) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };
  return (
    <H5Wrapper>
      <Form
        name="basic"
        className="h5-form"
        layout={"horizontal"}
        labelCol={{ span: 4 }}
        // wrapperCol={{ span:  }}
        labelAlign="left"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="您的姓名"
          name="name"
          rules={[
            {
              validator: (_: any, value: any) => {
                if (value) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(new Error("请输入您的姓名"));
                }
                //
              },
              validateTrigger: ["blur"],
            },
          ]}
        >
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item
          label="您的联系电话"
          name="mobile"
          rules={[
            {
              validator: (_: any, value: any) => {
                if (value) {
                  if (isPhone(value)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(new Error("手机号格式不正确"));
                  }
                } else {
                  return Promise.reject(new Error("请输入您的联系电话"));
                }
                //
              },
              validateTrigger: ["blur"],
            },
          ]}
        >
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item
          label="您的联系地址"
          name="contactAddress"
          // rules={[{ required: true, message: "请输入您的联系地址" }]}
        >
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item
          label="您的工作单位"
          name="company"
          // rules={[{ required: true, message: "请输入您的工作单位" }]}
        >
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item
          label="您的职务"
          name="post"
          // rules={[{ required: true, message: "请输入您的工作单位" }]}
        >
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item
          label="您的需求（多选)"
          name="demand"
          className="multiple"
          // rules={[{ required: true, message: "请输入您的工作单位" }]}
        >
          {/* <Space direction='vertical'> */}
          <Checkbox.Group options={options} onChange={onChange} />
          {/* </Space> */}
        </Form.Item>
        <Form.Item
          label=""
          name="otherDemand"
          className="text"
          // rules={[{ required: true, message: "请输入您的工作单位" }]}
        >
          <Input.TextArea placeholder="请输入" rows={4} disabled={disabled} />
        </Form.Item>
        <Form.Item className="submit">
          <Button type="primary" htmlType="submit" className="concat">
            提交
          </Button>
        </Form.Item>
      </Form>
    </H5Wrapper>
  );
};

export default SubForm;
