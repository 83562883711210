import { getParklist, getQueryCompanyList } from "@/api/park";
import { Divider, List, Skeleton } from "antd";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "umi";
import { ParkVo } from "@/models/park";
import { AddressImg, ListContainer, ListItem } from "../../style";
import { utilsArr } from "@/utils/common";
interface ResultListProps {
  height?: number;
  type?: number;
  value?: any;
  currentId?: string;
  onItemClick?: (value: any, index: number | undefined) => void;
}
const ResultList: React.FC<ResultListProps> = ({
  height = 800,
  type = 1,
  currentId = "1",
  onItemClick,
}) => {
  let [index, setIndex] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ParkVo[]>([]);
  const dispatch = useDispatch();
  const [total, setTotal] = useState<number>(0);
  const { parkInfo, parkSearchInfo } = useSelector(
    (state: { park: any }) => state.park
  );
  const loadMoreData = (page: number) => {
    if (loading) {
      return;
    }
    // index++;
    // setIndex(index);
    const params = {
      ...parkSearchInfo,
      index: page,
      size: 10,
      clusterId: parkInfo?.id,
    };
    setLoading(true);
    (currentId == "1" ? getParklist(params) : getQueryCompanyList(params)).then(
      (res: any) => {
        if (res.code === 1) {
          // dispatch({ type: "park/saveParkInfo", payload: res.data })
          setData([...res.data]);
          setLoading(false);
          dispatch({
            type: "park/saveParks",
            payload: [...res.data],
          });
          setTotal(res?.page?.count || 0);
        } else {
          setLoading(false);
        }
      }
    );
  };

  useEffect(() => {
    setIndex(1);
    setData([]);
    loadMoreData(1);
  }, [currentId]);
  return (
    <ListContainer
      id="scrollableDiv"
      style={{
        height: height + "px",
      }}
    >
      {/* <InfiniteScroll
        dataLength={data.length}
        next={loadMoreData}
        hasMore={data.length < total}
        loader={<></>}
        endMessage={
          data.length > 10 ? <Divider plain>我是有底线的</Divider> : <></>
        }
        scrollableTarget="scrollableDiv"
      > */}
      <List
        loading={loading}
        pagination={{
          total: total,
          showSizeChanger: false,
          simple: true,
          hideOnSinglePage: true,
          onChange: (page) => {
            loadMoreData(page);
          },
          pageSize: 10,
        }}
        dataSource={data}
        renderItem={(item: any, index) => (
          <List.Item key={index}>
            <ListItem
              onClick={() => {
                onItemClick?.(item, type);
                if (currentId == "1") {
                  dispatch({
                    type: "park/saveIndustrialInfo",
                    payload: item,
                  });
                  dispatch({ type: "park/savebackNumber", payload: 2 });
                } else if (currentId == "2") {
                  dispatch({ type: "park/saveCompanyInfo", payload: item });
                  dispatch({ type: "park/savebackNumber", payload: 3 });
                }
                dispatch({
                  type: "park/saveMapCenter",
                  payload: {
                    lat: Number(item.latitude),
                    lng: Number(item.longitude),
                  },
                });
              }}
            >
              <div className="left">
                <AddressImg>{index + 1}</AddressImg>
                <div className="container">
                  <div className="title">
                    {currentId == "1" ? item.parkName : item.companyName}
                  </div>
                  <div className="info">
                    {[item.province, item.city, item.area, item.contact]
                      .filter((it) => it !== "")
                      .join("") || "-"}
                  </div>
                </div>
              </div>
              <div className="right">
                {type == 0 && <div className="detail-btn">详情</div>}
                {type > 0 && (
                  <img
                    src={
                      currentId == "1"
                        ? item?.parkPic?.split(",")[0]
                        : item?.companyPic?.split(",")[0]
                    }
                    alt="公司logo"
                    className="img"
                  />
                )}
              </div>
            </ListItem>
          </List.Item>
        )}
      />
      {/* </InfiniteScroll> */}
    </ListContainer>
  );
};

export default ResultList;
