import { ContactView } from "@/models/ecological";
import request from "./request";

export const getRealInfoList = (params: any) => {
  return request("/business/hotInfo/list", {
    method: "POST",
    requestType: "json",
    data: params,
  });
};
export const getRealInfodetail = (query: { id: any }) => {
  return request(`/business/hotInfo/queryById/${query.id}`, {
    method: "GET",
    requestType: "json",
    params: query,
  });
};
// /meetingManage/list
export const getMettingList = (params: any) => {
  return request("/business/meeting/list", {
    method: "POST",
    requestType: "json",
    data: params,
  });
};

// /meetingManage/queryById/
export const getMettingDetail = (query: { id: any }) => {
  return request(`/business/meeting/queryById/${query.id}`, {
    method: "GET",
    requestType: "json",
    params: query,
  });
};

export const createContact = (params: ContactView) => {
  return request("/business/contact/create", {
    method: "POST",
    requestType: "json",
    base_type: "2",
    data: params,
  });
};

// 产业园区

export const getParklist = (params: {
  index: number;
  size: number;
  longitude: number;
  latitude: number;
}) => {
  return request("/park/list", {
    method: "POST",
    requestType: "json",
    data: params,
  });
};

export const getParkdetail = (query: { id: any }) => {
  return request(`/park/queryById/${query.id}`, {
    method: "GET",
    requestType: "json",
    params: query,
  });
};
export const getpartant = (params: any) => {
  return request(`/business/service/supplier/list`, {
    method: "post",
    requestType: "json",
    data: params,
  });
};

export const getConpany = (query: any) => {
  return request(`/company/queryListByParkId/${query.parkId}`, {
    method: "get",
    requestType: "json",
    params: query,
  });
};
export const getExpert = () => {
  return request(`/business/expert/homeExpertList`, {
    method: "get",
    requestType: "json",
  });
};
export const getExpertList = (params: any) => {
  return request(`/business/expert/pageList`, {
    method: "post",
    requestType: "json",
    data: params,
  });
};
export const getCase = (params: any) => {
  return request(`/business/case/pageList`, {
    method: "post",
    requestType: "json",
    data: params,
  });
};
export const getCaseDetailById = (query: any) => {
  return request(`/business/case/queryById/${query.id}`, {
    method: "get",
    requestType: "json",
    params: query,
  });
};
export const getPolicyList = (params: any) => {
  return request(`/business/industrialPolicy/homePageList`, {
    method: "post",
    requestType: "json",
    data: params,
  });
};
export const getRealPolicydetail = (query: { id: any }) => {
  return request(`/business/industrialPolicy/queryById/${query.id}`, {
    method: "GET",
    requestType: "json",
    params: query,
  });
};
//公司列表
export const getCompanyList = (params: any) => {
  return request(`/business/company/list`, {
    method: "post",
    requestType: "json",
    data: params,
  });
};
export const getHotInfoList = (params: any) => {
  return request(`/business/hotInfo/list`, {
    method: "post",
    requestType: "json",
    data: params,
  });
};
