import { CusButton, Title } from "@/styles/common-style";
import { CusList, HeaderListWrapper } from "./style";
import { List } from "antd";
import { history } from "umi";
import { useEffect, useState } from "react";
import { getPolicyList } from "@/utils/api";
interface HeaderListProps {
  isLinpin?: boolean;
}
const HeaderList: React.FC<HeaderListProps> = (props) => {
  const leftTabs = [
    {
      title:
        "2021年7月28日，临平数字时尚产业创新平台在临平新城正式发布。橙织临平数字时尚产业创新平台由中国服装科创研究院主导，依托中国服装协会产业资源，构建的一脑、二平台、三中心服务体系。平台协同数十家行业知名企业和大学机构，以产业链数字化资源链接为基础，打造数字化可信生态服务模式。",
    },
  ];
  const leftTab1 = [
    {
      title:
        "橙织工业互联网平台于2021年7月正式发布，平台中国服装科创研究院主导，依托中国服装协会产业资源，构建的一脑、二平台，三中心服务体系。平台协同数十家行业知名企业和大学机构，以产业链数字化资源链接为基础，打造数字化可信生态服务模式。",
    },
  ];
  interface PolicyListType {
    id: number;
    title: string;
    releaseTime: string;
    poster: string;
    introduction: string;
    exhibitionPlatform: string;
    exhibitionPlatformStr: string;
    sort: number;
    enclosure: string;
    detail: string;
    createTime: string;
    updateTime: string;
  }
  const [data, setData] = useState<PolicyListType[]>([]);
  const getPolicyListInfo = (params: any, cb: any) => {
    getPolicyList(params).then((res: any) => {
      if (res.code == 1) {
        cb(res.data);
      }
    });
  };
  useEffect(() => {
    try {
      getPolicyListInfo(
        { index: 1, size: 8, exhibitionPlatform: props?.isLinpin ? "2" : "1" },
        setData
      );
    } catch (error) {
      console.error(error);
    }
  }, [props.isLinpin]);
  return (
    <HeaderListWrapper>
      <div className="list-content">
        <div className="list-left">
          <Title fz={24}>
            {props.isLinpin ? (
              "橙织在临平"
            ) : (
              <div style={{ marginTop: 50 }}></div>
            )}
          </Title>
          <div className="list-left-wrap">
            <div className="list-left-content">
              {props.isLinpin
                ? leftTabs &&
                  leftTabs.map((tab) => (
                    <div className="list-left-item" key={tab.title}>
                      {tab.title}
                    </div>
                  ))
                : leftTab1 &&
                  leftTab1.map((tab) => (
                    <div className="list-left-item" key={tab.title}>
                      {tab.title}
                    </div>
                  ))}
            </div>
          </div>
        </div>
        <div className="list-right">
          <div className="list-right-header">
            <Title fz={24}>
              {props.isLinpin ? "服装产业政策" : "全国服装产业政策"}
            </Title>
            {props.isLinpin && (
              <CusButton
                type="link"
                onClick={() => history.push("/policy/policy-list")}
              >
                查看更多
              </CusButton>
            )}
          </div>
          <CusList
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item: any) => (
              <List.Item
                onClick={() =>
                  history.push({
                    pathname: `/policy/policy-detail/${item.id}`,
                    search: "?isProlicy=true",
                  })
                }
              >
                <div className="list-title">{item.title}</div>
                <div className="list-time">{item.createTime.slice(0, 10)}</div>
              </List.Item>
            )}
          />
        </div>
      </div>
    </HeaderListWrapper>
  );
};

export default HeaderList;
