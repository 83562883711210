import { FC } from "react";
import { useSelector } from "umi";
import { Train5 } from "../style";
import { Title } from "@/styles/common-style";
import { ConsultNow } from "./consult-now";

interface IProps {}

const Index: FC<IProps> = (props) => {
  const isLinpin = useSelector((state: any) => state.home.isLinpin);
  return (
    <Train5>
      <Title textAlign="center">专项咨询服务</Title>
      <div className="train-sub-tilte">
        以数据驱动企业内各环节的高效协同，打通上下游供应链间的物流和信息流，帮助企业降低运营成本，提升电商数据分析能力，依托丰富的全产业链资源及实战专家团队，快速助力企业数字化转型升级。
      </div>
      <img
        style={{ width: "100%", minWidth: "1440px" }}
        src={
          isLinpin
            ? require("@/assets/images/more-consulting/临平.png")
            : require("@/assets/images/more-consulting/全国.png")
        }
      />
      <ConsultNow
        name={"咨询"}
        title="立即咨询"
        style={{
          margin: "46px auto 0",
          position: "relative",
          width: 250,
          height: 48,
          backgroundColor: "#1848fd",
          color: "#fff",
        }}
      />
    </Train5>
  );
};

export default Index;
