import styled from "styled-components";

export const DropMenuWrapper = styled.div`
  .menu-content {
    margin: 0 auto;
    width: 1200px;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    .menu-item {
      width: 220px;
      height: 80px;
      padding: 16px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      background: rgba(255, 255, 255, 0.8);
      &:hover {
        box-shadow: 0px 2px 7px 2px rgba(0, 0, 0, 0.2);
      }
    }
    /* .memu-active {
    } */
    .menu-icon {
      margin-right: 30px;
      width: 40px;
      height: 40px;
    }
    .menu-title {
      font-size: 20px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      color: #333333;
      line-height: 28px;
    }
    .menu-item:nth-child(2) .menu-icon {
      margin-right: 20px;
    }
  }
`;
