import { Row, List, Space, Button } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { MyOrderWrapper } from "../style";
import { history, useSelector } from "umi";
import { getOrderList } from "@/api/knowledge";

interface pageVo {
  index: number;
  size: number;
  count: number;
  total: number;
}
const OrderList: React.FC = () => {
  const { user } = useSelector((state: any) => state.user);
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [request, setRequest] = useState<any>({
    index: 1,
    size: 4,
    status: 7,
    payer: user?.id,
  });
  const [page, setPage] = useState<pageVo>();
  const getCollect = () => {
    setLoading(true);
    getOrderList(request)
      .then((res: any) => {
        if (res.code === 1) {
          setData(res.data);
          setPage(res.page);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getCollect();
  }, [request]);
  return (
    <MyOrderWrapper>
      <List
        itemLayout="vertical"
        size="large"
        loading={loading}
        pagination={
          page
            ? {
                total: page && page.count ? page.count : 1,
                onChange: (page) => {
                  request.index = page;
                  setRequest({ ...request });
                  console.log(page);
                },
                pageSize: 4,
              }
            : false
        }
        dataSource={data}
        footer={false}
        renderItem={(item: any) => (
          <List.Item key={item.title}>
            <Row className="header" align="middle" justify="space-between">
              <div className="header-order-num">订单号 {item.id}</div>
              <div className="header-order-timer">
                {item.createTime &&
                  moment(item.createTime).format("YYYY-MM-DD HH:mm")}
              </div>
            </Row>
            <Row
              align="middle"
              className="content-info"
              justify="space-between"
            >
              <div className="order-title">{item.title}</div>
              <div className="goods-price">￥{item.payAmount || 0}</div>
              <Button type="text">已购买</Button>
            </Row>
          </List.Item>
        )}
      />
    </MyOrderWrapper>
  );
};

export default OrderList;
