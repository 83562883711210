import { getProductList } from "@/api/server";
import { useEffect, useState } from "react";
import { useSelector } from "umi";
import { ManufactureCateWrapper } from "../style";
import Cates, { DataSource } from "./cates";
import { history } from "umi";
interface ManufactureCateProps {}
const ManufactureCate: React.FC<ManufactureCateProps> = (props) => {
  const [dataSource, setDataSource] = useState<DataSource[]>([]);
  const { serverCates } = useSelector((state: any) => state.server);
  const [serverCate, setServerCate] = useState<any>();
  const getList = (id: number) => {
    const params = {
      index: 1,
      size: 10,
      serviceCateId: id,
    };
    getProductList(params).then((res: any) => {
      if (res.code === 1) {
        setDataSource(res.data);
      }
    });
  };
  const itemClick = (value: any) => {
    history.push("/server/server-detail/" + value.id);
  };
  useEffect(() => {
    let item = serverCates.filter((item: any) => item.id === 404)[0];
    setServerCate(item);
    if (item && item.metadataList) {
      getList(item.metadataList[0].id);
    }
  }, [serverCates]);
  return (
    <ManufactureCateWrapper>
      <Cates
        title={serverCate?.metadataName}
        data={dataSource}
        itemClick={(value) => itemClick(value)}
        tabs={serverCate?.metadataList || null}
        bgColor="#fff"
        type={1}
        TabClick={(value: any) => {
          getList(value.id);
        }}
      />
    </ManufactureCateWrapper>
  );
};

export default ManufactureCate;
