import BaseLayout from "@/layouts";
import { Partner } from "../order-platform/components/partner";
import { BgImage } from "../order-platform/style";
import { Train1 } from "./components/train1";
import { Train2 } from "./components/train2";
import { Train3 } from "./components/train3";
import { Train4 } from "./components/train4";
import { MoreTrainsWrapper } from "./style";
import Train5 from "./components/train5";

interface MoreTrainsProps {}
const MoreTrains: React.FC<MoreTrainsProps> = () => {
  return (
    <BaseLayout>
      <MoreTrainsWrapper>
        <BgImage
          bg={require("@/assets/images/more-consulting/more_con_bg.png")}
        />
        <Train1 />
        <Train3 />
        <Train2 />
        <Train4 />
        <Train5 />
        <Partner noDownload />
      </MoreTrainsWrapper>
    </BaseLayout>
  );
};

export default MoreTrains;
