import styled from "styled-components";

export const RegistWrap = styled.div`
  margin: 118px auto;
  width: 370px;
  .cus-form {
    .ant-form-item-label {
      margin-right: 14px;
    }
    .ant-form-item-label label {
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      color: #000000;
    }
    .ant-btn-primary {
      margin-left: 104px;
    }
  }
`;
