import { Title } from "@/styles/common-style";
import { DiagnosisWrapper } from "../style";

interface DiagnosisProps {}
export const Diagnosis: React.FC<DiagnosisProps> = () => {
  const data = [
    {
      title: "标准符合性评估",
      img: require("@/assets/images/maintain/img1.png"),
      info: "基于《中国服装数字化快反工厂等级认定标准》开展第三方标准符合性评估活动现场验证、综合评价",
    },
    {
      title: "现场验证、综合认定",
      img: require("@/assets/images/maintain/img2.png"),
      info: "通过人员访谈、问卷调查、系统演示、现场勘察等方式验证工厂数字化快反能力水平，帮助企业识别现状，发现短板确定数字化快反能力等级",
    },
    {
      title: "持续改进",
      img: require("@/assets/images/maintain/img3.png"),
      info: "通过开展评估认定，与标准对标，进行差距分析，确认下一步改进方向，持续提升工厂数字化快反能力",
    },
  ];
  return (
    <DiagnosisWrapper>
      <Title
        fz={36}
        fw={600}
        color="#000"
        textAlign="center"
        style={{ marginBottom: 27 }}
      >
        工厂等级认定诊断
      </Title>
      <div className="diag-list">
        {data.map((item, index) => (
          <div className="diag-list-item" key={index}>
            <div className="title">{item.title}</div>
            <div className="info">{item.info}</div>
            <img src={item.img} alt={item.img} className="img" />
          </div>
        ))}
      </div>
    </DiagnosisWrapper>
  );
};
