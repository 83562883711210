import { stringify } from "querystring";
import type { Reducer, Effect } from "umi";
import { history } from "umi";

// import { setAuthority } from '@/utils/authority';
// import { getPageQuery } from '@/utils/utils';
import { message } from "antd";

import { clearUserInfo, getPageQuery, SetToken } from "@/utils/common";
import { loginIn } from "@/api/login";

/** 用户登录状态类型 */
export type StateType = {
  status?: "ok" | "error";
  type?: string;
  isLogin?: boolean;
};
export interface UserInfo {
  id: any;
  avatar?: string;
  isLogin: any;
  access_token: string;
  account_id: number;
  account_name: string;
  expires_in: number;
  nickName: string;
  refresh_token: string;
  token_type: string;
  userName: string;
}

/** 用户登录模型类型 */
export type LoginModelType = {
  namespace: string;
  state: StateType;
  effects: {
    login: Effect;
    logout: Effect;
  };
  reducers: {
    changeLoginStatus: Reducer<StateType>;
    hasLogin: Reducer<StateType>;
  };
};

/** 用户登录模型 */
const Model: LoginModelType = {
  namespace: "login",

  state: {
    status: undefined,
  },

  effects: {
    /** 用户登录 */
    *login({ payload }: any, { call, put }: any) {
      // 使用try cache 捕获错误
      console.log({ payload });
      try {
        const response = yield call(loginIn, payload);
        if (response.code === 1) {
          message.success("登录成功");
          SetToken(response.data.access_token);
          // SetToken(response.data.access_token);
          const userData =
            ({
              ...response.data,
              status: "ok",
            } as any) || {};
          /** 用户信息存储 */
          const userInfo: UserInfo = {
            ...response.data,
            isLogin: userData.status == "ok",
            // userName: userData.userName || "",
            // nickName: userData.nickName || "",
          };
          yield put({
            type: "user/getUser",
            payload: response.data.account_id,
          });

          yield put({
            type: "changeLoginStatus",
            payload: userInfo,
          });

          setTimeout(() => {
            const urlParams = new URL(window.location.href);
            const params = getPageQuery();
            let { redirect } = params as { redirect: string };
            if (redirect) {
              const redirectUrlParams = new URL(redirect);
              if (redirectUrlParams.origin === urlParams.origin) {
                redirect = redirect.substr(urlParams.origin.length);
                if (redirect.match(/^\/.*#/)) {
                  redirect = redirect.substr(redirect.indexOf("#") + 1);
                }
              } else {
                window.location.href = "/";
                return;
              }
            }
            // history.replace(redirect || '/');
            // 二级目录部署，解决登录跳转问题
            history.replace("/");
            // window.location.reload()
          }, 30);
        } else {
          message.error(response.errMsg || "登录失败");
        }
      } catch (err) {
        console.error({ err });
      }
    },
    // islogin({ payload }: any, { call, put }: any): {

    // }
    /** 用户退出 */
    *logout({ payload }: any, { call, put }: any) {
      //   const { redirect } = getPageQuery();
      //   // Note: There may be security issues, please note
      if (window.location.pathname !== "/login") {
        clearUserInfo();
        SetToken("");
        yield put({
          type: "user/saveUser",
          payload: {},
        });
        // window.location.reload()
        history.replace("/");
      }
    },
  },

  reducers: {
    /** 设置用户登录状态 */
    changeLoginStatus(state: any, { payload }: any) {
      return {
        ...state,
        status: payload.status,
        type: payload.type,
      };
    },
    hasLogin(state: any, { payload }: any) {
      return {
        ...state,
        isLogin: payload.isLogin,
      };
    },
  },
};

export default Model;
