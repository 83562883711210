// @ts-nocheck
import axios, { AxiosResponse } from "axios";
import { message } from "antd";
import { history } from "umi";
import {
  GetToken,
  TokenHeaderKey,
  SetToken,
  clearUserInfo,
} from "@/utils/common";
const request = axios.create({
  // TODO 非测试环境
  baseURL: process.env.BASE_URL,
  // TODO 测试环境
  // baseURL: '/api'
});
// 异常拦截处理器
const errorHandler = (error: any) => {
  if (error.response.status == "401") {
    history.replace("/login");
    clearUserInfo();
    SetToken("");
  }
  console.log({ error });
  switch (error.response.status) {
    /* eslint-disable no-param-reassign */
    case 400:
      error.message = "请求错误";
      break;
    case 401:
      error.message = error.response.data.errMsg;
      break;
    case 403:
      error.message = "拒绝访问";
      break;
    case 404:
      error.message = `请求地址出错: ${error.response.config.url}`;
      break;
    case 408:
      error.message = "请求超时";
      break;
    case 500:
      error.message = "服务器内部错误";
      break;
    case 501:
      error.message = "服务未实现";
      break;
    case 502:
      error.message = "网关错误";
      break;
    case 503:
      error.message = "服务不可用";
      break;
    case 504:
      error.message = "网关超时";
      break;
    case 505:
      error.message = "HTTP版本不受支持";
      break;
    default:
      break;
    /* eslint-disabled */
  }
  message.error(error.data?.errMsg || error.message);
  return Promise.reject(error);
};

/** 请求拦截 */
request.interceptors.request.use((config: any) => {
  config.headers = config.headers || ({} as any);
  if (!((config.headers as any) || {})[TokenHeaderKey]) {
    const token = GetToken();
    token &&
      (config.headers = {
        ...config.headers,
        [TokenHeaderKey]: `bearer ${token}`,
      });
  }
  config.headers.clientId = "CHAINGE-WEB";
  return config;
}, errorHandler);

const apiCode = {
  code_1: "操作成功",
  code_2: "操作失败",
  code_3: "无结果集",
  code_4: "地址不存在",
  code_5: "系统异常",
  code_6: "入参错误",
  code_7: "授权错误",
  code_8: "系统繁忙，请稍后再试",
  code_9: "未知错误",
};

/** 响应拦截 */
request.interceptors.response.use((res: AxiosResponse) => {
  if (res.config.type !== "queryPreview") {
    if (!(!res.data.code || res.data.code == 1)) {
      if (apiCode[`code_${res.data.code}`]) {
        message.error(res?.data.errMsg || apiCode[`code_${res.data.code}`]);
      }
    }
  }

  return res.data;
}, errorHandler);

export default (url: string, options: any = {}) => {
  return request({ url, ...options });
};
