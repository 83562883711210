import { TagListWrapper } from "./style";
import { Row } from "antd";
import { useState } from "react";
interface TagListProps {
  title?: string;
  data?: any;
  tagClick: (value: any) => void;
}
let defaultdata = [
  { cateName: "免费", id: "1" },
  { cateName: "按需定价", id: "2" },
  { cateName: "1-999", id: "3" },
  { cateName: "1000-4999", id: "4" },
  { cateName: "5000-9999", id: "5" },
  { cateName: "10000以上", id: "6" },
];
const TagList: React.FC<TagListProps> = ({
  title = "服务分类",
  data = defaultdata,
  tagClick,
}) => {
  const [currentId, setCurrentId] = useState<number | string>("-1");
  return (
    <TagListWrapper>
      <div className="tag-label">{title}</div>
      <div
        className={currentId == "-1" ? "tag-item tag-active-item" : "tag-item"}
        onClick={() => {
          setCurrentId("-1");
          tagClick?.("-1");
        }}
      >
        <span className="tag-name">全部</span>
      </div>
      <Row className="tag-list" align="middle">
        {data &&
          data.map((tag: any, index: number) => (
            <div
              className={
                currentId == tag.id ? "tag-item tag-active-item" : "tag-item"
              }
              key={index}
              onClick={() => {
                setCurrentId(tag.id);
                tagClick?.(tag.id);
              }}
            >
              <span className="tag-name">{tag.name}</span>
            </div>
          ))}
      </Row>
    </TagListWrapper>
  );
};

export default TagList;
