import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
   body{
    width: 100%;
    overflow-x: hidden;
    font-family: PingFangSC, PingFangSC-Regular ;
   }
  .loginOverlay{
    z-index: 99999;
    /* top: 95px !important; */
    /* left: 1310px!important; */
    .ant-dropdown-menu{
      width: 198px;
      .ant-dropdown-menu-item{
        height: 54px;
        text-align: center;
        .ant-dropdown-menu-title-content{
          display: block;
          height: 54px;
          line-height: 54px;
          border-bottom: 1px solid rgba(151,151,151,0.34);
        }
         &:last-child .ant-dropdown-menu-title-content{
           padding-bottom: 0;
            border:none;
          }
        a {
          font-size: 18px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: center;
          color: #333333;
        }
      }
    }
  }
  .overlay-title{
    z-index: 99999;
    /* top: 95px !important; */
    .ant-dropdown-menu{
      width: 198px;
      .ant-dropdown-menu-item{
        height: 54px;
        text-align: center;
        .ant-dropdown-menu-title-content{
          display: block;
          height: 54px;
          line-height: 54px;
          border-bottom: 1px solid rgba(151,151,151,0.34);
        }
         &:last-child .ant-dropdown-menu-title-content{
           padding-bottom: 0;
            border:none;
          }
        a {
          font-size: 18px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: center;
          color: #333333;
        }
      }
    }
  }
  .ant-btn-link{
    color: #1848fd;
  }
  @media screen and (max-width: 1440px) {
    body {
      /* overflow-x: hidden; */
    }
  }
  .my-overlay{
    cursor: pointer;
    display: block;
    position: absolute;
    width: 60px;
    height: 25px;
    background-color: #F54336;
    padding: 3px;
    border-radius: 4px;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    box-sizing: border-box;
    box-shadow: 2px 10px 20px 0 rgba(0, 0, 0, .2);
  }
  .my-overlay::after{
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-top: 7px solid #F54336;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    position: absolute;
    bottom: -7px;
    left: 26px;
  }

  .my-overlay .count{
    border-radius: 3px 3px 0 0;
    color: #fff;
    /* background: #fff; */
  }
 
  .overlay{
    display: block;
    cursor: pointer;
    .icon-map{
      color: #F54336;
      width:30px;
      height:30px;
      margin:30px auto;
      border:solid 2px currentColor;
      background-color: currentColor;
      border-radius:16px 16px 16px 0;
      transform:rotate(-45deg);
      &:hover, &.active{
        color: #1890ff;
       }
      }
    
    .text{
      color:#fff;
      text-align: center;
      transform:rotate(45deg);
    }
    .dot{
      width:10px;
      height: 10px;
      border-radius: 50%;
      color: #F54336;
      background-color: currentColor;
      box-shadow: 2px 10px 20px 0 rgba(0, 0, 0, .2);
       &:hover, &.active{
        color: #1890ff;
      }
    }
  }
  .my-overlay1,.overlay1{
    display:none;
  }
  .ant-select-selection-placeholder{
    color:#333;
  }
  .rotate-circle{
    display: none;
  }
`;
