import { RealInfo } from "@/models/home";
import { getRealInfoList } from "@/utils/api";
import { Row, Col } from "antd";
import { history } from "umi";
import moment from "moment";
import { useEffect, useState } from "react";
import { Title } from "@/styles/common-style";
import { RealTimeInfoWrapper } from "./style";

const RealTimeInfo = () => {
  const [realinfolist, setRealinfolist] = useState<RealInfo[]>([]);
  const [infolist, setInfolist] = useState<RealInfo[]>([]);
  const getRealInfo = () => {
    const params = {
      index: 1,
      size: 5,
    };
    getRealInfoList(params).then((res: any) => {
      if (res.code == 1) {
        setRealinfolist([res.data[0]]);
        setInfolist(res.data.slice(1, 5));
      }
    });
  };
  useEffect(() => {
    getRealInfo();
  }, []);
  const titleStyle = {
    fontSize: "24px",
  } as any;
  const goDetails = (id: number) => {
    history.push({
      pathname: `/policy/policy-detail/${id}`,
      search: "?isProlicy=false",
    });
  };
  const moreCLick = () => {
    history.push("/real-list");
  };
  return (
    <>
      {realinfolist && realinfolist.length > 0 && (
        <RealTimeInfoWrapper>
          <>
            <Title textAlign="center">热点资讯</Title>
            <Title
              className="sub-title"
              fz={18}
              fw={400}
              color="#333"
              textAlign="center"
            >
              从这里 看见中国服装时尚产业的未来
            </Title>
            {realinfolist && (
              <Row className="real-info-row">
                {realinfolist && realinfolist[0] && (
                  <Col
                    className="left-col"
                    onClick={() => goDetails(realinfolist[0].id)}
                  >
                    <Title fz={24} color="#333" className="left-col-title">
                      {realinfolist[0].title}
                    </Title>
                    <Col className=" left-col-info">
                      {realinfolist[0].introduction}
                    </Col>
                    <Col className="info-date">
                      {moment(realinfolist[0].releaseTime).format("YYYY-MM-DD")}
                    </Col>
                  </Col>
                )}
                <Row className="right-col">
                  {infolist &&
                    infolist.length > 0 &&
                    infolist.map((item, index) => {
                      return (
                        <Col
                          className={`right-col-item`}
                          key={index}
                          onClick={() => goDetails(item.id)}
                        >
                          <Title
                            fz={24}
                            color="#333"
                            className={
                              index !== 3
                                ? "right-col-item-title"
                                : "right-col-item-title1"
                            }
                          >
                            {item.title}
                          </Title>
                          {index !== 3 && (
                            <Col className="info right-col-item-info">
                              {item.introduction}
                            </Col>
                          )}
                          <Col
                            className={
                              index !== 3
                                ? "info-date text-right right-col-date"
                                : "info-date text-right right-col-date"
                            }
                          >
                            {moment(item.releaseTime).format("YYYY-MM-DD")}
                          </Col>
                        </Col>
                      );
                    })}
                </Row>
              </Row>
            )}
            <div className="look-more" onClick={() => moreCLick()}>
              查看更多 &raquo;
            </div>
          </>
        </RealTimeInfoWrapper>
      )}
    </>
  );
};
export default RealTimeInfo;
