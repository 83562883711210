import { getKnowledgeTree } from "@/api/knowledge";
import { Effect, Reducer } from "umi";

export interface ServerInfoVo {
  id: string;
  cateName: string;
  parentName: string;
  serviceType: number;
  level: number;
}
export interface ServerDetailVo {
  serviceName: string;
  serviceCateId: number;
  serviceCateName: string;
  serviceSupplierId: number;
  serviceSupplierName: string;
  versionName: string;
  serviceWebUrl: string;
  brief: string;
  poster: string;
  priceType: number;
  price: number;
  deliveryMethodName: string;
  priceCompany: number;
  deliveryMethod: number;
  imageBytes: any;
}
export interface ServerSliceState {
  knowledgeCates: ServerInfoVo[] | undefined;
  knowledgeDetail: ServerDetailVo | undefined;
}

interface ServerSliceType {
  namespace: "knowledge";
  state: ServerSliceState;
  effects: {
    getKnowledgeCastes: Effect;
  };
  reducers: {
    saveKnowledgeCate: Reducer<ServerSliceState>;
    saveKnowledgeDetail: Reducer<ServerDetailVo>;
  };
}

const ServerSlice: ServerSliceType = {
  namespace: "knowledge",
  state: {
    knowledgeCates: [],
    knowledgeDetail: {
      imageBytes: null,
    },
  },
  effects: {
    *getKnowledgeCastes({ payload }: any, { call, put }: any) {
      try {
        const res = yield call(getKnowledgeTree, payload);
        if (res.code === 1) {
          yield put({
            type: "saveKnowledgeCate",
            payload: res.data,
          });
        }
      } catch (e) {}
    },
  },
  reducers: {
    saveKnowledgeCate(state: any, { payload }: any) {
      state.knowledgeCates = [...payload];
      return {
        ...state,
      };
    },
    saveKnowledgeDetail(state: any, { payload }: any) {
      state.knowledgeDetail = { ...payload };
      return {
        ...state,
      };
    },
  },
};

export default ServerSlice;
