import BaseFooter from "@/layouts/base-footer";
import CusHeader from "@/layouts/cus-header";
import { replaceId } from "@/utils/common";
import { useEffect, useState } from "react";
import PersionCommon from "../components/common";
import { ContentWrapper, CusButton, Wrap } from "../style";
import { history, useSelector } from "umi";
import EditInfo from "../base-security/edit-info";
import { Title } from "@/pages/server/server-center/style";
import { getUserAuthedInfo } from "@/api/auth";
import { AccountAuditVo } from "@/models/auth";
interface BaseAuthProps {}
const BaseAuth: React.FC<BaseAuthProps> = () => {
  const { user } = useSelector((state: any) => state.user);
  const [info, setInfo] = useState<AccountAuditVo>({});
  const [list, setlist] = useState([
    {
      icon: require("@/assets/images/persion/user.png"),
      title: "个人认证",
      info: "适用于个人用户，账号归属个人",
      show: true,
      onClick: () => {
        history.push("/persion/auth/user-auth/" + new Date().getTime());
      },
      renderRight: () => {
        return <span>&gt;</span>;
      },
    },
    {
      icon: require("@/assets/images/persion/company.png"),
      title: "企业认证",
      show: true,
      info:
        "适用企业、党政及国家机关、事业单位、民办非企业单位、社会团体、个体工商户等，账号归属于企业。",
      onClick: () => {
        history.push("/persion/auth/boss-auth/" + new Date().getTime());
      },
      renderRight: () => {
        return <span>&gt;</span>;
      },
    },
  ]);
  useEffect(() => {
    getUserAuthedInfo(user.id).then((res) => {
      setInfo(res.data);
      setlist([
        {
          icon: require("@/assets/images/persion/user.png"),
          title: "个人认证",
          info: "适用于个人用户，账号归属个人",
          show: res?.data?.roleType !== 1,
          onClick: () => {
            !res?.data
              ? history.push("/persion/auth/user-auth/" + new Date().getTime())
              : history.push(
                  "/persion/auth/user-authed/" + new Date().getTime()
                );
          },
          renderRight: () => {
            return <span>&gt;</span>;
          },
        },
        {
          icon: require("@/assets/images/persion/company.png"),
          title: "企业认证",
          show: res?.data?.roleType !== 2,
          info:
            "适用企业、党政及国家机关、事业单位、民办非企业单位、社会团体、个体工商户等，账号归属于企业。",
          onClick: () => {
            !res?.data
              ? history.push("/persion/auth/boss-auth/" + new Date().getTime())
              : history.push(
                  "/persion/auth/boss-authed/" + new Date().getTime()
                );
          },
          renderRight: () => {
            return <span>&gt;</span>;
          },
        },
      ]);
    });
  }, []);

  return (
    <>
      <CusHeader title="个人中心" showSearch={false}></CusHeader>
      <ContentWrapper>
        <Wrap>
          <PersionCommon></PersionCommon>
          <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <Title style={{ marginBottom: "24px" }}>实名认证</Title>
            <EditInfo data={list} />
          </div>
        </Wrap>
      </ContentWrapper>
      <BaseFooter />
    </>
  );
};

export default BaseAuth;
