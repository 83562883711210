import { CusButton, Title } from "@/styles/common-style";
import { HeaderInfoWrapper } from "../style";
import { Row, message } from "antd";
// import CusInputNumber from "@/components/cus-input-number"
import { useSelector, history } from "umi";
import { CollectCreate, CollectDelete } from "@/api/server";
import { useState } from "react";
import _ from "lodash";
import { ContactUs } from "@/utils/common";
interface HeaderInfoProps {}
const HeaderInfo: React.FC<HeaderInfoProps> = () => {
  const { serverDetail } = useSelector((state: any) => state.server);
  const { user } = useSelector((state: any) => state.user);
  const [isCollect, setIsCollect] = useState<number>(0);
  const getItemType = (data: any) => {
    switch (data.priceType) {
      case 2:
        return "询价";
      case 1:
        return "免费";
      case 3:
        return `<span className='server-price'>${data.price}</span>${
          data.priceCompany == 1 ? "/年" : "/次"
        }`;
      default:
        return "免费";
    }
  };
  const userCollectCreate = _.throttle(
    () => {
      if (user?.id) {
        const params = {
          serviceProductId: serverDetail.id,
          accountId: user?.id,
          clientSource: 1,
        };
        if (!serverDetail.isCollect) {
          CollectCreate(params).then((res: any) => {
            if (res.code === 1) {
              setIsCollect(1);
              message.success("收藏成功");
            } else {
              // setIsCollect(0);
            }
          });
        } else {
          // CollectDelete(serverDetail.id).then((res: any) => {
          //   if (res.code === 1) {
          //     setIsCollect(0)
          //     message.success("取消收藏成功")
          //   } else {
          //     // setIsCollect(0);
          //   }
          // })
        }
      } else {
        history.push("/login");
      }
    },
    60000,
    {
      leading: true,
      trailing: false,
    }
  );
  return (
    <>
      {serverDetail && (
        <HeaderInfoWrapper>
          <img
            src={require("@/assets/images/server/server_detail.png")}
            className="img"
          />
          <div className="card-wrapper">
            <div className="card-left">
              <Row className="left" align="middle">
                <img className="server-logo" src={serverDetail.poster} />

                <div className="server-info">
                  <Title className="server-title">
                    {serverDetail.serviceName}
                  </Title>
                  {/* <div className='server-info'>{serverDetail.serviceName}</div> */}
                  <div className="server-item">
                    <span className="server-label">订购单价：</span>
                    <span
                      className="server-label"
                      dangerouslySetInnerHTML={{
                        __html: getItemType(serverDetail),
                      }}
                    ></span>
                  </div>
                  <div className="server-item">
                    <span onClick={() => userCollectCreate()}>
                      {serverDetail.isCollect || isCollect ? "已收藏" : "收藏"}
                    </span>
                    {/* <CusButton type='link'>分享</CusButton> */}
                    {/* <span className='server-label'>购买方式：</span>
                    <span className='server-label type'>
                      {serverDetail.deliveryMethodName}
                    </span> */}
                  </div>
                  {/* <div className='server-item'>
                    <span className='server-label'>购买时长：</span>
                    <span className='server-label'>
                      <CusInputNumber
                        width={67}
                        inputHeight={25}
                        placeholder=' '
                        inputWidth={67}
                      />
                      <span className='type'>年</span>
                    </span>
                  </div> */}
                </div>
              </Row>
              <Row className="server-btn">
                {/* <CusButton type='primary' width={200} height={54}>
                  立即购买
                </CusButton> */}
                <CusButton
                  width={200}
                  height={54}
                  onClick={() => ContactUs(serverDetail.serviceName)}
                >
                  服务咨询
                </CusButton>
              </Row>
            </div>
            <div className="card-right">
              {serverDetail.serviceSupplierName && (
                <div className="server-company">
                  服务商：{serverDetail.serviceSupplierName || "-"}
                </div>
              )}
              <div className="server-company-cate">
                服务分类：{serverDetail.serviceCateName}
              </div>
              <div className="server-company-cate">
                交付方式： {serverDetail.deliveryMethodName}
              </div>
              <div className="server-company-cate">
                上架时间：{serverDetail.createTime}
              </div>
            </div>
          </div>
        </HeaderInfoWrapper>
      )}
    </>
  );
};

export default HeaderInfo;
