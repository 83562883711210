import styled from "styled-components";
import { Tabs } from "antd";
const { TabPane } = Tabs;
export const EconomicDetailWrapper = styled.div`
  width: 100%;
  min-width: 1440px;
  /* height: 100vh; */
`;

export const CusTabs = styled(Tabs)`
  .ant-tabs-nav {
    height: 75px;
    margin: 0;
    /* background: linear-gradient(180deg, #030a61, #07189a 100%); */
    .ant-tabs-nav-list {
      width: 1050px;
      justify-content: space-between;
    }
    .ant-tabs-tab .ant-tabs-tab-btn {
      color: #333;
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      font-size: 18px;
      color: #3360f2;
      /* text-shadow: 0px 3px 4px 0px rgba(11, 191, 204, 0.7); */
    }
    .ant-tabs-tab:hover {
      color: #3360f2;
    }
    .ant-tabs-ink-bar {
      background-color: #fff;
      bottom: 5px;
      height: 4px;
    }
  }
  .ant-tabs-nav::before {
    bottom: 0;
    border-bottom: none;
    /* border-bottom: 2px solid;
    border-image: linear-gradient(187deg, #026db2 0%, #12fee0 55%) 2 2; */
  }
  .ant-tabs-tab::after {
    position: absolute;
    content: "";
    top: 22px;
    left: -38px;
    width: 1px;
    height: 26px;
    background-color: #fff;
  }
  .ant-tabs-tab:first-child::after {
    background-color: transparent;
  }
`;
export const CusTabPane = styled(TabPane)``;
export const CusIframe = styled.iframe`
  width: 100%;
  min-width: 1440px;
  height: 800px;
  border: none;
`;
export const CusVideo = styled.video`
  width: 100%;
  min-width: 1440px;
  height: 800px;
  background: black;
`;
