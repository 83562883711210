import BaseFooter from "@/layouts/base-footer";
import CusHeader from "@/layouts/cus-header";
import PersionCommon from "../components/common";
import { ContentWrapper, CusButton, Wrap } from "../style";
import { Form, Button, Input } from "antd";
import { useRef, useState } from "react";
import { replaceId } from "@/utils/common";
import _ from "lodash";
import type { FormInstance } from "antd/es/form";
import EditPhoneInfo from "../edit-phone/edit-info";
import CusCode from "@/components/cus-code";
interface EditPhoneProps {}
const EditPassword: React.FC<EditPhoneProps> = () => {
  const [currentId, setCurrentId] = useState<number>(0);
  const [text] = useState<string>("获取验证码");
  const formRef2: any = useRef<FormInstance>();
  let [num, setNum] = useState<number>(60);
  let timer: any = null;
  const onFinish = (values: any) => {
    setCurrentId(1);
    setNum(60);
    clearInterval(timer);
  };
  const onFinish2 = (values: any) => {
    setCurrentId(2);
    setNum(60);
    clearInterval(timer);
  };
  const validator = (_: any, value: any) => {
    if (value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("验证码不能为空"));
  };
  const getCode = _.throttle(
    (type: number) => {
      let timer: any = null;
      clearInterval(timer);
      num = 60;
      timer = setInterval(() => {
        if (num == 0) {
          clearInterval(timer);
          num = 60;
        } else {
          num--;
        }
        setNum(num);
      }, 1000);
    },
    60000,
    {
      leading: true,
      trailing: false,
    }
  );

  const steps = [
    {
      id: 1,
      title: "验证身份",
      content: () => {
        return (
          <Form className="cus-form" onFinish={onFinish} autoComplete="off">
            <Form.Item label="您的手机号">
              <span>{replaceId("15988869706", 3, 4)}</span>
            </Form.Item>
            <Form.Item
              label="短信验证码"
              name="newcode"
              rules={[{ validator: validator }]}
            >
              <CusCode
                placeholder="请输入验证码"
                inputWidth={160}
                inputHeight={32}
                buttonWidth={81}
                disabled={num >= 60 ? false : true}
                onCodeClick={() => {
                  getCode(1);
                }}
                text={num >= 60 ? text : num + "秒"}
              />
            </Form.Item>
            <Form.Item>
              <CusButton
                type="primary"
                htmlType="submit"
                width={257}
                height={32}
              >
                下一步
              </CusButton>
            </Form.Item>
          </Form>
        );
      },
    },
    {
      id: 2,
      title: "重置密码",
      content: () => {
        return (
          <Form className="cus-form" onFinish={onFinish2} autoComplete="off">
            <Form.Item
              label="设置新密码"
              name="password"
              rules={[
                {
                  validator: (_: any, value: any) => {
                    if (value) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(new Error("请输入新密码"));
                    }
                    //
                  },
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="短信验证码"
              name="code"
              rules={[{ validator: validator }]}
            >
              <CusCode
                placeholder="请输入验证码"
                inputWidth={160}
                inputHeight={32}
                buttonWidth={81}
                disabled={num >= 60 ? false : true}
                onCodeClick={() => {
                  formRef2.current
                    ?.validateFields(["password"])
                    .then((values: any) => {
                      getCode(2);
                    });
                }}
                text={num >= 60 ? text : num + "秒"}
              />
            </Form.Item>
            <Form.Item>
              <CusButton
                type="primary"
                htmlType="submit"
                width={257}
                height={32}
              >
                下一步
              </CusButton>
            </Form.Item>
          </Form>
        );
      },
    },
    {
      id: 3,
      title: "完成",
      content: () => {
        return (
          <div className="container">
            <span className="title">修改成功</span>
            <img src={require("@/assets/images/persion/success.png")} />
          </div>
        );
      },
    },
  ];
  return (
    <>
      <CusHeader title="个人中心" showSearch={false}></CusHeader>
      <ContentWrapper>
        <Wrap>
          <PersionCommon></PersionCommon>
          <EditPhoneInfo steps={steps} current={currentId} />
        </Wrap>
      </ContentWrapper>
      <BaseFooter />
    </>
  );
};

export default EditPassword;
