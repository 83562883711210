import { ContactUs } from "@/utils/common";
import { ItemVo } from "../models/plans-items";
import { CardWrap, CusRow, PlansItemContainer } from "../style";
import { history } from "umi";
interface PlansItemProps {
  data: ItemVo;
  bgImage: string;
  type?: number;
}
const PlansItem: React.FC<PlansItemProps> = ({ data, bgImage, type }) => {
  return (
    <PlansItemContainer bgImage={bgImage}>
      <div className="left-column">
        <div className="title">{data.title}</div>
        <div className="solgan">{data.solgan}</div>
        <span className="underline"></span>
      </div>
      <div className="right-column">
        <div className="info">{data.info}</div>
        <CusRow type={data.list.length > 4 ? type : 0}>
          {data.list.map((item, index) => (
            <CardWrap
              key={index}
              className="card-wrap"
              onClick={() => {
                if (item?.path?.includes("https")) {
                  window.open(item?.path);
                } else {
                  if (item?.path) {
                    history.push(item?.path);
                  } else {
                    if (item?.type) {
                      ContactUs("在线咨询");
                    }
                  }
                }
              }}
            >
              <img
                src={require("@/assets/images/solution/circle_icon.png")}
                className="icon"
              />
              <div className="list-item">{item.title}</div>
            </CardWrap>
          ))}
        </CusRow>
      </div>
    </PlansItemContainer>
  );
};

export default PlansItem;
