import { CateListWrapper } from "../style";
import server_bg from "@/assets/images/server/server_bg.png";
import server_bg1 from "@/assets/images/server/serverbg1.png";
import server_bg2 from "@/assets/images/server/serverbg2.png";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Autoplay, Pagination, Thumbs } from "swiper";
import LeftNav from "./left-nav";

const CateList: React.FC = () => {
  const BANNAR_LIST = [
    {
      image: server_bg,
      id: "1",
      title: (
        <>
          <div>科技创新</div>
          <div style={{ fontSize: 60 }}>推动临平服装产业智能革新转型</div>
        </>
      ),
      titleclass: "bannar-slide-title",
      describelass: "bannar-slide-subtitle",
      describe: "橙织助力打造临平工业智造新生态",
    },
    {
      image: server_bg1,
      id: "2",
      title: (
        <>
          <div>橙织</div>
          <div style={{ fontSize: 60 }}>打造长三角数字时尚产业新生态</div>
        </>
      ),
      titleclass: "bannar-slide-title",
      describelass: "bannar-slide-subtitle",
      describe: "橙织助力服装产业提振升级",
    },
    {
      image: server_bg2,
      id: "3",
      title: (
        <>
          <div>橙织</div>
          <div style={{ fontSize: 60 }}>打造数字时尚产业创新区域标杆</div>
        </>
      ),
      titleclass: "bannar-slide-title",
      describelass: "bannar-slide-subtitle",
      describe: "融杭接沪区域一体化",
    },
  ];
  const setClassName = (data: { isActive?: any; align?: string }) => {
    let className = "";
    if (data.isActive) {
      if (data.align === "left") {
        className = " animated fadeInLeft";
      } else if (data.align === "right") {
        className = " animated fadeInRight";
      } else if (data.align === "center") {
        className = " animated fadeInDown";
      }
    }
    return className;
  };
  return (
    <CateListWrapper>
      <Swiper
        className="home-swiper"
        spaceBetween={0}
        autoplay
        loop
        modules={[Autoplay, Pagination, Thumbs]}
        pagination={{ clickable: true }}
      >
        {BANNAR_LIST &&
          BANNAR_LIST.length > 0 &&
          BANNAR_LIST.map((item, index) => {
            return (
              <SwiperSlide className="bannar-slide" key={index}>
                {({ isActive }) => (
                  <div>
                    <img src={item.image} className="img" />
                    {item.title}
                    <div className="bannar-slide-row">
                      <div
                        className={`${setClassName({
                          align: "center",
                          isActive: isActive,
                        })}  ${item.titleclass} `}
                      >
                        {item.title}
                      </div>
                      <div
                        className={`${setClassName({
                          align: "center",
                          isActive: isActive,
                        })}  ${item.describelass} `}
                      >
                        {item.describe}
                      </div>
                    </div>
                  </div>
                )}
              </SwiperSlide>
            );
          })}
      </Swiper>
      <LeftNav />
    </CateListWrapper>
  );
};

export default CateList;
