import { Layout, Button, Row, Alert, Modal } from "antd";
import styled from "styled-components";
const { Content } = Layout;

export const ContentWrapper = styled(Content)`
  min-width: 1440px;
  min-height: 604px;
  font-family: PingFangSC, PingFangSC-Regular;
  background-color: #fff;
`;
export const Wrap = styled.div`
  width: 1200px;
  margin: 50px auto 80px;
  display: flex;

  .ant-descriptions {
    width: 400px;
    .ant-descriptions-title {
      font-size: 36px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      color: #333333;
    }
    .ant-descriptions-item-container {
      align-items: center;
    }
    .ant-descriptions-item-label {
      font-size: 18px;
      width: 150px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      text-align: left;
      color: #000000;
    }
    .ant-descriptions-item-content {
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #000000;
    }
  }
  .descriptions .ant-descriptions-item-container {
    display: block;
    .ant-image {
      width: 170px;
      height: 148px;
      margin-top: 25px;
      margin-right: 20px;
      .ant-image-img {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

export const LeftMenu = styled.div`
  margin-left: 30px;
  margin-right: 58px;
  width: 297px;
  /* height: 300px; */
  display: flex;
  flex-direction: column;
  font-size: 18px;
  .menu-item {
    width: 100%;
    text-align: center;
    line-height: 60px;
    color: #333333;
    font-weight: 400;
    height: 60px;
    cursor: pointer;
    background: #f5fafd;
  }
  .menu-item-active {
    font-size: 24px;
    font-weight: 600;
    color: #ffffff;
    background: #1848fd;
  }
`;

// 基本信息

export const BaseInfoWrapper = styled.div`
  width: 400px;
  .ant-picker {
    width: 175px;
  }
  .tip {
    font-size: 12px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #666666;
  }
  .ant-form-item-label {
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    text-align: left;
    color: #000000;
  }
  .agree-item {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #333333;
    a {
      color: #333333;
    }
  }
  .agree-info {
    margin-top: 18px;
    margin-left: 24px;
    display: flex;
    flex-direction: column;
    width: 360px;
    span {
      font-size: 12px;
      margin-bottom: 6px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #666666;
    }
  }
  .business {
    width: 598px;
    .download {
      cursor: pointer;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-decoration: underline;
      color: #1890ff;
    }
  }
`;

export const EditInfoWrapper = styled.div`
  .edit-info-item {
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 844px;
    height: 80px;
    padding: 12px 34px 14px 30px;
    box-sizing: border-box;
    background: #f4f4f4;
  }
  .edit-info-container {
    display: flex;
    align-items: center;
  }
  .edit-info-title {
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    color: #000000;
  }
  .edit-info-icon {
    margin-right: 42px;
    height: 48px;
    width: 48px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .edit-info-info {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #666666;
  }
`;

export const CusButton = styled(Button)<{ width?: number; height?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.width ? props.width + "px" : "70px")};
  height: ${(props) => (props.height ? props.height + "px" : "30px")};
  .ant-btn-primary {
    background-color: #1848fd;
  }
`;

// 修改手机

export const EditPhoneWrapper = styled.div`
  .steps {
    margin-bottom: 62px;
    width: 370px;
    display: flex;
    justify-content: space-between;
  }
  .step {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: baseline;
    width: 72px;
  }
  .step:before {
    content: "";
    position: absolute;
    right: -84px;
    top: 9px;
    width: 90px;
    height: 2px;
    background-color: #d8d8d8;
  }
  .step:last-child:before {
    background-color: transparent;
  }
  .step-index {
    margin-bottom: 6px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    background: #d8d8d8;
    border-radius: 50%;
    font-size: 12px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #fff;
  }
  .step-title {
    font-size: 12px;
    color: #999999;
  }
  .step-active {
    .step-index {
      background: #007aff;
    }
    .step-title {
      color: #007aff;
    }
  }
  .ant-form {
    margin-top: 60px;
    width: 370px;
  }
  .code-input {
    display: flex;
    .ant-btn-default {
      margin-left: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .cus-form {
    .ant-form-item-label {
      margin-right: 2px;
    }
    .ant-form-item-label label {
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      color: #000000;
    }
  }
  .ant-btn-primary {
    margin-left: 104px;
  }
  .container {
    width: 370px;
    display: flex;
    align-items: center;
    justify-content: center;
    .title {
      margin-right: 10px;
      font-size: 24px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #333333;
    }
    img {
      width: 15px;
      height: 15px;
    }
  }
`;

// 我的收藏

export const ListWrapper = styled.div`
  width: 815px;
  padding-bottom: 10px;
  .ant-pagination-item-active {
    background-color: #1890ff;
    & a {
      color: #fff;
    }
  }
  .ant-list-item {
    flex-direction: row-reverse;
    padding: 34px 0;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin-left: 0;
    margin-right: 26px;
  }
  .ant-list-pagination {
    text-align: center;
  }
  .ant-list-item-meta-description {
    color: #666;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .ql-editor {
    height: auto;
    img {
      display: none;
    }
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #333333;
  }
  .type {
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #1848fd;
  }
`;

export const ContentContainer = styled(Row)`
  margin-top: 20px;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #999999;
  .ant-row-space-between {
    margin-top: 7px;
  }
`;

export const CusAlert = styled(Alert)<{ width?: number; height?: number }>`
  width: ${(props) => (props.width ? props.width : "604px")};
  margin-bottom: 12px;
`;

export const IdCardInfo = styled.div`
  position: relative;
  margin-top: 24px;
  display: flex;
  align-items: center;
  width: 461px;
  height: 109px;
  border: 1px solid #e4e4e4;
  .idcard-info-title {
    position: absolute;
    background: #fff;
    top: -14px;
    margin-left: 16px;
    padding: 1px 8px;
    transition: background-color 0.4s;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #333333;
  }
  .idcard-info-list {
    padding: 0 18px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .idcard-info-icon {
      width: 100px;
      height: 60px;
    }
    .idcard-item {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
  .idcard-info-text {
    margin-top: 3px;
    font-size: 12px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #666666;
  }
`;

export const CusModal = styled(Modal)`
  .ant-footer {
    margin-top: 60px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    .ant-btn {
      margin-right: 10px;
      width: 140px;
      height: 32px;
    }
    .ant-btn-primary {
      background: #1848fd;
    }
  }
  .ant-modal-title {
    font-size: 24px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #333333;
  }
  .ant-form-item-label label {
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #333333;
  }
  .company-name {
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #666666;
  }
`;
export const AuthWrapper = styled.div`
  .auth-info {
    margin-bottom: 16px;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #666666;
  }
  .auth-info-value {
    padding: 0 10px;
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #333333;
  }
  .auth-info-tip {
    font-size: 18px;
  }
  .auth-time-info {
    margin-top: 114px;
    display: flex;
    align-items: end;
    flex-direction: column;
    justify-content: flex-end;
  }
  .auth-info-time {
    width: 188px;
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #333333;
  }
`;
