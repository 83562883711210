import { useSelector } from "umi";
import { FooterWrapper } from "../style";

interface FooterInfoProps {
  payFunc?: (value: string) => void;
}

export const FooterInfo: React.FC<FooterInfoProps> = ({ payFunc }) => {
  const { knowledgeDetail } = useSelector((state: any) => state.knowledge);
  return (
    <FooterWrapper>
      {knowledgeDetail &&
        knowledgeDetail?.salePrice > 0 &&
        knowledgeDetail.playFlag !== 1 && (
          <>
            <div className="titlte">试读结束，需完整文档请先购买文档</div>
            <div className="info">
              剩余
              {knowledgeDetail?.totalPage
                ? knowledgeDetail.totalPage > knowledgeDetail.lookPage
                  ? knowledgeDetail.totalPage - knowledgeDetail.lookPage
                  : 0
                : 0}
              页未读
            </div>
          </>
        )}
      {knowledgeDetail?.salePrice == 0 && (
        <>
          <div className="titlte">试读结束，需完整文档请先下载文档</div>
          <div className="info">剩余 0 页未读</div>
        </>
      )}
      <div
        className="price-btn"
        onClick={() =>
          payFunc?.(
            knowledgeDetail && knowledgeDetail?.salePrice > 0
              ? knowledgeDetail.playFlag !== 1
                ? "2"
                : "3"
              : "1"
          )
        }
      >
        {knowledgeDetail && knowledgeDetail?.salePrice > 0
          ? knowledgeDetail.playFlag !== 1
            ? "购买下载"
            : "下载"
          : "免费下载"}
      </div>
    </FooterWrapper>
  );
};
