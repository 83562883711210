import { useState } from "react";
import { useSelector } from "umi";
import { AtlasInfoWrapper, AtlasWrapper } from "../../style";
import AtlasList from "../atlas-list";
import AtlasMap from "../atlas-map";
import SearchHeader from "../search-header";
import AtlasRelation from "./atlas-relation";
import AtlasRight from "./atlas-right";

interface AtlasInfoProps {}
const AtlasInfo: React.FC<AtlasInfoProps> = () => {
  const { showMap } = useSelector((state: any) => state.park);
  return (
    <>
      <AtlasInfoWrapper>
        <AtlasRelation></AtlasRelation>
        <AtlasRight />
      </AtlasInfoWrapper>
      {/**地图容器**/}
      <AtlasWrapper>
        <SearchHeader />
        {showMap ? <AtlasMap /> : <AtlasList />}
      </AtlasWrapper>
    </>
  );
};

export default AtlasInfo;
