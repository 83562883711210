import { Title } from "@/styles/common-style";
import { BenefitsWrapper } from "../style";

interface BenefitsProps {}

export const Benefits: React.FC<BenefitsProps> = () => {
  const data = [
    {
      icon: require("@/assets/images/maintain/step1.png"),
      title: "权威认定",
      info: "由中国服装协会颁发等级认定证书，彰显工厂实力",
    },
    {
      icon: require("@/assets/images/maintain/step2.png"),
      title: "品牌认可",
      info: "系统化、科学化的评估认定，精准衡量工厂实力，整体提升工厂形象，获得品牌的认可",
    },
    {
      icon: require("@/assets/images/maintain/step3.png"),
      title: "市场对接",
      info: "可获得中国服装工业互联网平台资源对接，获得品牌资源的精准对接",
    },
    {
      icon: require("@/assets/images/maintain/step4.png"),
      title: "工厂优化",
      info: "帮助中小微服装企业制定数字化快反的战略目标和顶层规划",
    },
    {
      icon: require("@/assets/images/maintain/step5.png"),
      title: "政策申报",
      info: "根据不同认定结果，参与当地政府政策申报",
    },
  ];
  return (
    <BenefitsWrapper>
      <Title
        fz={36}
        color="#000"
        textAlign="center"
        style={{
          fontFamily: "PingFangSC, PingFangSC-Semibold",
          marginBottom: 40,
        }}
      >
        五大收益
      </Title>
      <div className="ben-list">
        {data.map((item: any, index: number) => (
          <div className="ben-list-item" key={index}>
            <img src={item.icon} alt="" className="ben-list-item-icon" />
            <div className="info">
              <div className="title">{item.title}</div>
              <div className="info-item">{item.info}</div>
            </div>
          </div>
        ))}
      </div>
    </BenefitsWrapper>
  );
};
