import { useEffect, useRef, useState } from "react";
import { SliderSmsWrapper } from "./styled";
import ReactSimpleVerify from "react-simple-verify";
import "react-simple-verify/dist/react-simple-verify.css";
interface SliderSmsProps {
  onChange?: (value: number) => void;
}

const SliderSms: React.FC<SliderSmsProps> = ({ onChange }) => {
  const sliderRef = useRef<any>();
  const verify = useRef<any>();

  const success = () => {
    onChange?.(1);
  };
  return (
    <SliderSmsWrapper ref={sliderRef}>
      <ReactSimpleVerify
        ref={verify}
        width={370}
        height={54}
        success={success}
        bgColor="e8e8e8"
        movedColor="#1890ff"
      />
    </SliderSmsWrapper>
  );
};

export default SliderSms;
