import { Title } from "@/styles/common-style";
import { ProductInfoWrapper } from "../style";

interface ProductInfoProps {}

export const ProductInfo: React.FC<ProductInfoProps> = () => {
  const producList = [
    {
      icon: require("@/assets/images/data-assets/icon/icon1.png"),
      title: "行业全场景",
      desc: "行业数据资产定义、盘点、规划、开放、运营一站式完成",
    },
    {
      icon: require("@/assets/images/data-assets/icon/icon2.png"),
      title: "开放兼容",
      desc: "元数据适配绝大部分数据资源和数据应用，能够与企业实现无缝对接",
    },
    {
      icon: require("@/assets/images/data-assets/icon/icon3.png"),
      title: "安全可靠",
      desc:
        "数据资产采用腾讯云端，高系数安全保障，全流程敏感数据监控，防止关键数据泄露",
    },
    {
      icon: require("@/assets/images/data-assets/icon/icon4.png"),
      title: "稳定高效",
      desc:
        "自主研发的集群架构，采用负载均衡、集群健康检查、跨JVM资源同步等技术，大大提高了服务器的可靠性和并发性能",
    },
  ];
  return (
    <ProductInfoWrapper>
      <Title
        fz={36}
        fw={600}
        color="#000"
        textAlign="center"
        style={{ marginBottom: 84 }}
      >
        产品优势
      </Title>
      <div className="product-list">
        {producList.map((item, index) => (
          <div className="product-item" key={index}>
            <div className="title-info">
              <Title fz={24} fw={600} color="#000">
                {item.title}
              </Title>
              <img src={item.icon} alt="image" />
            </div>
            <div className="product-item-desc">{item.desc}</div>
          </div>
        ))}
      </div>
    </ProductInfoWrapper>
  );
};
