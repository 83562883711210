import styled from "styled-components";

export const FlatButtonWrapper = styled.div`
  position: fixed;
  right: 20px;
  z-index: 99999;
  bottom: 50px;
  width: 80px;
  height: 240px;
  padding: 0 4px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0px 0px 11px 2px rgb(0 0 0 / 11%);
  .btn-wrap {
    position: relative;
    cursor: pointer;
    height: 60px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #979797;
    .icon img {
      width: 20px;
      height: 20px;
    }
    &:last-child {
      border-bottom: none;
    }
    /* &:hover {
      background-color: #1848fd;
      .text {
        color: #fff;
      }
    } */
  }
  .phone-num {
    display: none;
    position: absolute;
    left: -125px;
    width: 120px;
    text-align: center;
    height: 60px;
    font-size: 14px;
    background-color: #fff;
    font-weight: 500;
    line-height: 60px;
    box-shadow: 0px 0px 11px 2px rgb(0 0 0 / 11%);
  }

  .colllect-img {
    display: none;
    position: absolute;
    left: -100px;
    width: 100px;
    height: 100px;
    img {
      width: 100px;
      height: 100px;
    }
  }
  .phone-wrap:hover .phone-num,
  .collect-wrap:hover .colllect-img {
    display: block;
  }
`;
