import { AuthWrapper, CusButton, CusModal } from "@/pages/persion/style";
import type { FormInstance } from "antd/es/form";
import { Form, Input, message } from "antd";
import { useEffect, useRef, useState } from "react";
import {
  downloadFile,
  GetToken,
  isIdCard,
  TokenHeaderKey,
} from "@/utils/common";
import moment from "moment";
import { createCertificate } from "@/api/auth";
import axios from "axios";

interface GrantModalProps {
  companyName: string;
  visibleModal: boolean;
  onCancel?: () => void;
  onOk?: () => void;
}
interface ValVo {
  name: string;
  idCardNo: string;
  companyName: string;
}
const GrantModal: React.FC<GrantModalProps> = ({
  companyName,
  visibleModal,
  onCancel,
  onOk,
}) => {
  const [visible, setVisible] = useState(false);
  const [okText, setOkText] = useState("下一步");
  const [title, setTitle] = useState("填写授权信息");
  const [val, setVal] = useState<ValVo>();
  const [cancelText, setCancelText] = useState("取消");
  const formRef: any = useRef<FormInstance>();
  const handleOk = () => {
    if (okText === "下一步") {
      formRef.current &&
        formRef.current
          .validateFields()
          .then((values: any) => {
            setVal(values);
            setOkText("下载授权书");
            setCancelText("上一步");
            setTitle("企业认领授权书");
          })
          .catch((error: any) => {});
    } else if (okText === "下载授权书") {
      const params = {
        ...val,
        companyName: companyName,
        // date: moment(new Date()).format("yyyy-MM-dd"),
      };
      axios
        .get(
          process.env.BASE_URL +
            "/account/accountAudit/createCertificate?companyName=" +
            companyName +
            "&name=" +
            params.name +
            "&idCardNo=" +
            params.idCardNo,
          {
            responseType: "blob",
            headers: {
              [TokenHeaderKey]: `bearer ${GetToken()}`,
              "Content-Type": "application/json, text/plain,text/html",
            },
          }
        )
        .then((res: any) => {
          if (res.data.size) {
            let a = document.createElement("a");
            let blob: any = new Blob([res.data], {
              //设置数据源
              type: "application/pdf", //设置文件格式
            });
            const temp = res.headers["content-disposition"]
              .split(";")[1]
              .split("filename=")[1];
            const fileName = decodeURIComponent(temp);
            let objectUrl = URL.createObjectURL(blob); //创建下载的链接

            a.href = objectUrl;
            a.download = fileName; //设置文件名
            //下面这个写法兼容火狐
            a.dispatchEvent(
              new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window,
              })
            );
            window.URL.revokeObjectURL(blob); //释放bolb对象
          } else {
            message.error("没有可导出的数据");
          }
        });
      // let url =
      //   process.env.BASE_URL +
      //   "/account/accountAudit/createCertificate?companyName=" +
      //   companyName +
      //   "&name=" +
      //   params.name +
      //   "&idCardNo=" +
      //   params.idCardNo;
      // // url = window.URL.createObjectURL(new Blob([url]))
      // let link = document.createElement("a");
      // link.style.display = "none";
      // link.href = url;
      // link.setAttribute("download", "企业认领授权书"); // 文件名
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link); // 下载完成移除元素
      // window.URL.revokeObjectURL(url); // 释放掉blob对象
      setVisible(false);
      setOkText("下一步");
      setCancelText("取消");
      setTitle("填写授权信息");
      onOk?.();
    }
  };

  const handleCancel = () => {
    if (cancelText == "取消") {
      setVisible(false);
      formRef.current && formRef.current.resetFields();
    } else if (cancelText == "上一步") {
      setOkText("下一步");
      setCancelText("取消");
      setTitle("填写授权信息");
    }
    onCancel?.();
  };
  useEffect(() => {
    setVisible(visibleModal);
  }, [visibleModal]);
  const validator = (_: any, value: any) => {
    if (value) {
      if (isIdCard(value)) {
        return Promise.resolve();
      } else {
        return Promise.reject(new Error("身份证号格式不正确"));
      }
    } else {
      return Promise.reject(new Error("请输入身份证号"));
    }
    //
  };
  return (
    <>
      <CusModal
        width={976}
        title={title}
        visible={visible}
        footer={null}
        centered
        onCancel={() => {
          setVisible(false);
          setOkText("下一步");
          setCancelText("取消");
          setTitle("填写授权信息");
          onCancel?.();
          formRef.current && formRef.current.resetFields();
        }}
      >
        {okText === "下一步" && (
          <Form
            name="basic"
            ref={formRef}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <Form.Item label="企业名称">
              <span className="company-name">{companyName}</span>
            </Form.Item>

            <Form.Item
              label="真实姓名"
              name="name"
              rules={[
                {
                  validator: (_: any, value: any) => {
                    if (value) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(new Error("请输入真实姓名"));
                    }
                    //
                  },
                  validateTrigger: ["blur"],
                },
              ]}
            >
              <Input placeholder="请输入" style={{ width: "254px" }} />
            </Form.Item>
            <Form.Item
              label="身份证号"
              name="idCardNo"
              rules={[{ validator: validator, validateTrigger: ["blur"] }]}
            >
              <Input placeholder="请输入" style={{ width: "254px" }} />
            </Form.Item>
          </Form>
        )}
        {okText === "下载授权书" && (
          <AuthWrapper>
            <div className="auth-info">
              <span className="auth-info-label">本企业</span>
              <span className="auth-info-value">{companyName}</span>
            </div>
            <div className="auth-info">
              <span className="auth-info-label">确认授权</span>
              <span className="auth-info-value">{val?.name}</span>
              <span className="auth-info-label">身份证号</span>
              <span className="auth-info-value">{val?.idCardNo}</span>
            </div>
            <div className="auth-info">
              <span className="auth-info-label auth-info-tip">
                代表本企业向贵司完成实名认证，执行企业信息维护和管理功能，有权以本企业名义同意《认证服务协议》，并对本企业具有约束力
              </span>
            </div>
            <div className="auth-time-info">
              <span className="auth-info-time">盖章处</span>
              <span className="auth-info-time">
                日期：{moment(new Date()).format("YYYY-MM-DD")}
              </span>
            </div>
          </AuthWrapper>
        )}
        <div className="ant-footer">
          <CusButton onClick={handleCancel}>{cancelText}</CusButton>
          <CusButton onClick={handleOk} type="primary">
            {okText}
          </CusButton>
        </div>
      </CusModal>
    </>
  );
};

export default GrantModal;
