import BaseFooter from "@/layouts/base-footer";
import CusHeader from "@/layouts/cus-header";
import { SetStateAction, useEffect, useState } from "react";
import { history, useSelector } from "umi";
import { Descriptions, Image } from "antd";
import { Title } from "@/pages/server/server-center/style";
import { ContentWrapper, CusAlert, CusButton, Wrap } from "../../style";
import PersionCommon from "../../components/common";
import { AccountAuditVo } from "@/models/auth";
import { getUserAuthedInfo } from "@/api/auth";
interface BaseAuthProps {
  showError: boolean;
}
const BaseAuth: React.FC<BaseAuthProps> = ({ showError = false }) => {
  const { user } = useSelector((state: any) => state.user);
  const [info, setInfo] = useState<AccountAuditVo>({});

  useEffect(() => {
    getUserAuthedInfo(user.id).then(
      (res: { data: SetStateAction<AccountAuditVo> }) => {
        setInfo(res.data);
      }
    );
  }, []);
  return (
    <>
      <CusHeader title="个人中心" showSearch={false}></CusHeader>
      <ContentWrapper>
        <Wrap>
          <PersionCommon></PersionCommon>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {/* <EditInfo data={list} /> */}
            <Title
              style={{ marginBottom: info.status !== 5 ? "30px" : "45px" }}
            >
              个人认证
            </Title>
            {info.status === 3 && (
              <CusAlert
                showIcon
                width={604}
                message={"您的实名认证审核不通过，原因：" + info?.auditExplain}
                type="error"
                closable
              />
            )}
            {info.status === 1 && (
              <CusAlert
                showIcon
                width={604}
                message={"您的实名认证正在审核中，请耐心等待"}
                type="warning"
                closable
              />
            )}
            <Descriptions title="" column={1}>
              <Descriptions.Item label="真实姓名">
                {info.auditName}
              </Descriptions.Item>
              <Descriptions.Item label="身份证号">
                {info.idCardNo}
              </Descriptions.Item>
              <Descriptions.Item label="身份证人像面" className="descriptions">
                <Image src={info.idCardFront} />
                <Image src={info.idCardBack} />
              </Descriptions.Item>
            </Descriptions>
            {info.status === 3 && (
              <CusButton
                type="primary"
                width={224}
                height={32}
                onClick={() => history.push("/persion/auth/user-auth/1")}
              >
                重新提交
              </CusButton>
            )}
          </div>
        </Wrap>
      </ContentWrapper>
      <BaseFooter />
    </>
  );
};

export default BaseAuth;
