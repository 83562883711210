import BaseFooter from "@/layouts/base-footer";
import CusHeader from "@/layouts/cus-header";
import { Title } from "@/styles/common-style";
import PersionCommon from "../components/common";
import { ContentWrapper, Wrap } from "../style";
import { Row } from "antd";
import { OrderList } from "./components/order-list";
interface SamOrderProps {}
const SamOrder: React.FC<SamOrderProps> = () => {
  return (
    <>
      <CusHeader title="个人中心" showSearch={false}></CusHeader>
      <ContentWrapper>
        <Wrap>
          <PersionCommon />
          <Row style={{ flexDirection: "column", flex: 1 }}>
            <Title style={{ marginBottom: "12px" }}> 我的订单 </Title>
            <OrderList />
          </Row>
        </Wrap>
      </ContentWrapper>
      <BaseFooter />
    </>
  );
};

export default SamOrder;
