import styled from "styled-components";
import { ListWrapper } from "../style";

export const MyOrderWrapper = styled(ListWrapper)`
  .ant-list-item {
    padding: 0;
    margin-bottom: 12px;
    height: 130px;
    border: 1px solid #d8d8d8;
    .ant-row {
      padding: 0 20px;
    }
    .header {
      height: 50px;
      background-color: #f4f4f4;
    }
    .header-order-num {
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #000000;
    }
    .header-order-timer {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #999999;
    }
    .content-info {
      height: 80px;
    }
    .order-title {
      width: 400px;
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #666666;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .toPay {
      margin-right: 10px;
      width: 100px;
      height: 40px;
      background: #ffffff;
      border: 1px solid #1848fd;
      border-radius: 2px;
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #1848fd;
    }
  }
`;
