import { Title } from "@/styles/common-style";
import { ContactUs } from "@/utils/common";
import { useEffect, useState } from "react";
import { useSelector, history } from "umi";
import { ConsulteCateWrapper } from "../style";
import { Row } from "antd";
import { RightOutlined } from "@ant-design/icons";
interface ConsulteCateProps {}
interface ConsulteVo {
  metadataName: string;
  introduction: string;
  type: string;
  img?: string;
}
const ConsulteCate: React.FC<ConsulteCateProps> = (props) => {
  const [consulte, setConsulte] = useState<ConsulteVo>(
    {} as ConsulteVo
    // {
    //   title: "数字化转型规划",
    //   introduction: "简介内容简介内容简介内容简介内容简介内容简介内容",
    //   type: "1",

    //   // img: require("@/assets/images/server/bg_10.png"),
    // },
  );
  const [cates, setCates] = useState<ConsulteVo[]>();

  // const cates = [
  //   {
  //     title: "精益咨询",
  //     introduction:
  //       "简介内容简介内容简介内容简介内容简介内简介内容简介内容简介内容简介内容简介内容简介内容容简介内容",
  //     type: "2",
  //   },
  //   {
  //     title: "精益咨询",
  //     introduction: "简介内容简介内容简介内容简介内容简介内容简介内容",
  //     type: "3",
  //   },
  //   {
  //     title: "精益咨询",
  //     introduction: "简介内容简介内容简介内容简介内容简介内容简介内容",
  //     type: "4",
  //   },
  //   {
  //     title: "精益咨询",
  //     introduction: "简介内容简介内容简介内容简介内容简介内容简介内容",
  //     type: "1",
  //   },
  // ]
  const getItemType = (data: any) => {
    switch (data.priceType) {
      case 2:
        return "按需定价";
      case 1:
        return "免费";
      case 3:
        return `${data.price}${data.priceCompany == 1 ? "元/年" : "元/次"}`;
      default:
        return "免费";
    }
  };
  const { serverCates } = useSelector((state: any) => state.server);
  const [serverCate, setServerCate] = useState<any>();

  useEffect(() => {
    let item = serverCates.filter((item: any) => item.id === 421)[0];
    setServerCate(item);
    if (item && item.metadataList) {
      setConsulte(
        item.metadataList.map((it: any, index: any) => {
          if (index == 0) {
            return {
              metadataName: "数字化战略咨询",
              introduction:
                "战略咨询、业务咨询、技术咨询的融合，快速迭代、直接连接市场及产业链上下游的轻量级咨询，以数据和数字化技术为核心，绘制一张数字化转型的蓝图。",
            };
          }
        })[0]
      );
      setCates(
        item.metadataList.slice(1, 5).map((cate: any, index: number) => {
          let metadataName = "";
          let introduction =
            "协助企业正确定位并规划企业战略，及时跟进战略执行，体检和重整，确保企业规避风险与把握机会，高效开创顾客保障业务潜力实现。";
          if (index == 0) {
            metadataName = "商业模式定位";
            introduction =
              "协助企业正确定位并规划企业战略，及时跟进战略执行，体检和重整，确保企业规避风险与把握机会，高效开创顾客保障业务潜力实现。";
          } else if (index == 1) {
            metadataName = "运营管理咨询";

            introduction =
              "帮助企业通过计划、组织、指挥、协调、控制、激励等活动 , 对价值链增值过程进行有效管理。";
          } else if (index == 2) {
            metadataName = "品牌定位咨询";
            introduction =
              "为品牌提供全面的品牌策划与品牌设计服务，以更高的服务标准，全面提升品牌营销能力。";
          } else if (index == 3) {
            metadataName = "供应链管理咨询";
            introduction =
              "有效帮助企业制定供应链计划，降低库存和运作成本，优化采购、物流和供应商管理，实现企业销售增长。";
          }
          return {
            metadataName,
            introduction,
          };
        })
      );
    }
  }, [serverCates]);
  console.log(consulte, "consulte");
  return (
    <ConsulteCateWrapper>
      <Row
        align="middle"
        justify="space-between"
        style={{ width: 1200, margin: "0 auto  20px" }}
      >
        <Title>{serverCate?.metadataName}</Title>
        <div
          onClick={() => history.push("/more-consult")}
          style={{ cursor: "pointer" }}
        >
          查看更多 <RightOutlined />
        </div>
      </Row>
      <div className="wrapper">
        <div
          className="consulte-info"
          onClick={() => ContactUs(consulte.metadataName)}
        >
          <img
            src={require("@/assets/images/server/bg_10.png")}
            className="img"
          />
          <div className="info">
            <div className="title">{consulte.metadataName}</div>
            <div className="intro first">{consulte.introduction}</div>
            {/* <div className="btn">{getItemType(consulte)}</div> */}
          </div>
        </div>
        <div className="right-info">
          {cates &&
            cates.map((item: any, index) => {
              return (
                <div key={index} className="item">
                  <div
                    className="item-wrapper"
                    onClick={() => ContactUs(item.metadataName)}
                  >
                    <div className="title">{item.metadataName}</div>
                    {/* <div className="btn">{getItemType(item)}</div> */}
                  </div>
                  <div className="intro">{item.introduction}</div>
                </div>
              );
            })}
        </div>
      </div>
    </ConsulteCateWrapper>
  );
};

export default ConsulteCate;
