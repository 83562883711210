// import { htmlStirng } from "@/utils/common"
import Storage from "@/utils/storage";
import { useEffect } from "react";
interface ParseHtmlProps {}
// 支付中间页
const PayHtml: React.FC<ParseHtmlProps> = () => {
  const str = Storage.get("trans_assembled_url");
  useEffect(() => {
    console.log({ str });
    if (str) {
      document.write(str);
      document.close();
    }
  }, [str]);
  return <></>;
};
export default PayHtml;
