import styled from "styled-components";

export const OrderWrap = styled.div`
  margin: 32px auto 82px;
  width: 1200px;
  .order-title {
  }
  .goods-info {
    display: flex;
    align-items: center;
    margin: 20px 0 30px;
    height: 240px;
    padding: 30px 34px;
    box-sizing: border-box;
    background: #f5fafd;
    .left {
      width: 200px;
      height: 150px;
      .img {
        width: 200px;
        height: 150px;
      }
    }
    .right {
      height: 150px;
      padding-left: 20px;
      width: 960px;
      .right-title {
        line-height: 1;
        font-size: 24px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        color: #333333;
      }
      .right-intro {
        margin-top: 10px;
        margin-bottom: 16px;
        height: 66px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #666666;
      }
      .info {
        /* margin-bottom: 21px; */
        display: flex;
        line-height: 1;
        align-items: baseline;
        color: #999999;
        .left-info {
          margin-right: 80px;
          width: 600px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .download-info {
          margin-right: 0;
        }
        .total-info {
          margin-right: 0;
        }
        .price-info {
          margin-left: 0;
        }
        .price {
          font-size: 30px;
          color: #1848fd;
        }
      }
    }
  }

  .pay-title {
    padding-left: 30px;
    height: 50px;
    line-height: 50px;
    background: #f4f4f4;
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    color: #000000;
  }
  .pay-way-description {
    padding-left: 30px;
    .pay-card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 215px;
      height: 85px;
      padding: 0 36px 0 24px;
      box-sizing: border-box;
      background: #ffffff;
      border-radius: 2px;
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.13);
      img {
        width: 52px;
        height: 52px;
      }
      .text {
        font-size: 18px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .pay-amount {
    margin-top: 33px;
    padding-left: 30px;
    font-size: 24px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #333333;
  }
  .pay-act {
    padding-left: 30px;
    margin-top: 47px;
    .ant-checkbox-wrapper {
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #666;
      a {
        color: #666;
      }
    }
  }
  .pay-btn {
    margin-right: 48px;
    width: 130px;
    height: 50px;
    background: #1848fd;
    border-radius: 2px;
    font-size: 24px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #ffffff;
  }
`;
