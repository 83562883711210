import React, { useEffect, useState } from "react";
import type { CascaderProps } from "antd";
import { Cascader } from "antd";
import type { CzAddr } from "@/models/czAddr";
import request from "@/utils/request";
import "./index.less";
export interface AreaSelectNewProps
  extends Pick<CascaderProps<CzAddr>, "onChange"> {
  hasNoStreet?: boolean;
  hasNotown?: boolean;
  multiple?: boolean;
  defaultValue?: any;
  showName?: boolean;
  all?: boolean;
  value?: any;
  className?: string;
  width?: number;
  placeholder?: string;
}

interface Option extends CzAddr {
  children: Option[];
}

const AreaSelectNew: React.FC<AreaSelectNewProps> = (props) => {
  const [options, setOptions] = useState<Option[]>([]);
  const { placeholder = "请选择" } = props;
  const [selectedValue, setSelectedValue] = useState<any[]>([]);
  const {
    hasNoStreet = false,
    hasNotown = false,
    multiple = false,
    value,
  } = props;
  console.log({ value });
  const _request = (
    parentId: number,
    type: number,
    parent?: Option
  ): Promise<Option[]> => {
    return request(`/setting/area/list/${parentId}`, {
      method: "get",
    }).then((res) => {
      // 区
      let maxParentLevel = 3;
      if (hasNoStreet) {
        // 镇
        maxParentLevel = 2;
      }
      if (hasNotown) {
        // 市
        maxParentLevel = 1;
      }
      const isLeaf = (parent && parent.level === maxParentLevel) || false;
      let arr = [
        ...(type == 1
          ? [
              {
                id: "1100000",
                name: "全国",
                areaCode: "1",
                level: 5,
                parentId: 4,
                isLeaf: false,
                children: [],
              },
            ]
          : []),
        ...res.data.map((item: any) => ({
          ...item,
          isLeaf: isLeaf,
        })),
      ];
      console.log(arr);
      return arr;
    });
  };
  useEffect(() => {
    setSelectedValue([value]);
  }, [value]);
  useEffect(() => {
    _request(0, 1).then(setOptions).catch(console.error);
  }, []);

  return (
    <Cascader<Option>
      style={{ width: props.width || 150 }}
      options={options}
      className={props?.className}
      multiple={multiple}
      placeholder={
        selectedValue && selectedValue.length > 0
          ? selectedValue.join(" / ")
          : value
          ? value
          : placeholder
      }
      defaultValue={selectedValue}
      value={[]}
      fieldNames={{
        label: "name",
        value: "areaCode",
      }}
      loadData={(selectOptions) => {
        console.log(selectOptions, "selectOptions");
        if (
          selectOptions &&
          selectOptions.length > 0 &&
          selectOptions[0].areaCode
        ) {
          const last = selectOptions[selectOptions.length - 1] as any as Option;
          _request(last.id, last.level + 1, last).then((res) => {
            last.children = props.all
              ? [
                  {
                    id: 9999,
                    name: "全部",
                    areaCode: last.areaCode,
                    level: 4,
                    parentId: 4,
                    children: [],
                  },
                  ...res,
                ]
              : res;
            setOptions([...options]);
          });
        }
      }}
      onChange={(value: any, selectedOptions: any) => {
        let name: string[] = [];
        Array.isArray(value) &&
          value.forEach((item: any) => {
            selectedOptions.forEach((it: any) => {
              if (it.areaCode == item) {
                name.push(it.name);
              }
            });
          });
        name = [...new Set(name)].filter((item: any) => item != "全部");
        setSelectedValue(name);
        let hash = {};
        let data: any =
          Array.isArray(selectedOptions) &&
          selectedOptions.reduce(
            (preVal: any[], curVal: { areaCode: string | number }) => {
              hash[curVal.areaCode]
                ? ""
                : (hash[curVal.areaCode] = true && preVal.push(curVal));
              return preVal;
            },
            []
          );
        if (props.all) {
          props.onChange && props.onChange(data, selectedOptions);
        } else if (props.showName) {
          props.onChange && props.onChange(name, selectedOptions);
        } else {
          props.onChange && props.onChange(value, selectedOptions);
        }
      }}
    />
  );
};

export default AreaSelectNew;
