import { Button, Input, Tag } from "antd";
import styled from "styled-components";
export const CusButton = styled(Button)<{ width?: number; height?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.width ? props.width + "px" : "70px")};
  height: ${(props) => (props.height ? props.height + "px" : "30px")};
  .ant-btn-primary,
  .ant-btn-link {
    background-color: #1848fd;
  }
`;

export const Title = styled.div<{
  color?: string;
  fz?: number;
  fw?: number;
  textAlign?: string;
}>`
  position: relative;
  font-size: ${(props) => (props.fz ? props.fz + "px" : " 36px")};
  font-family: PingFangSC, PingFangSC-Semibold;
  font-weight: ${(props) => (props.fw ? props.fw : 600)};
  color: ${(props) => (props.color ? props.color : " #000000")};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
`;
export const CusInput = styled(Input)<{ width?: number; height?: number }>`
  width: ${(props) => (props.width ? props.width + "px" : "370px")};
  height: ${(props) => (props.height ? props.height + "px" : "54px")};
`;
export const CusPassword = styled(Input.Password)<{
  width?: number;
  height?: number;
}>`
  width: ${(props) => (props.width ? props.width + "px" : "370px")};
  height: ${(props) => (props.height ? props.height + "px" : "54px")};
`;

export const BannarInfo = styled.div<{ bgImage?: string }>`
  width: 100%;
  min-width: 1500px;
  height: 146px;
  background: url(${(props) => (props.bgImage ? props.bgImage : "")}) no-repeat
    center;
  background-size: cover;
`;

export const CusTag = styled(Tag)<{ bgColor?: string; Color?: string }>`
  min-width: 64px;
  height: 22px;
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#fff")};
  color: ${(props) => (props.Color ? props.Color : "#333")};
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  margin-bottom: 4px;
  text-align: center;
`;
