import { CusButton, Title } from "@/styles/common-style";
import { useState } from "react";
import { Row } from "antd";
import { ProductServicesWrapper } from "./style";
import CatesList from "./components/cates-item";
import { TABS_ITEMS } from "@/utils/common";
interface ProductServiceProps {}
const ProductService: React.FC<ProductServiceProps> = () => {
  const [tabs, setTabs] = useState<any>(TABS_ITEMS);
  const [currentId, setCurrentId] = useState<number>(0);
  const [activeItem, setActiveItem] = useState<any>(TABS_ITEMS[0]);
  return (
    <ProductServicesWrapper>
      <Title className="product-title" textAlign="center">
        产品服务
      </Title>
      <Title className="product-sub-title" fw={400} fz={18} textAlign="center">
        助力企业实现低成本数字化重塑、始终站在业界前沿
      </Title>
      <div className="product-server-info">
        <div className="product-server-content">
          <div className="product-server-left">
            {tabs &&
              tabs.map((tab: any, index: number) => (
                <div
                  className={
                    currentId === index
                      ? "product-server-item product-server-actie-item"
                      : "product-server-item"
                  }
                  key={index}
                  onClick={() => {
                    setCurrentId(index);
                    setActiveItem(tabs[index]);
                  }}
                >
                  <Title
                    className="product-server-title title"
                    textAlign="center"
                    fz={20}
                    fw={400}
                  >
                    {tab.label}
                  </Title>
                  <Title
                    className="product-server-sub"
                    fw={400}
                    fz={12}
                    color="#1848fd"
                    textAlign="center"
                  >
                    {tab.sub}
                  </Title>
                </div>
              ))}
          </div>
          <div className="product-server-right">
            <CatesList data={activeItem} />
          </div>
        </div>
      </div>
    </ProductServicesWrapper>
  );
};

export default ProductService;
