import { BaseInfoWrapper, ContentWrapper, CusButton, Wrap } from "../../style";
import { Checkbox, Form, Input, message } from "antd";
import FileUpload from "@/components/file-upload";
import BaseFooter from "@/layouts/base-footer";
import CusHeader from "@/layouts/cus-header";
import type { FormInstance } from "antd/es/form";
import PersionCommon from "../../components/common";
import { Title } from "@/pages/server/server-center/style";
import { useEffect, useRef, useState } from "react";
import { isIdCard } from "@/utils/common";
import Agreement from "@/components/agreement";
import { createAuth, getUserAuthedInfo } from "@/api/auth";
import { useSelector, history, useParams } from "umi";
import { AccountAuditVo } from "@/models/auth";
interface UserAuthProps {}
const UserAuth: React.FC<UserAuthProps> = () => {
  const formRef: any = useRef<FormInstance>();
  const { user } = useSelector((state: any) => state.user);
  const [info, setInfo] = useState<AccountAuditVo>({} as AccountAuditVo);
  const param = useParams<any>();
  const validator = (_: any, value: any) => {
    if (value) {
      if (isIdCard(value)) {
        return Promise.resolve();
      } else {
        return Promise.reject(new Error("身份证号格式不正确"));
      }
    } else {
      return Promise.reject(new Error("请输入身份证号"));
    }
    //
  };
  // useEffect(() => {
  //   if (params.id == 1) {
  //     getUserAuthedInfo(user.id).then(
  //       (res: { data: SetStateAction<AccountAuditVo> }) => {
  //         setInfo(res.data)
  //       }
  //     )
  //   }
  // }, [params.id])
  const onFinish = (values: any) => {
    const params = {
      ...values,
      roleType: 2,
      accountId: user.id,
      sourceClient: 3,
    };
    createAuth(params).then((res: any) => {
      if (res.code == 1) {
        message.success("提交成功");
        history.push("/persion/base/" + new Date().getTime());
      }
    });
  };
  useEffect(() => {
    if (param.id == 1) {
      getUserAuthedInfo(user.id).then((res: any) => {
        if (res.code == 1) {
          setInfo(res.data);
          formRef.current?.setFieldsValue({
            auditName: res.data.auditName,
            idCardNo: res.data.idCardNo,
            idCardFront: res.data.idCardFront,
            idCardBack: res.data.idCardBack,
          });
        }
      });
    }
  }, []);
  return (
    <>
      <CusHeader title="个人中心" showSearch={false}></CusHeader>
      <ContentWrapper>
        <Wrap>
          <PersionCommon></PersionCommon>
          <BaseInfoWrapper>
            <Title style={{ marginBottom: "24px" }}>个人认证</Title>
            <Form
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 12 }}
              ref={formRef}
              onFinish={onFinish}
            >
              <Form.Item
                label="真实姓名"
                name="auditName"
                rules={[
                  {
                    validator: (_: any, value: any) => {
                      if (value) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(new Error("请输入真实姓名"));
                      }
                      //
                    },
                    validateTrigger: ["blur"],
                  },
                ]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
              <Form.Item
                label="身份证号"
                name="idCardNo"
                rules={[{ validator: validator, validateTrigger: ["blur"] }]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
              <Form.Item
                label="身份证人像面"
                name="idCardFront"
                rules={[
                  {
                    validator: (_: any, value: any) => {
                      if (value) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(new Error("请上传身份证人像面"));
                      }
                      //
                    },
                  },
                ]}
              >
                <FileUpload
                  noCrope
                  height={194}
                  value={1}
                  width={224}
                  defaultUrl={require("@/assets/images/persion/zhengmian.png")}
                />
              </Form.Item>
              <Form.Item
                label="身份证国徽面"
                name="idCardBack"
                rules={[
                  {
                    validator: (_: any, value: any) => {
                      if (value) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(new Error("请上传身份证国徽面"));
                      }
                      //
                    },
                  },
                ]}
              >
                <FileUpload
                  noCrope
                  height={194}
                  width={224}
                  defaultUrl={require("@/assets/images/persion/fanmian.png")}
                />
              </Form.Item>
              <Form.Item
                wrapperCol={{ offset: 8, span: 16 }}
                name="agree"
                rules={[
                  {
                    validator: (_: any, value: any) => {
                      if (value) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          new Error("请勾选并同意《认证服务协议》")
                        );
                      }
                      //
                    },
                  },
                ]}
              >
                <Checkbox
                  onChange={(e) => {
                    formRef.current?.setFieldsValue({
                      agree: e.target.checked,
                    });
                  }}
                >
                  <span className="agree-item">
                    <span>阅读并同意</span>
                    <Agreement title="《认证服务协议》"></Agreement>
                  </span>
                </Checkbox>
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 12 }}>
                <CusButton
                  type="primary"
                  width={224}
                  height={32}
                  htmlType="submit"
                >
                  提交申请
                </CusButton>
              </Form.Item>
            </Form>
          </BaseInfoWrapper>
        </Wrap>
      </ContentWrapper>
      <BaseFooter />
    </>
  );
};

export default UserAuth;
