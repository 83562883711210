import styled from "styled-components";

export const VideoWrapper = styled.div`
  width: 100%;
  min-width: 1440px;
  background-color: #edf1f8;
  padding-top: 30px;
  .video-content {
    margin: 0 auto;
    width: 1288px;
    .video-title {
      width: 1288px;
      background-color: #fff;
    }
  }
  .video {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 0;
    width: 1288px;
    video {
      width: 100%;
      height: 100%;
    }
  }
`;
