import BaseLayout from "@/layouts";
import { Partner } from "../order-platform/components/partner";
import { BgImage } from "../order-platform/style";
import { DCenterInfo } from "./components/d-center-info";
import { DeveloperCenterWrapper } from "./style";

interface DeveloperCenterProps {}
const DeveloperCenter: React.FC<DeveloperCenterProps> = () => {
  return (
    <BaseLayout>
      <DeveloperCenterWrapper>
        <BgImage bg={require("@/assets/images/d-center/develop_bg.png")} />
        <DCenterInfo />
        <Partner noDownload />
      </DeveloperCenterWrapper>
    </BaseLayout>
  );
};

export default DeveloperCenter;
