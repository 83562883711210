import BaseFooter from "@/layouts/base-footer";
import CusHeader from "@/layouts/cus-header";
import { replaceId } from "@/utils/common";
import { useState } from "react";
import PersionCommon from "../components/common";
import { ContentWrapper, CusButton, Wrap } from "../style";
import EditInfo from "./edit-info";
import { history, useSelector } from "umi";
interface PersonProps {}
const BaseSecurity: React.FC<PersonProps> = () => {
  const { user } = useSelector((state: any) => state.user);
  const [list, setlist] = useState([
    {
      icon: require("@/assets/images/persion/phone.png"),
      title: "手机号",
      info: replaceId(user?.mobile, 3, 4),
      show: true,
      renderRight: () => {
        return (
          <CusButton
            type="primary"
            onClick={() =>
              history.push("/persion/phone/" + new Date().getTime())
            }
          >
            修改
          </CusButton>
        );
      },
    },
    {
      icon: require("@/assets/images/persion/lock.png"),
      title: "账号密码",
      info: "上次设置时间：2021年5月10日",
      show: false,
      renderRight: () => {
        return (
          <CusButton onClick={() => history.push("/persion/reset/1")}>
            重置
          </CusButton>
        );
      },
    },
  ]);
  return (
    <>
      <CusHeader title="个人中心" showSearch={false}></CusHeader>
      <ContentWrapper>
        <Wrap>
          <PersionCommon></PersionCommon>
          <EditInfo data={list} />
        </Wrap>
      </ContentWrapper>
      <BaseFooter />
    </>
  );
};

export default BaseSecurity;
