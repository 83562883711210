import { Row, Button } from "antd";
import BaseLayout from "@/layouts";
import BannarInfo from "../trains/components/banner-info";
import Icon from "@ant-design/icons";
import "@/styles/metting-details.less";
import { AddressSvg } from "@/icon";
import { useEffect, useState } from "react";
import { Metting } from "@/models/metting";
import { getMettingDetail } from "@/utils/api";
import { useParams } from "umi";
import Title from "@/components/title";

const AddressIcon = (props: any) => <Icon component={AddressSvg} {...props} />;
interface MettingDetailsProps {}
const MettingDetails: React.FC<MettingDetailsProps> = (props) => {
  const params = useParams<any>();
  // const data = {
  //   title: '大赛·传播专业培训',
  //   desc: '通过数智化手段帮助服装企业构建高灵敏性、高柔性、高可靠性以及高弹复性的供应链体系',
  //   times: '2021-03-24 13:00 —— 2021-05-10 14:00',
  //   address: '浙江省杭州余杭区艺尚小镇',
  //   detail:
  //     '数智化、一体化产品创新研发设计平台，从市场、创意、AI智能评估、智能设计多维度，帮助企业通过数据驱动进行组合创新、实现差异化竞争，并沉淀企业专属数据资产 数智化、一体化产品创新研发设计平台，从市场、创意、AI智能评估、智能设计多维度，帮助企业通过数据驱动进行组合创新、实现差异化竞争，并沉淀企业专属数据资产数智化、一体化产品创新研发设计平台，从市场、创意、AI智能评估、智能设计多维度，帮助企业通过数据驱动进行组合创新、实现差异化竞争，并沉淀企业专属数据资产',
  //   imags: [
  //     { img: require('@/assets/images/card-list.png'), key: '1' },
  //     { img: require('@/assets/images/card-list.png'), key: '2' },
  //   ],
  // };
  const [mettingdetail, setmettingdetail] = useState<Metting>();
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const getDetails = () => {
    getMettingDetail(params).then((res: any) => {
      if (res.code == 1) {
        setmettingdetail(res.data);
      }
    });
  };
  useEffect(() => {
    getDetails();
  }, []);
  const navigateTo = () => {
    window.open(`https://apis.map.qq.com/uri/v1/marker?marker=coord:${
      mettingdetail?.latitude
    },${mettingdetail?.longitude};title:${
      mettingdetail?.meetingName
    };addr:${`${mettingdetail?.province}${mettingdetail?.city}
    ${mettingdetail?.area}
    ${mettingdetail?.address}`}
   &referer=myapp`);
  };
  const joinIt = (path: string | URL | undefined) => {
    window.open(path);
  };
  return (
    <BaseLayout>
      <div className="conference-training">
        <div className="img-poster">
          <img src={mettingdetail?.poster} alt="会议培训海报" />
        </div>
        {mettingdetail && (
          <div>
            <div className="details-intro">
              <div className="detail-info">
                {/* <img src={mettingdetail.poster} /> */}
                {/* <div
                  style={{
                    width: "476px",
                    height: "377px",
                    borderRadius: "5px",
                    backgroundColor: "#d8d8d8",
                    backgroundImage: `url(${mettingdetail.poster})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                ></div> */}
                <div className="detail-right-container">
                  <div>
                    <div>
                      <Title text={mettingdetail.meetingName} />
                    </div>
                    <div
                      className="detail-info-desc"
                      dangerouslySetInnerHTML={{
                        __html: mettingdetail.introduction || "-",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      className="detail-info-date"
                      // style={{ fontSize: "16px" }}
                    >
                      时间：{mettingdetail.startTime}
                    </div>
                    <div className="detail-info-address">
                      {(mettingdetail.province ||
                        mettingdetail.city ||
                        mettingdetail.area ||
                        mettingdetail.address) && (
                        <Row align="middle">
                          <span>
                            地址：{mettingdetail.province || ""}
                            {mettingdetail.city || ""}
                            {mettingdetail.area || ""}
                            {mettingdetail.address || ""}
                          </span>
                          <span
                            className="navigate-icon"
                            onClick={() => navigateTo()}
                          >
                            <AddressIcon style={{ fontSize: "26px" }} />
                            到这里
                          </span>
                        </Row>
                      )}
                      {mettingdetail.pcPath &&
                        mettingdetail.meetingStatus !== "已结束" && (
                          <Button
                            type="primary"
                            className="detail-info-join"
                            // style={{ fontSize: "14px" }}
                            onClick={() => joinIt(mettingdetail.pcPath)}
                          >
                            去参加
                          </Button>
                        )}
                      {mettingdetail.meetingStatus === "已结束" && (
                        <Button disabled className="detail-info-join">
                          已结束
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detail">
              {/* <Row align='middle' justify='center' className='detail-title'>
                <Title text='具体详情' />
              </Row> */}
              <div
                className="detail-text ql-editor"
                dangerouslySetInnerHTML={{
                  __html: mettingdetail.detail || "-",
                }}
              ></div>
              <div>
                {/* {mettingdetail.imags &&
                  mettingdetail.imags.map((item) => (
                    <img key={item.key} src={item.img} className="detail-img" />
                  ))} */}
              </div>
            </div>
          </div>
        )}
      </div>
    </BaseLayout>
  );
};
export default MettingDetails;
