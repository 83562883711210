import BaseLayout from "@/layouts"
import "@/styles/conference-training.less"
import BannarInfo from "./components/banner-info"
import CardList from "./components/card-list"
import MettingCaties from "./components/metting-caties"

const ConferenceTraining: React.FC = () => {
  return (
    <BaseLayout>
      <div className='conference-training'>
        <BannarInfo />
        {/* <CardList /> */}
        <MettingCaties />
      </div>
    </BaseLayout>
  )
}

export default ConferenceTraining
