import { useSelector, history, useDispatch } from "umi";
import { CusDropdown, LoginWrapper } from "./style";
import { Button, Menu, Space, Avatar } from "antd";
import { DownOutlined } from "@ant-design/icons";

import { useState } from "react";
interface HeaderLoginProps {}
const HeaderLogin: React.FC<HeaderLoginProps> = () => {
  const { user } = useSelector((state: any) => state.user);
  const isLogin = user?.id ? true : false;
  const [current, setCurrent] = useState<string>("1");
  // const user = useSelector(state => state.login)
  const dispatch = useDispatch();
  const menu = (
    <Menu
      onClick={({ item, key, keyPath, domEvent }) => {
        setCurrent(key);
      }}
      items={[
        {
          key: "1",
          label: (
            <a
              target="_self"
              onClick={() =>
                history.push("/persion/base/" + new Date().getTime())
              }
            >
              个人中心
            </a>
          ),
        },
        {
          key: "2",
          label: (
            <a
              target="_self"
              onClick={() => {
                dispatch({
                  type: "login/logout",
                });
              }}
            >
              退出登录
            </a>
          ),
        },
      ]}
    />
  );
  return (
    <LoginWrapper className="login-wrapper">
      {isLogin ? (
        <CusDropdown
          overlayStyle={{
            top: "95px",
            left: "1310px",
          }}
          overlay={menu}
          trigger={["click"]}
          overlayClassName="loginOverlay"
        >
          <Space>
            <Avatar
              src={user.avatar}
              style={{ width: "58px", height: "58px" }}
            />
            <DownOutlined />
          </Space>
        </CusDropdown>
      ) : (
        <Button onClick={() => history.push("/login")}>登录</Button>
      )}
    </LoginWrapper>
  );
};

export default HeaderLogin;
