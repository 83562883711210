import BaseLayout from "@/layouts";
import { OrderDesc } from "./components/order-desc";
import { OrderInfo } from "./components/order-info";
import { OrderProcess } from "./components/order-process";
import { Partner } from "./components/partner";
import { BgImage, OrderPlatformWrapper } from "./style";

interface OrderPlatformProps {}
const OrderPlatform: React.FC<OrderPlatformProps> = () => {
  return (
    <BaseLayout>
      <OrderPlatformWrapper>
        <BgImage className="bgImage" />
        <OrderInfo />
        <OrderDesc />
        <OrderProcess />
        <Partner />
      </OrderPlatformWrapper>
    </BaseLayout>
  );
};

export default OrderPlatform;
