import request from "@/utils/request";

// 获取 产业链鱼骨图
export const getFishboneTree = () => {
  return request("/business/industrialNode/fishboneTree", {
    method: "GET",
    requestType: "json",
  });
};
// 获取 产业链鱼骨图
export const getFishboneTreeNodeCount = () => {
  return request("/business/industrialNode/queryNodeTotal", {
    method: "GET",
    requestType: "json",
  });
};

// 根据 产业链鱼骨图的 node id 查询子树
export const getSubTreeById = (id: any) => {
  return request(`/business/industrialNode/queryByIdTree/${id}`, {
    method: "get",
    requestType: "json",
  });
};

// 产业园区

export const getParklist = (params: any) => {
  return request("/business/park/list", {
    method: "POST",
    requestType: "json",
    data: params,
  });
};
// 根据 园区id 查询企业
export const getParklistCompany = (id: any) => {
  return request("/business/company/queryListByParkId/" + id, {
    method: "get",
    requestType: "json",
  });
};

export const getParkdetail = (query: { id: any }) => {
  return request(`/business/park/queryById/${query.id}`, {
    method: "GET",
    requestType: "json",
    // params: query,/
  });
};

export const getConpany = (query: any) => {
  return request(`/business/company/queryById/${query.id}`, {
    method: "get",
    requestType: "json",
  });
};
//集群列表
export const getClusterAreaList = (params: any) => {
  return request(`/business/cluster/clusterCount`, {
    method: "post",
    requestType: "json",
    data: params,
  });
};
// 统计各市园区数量(按省份)
export const getParkProvinceList = (params: any) => {
  return request(`/business/park/parkProvinceCount`, {
    method: "POST",
    requestType: "json",
    data: params,
  });
};
//统计各市园区数量(市)
export const getParkCityList = (params: any) => {
  return request(`/business/park/parkCityCount`, {
    method: "POST",
    requestType: "json",
    data: params,
  });
};

// 查询企业节点 集群列表接口

export const queryClusterList = (params: any) => {
  return request(`/business/cluster/pageList`, {
    method: "post",
    requestType: "json",
    data: params,
  });
};
///列表查询
export const searchList = (params: any) => {
  return request(`/business/atlas/searchList`, {
    method: "post",
    requestType: "json",
    data: params,
  });
};
//查询企业列表接口
export const getQueryCompanyList = (params: any) => {
  return request(`/business/company/list`, {
    method: "post",
    requestType: "json",
    data: params,
  });
};

//查询园区详情
export const queryParkList = (params: any) => {
  return request(`/business/park/list`, {
    method: "post",
    requestType: "json",
    data: params,
  });
};

// 获取公司分类统计

// /business/company/companyCateCount

export const queryCompanyList = (params: any) => {
  return request(`/business/company/companyCateCount`, {
    method: "post",
    requestType: "json",
    data: params,
  });
};

export const GetTreeData = (params: any) => {
  ///
  return request(`/business/metadata/queryById/368`, {
    method: "get",
    requestType: "json",
  });
};

//统计公司的市 节点
export const getCompanyCityCount = (params: any) => {
  return request(`/business/company/companyCityCount`, {
    method: "post",
    requestType: "json",
    data: params,
  });
};
//统计公司的市
export const getCompanyCount = (params: any) => {
  return request(`/business/company/companyCount`, {
    method: "post",
    requestType: "json",
    data: params,
  });
};
//统计园区的市
export const getParkCount = (params: any) => {
  return request(`/business/park/parkCount`, {
    method: "post",
    requestType: "json",
    data: params,
  });
};
//
export const getCompanyProvinceCount = (params: any) => {
  return request(`/business/company/companyProvinceCount`, {
    method: "post",
    requestType: "json",
    data: params,
  });
};
