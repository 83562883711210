import { Title } from "@/styles/common-style";
import { TABS_ITEMS } from "@/utils/common";
import { DCenterInfoWrapper } from "../style";

interface DCenterInfoProps {}
export const DCenterInfo: React.FC<DCenterInfoProps> = () => {
  const tab = TABS_ITEMS[4];
  return (
    <DCenterInfoWrapper>
      {tab.item.map((it, index) => (
        <div className="card" key={index}>
          <div className="icon">
            <img src={it.icon} alt="" />
          </div>
          <Title fz={24} fw={600} color="#000" textAlign="center">
            {it.title}
          </Title>
          <div className="intro">
            {index == 3
              ? it.intro.split("，").map((iit, key) => (
                  <div key={key} className="intro-text">
                    {iit}
                  </div>
                ))
              : it.intro}
          </div>
        </div>
      ))}
    </DCenterInfoWrapper>
  );
};
