import styled from "styled-components";
import case_bg1 from "@/assets/images/home/case_bg_1.png";
import case_icon_2 from "@/assets/images/home/case_icon_2.png";
import case_icon_1 from "@/assets/images/home/case_icon_1.png";

export const CaseInfoWrapper = styled.div<{ bgImage?: string }>`
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 111px;
  .sub-title {
    margin-bottom: 48px;
    font-family: Alibaba, Alibaba-Regular;
  }
  .case-info-content {
    width: 1200px;
    height: 440px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 54px 30px 40px 48px;
    box-sizing: border-box;
    background: url(${(props) => (props.bgImage ? props.bgImage : case_bg1)})
      no-repeat center;
    background-size: 1200px 440px;
  }
  .left-content {
    width: 532px;
    height: 345px;
    padding: 17px 20px 36px 23px;
    box-sizing: border-box;
    background: rgba(30, 29, 61, 0.5);
    border-radius: 2px;
    backdrop-filter: blur(4px);
    .title {
      line-height: 1;
      position: relative;
      margin-bottom: 30px;
      &:before {
        position: absolute;
        content: "";
        left: 0;
        bottom: -14px;
        width: 40px;
        height: 4px;
        background-color: #fff;
      }
    }
    .case-intro {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      color: #fff;
      .info-title {
        line-height: 1.3;
        margin-bottom: 14px;
      }
      .info {
        line-height: 1;
        margin-bottom: 10px;
      }
      .case-info-orange {
        padding: 0 4px;
        font-size: 24px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        color: #ff453a;
      }
    }
    .case-list {
      padding-top: 6px;
      display: flex;
      flex-wrap: wrap;
    }
    .item-img {
      margin-right: 4px;
      margin-bottom: 6px;
      width: 70px;
      height: 30px;
      img {
        width: 70px;
        height: 30px;
      }
      &:nth-child(6n) {
        margin-right: 0;
      }
    }
  }
  .right-content {
    display: flex;
    flex-direction: column;
  }
`;

export const TabsCover = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 11px;
  cursor: pointer;
  width: 211px;
  height: 67px;
  background: url(${case_icon_2}) no-repeat center;
  background-size: 100%;
  &.tab-content-active {
    background-image: url(${case_icon_1});
  }
`;
