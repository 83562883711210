import { OrderListWrapper } from "../style";
import { Radio, Row, List, message } from "antd";
import { useEffect, useState } from "react";
import moment from "moment";
import { getOrderList } from "@/api/persion";
import { useSelector } from "umi";
import { OrderStatus, PayStatus } from "@/models/order";
import { createPay } from "@/api/knowledge";
import _ from "lodash";
import { getUserbankCardInfo, getUserPay } from "@/api/user";
interface OrderListProps {}
interface OrderResVo {
  stateList?: any;
  payStatusList?: any;
  orderStatus?: number | null;
  index?: number;
  size?: number;
  buyer?: string;
}
interface pageVo {
  index: number;
  size: number;
  count: number;
  total: number;
}
export const OrderList: React.FC<OrderListProps> = () => {
  const options = [
    { value: 0, label: "全部" },
    { value: 5, label: "报价中" },
    { value: 2, label: "待付款" },
    { value: 11, label: "待发货" },
    { value: 13, label: "待确认交付" },
    // { value: 13, label: "已取消" },
    { value: 25, label: "已完成" },
  ];
  const { user } = useSelector((state: any) => state.user);
  const [loading, setLoading] = useState<boolean>(false);
  let [data, setData] = useState<any>([]);
  let [bankInfo, seBankInfo] = useState<any>({
    bankAcctType: 2,
  });
  const [page, setPage] = useState<pageVo | null>();
  const [request, setRequest] = useState<OrderResVo>({
    index: 1,
    size: 10,
    buyer: user?.id,
  });
  const getOrder = async () => {
    setLoading(true);
    getOrderList(request)
      .then((res: any) => {
        if (res.code === 1) {
          setData(res.data);
          setPage(res.page);
        } else {
          setPage(null);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setPage(null);
      });
    const res: any = await getUserbankCardInfo(user?.id);
    seBankInfo({
      ...res.data,
      bankAcctType: res.data?.bankAcctType ? res.data.bankAcctType : 2,
    });
  };
  const goPay = _.throttle(async (item: any) => {
    if (!bankInfo?.bankCode) {
      message.warning("请先在橙织小程序绑定银行卡, 然后在进行付款操作");
      return;
    }
    if (!bankInfo?.adapayProcessResult || bankInfo?.adapayProcessResult == 2) {
      message.error(bankInfo?.remarks);
      return;
    }
    // if (res.data.bankAcctType != 1) {
    //   message.warning("请绑定对公账户进行支付");
    //   return;
    // }
    let rst: any = null;
    if (bankInfo) {
      if (item.businessType == 2) {
        rst = await getUserPay({
          orderNo: item.orderNo,
          payType: 2,
          // paySubType: "ALIPAY",
        });
      }
      if (rst.code != 1) {
        return;
      }
      let returnCitySN = (window as any).returnCitySN;
      const params = {
        orderNo: item.orderNo,
        businessType: item.businessType,
        // wxPubOpenId: "",
        payChannelCode: "b2b",
        goodsTitle: item.businessType == 1 ? "需求订单" : "样品订单",
        goodsDesc: item.businessType == 1 ? "需求订单" : "样品订单",
        deviceType: "4",
        acctIssrId: bankInfo.bankCode || "03080000",
        callbackUrl: window.location.href,
        deviceIp: returnCitySN["cip"],
      };

      createPay(params).then((re: any) => {
        if (re.code == 1) {
          // Storage.set("trans_assembled_url", re.data?.transAssembledUrl)
          // history.push("/to-pay")
          document.write(re.data?.transAssembledUrl);
          document.close();
          // window.open(process.env.LOCATION_URL + "/#/to-pay")
        }
      });
    } else {
      message.warning("您当前没有绑定银行卡， 请先去橙织小程序进行绑卡操作");
    }
  }, 2000);
  useEffect(() => {
    getOrder();
  }, [request]);
  return (
    <OrderListWrapper>
      <div className="nav-btn">
        <Radio.Group
          defaultValue={0}
          buttonStyle="solid"
          onChange={(e) => {
            if (e.target.value != 0) {
              if (e.target.value == 2) {
                request.payStatusList = [
                  PayStatus.FOR_THE_PAYMENT,
                  PayStatus.PAYMENT_PENDING_REVIEW,
                ];
                request.stateList = [
                  OrderStatus.WAITING_FACTORY_DELIVER,
                  OrderStatus.WAITING_CUSTOMER_EXAM,
                  OrderStatus.WAIT_COMMENT,
                  OrderStatus.FINISHED,
                ];
                request.orderStatus = null;
              } else {
                request.stateList = null;
                request.payStatusList = null;
                request.orderStatus = e.target.value;
              }
            } else {
              request.stateList = null;
              request.payStatusList = null;
              request.orderStatus = null;
            }
            request.index = 1;
            data = [];
            setData([]);
            setRequest({ ...request });
          }}
        >
          {options.map((item: any, index: number) => (
            <Radio.Button key={index} value={item.value}>
              {item.label}
            </Radio.Button>
          ))}
        </Radio.Group>
      </div>
      <List
        itemLayout="vertical"
        size="large"
        loading={loading}
        pagination={
          page
            ? {
                current: request.index,
                total: page && page.count ? page.count : 1,
                onChange: (page, pageSize) => {
                  request.index = page;
                  request.size = pageSize;
                  setRequest({ ...request });
                  console.log(page);
                },
                pageSize: 10,
              }
            : false
        }
        dataSource={data}
        footer={false}
        renderItem={(item: any) => (
          <List.Item key={item.title}>
            <Row className="header" align="middle" justify="space-between">
              <Row align="middle">
                <div className="header-order-num">
                  订单号{" "}
                  {item.businessType == 1
                    ? item?.demandOrderVo?.orderNo
                    : item.businessType == 2
                    ? item.productOrderVo?.orderNo
                    : ""}
                </div>
                <div className="order-status">
                  {item.orderStatus == 25 && <span>已完成</span>}
                  {item.orderStatus == 1 && <span>待审核</span>}
                  {item.orderStatus == 13 && (
                    <>
                      <span className="green"> 待确认交付</span>
                      （已付款）
                    </>
                  )}
                  {item.orderStatus == 3 && <span> 已关闭</span>}
                  {item.orderStatus == 27 && <span> 已取消</span>}
                  {item.orderStatus == 11 && (
                    <>
                      <span className="yellow">
                        {item.productOrderVo?.orderStatusName}
                      </span>
                      （
                      {item.productOrderVo?.payStatus == 1
                        ? "待付款"
                        : item.productOrderVo?.payStatusName}
                      ）
                    </>
                  )}
                  {item.orderStatus == 5 && (
                    <span className="green">报价中</span>
                  )}
                </div>
              </Row>
              <div className="header-order-timer">
                {item.createTime &&
                  moment(item.createTime).format("YYYY-MM-DD HH:mm")}
              </div>
            </Row>
            <Row
              align="middle"
              className="content-info"
              justify="space-between"
            >
              <Row className="left" align="middle">
                <Row>
                  {item.businessType == 1 ? (
                    <img
                      src={
                        item?.demandOrderVo?.orderPic &&
                        item?.demandOrderVo?.orderPic?.split(",")?.[0]
                      }
                      className="img"
                    />
                  ) : (
                    <Row
                      align="middle"
                      style={{
                        flexWrap: "wrap",
                        width: 304,
                      }}
                    >
                      {item?.productOrderVo.mainImageList &&
                        item?.productOrderVo.mainImageList.length > 0 &&
                        item?.productOrderVo.mainImageList.map(
                          (it: any, index: number) => (
                            <img src={it} key={index} className="img" />
                          )
                        )}
                    </Row>
                  )}
                  {item.businessType == 1 && (
                    <div className="order-title">
                      {item?.demandOrderVo?.name || ""}
                    </div>
                  )}
                </Row>
                <div className="orderType order">
                  {item.businessType == 1 ? "需求订单" : "样品订单"}
                </div>
                <div className="orderTotal order">
                  {item.businessType == 1
                    ? item.demandOrderVo?.orderNum
                    : item.businessType == 2
                    ? item.productOrderVo.productIdList.length
                    : ""}
                  件
                </div>
              </Row>
              <Row
                className="right"
                align="middle"
                justify={
                  item.payStatus == 1 &&
                  bankInfo.bankAcctType == 1 &&
                  item.orderStatus != 27
                    ? "space-between"
                    : "end"
                }
              >
                <div className="goods-price">
                  合计 ￥
                  {item.businessType == 1
                    ? item.demandOrderVo?.price
                      ? item?.demandOrderVo?.price.toFixed(2)
                      : "0.00"
                    : item.businessType == 2
                    ? item.productOrderVo?.totalAmount
                      ? item.productOrderVo?.totalAmount.toFixed(2)
                      : "0.00"
                    : "0.00"}
                </div>
                {item.payStatus == 1 &&
                  bankInfo.bankAcctType == 1 &&
                  item.orderStatus != 27 && (
                    <div
                      className="pay"
                      onClick={() => {
                        goPay(item);
                      }}
                    >
                      立即付款
                    </div>
                  )}
              </Row>
            </Row>
          </List.Item>
        )}
      />
    </OrderListWrapper>
  );
};
