import { history, useSelector } from "umi";
import { Modal } from "antd";
import { NavWrapper, LeftNavWrapper, RightContent } from "../style";
import { useState } from "react";

interface NavVo {
  id: string;
  cateName: string;
  parentName: string;
  serviceType: number;
  level: number;
  childCateList: NavVo[];
}
interface LeftNavProps {}
export const LeftNav: React.FC<LeftNavProps> = () => {
  const [visible, setvisible] = useState<boolean>(false);
  const [modal, setModal] = useState<any>({});
  const { serverCates } = useSelector((state: any) => state.server);
  const [certificateQuery] = useState([
    {
      metadataName: "证书查询",
      metadataList: [
        {
          metadataName: "时尚工程师证书",
          id: "9991",
          img: require("@/assets/images/server/fashion.png"),
        },
        {
          metadataName: "版师大联盟证书",
          id: "9992",
          img: require("@/assets/images/server/banshi.png"),
        },
        {
          metadataName: "中国服装数字化快反工厂等级认定证书",
          id: "9993",
          img: require("@/assets/images/server/chaige.png"),
        },
        {
          metadataName: "专家聘书",
          id: "9994",
        },
      ],
    },
  ]);
  return (
    <>
      <NavWrapper className="left-nav">
        <LeftNavWrapper>
          <div className=" cate-title-info">
            <span className="server-title">服务分类</span>
            <span
              className="server-more"
              onClick={() => history.push("/server/all-service")}
            >
              查看全部服务
            </span>
          </div>
          <>
            {Array.isArray(serverCates) &&
              [...serverCates, ...certificateQuery].map(
                (nav: any, index: number) => (
                  <div className="cate-info" key={index}>
                    <div className="cate-title">
                      <span>{nav.metadataName}</span>
                    </div>
                    <div className="cate-sub-list">
                      {Array.isArray(nav.metadataList) &&
                        nav.metadataList
                          .slice(0, 3)
                          .map((item: any, key: number) => (
                            <span key={key}>{item.metadataName}</span>
                          ))}
                    </div>
                    <RightContent className="right-content">
                      <div className="right-cate-title">{nav.metadataName}</div>
                      <div className="right-cate-sub-list">
                        {nav.metadataList &&
                          nav.metadataList.map((item: any, index: number) => (
                            <div
                              className="right-cate-info"
                              key={index}
                              style={{
                                width: item.parentId == 430 ? "36%" : "31%",
                              }}
                              onClick={() => {
                                if (item.id == 431) {
                                  history.push("/maintain");
                                } else if (item.id == 432) {
                                  history.push("/engineer-certification");
                                } else if (
                                  item.id == 9991 ||
                                  item.id == 9992 ||
                                  item.id == 9993
                                ) {
                                  setModal({
                                    ...item,
                                    title: item.metadataName,
                                  });
                                  setvisible(true);
                                } else if (item.id == 9994) {
                                  history.push("/expert");
                                } else {
                                  if (
                                    item.parentId != 421 &&
                                    item.parentId != 436
                                  ) {
                                    history.push({
                                      pathname: "/server/all-service",
                                      state: {
                                        serviceCateId: item.id,
                                      },
                                    });
                                  } else {
                                    console.log(
                                      item.parentId,
                                      "item.parentId "
                                    );
                                    item.parentId == 421 &&
                                      history.push("/more-consult");
                                    item.parentId == 436 &&
                                      history.push("/more-trains");
                                  }
                                }
                              }}
                            >
                              <div className="cate-title">
                                {item.metadataName}
                              </div>
                              <div className="cate-sub-list">{item.info}</div>
                            </div>
                          ))}
                      </div>
                    </RightContent>
                  </div>
                )
              )}
          </>
        </LeftNavWrapper>
      </NavWrapper>
      <Modal
        width={400}
        title={modal.title + "查询"}
        style={{ zIndex: 999999999999999, height: "328px" }}
        open={visible}
        onCancel={() => setvisible(false)}
        footer={false}
      >
        <img src={modal.img} className="modal-img" alt="二维码" />
      </Modal>
    </>
  );
};

export default LeftNav;
