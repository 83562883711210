import { AtlasListWrapper, CusTable } from "./style";
import { Button } from "antd";
import type { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "umi";
import {
  queryClusterList,
  queryParkList,
  getQueryCompanyList,
} from "@/api/park";
interface AtlasListProps {}
type DataType = {
  key: string;
  companyName: string;
  provice: string;
  address: string;
  clusterName: string;
};
const AtlasList: React.FC<AtlasListProps> = ({}) => {
  const [dataSource, setDataSource] = useState<DataType[]>();
  const { parkList, parkSearchInfo } = useSelector((state: any) => state.park);
  const dispatch = useDispatch();
  const [columns, setColumns] = useState<ColumnsType<DataType>>([
    {
      title: "序号",
      width: 80,
      dataIndex: "index",
      align: "center",
      key: "index",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "企业名称",
      align: "center",
      dataIndex: "companyName",
      key: "companyName",
      render: (_, record: any) => {
        return record.companyName || "-";
      },
    },
    {
      title: "所在地区",
      align: "center",
      dataIndex: "provice",
      key: "provice",
      render: (_, record: any) => {
        return (
          [record.province, record.city, record.area, record.contact]
            .filter((item) => item !== "")
            .join(",") || "-"
        );
      },
    },
    {
      title: "详细地址",
      align: "center",
      dataIndex: "address",
      key: "address",
      render: (_, record: any) => {
        return record.address || "-";
      },
    },
    {
      title: "产业节点",
      align: "center",
      dataIndex: "industrialNodeName",
      key: "industrialNodeName",
      render: (_, record: any) => {
        return record.industrialNodeName || "-";
      },
    },
    {
      width: 100,
      title: "操作",
      align: "center",
      key: "action",
      render: (_, record: any) => (
        <Button
          type="link"
          onClick={() => {
            dispatch({
              type: "park/saveMapCenter",
              payload: {
                lat: Number(record.latitude),
                lng: Number(record.longitude),
              },
            });
            dispatch({
              type: "park/setShowMap",
              payload: true,
            });

            if (parkList.displayType == 3) {
              dispatch({ type: "park/saveCompanyInfo", payload: record });
              dispatch({ type: "park/savebackNumber", payload: 3 });
            } else if (parkList.displayType == 2) {
              dispatch({ type: "park/saveIndustrialInfo", payload: record });
              dispatch({ type: "park/savebackNumber", payload: 2 });
            } else {
              dispatch({ type: "park/saveParkInfo", payload: record });
              dispatch({ type: "park/savebackNumber", payload: 1 });
              dispatch({
                type: "park/saveClusterList",
                payload: [record],
              });
            }
          }}
        >
          查看
        </Button>
      ),
    },
  ]);
  useEffect(() => {
    if (parkList && parkList.data && Array.isArray(parkList.data)) {
      setDataSource(parkList.data);
      if (parkList.displayType == 3) {
        columns[1] = {
          title: "企业名称",
          align: "center",
          dataIndex: "companyName",
          key: "companyName",
          render: (_, record: any) => {
            return record.companyName || "-";
          },
        };
        setColumns([...columns]);
      } else if (parkList.displayType == 2) {
        columns[1] = {
          title: "园区名称",
          align: "center",
          dataIndex: "parkName",
          key: "parkName",
          render: (_, record: any) => {
            return record.parkName || "-";
          },
        };
        setColumns([...columns]);
      } else {
        columns[1] = {
          title: "集群名称",
          align: "center",
          dataIndex: "clusterName",
          key: "clusterName",
          render: (_, record: any) => {
            return record.clusterName || "-";
          },
        };
        setColumns([...columns]);
      }
    }
  }, [parkList]);

  return (
    <AtlasListWrapper>
      <CusTable
        dataSource={dataSource}
        columns={columns}
        pagination={{
          pageSize: 10,
          total: parkList?.page?.count,
          onChange: (page: any, pageSize: any) => {
            parkSearchInfo.index = page;
            (parkList.displayType === 1
              ? queryClusterList({ ...parkSearchInfo })
              : parkSearchInfo.displayType === 2
              ? queryParkList(parkSearchInfo)
              : parkSearchInfo.displayType === 3
              ? getQueryCompanyList(parkSearchInfo)
              : queryClusterList(parkSearchInfo)
            ).then((res: any) => {
              if (res.code == 1) {
                dispatch({
                  type: "park/saveParkList",
                  payload: {
                    data: res.data,
                    displayType: parkSearchInfo.displayType,
                    page: res.page,
                  },
                });
                dispatch({
                  type: "park/saveClusterList",
                  payload: { data: [...res.data], page: res.page },
                });
              }
            });
          },
        }}
      />
    </AtlasListWrapper>
  );
};

export default AtlasList;
