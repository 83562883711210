import { Title } from "@/styles/common-style";
import { ProcessWrapper } from "../style";
import { Button } from "antd";
import ShowModal from "@/components/showModal";
import { useState } from "react";
interface ProcessProps {}
export const Process: React.FC<ProcessProps> = () => {
  const data = [
    {
      title: "认定申请",
    },
    {
      title: "认定受理",
    },
    {
      title: "认定小组成立",
    },
    {
      title: "资料分析",
    },
    {
      title: "补充递交",
    },
    {
      title: "实地调研",
    },
    {
      title: "专家认定",
    },
    {
      title: "等级复核",
    },
    {
      title: "通知结论",
    },
    {
      title: "结论公开",
    },
  ];
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <>
      <ProcessWrapper>
        <Title
          fz={36}
          fw={600}
          color="#000"
          textAlign="center"
          style={{ marginBottom: 80 }}
        >
          认定流程
        </Title>
        <div className="info">
          <div className="line"></div>
          <div className="step-list">
            {data.map((item: any, index: number) => (
              <div className="step-list-item" key={index}>
                <div className="icon">{index + 1}</div>
                <div className="text">{item.title}</div>
              </div>
            ))}
          </div>
          <div className="btn-au">
            <Button
              type="primary"
              className="step-primary"
              onClick={() => setVisible(true)}
            >
              立即申请认定
            </Button>
          </div>
        </div>
      </ProcessWrapper>
      <ShowModal
        visibleModal={visible}
        img={require("@/assets/images/maintain/scale.png")}
        onCancel={() => setVisible(false)}
      />
    </>
  );
};
