import { useSelector } from "umi";
import { ContentInfoWrapper } from "../style";
import { useEffect, useState } from "react";
import { List, Spin } from "antd";

import InfiniteScroll from "react-infinite-scroll-component";
interface ContentInfoProps {
  payFunc?: (value: string) => void;
  url: string;
}
const splitArray = (array: any[], num: number) => {
  let index = 0;
  let newArray = [];
  while (index < array.length) {
    newArray.push(array.slice(index, (index += num)));
  }
  return newArray;
};

export const ContentInfo: React.FC<ContentInfoProps> = ({ payFunc, url }) => {
  const { knowledgeDetail }: any = useSelector((state: any) => state.knowledge);
  const fileReg = /^\.png|\.jpg|\.gif/i;
  const [imgList, setImageList] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  let [num, setNum] = useState<number>(0);
  useEffect(() => {
    if (knowledgeDetail?.imageBytes?.length > 0) {
      let arr = splitArray(knowledgeDetail.imageBytes, 10);
      setImageList(arr);
      setData(arr[0]);
    } else {
      setData([]);
    }
    console.log(knowledgeDetail.imageBytes);
  }, [knowledgeDetail]);
  const loadMoreData = () => {
    let timer: any = null;
    clearTimeout(timer);
    num++;
    setNum(num);
    setTimeout(() => {
      console.log({ num });
      if (num < imgList.length) {
        console.log(data, "data", imgList[num], imgList);
        setData(data.concat(imgList[num]));
      } else {
        clearTimeout(timer);
        num == imgList.length;
      }
    }, 4000);
  };
  return (
    <>
      {knowledgeDetail && (
        <ContentInfoWrapper>
          <div className="content-wrapper" id="scrollableDiv">
            <>
              {knowledgeDetail.imageBytes &&
              knowledgeDetail.imageBytes.length > 0 ? (
                <InfiniteScroll
                  dataLength={data.length}
                  next={loadMoreData}
                  hasMore={data.length < knowledgeDetail.imageBytes.length}
                  loader={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Spin tip="加载中..." />
                    </div>
                  }
                  endMessage={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {/* 已经看完啦！ */}
                    </div>
                  }
                  scrollableTarget="scrollableDiv"
                >
                  <List
                    dataSource={data}
                    renderItem={(item: any) => (
                      <img
                        src={
                          item
                          //+ "?x-oss-process=image/resize,w_600/quality,Q_80/quality,Q_80/watermark,type_d3F5LXplbmhlaQ,size_30,text_55-l6K-G5bqT,color_333333,shadow_50,t_100,rotate_30,g_se,x_10,y_10"
                        }
                        alt="image"
                        className="pdf-img"
                      />
                    )}
                  />
                </InfiniteScroll>
              ) : // 图片类型
              fileReg.test(knowledgeDetail.annex) ? (
                <img
                  style={{
                    display: "block",
                    width: "600px",
                    margin: "100px auto",
                    height: "600px",
                  }}
                  src={knowledgeDetail.annex}
                  className="pdf-img"
                />
              ) : knowledgeDetail.previewImg ? (
                <img
                  style={{
                    display: "block",
                    width: "600px",
                    margin: "100px auto",
                    height: "600px",
                  }}
                  src={
                    knowledgeDetail.previewImg &&
                    knowledgeDetail.previewImg.includes("http")
                      ? knowledgeDetail.previewImg
                      : knowledgeDetail.coverImg
                  }
                  className="pdf-img"
                />
              ) : (
                knowledgeDetail.nofile && (
                  <div className="pdf-img" style={{ fontSize: 36 }}>
                    该文件无法预览
                  </div>
                )
              )}
            </>
          </div>
        </ContentInfoWrapper>
      )}
    </>
  );
};
