import { useEffect, useState } from "react";
import { LeftMenu } from "../style";

import { history, useSelector } from "umi";
import { getUserAuthedInfo } from "@/api/auth";
import { AccountAuditVo } from "@/models/auth";
import { GetToken } from "@/utils/common";
const PersionCommon: React.FC = () => {
  const [currentId, setCurrentId] = useState(2);
  const { user } = useSelector((state: any) => state.user);
  const [info, setInfo] = useState<AccountAuditVo>({} as AccountAuditVo);
  const token = GetToken();

  useEffect(() => {
    if (token && user.id) {
      getUserAuthedInfo(user.id).then((res: any) => {
        if (res.code == 1) {
          setInfo(res.data);
        }
      });
    } else {
      history.push("/login");
    }
  }, [token]);
  const menu = [
    {
      name: "账户管理",
      id: 1,
      path: "",
    },
    {
      name: "基本信息",
      id: 2,
      path: "/persion/base",
    },
    {
      name: "账号与安全",
      path: "/persion/security",
      id: 3,
    },
    {
      name: "实名认证",
      path: "/persion/auth",
      id: 4,
    },

    {
      name: "我的订单",
      path: "/persion/order",
      id: 52,
    },
    {
      name: "我的收藏",
      path: "/persion/my-collect",
      id: 5,
    },
    {
      name: "我的购买",
      path: "/persion/my-order",
      id: 6,
    },
    {
      name: "我的下载",
      path: "/persion/my-download",
      id: 7,
    },
  ];
  useEffect(() => {
    if (window.location.href.includes("base")) {
      setCurrentId(2);
    } else if (
      window.location.href.includes("security") ||
      window.location.href.includes("phone")
    ) {
      setCurrentId(3);
    } else if (window.location.href.includes("auth")) {
      setCurrentId(4);
    } else if (window.location.href.includes("my-collect")) {
      setCurrentId(5);
    } else if (window.location.href.includes("/persion/order")) {
      setCurrentId(52);
    } else if (window.location.href.includes("my-order")) {
      setCurrentId(6);
    } else if (window.location.href.includes("my-download")) {
      setCurrentId(7);
    }
  }, [window.location.href]);
  return (
    <LeftMenu>
      {menu.map((item, index) => (
        <div
          key={index}
          className={
            currentId === item.id && item.id !== 1
              ? "menu-item menu-item-active"
              : "menu-item"
          }
          onClick={() => {
            if (item.path == "/persion/auth") {
              if (info && info.roleType === 1) {
                history.push(
                  "/persion/auth/boss-authed/" + new Date().getTime()
                );
              } else if (info && info.roleType === 2) {
                history.push(
                  "/persion/auth/user-authed/" + new Date().getTime()
                );
              } else {
                history.push(item.path + "/" + new Date().getTime());
              }
            } else {
              item.id !== 1 && setCurrentId(item.id);
              item.path && history.push(item.path + "/" + new Date().getTime());
            }
          }}
        >
          {item.name}
        </div>
      ))}
    </LeftMenu>
  );
};

export default PersionCommon;
