import { Slider } from "antd";
import styled from "styled-components";
import bg from "@/assets/images/login/bg_login.png";

export const LoginWrapper = styled.div`
  width: 100%;
  min-width: 1440px;
  height: 100vh;
  background: url(${bg}) no-repeat center;
  background-size: 100% 100%;
`;

export const LoginHeader = styled.div`
  width: 100%;
  min-width: 1440px;
  height: 95px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .site-title {
    width: 380px;
    padding-left: 30px;
    padding-right: 40px;
    display: flex;
    font-family: Alibaba, Alibaba-Regular;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #000;
    font-weight: 400;
    .sub-title {
      font-size: 12px;
      color: #333333;
    }
  }
  .ant-btn {
    margin-right: 96px;
  }
`;

export const LoginContainer = styled.div`
  width: 1200px;
  margin: 164px auto 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .login-img {
    width: 542px;
    height: 542px;
  }
`;

export const LoginWrap = styled.div`
  /* margin-left: 246px; */
  width: 513px;
  padding-bottom: 40px;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 2px 7px 2px rgba(0, 0, 0, 0.11);
  .login-title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 82px;
    border-bottom: 1px solid #d8d8d8;
    .title {
      font-size: 24px;
      text-align: center;
    }
  }
  .ant-form {
    margin: 40px auto;
    width: 370px;
  }
  .ant-form-item {
    margin-bottom: 14px;
  }
  .code-input {
    width: 370px;
    display: flex;
    align-items: center;
    .ant-input-affix-wrapper {
      margin-right: 14px;
    }
  }
  .ant-slider {
    margin: 0;
  }
  .ant-checkbox-wrapper {
    width: 370px;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #666666;
    a {
      font-size: 14px;
      color: #666666;
    }
  }
  .regist {
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #666666;
  }
  .content-wrapper {
    height: 600px;
    overflow: auto;
  }
`;

export const CusSlider = styled(Slider)<{
  width?: number;
  height?: number;
  color?: string;
}>`
  width: ${(props) => (props.width ? props.width + "px" : "370px")};
  height: ${(props) => (props.height ? props.height + "px" : "54px")};
  .ant-slider-rail,
  .ant-slider-track {
    width: ${(props) => (props.width ? props.width + "px" : "370px")};
    height: ${(props) => (props.height ? props.height + "px" : "54px")};
  }

  .ant-slider-track,
  .ant-slider-handle {
    background-color: ${(props) => (props.color ? props.color : "#1890ff")};
  }
  .ant-slider-handle {
    margin-top: 0;
    margin-left: 16px;
    border-radius: 0;
    height: ${(props) => (props.height ? props.height + "px" : "54px")};
    width: 30px;
  }
`;
