import { getProductTree } from "@/api/server";
import { Effect, Reducer } from "umi";

export interface ServerInfoVo {
  id: string;
  cateName: string;
  parentName: string;
  serviceType: number;
  level: number;
}
export interface ServerDetailVo {
  serviceName: string;
  serviceCateId: number;
  serviceCateName: string;
  serviceSupplierId: number;
  serviceSupplierName: string;
  versionName: string;
  serviceWebUrl: string;
  brief: string;
  poster: string;
  priceType: number;
  price: number;
  deliveryMethodName: string;
  priceCompany: number;
  deliveryMethod: number;
}
export interface ServerSliceState {
  serverCates: ServerInfoVo[] | undefined;
  serverDetail: ServerDetailVo | undefined;
}

interface ServerSliceType {
  namespace: "server";
  state: ServerSliceState;
  effects: {
    getServerCastes: Effect;
  };
  reducers: {
    saveServerCaste: Reducer<ServerSliceState>;
    saveDetails: Reducer<ServerDetailVo>;
  };
}

const ServerSlice: ServerSliceType = {
  namespace: "server",
  state: {
    serverCates: [],
    serverDetail: undefined,
  },
  effects: {
    *getServerCastes({ payload }: any, { call, put }: any) {
      try {
        const res = yield call(getProductTree, payload);
        if (res.code === 1) {
          yield put({
            type: "saveServerCaste",
            payload: res.data.map((item: any) => {
              if (item.id == 430) {
                item = {
                  ...item,
                  metadataList: [
                    {
                      parentId: 430,
                      metadataName: "中国服装数字化快反工厂等级认定",
                      id: 431,
                    },
                    {
                      parentId: 430,
                      metadataName: "专业人才认证",
                      id: 432,
                    },
                  ],
                };
              }
              return item;
            }),
          });
        }
      } catch (e) {}
    },
  },
  reducers: {
    saveServerCaste(state: any, { payload }: any) {
      state.serverCates = [...payload];
      return {
        ...state,
      };
    },
    saveDetails(state: any, { payload }: any) {
      state.serverDetail = { ...payload };
      return {
        ...state,
      };
    },
  },
};

export default ServerSlice;
