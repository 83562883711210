// 订单状态(1-待审核，3-审核拒绝，5-审核通过,待报价，7-待签约，9-待支付，11-待交付（待发货），13-待验收(待确认交付)，15-验收拒绝，17-平台介入，19-验收通过,待支付供应商，21-待退款，23-待评价，25-订单完成，27-取消订单, 29-已删除)
export enum OrderStatus {
  PUBLISHED = 1,
  REVIEW_REFUSED = 3,
  WAITING_OFFER = 5,
  WAITING_SIGNING = 7,
  WAITING_PAY = 9,
  WAITING_FACTORY_DELIVER = 11,
  WAITING_CUSTOMER_EXAM = 13,
  EXAM_REFUSE = 15,
  PLATFORM = 17,
  WAITING_MONEY_TO_FACTORY = 19,
  WAITING_REFUND = 21,
  WAIT_COMMENT = 23,
  FINISHED = 25,
  CANCELED = 27,
}

//支付状态(1-待付款，3-付款待审核，5-已付款)
export enum PayStatus {
  FOR_THE_PAYMENT = 1,
  PAYMENT_PENDING_REVIEW = 3,
  PAYMENT_HAS_BEEN = 5,
}
