const CryptoJS = require("crypto-js");

const _KEY_STR = "CHACOLLEGE@#2022";
const _KEY = CryptoJS.enc.Utf8.parse(_KEY_STR);

export default function encrypt(
  origin: string
): { encrypt: string; iv: string } {
  let iv_str = Date.now().toString() + "000";
  // iv_str = '1630475010379000';
  let iv = CryptoJS.enc.Utf8.parse(iv_str);

  let srcs = CryptoJS.enc.Utf8.parse(origin);
  let encrypted = CryptoJS.AES.encrypt(srcs, _KEY, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.ZeroPadding,
  });
  let hex = encrypted.ciphertext.toString();
  let rst = hexToBase64(hex);

  return {
    encrypt: rst,
    iv: iv_str,
  };
}

function _b2a(bin: any) {
  let base64 = [];
  let len = bin.length / 3;
  let i = 0;
  let j = 0;
  const tableStr =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
  const table = tableStr.split("");
  for (; i < len; ++i) {
    const a = bin.charCodeAt(j++),
      b = bin.charCodeAt(j++),
      c = bin.charCodeAt(j++);
    if ((a | b | c) > 255) {
      throw new Error("String contains an invalid character");
    }
    // @ts-ignore
    base64[base64.length] =
      table[a >> 2] +
      table[((a << 4) & 63) | (b >> 4)] +
      (isNaN(b) ? "=" : table[((b << 2) & 63) | (c >> 6)]) +
      (isNaN(b + c) ? "=" : table[c & 63]);
  }
  return base64.join("");
}

function hexToBase64(str: any) {
  return _b2a(
    String.fromCharCode.apply(
      null,
      str
        .replace(/\r|\n/g, "")
        .replace(/([\da-fA-F]{2}) ?/g, "0x$1 ")
        .replace(/ +$/, "")
        .split(" ")
    )
  );
}
