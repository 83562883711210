import { getFishboneTreeNodeCount } from "@/api/park";
import Tool from "@/components/tool";
import { Title } from "@/styles/common-style";
import { Row } from "antd";
import { useEffect, useState } from "react";
import { AtlasRelationWrapper } from "../../style";
import FishboneChart from "./fishbone-chart";

// import * as echarts from "echarts"
// import ReactEcharts from "echarts-for-react"
interface AtlasRelationProps {}

const AtlasRelation: React.FC<AtlasRelationProps> = () => {
  const [fullscreen, setFullscreen] = useState<boolean>(false);

  const [nodeTotal, setNodeTotal] = useState(0);
  const [coverTotal, setCoverTotal] = useState(0);

  useEffect(() => {
    getFishboneTreeNodeCount().then((res) => {
      setNodeTotal(res.data.nodeTotal);
      setCoverTotal(res.data.coverTotal);
    });
  }, []);

  // const getOptionTwo = () => {
  //   let optionTwo = {
  //     title: {
  //       text: "药品各类别销量统计",
  //       subtext: "（单位：件/年）",
  //       left: "center",
  //     },
  //     tooltip: {
  //       trigger: "item",
  //     },
  //     legend: {
  //       orient: "vertical",
  //       left: "left",
  //     },
  //     series: [
  //       {
  //         name: "销售量",
  //         type: "pie",
  //         radius: "50%",
  //         data: [
  //           { value: 1048, name: "颗粒剂" },
  //           { value: 735, name: "丸剂" },
  //           { value: 580, name: "散剂" },
  //           { value: 484, name: "酊剂" },
  //           { value: 300, name: "片剂" },
  //           { value: 500, name: "胶囊剂" },
  //         ],
  //         emphasis: {
  //           itemStyle: {
  //             shadowBlur: 10,
  //             shadowOffsetX: 0,
  //             shadowColor: "rgba(0, 0, 0, 0.5)",
  //           },
  //         },
  //       },
  //     ],
  //   }
  //   return optionTwo
  // }

  return (
    <AtlasRelationWrapper fullscreen={fullscreen}>
      <Row align="middle" justify="space-between">
        <Title className="title">产业链图谱</Title>
        <Tool
          onClick={() => {
            setFullscreen(!fullscreen);
            document.body.style.overflow = !fullscreen ? "hidden" : "auto";
          }}
        />
      </Row>

      <Row style={{ marginBottom: 20, marginLeft: 50 }}>
        <div>
          <span style={{ fontSize: 18 }}>总结点数</span>
          <span style={{ fontSize: 18 }}>{nodeTotal}</span>
        </div>
        <div style={{ marginLeft: 20 }}>
          <span style={{ fontSize: 18 }}>覆盖点数</span>
          <span style={{ fontSize: 18 }}>{coverTotal}</span>
        </div>
      </Row>
      <FishboneChart fullscreen={fullscreen} />
      {/* <ReactEcharts
        option={getOptionTwo()}
        style={{ height: "calc(100% - 97px)", width: "100%" }}
      /> */}
    </AtlasRelationWrapper>
  );
};

export default AtlasRelation;
