import CusTabbar, { TabProps } from "@/components/cus-tabbar";
import Title from "@/components/title";
import { Metting } from "@/models/metting";
import { getMettingList } from "@/utils/api";
import { Row, List, Divider, Spin } from "antd";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { history } from "umi";
interface MettingCatiesProps {}
const MettingCaties: React.FC<MettingCatiesProps> = () => {
  const [meetingType, setmeetingType] = useState<string | number>("1");
  const [mettings, setmettings] = useState<Metting[]>([]);
  const [totals, setTotals] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  let index = 1;
  const dataSource = [
    { label: "产业峰会", key: "1" },
    { label: "行业大赛", key: "2" },
    { label: "专业培训", key: "3" },
  ];
  const getMettings = (index: number, type: string | number) => {
    const params = {
      index: index,
      size: 10,
      meetingType: type,
      exhibitionClient: "1",
    };
    setLoading(true);
    getMettingList(params).then((res: any) => {
      if (res.code == 1) {
        setLoading(false);
        setmettings(
          type == meetingType ? [...mettings, ...res.data] : [...res.data]
        );
        setTotals(res.page?.count || 0);
      } else {
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    setmettings([]);
    getMettings(1, "1");
  }, []);
  const onTabItemClick = (data: TabProps) => {
    setmeetingType(data.key);
    setmettings([]);
    getMettings(1, data.key);
  };
  const navigato = (item: Metting) => {
    history.push(`/trains/trains-details/${item.id}`);
  };
  const loadMoreData = () => {
    index++;
    getMettings(index, meetingType);
  };
  return (
    <div className="caties">
      <CusTabbar dataSource={dataSource} onTabItemClick={onTabItemClick} />
      {/* <Row>
        {mettings &&
          mettings.map((item) => (
            <div
              className="caties-item-wrap"
              key={item.id}
              onClick={() => navigato(item)}
            >
              <Row className="caties-image-wrap">
                <div
                  style={{
                    width: "100%",
                    height: "250px",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundImage: `url(${item.poster})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
               
                </div>
              </Row>
              <div className="caties-card">
                <Row justify="space-between" align="middle">
                  <Title
                    text={item.meetingName}
                    titleStyle={{
                      width: "calc(100% - 180px)",
                      fontSize: "26px",
                    }}
                  />
                  <span
                    className={
                      item.meetingStatus == "进行中"
                        ? "caties-item-button caties-item-button-active"
                        : "caties-item-button"
                    }
                  >
                    {item.meetingStatus}
                  </span>
                </Row>
                <Row className="caties-item-date">{item.startTime}</Row>
                <Row
                  className="over-clip"
                  dangerouslySetInnerHTML={{ __html: item.introduction || "-" }}
                ></Row>
              </div>
            </div>
          ))}
      </Row> */}
      <div className="caties-container">
        <InfiniteScroll
          dataLength={mettings?.length}
          next={loadMoreData}
          loader={<></>}
          hasMore={mettings.length < totals}
          endMessage={
            mettings.length > 10 && <Divider plain>我是有底线的</Divider>
          }
        >
          <List
            dataSource={mettings}
            loading={loading}
            renderItem={(item) => (
              <List.Item key={item.id}>
                <div
                  className="caties-item-wrap"
                  style={{
                    margin: "0 6px 38px",
                  }}
                  key={item.id}
                  onClick={() => navigato(item)}
                >
                  <Row className="caties-image-wrap">
                    <div
                      style={{
                        width: "100%",
                        height: "250px",
                        borderRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundImage: `url(${item.poster})`,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                    >
                      {/* <img
                    src={item.poster}
                    style={{ height: "380px", width: "100%" }}
                  /> */}
                    </div>
                  </Row>
                  <div className="caties-card">
                    <Row justify="space-between" align="middle">
                      <Title
                        text={item.meetingName}
                        titleStyle={{
                          width: "calc(100% - 180px)",
                          fontSize: "26px",
                        }}
                      />
                      <span
                        className={
                          item.meetingStatus == "进行中"
                            ? "caties-item-button caties-item-button-active"
                            : "caties-item-button"
                        }
                      >
                        {item.meetingStatus}
                      </span>
                    </Row>
                    <Row className="caties-item-date">{item.startTime}</Row>
                    <Row
                      className="over-clip"
                      dangerouslySetInnerHTML={{
                        __html: item.introduction || "-",
                      }}
                    ></Row>
                  </div>
                </div>
              </List.Item>
            )}
          />
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default MettingCaties;
