import { FC } from "react";
import { VideoWrapper } from "./style";
import poster from "@/assets/images/home/video-bg.jpg";
import { base_image_oss } from "@/utils/common";
import { Title } from "@/styles/common-style";
interface IProps {
  isLinpin: boolean;
}

const Index: FC<IProps> = ({ isLinpin, ...props }) => {
  const onContextMenu = (e: any) => {
    e.preventDefault();
  };
  return isLinpin ? (
    <VideoWrapper>
      <div className="video-content">
        <Title textAlign="center" className="video-title">
          临平时尚（服装）产业数智平台
        </Title>
        <div className="video">
          <video
            src={base_image_oss + "home/video.mp4"}
            controls
            onContextMenu={onContextMenu}
            poster={poster}
            controlsList="nofullscreen nodownload noremoteplayback"
          />
        </div>
      </div>
    </VideoWrapper>
  ) : null;
};

export default Index;
