import { Row, Button } from "antd";
import { useEffect, useState } from "react";
import { history, useHistory, useSelector } from "umi";
import DropMenu from "../drop-menu";
import { GlobalStyle } from "@/styles/global";
// import "./base-header.less"
import { BaseHeaderWrapper } from "./style";
import { HeaderTitle } from "../header-title";
import HeaderLogin from "../header-login";
import { NavigationWithAuth } from "@/utils/common";
const BaseHeader: React.FC = () => {
  const [activeMenu, setactiveMenu] = useState<string>("1");
  const { location } = useHistory();
  const [showLeftMenu, setshowLeftMenu] = useState<boolean>(false);
  const MENUS = [
    {
      key: "1",
      path: "/",
      title: "首页",
    },
    {
      key: "2",
      path: "",
      title: "产品及服务",
      subMenu: <DropMenu showLeftMenu={showLeftMenu} />,
    },
    {
      key: "3",
      path: "/solution",
      title: "解决方案",
    },
    {
      key: "4",
      path: "/expert",
      title: "专家中心",
    },
    {
      key: "5",
      path: "/case-center",
      title: "案例中心",
    },
    {
      key: "6",
      path: "/economic/economic-detail",
      title: "经济监测",
    },
    {
      key: "7",
      path: "/atlas",
      title: "产业图谱",
    },
    {
      key: "9",
      path: "/knowledge",
      title: "知识图谱",
    },
    {
      key: "8",
      path: "/trains",
      title: "会议培训",
    },
  ];
  const [fixed, setFixed] = useState(false);
  useEffect(() => {
    if (MENUS.some((item) => item.path === location.pathname)) {
      const obj = MENUS.filter((item) => item.path === location.pathname)[0];
      setactiveMenu(obj.key);
    }
    if (location.pathname.includes("trains/trains-details/")) {
      setactiveMenu("8");
    }
    if (location.pathname.includes("economic-detail")) {
      setactiveMenu("6");
    }
    if (
      location.pathname.includes("ecological") ||
      location.pathname.includes("data-assets") ||
      location.pathname.includes("order-platform") ||
      location.pathname.includes("capacity-center") ||
      location.pathname.includes("d-center") ||
      location.pathname.includes("maintain") ||
      location.pathname.includes("more-trains") ||
      location.pathname.includes("engineer-certification")
    ) {
      setactiveMenu("");
    }
    if (location.pathname.includes("case")) {
      setactiveMenu("5");
    }
  }, [location]);
  const navigateTo = ({ ...data }) => {
    if (data.key == "6" || data.key == "7") {
      NavigationWithAuth(data.path);
    } else {
      data.path && history.push(data.path);
    }
  };
  const setClassName = (key: string | number) => {
    if (activeMenu === key) {
      return key === "2" || key === "3"
        ? "base-menu-item base-menu-item-drop base-menu-active-item"
        : "base-menu-item base-menu-active-item";
    } else {
      return key === "2" || key === "3"
        ? "base-menu-item base-menu-item-drop"
        : "base-menu-item";
    }
  };
  useEffect(() => {
    window.addEventListener(
      "scroll",
      (e) => {
        let scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        if (scrollTop > 95) {
          if (location.pathname.includes("atlas")) {
            setFixed(false);
          } else {
            setFixed(true);
          }
        } else {
          setFixed(false);
        }
        setshowLeftMenu(false);
      },
      false
    );
    return () => {
      window.removeEventListener("scroll", () => {});
    };
  }, []);
  return (
    <BaseHeaderWrapper isfixed={fixed}>
      <GlobalStyle />
      <Row className="base-menu">
        <HeaderTitle />
        <ul className="base-menu-wrap">
          {MENUS.map((item) => (
            <li
              className={setClassName(item.key)}
              key={item.key}
              onMouseOver={() => item.key == "2" && setshowLeftMenu(true)}
              // onMouseOut={() => setshowLeftMenu(false)}
            >
              <div
                onClick={() => navigateTo({ ...item })}
                className="base-menu-container"
              >
                <span className="base-menu-item-title">{item.title}</span>
                {item.subMenu && item.subMenu}
              </div>
            </li>
          ))}
        </ul>
      </Row>
      <Row align="middle" justify="center" className="sign-login">
        <HeaderLogin />
        {/* <span className="tel">400-662 -1521</span> */}
      </Row>
    </BaseHeaderWrapper>
  );
};
export default BaseHeader;
