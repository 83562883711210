import { Title } from "@/styles/common-style";
import { MarketCusCard, MarketInfoWrapper } from "./style";

interface CatesItemProps {
  item: any;
}
const CatesItem: React.FC<CatesItemProps> = ({ item }) => {
  return (
    <MarketCusCard hoverable>
      <div className="card-img">
        <img src={item.icon} alt={item.icon} />
      </div>
      <div className="card-content">
        <Title fz={16} fw={500} className="title" style={{ lineHeight: 1.2 }}>
          {item.title}
        </Title>
        <Title className="sub-title" fz={14} fw={400}>
          {item.info &&
            item.info.map((info: any, index: number) => (
              <div className="sub-info" key={index}>
                {info}
              </div>
            ))}
        </Title>
      </div>
    </MarketCusCard>
  );
};
interface MarketInfoProps {
  data: any;
}

const MarketInfo: React.FC<MarketInfoProps> = ({ data }) => {
  return (
    <MarketInfoWrapper>
      <div className="title-wrap">
        <Title fz={30}>{data.title}</Title>
      </div>
      <div className="market-info-content">
        <div className="market-info">{data.info}</div>
        <div className="market-info-list">
          {data.items &&
            data.items.map((item: any, index: any) => (
              <CatesItem item={item} key={index} />
            ))}
        </div>
      </div>
    </MarketInfoWrapper>
  );
};

export default MarketInfo;
