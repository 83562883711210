import styled from "styled-components";
import { ListWrapper } from "../style";

export const DownloadListWrapper = styled(ListWrapper)`
  .ant-list-item {
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    height: 85px;
    border: 1px solid #d8d8d8;
    .goods-title {
      max-width: 600px;
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #666666;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .reload-btn {
      width: 100px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      border: 1px solid #1848fd;
      border-radius: 2px;
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #1848fd;
    }
  }
`;
