import { useEffect, useState } from "react";
import { CusStepWrapper } from "./style";

interface StepsVo {
  id: number;
  title: string;
  content: () =>
    | React.ReactNode
    | {
        children: React.ReactNode;
        props: any;
      };
}
interface CusStepProps {
  steps: StepsVo[];
  current: number;
  width: number;
  height: number;
}
const CusStep: React.FC<CusStepProps> = (props) => {
  const [steps, setSteps] = useState<StepsVo[]>(props.steps || "");
  const [currentId, setCurrentId] = useState<number>(0);
  useEffect(() => {
    if (props.current) {
      setCurrentId(props.current);
    }
  }, [props.current]);
  return (
    <CusStepWrapper width={props.width} height={props.height}>
      <div className="steps">
        {steps &&
          steps.map((step: any, index) => (
            <div
              className={currentId >= index ? "step step-active" : "step"}
              key={step.id}
            >
              <div className="step-index">{step.id}</div>
              <div className="step-title">{step.title}</div>
            </div>
          ))}
      </div>
      <div>{steps && steps[currentId]?.content()}</div>
    </CusStepWrapper>
  );
};

export default CusStep;
