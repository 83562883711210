import { RightHeaderWrapper } from "../../style";
import { Select, Input } from "antd";
import Tool from "@/components/tool";
import { parkSearchInfoVo, useDispatch, useSelector } from "umi";
import { useEffect, useState } from "react";
import {
  getQueryCompanyList,
  queryClusterList,
  queryParkList,
} from "@/api/park";
import CateiesList from "@/components/cate-list";
import AreaSelectNew from "@/components/area";
const { Option } = Select;
interface RightHeaderProps {
  onClickAll?: () => void;
  name?: string;
  cateName?: string;
  onSelectCate?: (value: number) => void;
  onClickCates?: () => void;
}
const RightHeader: React.FC<RightHeaderProps> = ({
  onClickAll,
  name,
  cateName,
  onSelectCate,
  onClickCates,
}) => {
  const dispatch = useDispatch();
  const { showMap, fullscreen, parkSearchInfo } = useSelector(
    (state: any) => state.park
  );
  const [params, setParams] = useState<parkSearchInfoVo>(
    {} as parkSearchInfoVo
  );
  const [currentId, setCurrentId] = useState<number>(3);
  const [value, setValue] = useState<string>("浙江省/杭州市/临平区");
  const options = [
    { label: "全部规模", value: "" },
    { label: "中小企业", value: 0 },
    { label: "规上企业", value: 1 },
  ];
  const getDetaillist = [
    {
      current: 1,
      func: (name: any) =>
        queryClusterList(name).then((res: any) => {
          if (res.code == 1) {
            dispatch({
              type: "park/saveParkList",
              payload: {
                data: res.data,
                displayType: 1,
                page: res.page,
              },
            });
            dispatch({
              type: "park/saveClusterList",
              payload: { data: res.data },
            });
            dispatch({ type: "park/savebackNumber", payload: 0 });
          }
        }),
    },
    {
      current: 2,
      func: (name: any) =>
        queryParkList(name).then((res: any) => {
          if (res.code == 1) {
            // console.log("es.data", res.data);
            // dispatch({
            //   type: "park/saveParkList",
            //   payload: {
            //     data: res.data,
            //     displayType: 2,
            //     page: res.page || null,
            //   },
            // })
            dispatch({
              type: "park/saveParkList",
              payload: {
                data: res.data,
                displayType: 2,
                page: res.page,
              },
            });
            dispatch({ type: "park/saveParks", payload: res.data });
            dispatch({ type: "park/savebackNumber", payload: 51 });
          }
        }),
    },
    {
      current: 3,
      func: (name: any) =>
        getQueryCompanyList(name).then((res: any) => {
          if (res.code == 1) {
            dispatch({
              type: "park/saveParkList",
              payload: {
                data: res.data,
                displayType: 3,
                page: res.page,
              },
            });
            dispatch({ type: "park/saveParks", payload: res.data });
            dispatch({ type: "park/savebackNumber", payload: 52 });
          }
        }),
    },
  ];
  const handleChange = (value: number) => {
    setCurrentId(value);
    onSelectCate?.(value);
    // dispatch({
    //   type: "park/savePre",
    //   payload: [1],
    // })
    // params.displayType = value
    parkSearchInfo.displayType = value;
    dispatch({
      type: "park/saveParkSearchInfo",
      payload: { ...parkSearchInfo },
    });
    setParams({ ...params });

    getDetaillist
      .filter((it: any) => it.current == value)[0]
      .func({ displayType: value, index: 1, size: 10 });
  };
  useEffect(() => {
    if (parkSearchInfo.displayType) {
      setCurrentId(parkSearchInfo.displayType);
    }
    if (
      parkSearchInfo?.province ||
      parkSearchInfo?.city ||
      parkSearchInfo?.area
    ) {
      setValue(
        [parkSearchInfo?.province, parkSearchInfo?.city, parkSearchInfo.area]
          .filter((item) => item)
          .join(" / ")
      );
    }
  }, [parkSearchInfo]);
  return (
    <RightHeaderWrapper>
      <AreaSelectNew
        all
        hasNoStreet
        value={value}
        placeholder="浙江省/杭州市/临平区"
        onChange={(value) => {
          parkSearchInfo.contact = null;
          parkSearchInfo.contactCode = null;
          parkSearchInfo.area = null;
          parkSearchInfo.areaCode = null;
          parkSearchInfo.city = null;
          parkSearchInfo.cityCode = null;
          parkSearchInfo.province = null;
          parkSearchInfo.provinceCode = null;
          value &&
            Array.isArray(value) &&
            value.forEach((item: any) => {
              if (item.level == 1) {
                parkSearchInfo.province = item.name;
                parkSearchInfo.provinceCode = item.areaCode;
              } else if (item.level == 2) {
                parkSearchInfo.city = item.name;
                parkSearchInfo.cityCode = item.areaCode;
              } else if (item.level == 3) {
                parkSearchInfo.area = item.name;
                parkSearchInfo.areaCode = item.areaCode;
              } else if (item.level == 4) {
                parkSearchInfo.contact = item.name;
                parkSearchInfo.contactCode = item.areaCode;
              } else if (item.level == 5) {
                parkSearchInfo.contact = null;
                parkSearchInfo.contactCode = null;
                parkSearchInfo.area = null;
                parkSearchInfo.areaCode = null;
                parkSearchInfo.city = null;
                parkSearchInfo.cityCode = null;
                parkSearchInfo.province = null;
                parkSearchInfo.provinceCode = null;
              }
            });
          dispatch({
            type: "park/saveParkSearchInfo",
            payload: { ...parkSearchInfo },
          });
        }}
      />
      <Select
        className="select"
        style={{ width: 150 }}
        value={currentId}
        onChange={handleChange}
        dropdownStyle={{
          zIndex: 999999999,
        }}
      >
        <Option value={1}>集群</Option>
        <Option value={2}>工业园区</Option>
        <Option value={3}>企业</Option>
      </Select>

      {currentId === 3 && (
        <>
          <CateiesList
            width={150}
            value={parkSearchInfo?.companyTypeId}
            onChange={(value) => {
              parkSearchInfo.companyTypeId = value.companyTypeId;
              dispatch({
                type: "park/saveParkSearchInfo",
                payload: { ...parkSearchInfo },
              });
            }}
          />
          <Select
            defaultValue={parkSearchInfo?.scaleCompany}
            className="select"
            placeholder="全部规模"
            style={{ width: 150 }}
            dropdownStyle={{
              zIndex: 999999999,
            }}
            onChange={(value) => {
              parkSearchInfo.scaleCompany = value;
              dispatch({
                type: "park/saveParkSearchInfo",
                payload: { ...parkSearchInfo },
              });
            }}
          >
            {options.map((option) => (
              <Option value={option.value} key={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </>
      )}
      <Input
        style={{ width: 150 }}
        placeholder="请输入关键词"
        onChange={(e) => {
          if (params.displayType == 1) {
            parkSearchInfo.clusterName = e.target.value;
          } else if (params.displayType == 2) {
            parkSearchInfo.parkName = e.target.value;
          } else {
            parkSearchInfo.companyName = e.target.value;
          }
          dispatch({
            type: "park/saveParkSearchInfo",
            payload: { ...parkSearchInfo },
          });
        }}
      />
      {showMap && fullscreen && (
        <Tool
          text="退出全屏"
          onClick={() =>
            dispatch({
              type: "park/setShowMapfullscreen",
              payload: false,
            })
          }
        />
      )}
    </RightHeaderWrapper>
  );
};

export default RightHeader;
