import styled from "styled-components";
import { Tabs } from "antd";
import market_bg from "@/assets/images/home/market_bg.png";
import { CusCard } from "../product-server/style";
const { TabPane } = Tabs;
export const MarketWrapper = styled.div``;

export const MarketTabs = styled(Tabs)`
  .market-tab-title {
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    text-align: center;
    color: #666;
  }
  .ant-tabs-tab-active .market-tab-title {
    color: #333;
  }
  .ant-tabs-nav .ant-tabs-ink-bar {
    height: 5px;
  }
  .ant-tabs-nav {
    margin-bottom: 45px;
  }
  .ant-tabs-content-holder {
    width: 1200px;
    margin: 0 auto 105px;
    height: 440px;
    padding: 36px 60px 0 47px;
    box-sizing: border-box;
    background: url(${market_bg}) no-repeat center;
    background-size: 100%;
  }
`;
export const MarketTabPane = styled(TabPane)``;

export const MarketInfoWrapper = styled.div`
  display: flex;
  .title-wrap {
    margin-right: 20px;
    width: 230px;
  }
  .market-info-content {
    width: 970px;
  }
  .market-info {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-bottom: 40px;
    height: 44px;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #333333;
  }

  .market-info-list {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const MarketCusCard = styled(CusCard)`
  .ant-card-body:hover .title {
    color: #1848fd !important;
  }
  .ant-card-body {
    height: 112px;
    padding: 15px;
    box-sizing: border-box;
    justify-content: space-between;
  }
  .card-img {
    width: 34px;
    height: 34px;
    background-color: #fff;
    img {
      height: 34px;
      width: 34px;
    }
  }
  .card-content {
    width: 248px;
  }
`;
