import {
  getParkProvinceList,
  getClusterAreaList,
  getParkCityList,
  queryClusterList,
  queryParkList,
  getQueryCompanyList,
  getCompanyCityCount,
  getCompanyProvinceCount,
} from "@/api/park";
import { CSSProperties, useEffect, useState } from "react";
import { Input, Button, Row } from "antd";
import {
  AreaContent,
  SelectAreaWrapper,
  SelectTabPane,
  SelectTabs,
} from "../../style";
import { useDispatch, useSelector } from "umi";

interface SelectAreaProps {
  onClick?: (value: any) => void;
  current?: number;
  style?: CSSProperties;
  contentStyle?: CSSProperties;
}
const SelectArea: React.FC<SelectAreaProps> = ({
  onClick,
  current = 1,
  style = {},
  contentStyle = {},
}) => {
  const [selectArea, setSelectArea] = useState<any>();
  const [currentId, setCurrentId] = useState<string>("1");
  const [params, setParams] = useState<any>();
  const { parkSearchInfo } = useSelector((state: any) => state.park);
  const dispatch = useDispatch();
  console.log({ current }, parkSearchInfo.displayType);
  const [tabs, setTabs] = useState<any>([
    {
      title: "按城市",
      key: "2",
    },
    {
      title: "按省份",
      key: "1",
    },
  ]);
  useEffect(() => {
    if (parkSearchInfo.displayType === 1) {
      setCurrentId("0");
      setTabs([
        {
          title: "按城市",
          key: "0",
        },
      ]);
      getArea({ index: 1, size: 20 });
    } else {
      setCurrentId("2");
      setTabs([
        {
          title: "按城市",
          key: "2",
        },
        {
          title: "按省份",
          key: "1",
        },
      ]);
      if (current == 2) {
        getAreaCity({});
      } else if (current == 3) {
        getCompanyCity({});
      }
    }
  }, [current]);
  useEffect(() => {
    if (parkSearchInfo.displayType == "1" || !parkSearchInfo.displayType) {
      setCurrentId("1");
      setTabs([
        {
          title: "按城市",
          key: "1",
        },
      ]);
    } else {
      setCurrentId("2");
      setTabs([
        {
          title: "按城市",
          key: "2",
        },
        {
          title: "按省份",
          key: "1",
        },
      ]);
    }
  }, [parkSearchInfo.displayType]);
  useEffect(() => {
    if (parkSearchInfo.displayType == "2") {
      // setCurrentId("2")
      getAreaCity({});
    } else if (
      parkSearchInfo.displayType == "1" ||
      !parkSearchInfo.displayType
    ) {
      getArea({});
    } else if (parkSearchInfo.displayType == "3") {
      getCompanyCity({});
    }
  }, [parkSearchInfo]);
  const getDetaillist = [
    {
      current: 1,
      func: (name: any) =>
        queryClusterList(name).then((res: any) => {
          if (res.code == 1) {
            dispatch({
              type: "park/saveParkList",
              payload: {
                data: res.data,
                displayType: 1,
                page: res.page,
              },
            });
            dispatch({ type: "park/saveClusterList", payload: res.data });
            dispatch({ type: "park/savebackNumber", payload: 0 });
            dispatch({
              type: "park/saveParkSearchInfo",
              payload: { ...parkSearchInfo, ...name },
            });
          }
        }),
    },
    {
      current: 2,
      func: (name: any) =>
        queryParkList(name).then((res: any) => {
          if (res.code == 1) {
            dispatch({
              type: "park/saveParkList",
              payload: {
                data: res.data || [],
                displayType: 2,
                page: res.page,
              },
            });
            dispatch({ type: "park/saveParks", payload: res.data || [] });
            dispatch({ type: "park/savebackNumber", payload: 51 });
          }
        }),
    },
    {
      current: 3,
      func: (name: any) =>
        getQueryCompanyList(name).then((res: any) => {
          if (res.code == 1) {
            dispatch({
              type: "park/saveParkList",
              payload: {
                data: res.data || [],
                displayType: 3,
                page: res.page,
              },
            });
            dispatch({ type: "park/saveParks", payload: res.data });
            dispatch({ type: "park/savebackNumber", payload: 52 });
          }
        }),
    },
  ];
  const getAreaProvice = (params: any) => {
    getParkProvinceList(params).then((res: any) => {
      if (res.code === 1) {
        setSelectArea(
          res.data
            ? res.data.map((item: any) => {
                item.children = item;
                item = {
                  ...item,
                  tag: item.perPinYin,
                };
                return item;
              })
            : ""
        );
      }
    });
  };
  const getCompanyProvince = (params: any) => {
    getCompanyProvinceCount(params).then((res: any) => {
      if (res.code === 1) {
        setSelectArea(
          res.data
            ? res.data
                .map((item: any) => {
                  item.children = item;
                  item = {
                    ...item,
                    tag: item.perPinYin,
                  };
                  return item;
                })
                .filter((item: any) => item.province)
            : ""
        );
      }
    });
  };

  const getCompanyCity = (params: any) => {
    getCompanyCityCount(params).then((res: any) => {
      if (res.code === 1) {
        setSelectArea(
          res.data
            ? res.data.map((item: any) => {
                item = {
                  ...item,
                  tag: item.perPinYin,
                };
                return item;
              })
            : ""
        );
      }
    });
  };
  const getAreaCity = (params: any) => {
    getParkCityList(params).then((res: any) => {
      if (res.code === 1) {
        setSelectArea(
          res.data
            ? res.data.map((item: any) => {
                item = {
                  ...item,
                  tag: item.perPinYin,
                };
                return item;
              })
            : ""
        );
      }
    });
  };
  const getArea = (params: any) => {
    getClusterAreaList(params).then((res: any) => {
      if (res.code == 1 && res.data) {
        setSelectArea(
          res.data
            .map((item: any) => {
              return {
                ...item,
                province: item.city,
                name: item.city,
              };
            })
            .filter((item: any) => item.province)
        );
      } else {
        setSelectArea("");
      }
    });
  };

  const SelectAreaContent = () => {
    return (
      <AreaContent style={{ ...contentStyle }}>
        <Button
          type="link"
          className="provice"
          onClick={() => {
            onClick?.({
              name: "全国",
              cityCode: null,
              province: null,
              city: null,
              provinceCode: null,
            });
            // getLocation(item.name)
            getDetaillist
              .filter((it: any) => it.current == parkSearchInfo.displayType)[0]
              .func({ province: null, index: 1, size: 10 });
          }}
        >
          全国
        </Button>
        {selectArea &&
          selectArea.map((item: any, key: number) => (
            <Row key={key}>
              {item.province && currentId === "1" ? (
                <Button
                  type="link"
                  className="provice"
                  onClick={() => {
                    onClick?.({
                      name: item.province,
                      cityCode: null,
                      province:
                        parkSearchInfo.displayType == 1 ? null : item.province,
                      city: parkSearchInfo.displayType == 1 ? item.city : null,
                      provinceCode: null,
                    });
                    console.log({
                      cityCode: null,
                      province:
                        parkSearchInfo.displayType == 1 ? null : item.province,
                      city: parkSearchInfo.displayType == 1 ? item.city : null,
                      provinceCode: null,
                    });
                    // getLocation(item.name)
                    dispatch({
                      type: "park/saveMapCenter",
                      payload: {
                        lat: Number(item.lat),
                        lng: Number(item.lon),
                        value: 1,
                      },
                    });

                    getDetaillist
                      .filter(
                        (it: any) => it.current == parkSearchInfo.displayType
                      )[0]
                      .func({ province: item.province, index: 1, size: 10 });
                  }}
                >
                  {item.province &&
                    item.province.substring(0, 2) + "(" + item.count + ")"}
                </Button>
              ) : (
                item.tag && <Button type="text">{item.tag}</Button>
              )}
              <div className="child-link">
                {item.getCountVoList &&
                  item.getCountVoList.map((it: any, index: number) => (
                    <Button
                      key={index}
                      type="link"
                      onClick={() => {
                        // getLocation(item.name)
                        onClick?.({
                          name: it.city,
                          cityCode: it?.cityCode,
                          province: item.province,
                          city: it.city,
                          provinceCode: null,
                        });
                        dispatch({
                          type: "park/saveMapCenter",
                          payload: {
                            lat: Number(it.lat),
                            lng: Number(it.lon),
                            value: 2,
                          },
                        });
                        getDetaillist
                          .filter(
                            (it: any) =>
                              it.current == parkSearchInfo.displayType
                          )[0]
                          .func({ city: it.city, index: 1, size: 10 });
                      }}
                    >
                      {it.city.substring(0, 2) + "(" + it.count + ")"}
                    </Button>
                  ))}
              </div>
            </Row>
          ))}
      </AreaContent>
    );
  };
  const operations = (
    <Input.Search
      style={{ width: 160 }}
      onSearch={(value) => {
        setParams({ name: value });
        if (parkSearchInfo.displayType == 1) {
          getArea({
            name: value,
          });
        } else if (parkSearchInfo.displayType == 3) {
          if (currentId == "2") {
            getCompanyCity({ city: value });
          } else {
            getCompanyProvince({ province: value });
          }
        } else {
          if (currentId == "2") {
            getAreaCity({
              city: value,
            });
          } else {
            getAreaProvice({ province: value });
          }
        }
      }}
    />
  );
  return (
    <SelectAreaWrapper style={{ ...style }}>
      <SelectTabs
        type="card"
        defaultActiveKey="1"
        activeKey={currentId}
        tabBarExtraContent={parkSearchInfo.displayType != 1 && operations}
        onChange={(key) => {
          setSelectArea("");
          setCurrentId(key);
          if (parkSearchInfo.displayType == 2) {
            if (key == "2") {
              getAreaCity({});
            } else {
              getAreaProvice({});
            }
          } else if (parkSearchInfo.displayType == 3) {
            if (key == "2") {
              getCompanyCity({});
            } else {
              getCompanyProvince({});
            }
          } else {
            getArea({});
          }
        }}
      >
        {tabs &&
          tabs.map((tab: any) => (
            <SelectTabPane tab={tab.title} key={tab.key}>
              <SelectAreaContent />
            </SelectTabPane>
          ))}
      </SelectTabs>
    </SelectAreaWrapper>
  );
};

export default SelectArea;
