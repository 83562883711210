import { Title } from "@/styles/common-style";
import { useEffect, useState } from "react";
import {
  Chief,
  ChiefMeta,
  ExpertWrapper,
  ExportSeniorCard,
  ExportSeniorMeta,
  SeniorChief,
} from "./style";
import { history } from "umi";
import { getExpertList } from "@/utils/api";
import { ConsultantListType } from "@/pages/expert/components/expert-list";
interface ExpertProps {}
const Expert: React.FC<ExpertProps> = ({}) => {
  const [data, setData] = useState<ConsultantListType>(
    [] as ConsultantListType
  ); //首席
  const [seniorData, setSeniorData] = useState<ConsultantListType>(
    [] as ConsultantListType
  ); //高级顾问
  const [seniosData, setSeniosData] = useState<ConsultantListType>(
    [] as ConsultantListType
  );
  const tabs = [
    { title: "中国服装流行趋势研发与发布中心" },
    { title: "智能可穿戴研发中心" },
    { title: "中国服装数字化转型创新中心" },
  ];
  const [currentId, setCurrentId] = useState<number>(0);
  const getInfo = (params: any, cb: any) => {
    getExpertList(params).then((res: any) => {
      if (res.code === 1) {
        if (params.expertType == 1) {
          cb(
            res.data.sort(
              (a: { sort: number }, b: { sort: number }) => b.sort - a.sort
            )
          );
        } else {
          cb(res.data);
        }
      }
    });
  };
  useEffect(() => {
    getInfo(
      {
        expertType: 1,
      },
      setData
    );
    getInfo(
      {
        expertType: 2,
        index: 1,
        size: 4,
      },
      setSeniorData
    );
    getInfo(
      {
        expertType: 3,
        index: 1,
        size: 8,
      },
      setSeniosData
    );
  }, []);
  return (
    <>
      {data && data.length > 0 && (
        <ExpertWrapper>
          <Title textAlign="center">专家中心</Title>
          <Title
            textAlign="center"
            fz={18}
            color="#333"
            className="sub-title"
            fw={400}
          >
            构建国内一流的服装行业专家服务平台
          </Title>
          <div className="senior">
            <div className="senior-left">
              {data[0] && (
                <Chief
                  hoverable
                  cover={<img alt="example" src={data[0]?.expertAvatar} />}
                >
                  <ChiefMeta
                    description={
                      <>
                        <div className="meta-title">首席科学家</div>
                        <div className="name">{data[0]?.expertName}</div>
                        <div className="info info-title">
                          {data[0]?.introduction}
                        </div>
                      </>
                    }
                  />
                </Chief>
              )}
            </div>
            <div className="senior-right">
              {seniorData.length &&
                seniorData.map((item: any, index: number) => (
                  <SeniorChief
                    key={index}
                    hoverable
                    cover={<img alt="example" src={item?.expertAvatar} />}
                  >
                    <ChiefMeta
                      description={
                        <>
                          <div className="meta-title">高级顾问</div>
                          <div className="name">{item?.expertName}</div>
                          <div className="info">{item?.introduction}</div>
                        </>
                      }
                    />
                  </SeniorChief>
                ))}
            </div>
          </div>
          <div className="senior-list">
            <div className="header-nav">
              <div className="header-nav-items">
                {tabs &&
                  tabs.map((tab: any, index: number) => (
                    <Title
                      fz={18}
                      onClick={() => {
                        getInfo(
                          {
                            expertType: index + 3,
                            index: 1,
                            size: 8,
                          },
                          setSeniosData
                        );
                        setCurrentId(index);
                      }}
                      className={
                        currentId === index
                          ? "header-nav--active-title header-nav-title"
                          : " header-nav-title"
                      }
                      key={index}
                    >
                      {tab.title}
                    </Title>
                  ))}
              </div>
              <Title
                className="senior-more"
                fz={18}
                color="#666"
                onClick={() => history.push("/expert")}
              >
                查看更多
              </Title>
            </div>
          </div>
          <div className="senior-info-list">
            {seniosData.length > 0
              ? seniosData.map((item: any, index: number) => (
                  <ExportSeniorCard
                    hoverable
                    key={index}
                    cover={<img alt="example" src={item?.expertAvatar} />}
                  >
                    <ExportSeniorMeta
                      title={item?.expertName}
                      description={
                        <>
                          <div className="info">{item?.title}</div>
                          <div className="icon">
                            <img
                              src={require("@/assets/images/home/arrow_right_gray.png")}
                            />
                          </div>
                        </>
                      }
                    />
                  </ExportSeniorCard>
                ))
              : ""}
          </div>
        </ExpertWrapper>
      )}
    </>
  );
};

export default Expert;
