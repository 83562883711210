import type { Effect, Reducer } from "umi";
import {
  getParklist,
  getQueryCompanyList,
  queryClusterList,
  queryParkList,
} from "@/api/park";
export interface ParkVo {
  id: number;
  parkName: string;
  parkType: number;
  parkPic: string;
  parkIntroduction: string;
  province: string;
  provinceCode: string;
  city: string;
  cityCode: string;
  area: string;
  areaCode: string;
  address: string;
  longitude: string;
  latitude: string;
}
export interface mapCenterVo {
  lat: number;
  lng: number;
}

export interface parkSearchInfoVo {
  companyName: string;
  parkName: string;
  clusterName: string;
  companyNodeType?: number;
  displayType?: number;
  keyword?: string;
  nodeId?: number;
  provinceCode?: number;
  cityCode?: number;
  areaCode?: number;
  type?: number;
  index: number;
  size: number;
}

export interface ParkSliceState {
  parkInfo: ParkVo | null;
  parkDeatil?: ParkVo | null;
  selectArea?: any;
  mapCenter?: any;
  showMap?: boolean;
  fullscreen?: boolean;
  parkSearchInfo?: parkSearchInfoVo | null;
  parkList: any;
  clusterList: any; //集群列表
  companyInfo: any;
  industrialInfo: any; //工业园区详情
  parkId: any;
  culsterId: any;
  companyId: any;
  parks: any;
  backInfo: number;
  pre: number[];
  keyword: string;
}

interface ParkSliceType {
  namespace: "park";
  state: ParkSliceState;
  effects: {
    getParkInfo: Effect;
    getParklists: Effect;
  };
  reducers: {
    saveParkInfo: Reducer<ParkSliceState>;
    saveSelectArea: Reducer<ParkSliceState>;
    saveMapCenter: Reducer<ParkSliceState>;
    setShowMap: Reducer<ParkSliceState>;
    setShowMapfullscreen: Reducer<ParkSliceState>;
    saveParkSearchInfo: Reducer<ParkSliceState>;
    saveParkList: Reducer<ParkSliceState>;
    saveIndustrialInfo: Reducer<ParkSliceState>;
    saveCompanyInfo: Reducer<ParkSliceState>;
    saveClusterList: Reducer<ParkSliceState>;
    saveParks: Reducer<ParkSliceState>;
    savebackNumber: Reducer<ParkSliceState>;
    savesearch: Reducer<ParkSliceState>;
    savePre: Reducer<ParkSliceState>;
  };
}

const ParkSlice: ParkSliceType = {
  namespace: "park",
  state: {
    parkInfo: null,
    parkDeatil: null,
    selectArea: null,
    mapCenter: null,
    showMap: true,
    fullscreen: false,
    parkSearchInfo: {
      index: 1,
      size: 10,
      provinceCode: 330000,
      cityCode: 330100,
      area: "临平区",
      displayType: 3,
      areaCode: 330113,
      province: "浙江省",
      city: "杭州市",
    },
    backInfo: -1,
    pre: [1],
    clusterList: null,
    parkList: null,
    industrialInfo: null,
    companyInfo: null,
    parkId: null,
    culsterId: null,
    companyId: null,
    parks: null,
    keyword: "",
  },
  effects: {
    *getParkInfo({ payload }: any, { call, put }: any) {
      try {
        const res = yield call(getParklist, payload);
        if (res.code == 1) {
          yield put({
            type: "saveParkList",
            payload: { data: res.data, displayType: payload.displayType },
          });
        }
      } catch (error) {}
    },
    *getParklists({ payload }: any, { call, put }: any) {
      try {
        const featchList =
          payload.displayType && payload.displayType === 1
            ? queryClusterList({ ...payload })
            : payload.displayType === 2
            ? queryParkList(payload)
            : payload.displayType === 3
            ? getQueryCompanyList(payload)
            : queryClusterList(payload);
        const res = yield call(featchList, payload);
        if (res.code == 1) {
          yield put({
            type: "saveParkList",
            payload: { data: res.data, displayType: payload.displayType },
          });
        }
      } catch (error) {}
    },
  },
  reducers: {
    saveParkInfo(state: any, { payload }: any) {
      state.parkInfo = payload;
      return {
        ...state,
      };
    },
    saveIndustrialInfo(state: any, { payload }: any) {
      state.industrialInfo = payload;
      return {
        ...state,
      };
    },
    saveCompanyInfo(state: any, { payload }: any) {
      state.companyInfo = payload;
      return {
        ...state,
      };
    },
    saveSelectArea(state: any, { payload }: any) {
      state.selectArea = [...payload];
      return {
        ...state,
      };
    },
    saveMapCenter(state: any, { payload }: any) {
      state.mapCenter = payload;
      return {
        ...state,
      };
    },
    setShowMap(state: any, { payload }: any) {
      state.showMap = payload;
      return {
        ...state,
      };
    },
    setShowMapfullscreen(state: any, { payload }: any) {
      state.fullscreen = payload;
      return {
        ...state,
      };
    },
    saveParkSearchInfo(state: any, { payload }: any) {
      state.parkSearchInfo = { ...payload };
      return {
        ...state,
      };
    },
    saveParkList(state: any, { payload }: any) {
      state.parkList = { ...payload };
      return {
        ...state,
      };
    },
    saveClusterList(state: any, { payload }: any) {
      state.clusterList = { ...payload };
      return {
        ...state,
      };
    },
    saveParks(state: any, { payload }: any) {
      state.parks = [...payload];
      return {
        ...state,
      };
    },
    savebackNumber(state: any, { payload }: any) {
      state.backInfo = payload;
      return {
        ...state,
      };
    },
    savePre(state: any, { payload }: any) {
      state.pre = [...payload];
      return {
        ...state,
      };
    },
    savesearch(state: any, { payload }: any) {
      state.keyword = payload;
      return {
        ...state,
      };
    },
  },
};

export default ParkSlice;
