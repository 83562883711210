import BaseLayout from "@/layouts";
import { CusTabPane, CusTabs, CusVideo, EconomicDetailWrapper } from "./style";
import { useParams } from "umi";
import { useState } from "react";
import { base_image_oss } from "@/utils/common";
interface EconomicDetailProps {}
export const EconomicDetail: React.FC<EconomicDetailProps> = () => {
  // const tabs = [
  //   {
  //     tab: "政府侧",
  //     key: "1",
  //     url: "https://workspace.easyv.cloud/shareScreen/eyJzY3JlZW5JZCI6MzUyNDAwfQ==",
  //   },
  //   {
  //     tab: "订单平台",
  //     key: "2",
  //     url: "https://workspace.easyv.cloud/shareScreen/eyJzY3JlZW5JZCI6MzM3MzI1fQ==",
  //   },
  //   {
  //     tab: "数据资产平台",
  //     key: "3",
  //     url: "https://workspace.easyv.cloud/shareScreen/eyJzY3JlZW5JZCI6MzUwODkxfQ==",
  //   },
  //   {
  //     tab: "产能中心",
  //     key: "4",
  //     url: "https://workspace.easyv.cloud/shareScreen/eyJzY3JlZW5JZCI6MzQxMzg1fQ==",
  //   },
  //   {
  //     tab: "服务中心",
  //     key: "5",
  //     url: "https://workspace.easyv.cloud/shareScreen/eyJzY3JlZW5JZCI6MzM3NzYwfQ==",
  //   },
  //   {
  //     tab: "研发中心",
  //     key: "6",
  //     url: "https://workspace.easyv.cloud/shareScreen/eyJzY3JlZW5JZCI6MzM3MzU5fQ==",
  //   },
  //   {
  //     tab: "产业园区",
  //     key: "7",
  //     url: "https://workspace.easyv.cloud/shareScreen/eyJzY3JlZW5JZCI6ODA4MjQ1fQ==",
  //   },
  // ];
  const tabs = [
    {
      tab: "政府侧",
      key: "1",
      url: base_image_oss + "screen/政府侧_橙织.mov",
    },
    {
      tab: "订单平台",
      key: "2",
      url: base_image_oss + "screen/订单平台_橙织.mov",
    },
    {
      tab: "数据资产平台",
      key: "3",
      url: base_image_oss + "screen/数据资产平台_橙织.mov",
    },
    {
      tab: "产能中心",
      key: "4",
      url: base_image_oss + "screen/产能中心_橙织.mov",
    },
    {
      tab: "服务中心",
      key: "5",
      url: base_image_oss + "screen/服务中心_橙织.mov",
    },
    {
      tab: "研发中心",
      key: "6",
      url: base_image_oss + "screen/研发中心_橙织.mov",
    },
    {
      tab: "产业园区",
      key: "7",
      url: base_image_oss + "screen/产业园区_橙织.mov",
    },
  ];
  const params = useParams<{ id: string }>();
  const [currentId, setCurrentId] = useState<string>("1");

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   if (params.id) {
  //     setCurrentId(params.id);
  //   }
  // }, [params.id]);
  return (
    <BaseLayout footer={false}>
      <EconomicDetailWrapper>
        <CusTabs
          activeKey={currentId}
          centered
          animated
          tabPosition="bottom"
          onChange={(key: string) => setCurrentId(key)}
        >
          {tabs &&
            tabs.map((tab) => (
              <CusTabPane tab={tab.tab} key={tab.key}>
                <CusVideo src={tab.url}></CusVideo>
              </CusTabPane>
            ))}
        </CusTabs>
      </EconomicDetailWrapper>
    </BaseLayout>
  );
};

export default EconomicDetail;
