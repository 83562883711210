import { getheadCompany, getheadSupplier, getScaleCompany } from "@/api/home";
import { CusButton, Title } from "@/styles/common-style";

import { useEffect, useState } from "react";
import { history } from "umi";
import { SeverTabPane, SeverTabs, SeverWrapper } from "./style";
import { Button } from "antd";
import ServiceProvider from "./components/server";
interface ServerPartProps {}
type TabsItem = {
  title: string;
  key: string;
};
type TabsContentType = {
  supplierName?: string;
  logUrl?: string;
  id?: number;
  companyName?: string;
  companyTypeId?: string;
  companyTypeName?: string;
  parkId?: number;
  parkName?: string;
  companyPic: string;
  companyIntroduction: string;
  province?: string;
  provinceCode?: string;
  city?: string;
  cityCode?: string;
  area?: string;
  areaCode?: string;
  address?: string;
  longitude?: string;
  latitude?: string;
  clusterId?: number;
  industrialNodeId?: number;
  createTime?: string;
  updateTime?: string;
};
const ServerPart: React.FC<ServerPartProps> = () => {
  const [tabs] = useState<TabsItem[]>([
    {
      title: "龙头企业",
      key: "0",
    },
    { title: "规上企业", key: "1" },
  ]);
  const [tabsContent, setTabsContent] = useState<TabsContentType[]>([]);

  const [currentId, setCurrentId] = useState<string>("0");
  const [currentTab, setCurrentTab] = useState<TabsContentType>(
    {} as TabsContentType
  );
  const [currentTabId, setCurrentTabId] = useState<number>(0);
  const tabsCon: TabsContentType[] = [
    {
      companyPic: require("@/assets/images/home/company_logo1.png"),
      companyName: "杭州意丰歌服饰有限公司",
      companyIntroduction:
        "伊芙丽集团（杭州意丰歌服饰有限公司）成立于2001年，旗下有四个品牌，“EIFINI(伊芙丽)”、“SEIFINI(诗凡黎)”、“MM(麦檬)”、“Pure:Moment:”，或优雅，或俏皮，或简约，或舒适。作为服装界娱乐营销的发起者，集团擅长用市场语言构建品牌战略规划，致力于塑造有潜力价值的品牌形象。",
    },
    {
      companyPic: require("@/assets/images/home/company_logo2.png"),
      companyName: "浙江米居梦家纺股份有限公司",
      companyIntroduction:
        "浙江米居梦家纺股份有限公司（原杭州中亚布艺有限公司）成立于1999年，是一家专业生产沙发布、沙发套、床上用品、抱枕、窗帘、沙发等一系列装饰家纺用品的公司。",
    },
    {
      companyPic: require("@/assets/images/home/company_logo3.png"),
      companyName: "众望布艺股份有限公司",
      companyIntroduction:
        "众望布艺股份有限公司成立于1994年，地处浙江杭州，目前公司旗下拥有：杭州众望化纤有限公司，Z-WOVENS,LLC.，公司主营业务为中高档装饰面料及制品的研发、设计、生产与销售。本着“用布艺来装扮世界，并影响世界”的宗旨，通过二十几年的努力，公司已经成为中国布艺行业中最具有核心竞争力的企业之一。 公司已获得国家高新技术企业、浙江省企业技术中心认定，多次获得中国家用纺织品行业协会评选的年度中国布艺家居十大竞争力品牌（金销奖）。",
    },
    {
      companyPic: require("@/assets/images/home/company_logo4.png"),
      companyName: "浙江纳雅服饰有限公司",
      companyIntroduction:
        "浙江纳雅服饰有限公司是一家集设计、生产、销售于一体的专业女装公司。目前旗下主打品牌“DESIGNICE ”-迪赛尼斯，产品涉及服装、鞋类、箱包、围巾、饰品等。",
    },
    {
      companyPic: require("@/assets/images/home/company_logo5.png"),
      companyName: "杭州嘉溢制衣有限公司",
      companyIntroduction:
        "公司位于素有“人间天堂”美誉的浙江杭州，环境优美，交通便捷。经过多年的不断发展，创新，目前公司占地七十余亩，拥有职工千余人，并引进了各类先进的生产，加工设备，是一家专业生产各类外贸服装的企业。公司自上世纪九十年代初成立至今，公司规模日益壮大，业务量持续增长。承接了许多如：HM，LA REDOUTE，Lane Bryant，MATALAN,ONLY等知名品牌，产品主要以丝绸，棉，麻，化纤为主要面料的梭织服装，并100%出口到欧洲，美国等世界各地。",
    },
    {
      companyPic: require("@/assets/images/home/company_logo9.png"),
      companyName: "杭州奥坦斯布艺有限公司",
      companyIntroduction:
        "杭州奥坦斯布艺有限公司是一家专业生产家用纺织面料的制造企业。公司座落于浙江杭州余杭经济开发区，交通便利。公司主要产品为沙发布、窗帘布、床品布以及其它装饰用布。公司总投资1.8亿元，年销售超过3亿元人民币。目前已通过ISO9001国际质量管理体系认证，ISO14000国际环境管理体系认证和OSHAS18000职业健康管理体系。公司是国内《纺织品装饰织物》国家级标准起草单位之一，获得“全国纺织工业先进集体”称号，公司生产的“澳坦斯”牌装饰面料被评为“国家免检”、“中国名牌”，“中国驰名商标”等称号。",
    },
    {
      companyPic: require("@/assets/images/home/company_logo7.png"),
      companyName: "杭州菲摩斯纺织有限公司",
      companyIntroduction:
        "浙江菲摩斯纺织有限公司（杭州新纺布艺有限公司）是一家集设计、开发、销售为一体的装饰布面料生产企业。公司坐落于环境优美的国家级经济开发区-余杭经济技术开发区内。公司距离杭州市区20公里，萧山国际机场35公里，",
    },
    {
      companyPic: require("@/assets/images/home/company_logo8.png"),
      companyName: "杭州琪瑶纺织有限公司",
      companyIntroduction:
        "杭州琪瑶纺织有限公司成立于2007年6月，坐落于美丽的江南水乡--杭州；交通便利，距离上海浦东国际机场2小时车程，距离杭州萧山国际机场40分钟车程。公司建筑面积38500㎡，是一家专业的家用纺织品生产制造商，主要产品有：窗帘、靠垫、毯子、床品、桌布、卷帘等，产品远销世界各地的大型连锁超市。",
    },
  ];
  const getSupplierListInfo = () => {
    (currentId == "0" ? getheadCompany() : getScaleCompany()).then(
      (res: any) => {
        if (res.code == 1) {
          if (currentId == "1") {
            setTabsContent(
              res.data.length > 8 ? res.data.slice(0, 8) : res.data
            );
          } else {
            setTabsContent(tabsCon);
          }

          // setCurrentTab(res.data[0])
        }
      }
    );
  };
  useEffect(() => {
    getSupplierListInfo();
  }, [currentId]);
  return (
    <SeverWrapper>
      <Title textAlign="center" className="server-title">
        云集服装产业全领域优质服务商 满足服装企业多方面业务需求
      </Title>
      <SeverTabs
        centered
        activeKey={currentId}
        onChange={(key) => {
          setCurrentId(key);
        }}
      >
        {tabs &&
          tabs.map((item: any, index: number) => (
            <SeverTabPane tab={item.title} key={index}>
              <div className="server-content">
                {tabsContent ? (
                  tabsContent.map((it: any, key: number) => (
                    <div className="server-item">
                      <div className="server-items">
                        <div className="img-wrap">
                          <img
                            src={it?.companyPic || it.logUrl}
                            alt=""
                            className="server-logo"
                          />
                        </div>
                        <div className="header-title">
                          {it.companyName || it.supplierName}
                        </div>
                      </div>

                      <div className="server-info">
                        {/* <CusButton type='link'>了解更多</CusButton> */}
                        <div className="content-info">
                          {it.companyIntroduction || it.brief}
                        </div>
                        <Button type="primary" className="look-more">
                          了解更多
                        </Button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div style={{ color: "#999", textAlign: "center", flex: 1 }}>
                    {" "}
                    暂无数据{" "}
                  </div>
                )}
              </div>
            </SeverTabPane>
          ))}
      </SeverTabs>
      <ServiceProvider />
    </SeverWrapper>
  );
};

export default ServerPart;
