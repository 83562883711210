import ShowModal from "@/components/showModal";
import { JoinUsImg } from "@/pages/server/server-center/style";
import { CusButton } from "@/styles/common-style";
import { useState } from "react";
import { GoTrainWrapper } from "../style";

interface GoTrainProps {}
export const GoTrain: React.FC<GoTrainProps> = () => {
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <>
      <GoTrainWrapper>
        <JoinUsImg
          src={require("@/assets/images/engineer/go_train_bg.png")}
          style={{ minHeight: 220 }}
        />
        <div className="footer-content">
          <div className="info">
            时尚工程师、时尚工艺师、新媒体营销师报名入口
          </div>
          <CusButton
            type="primary"
            width={285}
            height={56}
            className="join-us-btn"
            onClick={() => {
              // history.push("/ecological")
              setVisible(true);
            }}
          >
            立即报名
          </CusButton>
        </div>
      </GoTrainWrapper>
      <ShowModal
        visibleModal={visible}
        img={require("@/assets/images/server/gj.jpg")}
        onCancel={() => setVisible(false)}
      />
    </>
  );
};
