import { CusButton } from "@/styles/common-style";
import joinUs from "@/assets/images/server/join-us.png";
import { Join, JoinUsImg } from "../style";
// import { ContactUs } from "@/utils/common";
import { history } from "umi";
import { ContactUs } from "@/utils/common";
interface JoinUSVo {
  title?: string;
  btnText?: string;
  img?: string;
  conactUs?: true;
}
export const JoinUs: React.FC<JoinUSVo> = ({
  title,
  btnText,
  img,
  conactUs = false,
}) => {
  return (
    <Join className="join-us">
      <JoinUsImg src={joinUs} />
      <div className="join-us-container">
        <div className="title">
          <img
            src={img ? img : require("@/assets/images/server/server_icon1.png")}
            style={{
              width: "40px",
              height: "40px",
              marginTop: "-50px",
            }}
          />
          <span>
            {title ||
              "已有1000+企业入驻数字时尚产业创新平台，携手共创、共享、共赢"}
          </span>
          {!img && (
            <img
              src={require("@/assets/images/server/server_icon2.png")}
              style={{ width: "40px", height: "40px", marginBottom: "-50px" }}
            />
          )}
        </div>
        <CusButton
          type="primary"
          width={210}
          height={60}
          className="join-us-btn"
          onClick={() => {
            if (conactUs) {
              ContactUs(title);
            } else {
              history.push("/ecological");
            }
          }}
        >
          {btnText || "立即入驻"}
        </CusButton>
      </div>
    </Join>
  );
};
