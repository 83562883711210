import styled from "styled-components";
import { Button, Row } from "antd";

import bg3 from "@/assets/images/server/bg3.png";
import bg3_w from "@/assets/images/server/bg3_w.png";
import bg4 from "@/assets/images/server/bg4.png";
import bg4_w from "@/assets/images/server/bg4_w.png";
import bg6 from "@/assets/images/server/bg6.png";
import bg6_w from "@/assets/images/server/bg6_w.png";
import bg7_w from "@/assets/images/server/bg7_w.png";
import bg7 from "@/assets/images/server/bg7.png";
import arrowRight from "@/assets/images/server/arrow-right.png";
export const CateListWrapper = styled.div`
  position: relative;
  width: 100%;
  min-width: 1440px;
  margin-bottom: 30px;
  height: 654px;
  .img {
    width: 100%;
    height: 654px;
  }
  .bannar-slide {
    width: 100%;
    height: 654px;
    color: #fff;
    font-weight: 400;
    text-align: right;
    color: #ffffff;
  }
  .bannar-slide-title {
    font-size: 100px;
    font-family: "RuiZiRuiXianNuFangHeiJian";
    font-weight: 400;
  }
  .bannar-slide-subtitle {
    font-size: 26px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: right;
    color: #ffffff;
    letter-spacing: 20px;
  }
  .bannar-slide-row {
    position: absolute;
    top: 100px;
    width: 1300px;
    height: 400px;
    right: 50%;
    margin-right: -650px;
  }
  .swiper-pagination-bullet {
    width: 40px;
    height: 6px;
    border-radius: 2px;
  }
  .swiper-pagination-bullet-active {
    background: #d8d8d8;
  }
`;

// 分类导航
export const NavWrapper = styled(Row)`
  display: flex;
  width: 370px;
  position: absolute;
  left: 50%;
  margin-left: -600px;
  top: 40px;
  z-index: 99;
`;
export const LeftNavWrapper = styled.div`
  cursor: pointer;
  position: relative;
  background-color: #fff;
  width: 370px;
  height: 616px;
  .cate-title-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 10px 20px;
    background-color: #eeeeef;
    color: #333333;
    .server-title {
      font-family: PingFangSC, PingFangSC-Semibold;
      font-size: 24px;
      font-weight: 600;
      color: #333333;
    }
    .server-more {
      position: relative;
      font-family: PingFangSC, PingFangSC-Regular;
      font-size: 14px;
      font-weight: 400;
      padding-right: 18px;
      &::before {
        content: "";
        position: absolute;
        right: 0;
        top: 3px;
        width: 18px;
        height: 18px;
        background: url(${arrowRight}) no-repeat center;
        background-size: 100%;
      }
    }
  }
  .cate-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 80px;
    padding: 10px 10px 10px 20px;
    font-size: 18px;
    box-sizing: border-box;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #333333;
    &:hover {
      box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.11);
    }
    &:hover .right-content {
      display: block;
    }
    .cate-title span {
      position: relative;
      padding-right: 18px;
      &::before {
        content: "";
        position: absolute;
        right: 0;
        top: 3px;
        width: 18px;
        height: 18px;
        background: url(${arrowRight}) no-repeat center;
        background-size: 100%;
      }
    }
  }

  .cate-sub-list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #666666;
    span {
      margin-right: 20px;
    }
  }
`;
export const RightContent = styled.div`
  position: absolute;
  width: 830px;
  display: none;
  top: 0;
  left: 370px;
  margin-top: 58px;
  height: 558px;
  padding: 30px;

  box-sizing: border-box;
  background-color: #fff;
  .right-cate-sub-list {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }
  box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.13);
  .right-cate-info {
    width: 33%;
    height: 58px;
    margin-right: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
  .cate-title:hover {
    color: #1848fd;
  }
  .right-cate-title {
    padding-left: 10px;
    font-size: 22px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #333333;
    margin-bottom: 50px;
    border-left: 7px solid #1848fd;
  }
`;

// 分类列表
export const CusTabWrapper = styled.div`
  min-width: 1440px;
  padding-bottom: 60px;
  .ant-tabs-nav-list,
  .ant-tabs-content-holder {
    margin: 0 auto;
    width: 1200px;
  }
  .title {
    margin: 0 auto;
    width: 1200px;
    font-size: 36px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    color: #000000;
  }
  .ant-tabs {
    margin: 0 auto;
    /* width: 1200px; */
    min-width: 1440px;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 1px solid #d8d8d8;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 5px;
  }
  .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #666666;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    text-align: center;
    color: #333333;
    text-shadow: 0 0 0.25px transparent;
  }
`;

export const DigitalCateWrapper = styled.div`
  margin-top: 40px;
  padding-top: 40px;
`;

export const ManufactureCateWrapper = styled.div`
  padding-top: 60px;
  background: #eeeeef;
`;
export const ListCardWrapper = styled.div`
  display: flex;
  height: auto;
  .img-container {
    width: 304px;
    height: 438px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .cate-info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 310px;
    padding: 32px 60px 0 32px;
  }
  .cate-type {
    position: absolute;
    left: 32px;
    bottom: 20px;
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    color: #1848fd;
  }
  .cate-title {
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    font-size: 30px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    color: #333333;
  }
  .cate-intro {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    /* height: 92px; */
    text-align: left;
    color: #666666;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    /* margin-bottom: 40px; */
    img {
      width: 100%;
      height: 100%;
    }
  }
  .truncate {
    /* height: 40px; */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .look-more {
    position: absolute;
    bottom: -80px;
    left: 32px;
    width: 96px;
    height: 35px;
    border: 1px solid #1848fd;
    border-radius: 2px;
    font-size: 14px;
    background-color: transparent;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #1848fd;
  }
`;
// 金融服务
export const FinancialWrapper = styled.div`
  margin-top: 104px;
  width: 100%;
  min-width: 1440px;
  margin-bottom: 106px;
  .card-wrapper {
    cursor: pointer;
    margin: 0 auto;
    width: 1200px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
export const CardWrapper = styled.div`
  margin-top: 23px;
  width: 380px;
  /* height: 300px; */
  position: relative;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.12);
  .content {
    height: 280px;
    padding: 33px 44px 40px 32px;
    display: flex;
    /* align-items: center; */
    .card-img {
      margin-top: 30px;
      margin-right: 32px;
      width: 52px;
      height: 52px;
    }
    .title {
      font-size: 30px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      color: #333333;
    }
    .intro {
      margin-top: 12px;
      font-size: 18px;
      line-height: 25px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      color: #666666;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 6;
      overflow: hidden;
    }
  }
  .card-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 66px;
    line-height: 66px;
    background: #eaf1fb;
    border-radius: 3px;
    font-size: 24px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #1848fd;
  }
`;
export const Title = styled.div`
  font-size: 36px;
  font-family: PingFangSC, PingFangSC-Semibold;
  font-weight: 600;
  color: #000000;
`;
export const ConsulteCateWrapper = styled(FinancialWrapper)<{ all?: boolean }>`
  .wrapper {
    margin: 0 auto;
    width: 1200px;
    display: flex;
    align-items: center;
  }

  .consulte-info {
    width: 304px;
    height: 314px;
    position: relative;
    background-color: #eeeeef;
    .img {
      width: 100%;
      height: 100%;
    }
    .info {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      padding: 24px 50px 0 20px;
    }
    .intro {
      margin-bottom: 20px;
    }
  }

  .title {
    font-size: 24px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    color: #333333;
  }
  .intro {
    margin-top: 10px;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    color: #666666;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
  .first {
    -webkit-line-clamp: 5;
  }
  .btn {
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #1848fd;
  }
  .right-info {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 304px);
    height: 314px;
    background: #f8f8f8;
    .item {
      display: flex;
      flex-direction: column;
      padding: 20px 10px 0 20px;
      width: 50%;
      height: 50%;
    }
    .item:first-child,
    .item:last-child {
      background-color: ${(props) =>
        props.all ? "#f8f8f8" : "rgba(216,216,216, .63)"};
    }
    .item:first-child {
      border-right: ${(props) => (props.all ? "1px solid #d8d8d8;" : "none")};
      border-bottom: ${(props) => (props.all ? "1px solid #d8d8d8;" : "none")};
    }
    .item:nth-child(2) {
      border-bottom: ${(props) => (props.all ? "1px solid #d8d8d8;" : "none")};
    }
    .item:nth-child(3) {
      border-right: ${(props) => (props.all ? "1px solid #d8d8d8;" : "none")};
    }

    .item-wrapper {
      /* width: 222px; */
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        flex: 1;
      }
    }
  }
`;

export const CertificationWrapper = styled(FinancialWrapper)`
  .certificat-info {
    margin: 0 auto;
    width: 1200px;
    height: 200px;
    background: #f5fafd;
    display: flex;
    align-items: center;
  }
  .certificat-item {
    padding: 20px 40px 20px 40px;
    width: 50%;
    height: 200px;
    box-sizing: border-box;
    border-right: 1px solid #d8d8d8;
    &:last-child {
      border-right: 0;
    }
    .certificat-wrap {
      display: flex;
      align-items: center;
    }
    .icon {
      margin-left: 10px;
      width: 20px;
      height: 20px;
    }
    .title {
      font-size: 24px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      color: #333333;
    }
    .intro {
      font-size: 14px;
      height: 62px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      color: #666666;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
  }
`;
export const TagsButton = styled.span`
  font-size: 12px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: center;
  color: #1848fd;
`;
export const LookMoreBtn = styled(Button)`
  margin-top: 64px;
  width: 96px;
  height: 35px;
  border: 1px solid #1848fd;
  border-radius: 2px;
  font-size: 14px;
  background-color: transparent;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #1848fd;
`;

export const Copartanter = styled.div`
  .co-partner-container {
    padding-top: 50px;
    padding-bottom: 50px;
    // height: 640px;
    margin-bottom: 0;
    min-width: 1440px;
    width: 100%;
    line-height: 1;
    background-color: #f5fafd;
  }
  .co-partner {
    margin: 0 auto;
    width: 1200px;
  }
  .co-partner-swiper {
    width: auto;
  }
  .co-partner-slide {
    width: 200px !important;
  }
  .co-partner-slide-wrap {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 150px;
    background: #fff;
  }
  .co-partner-slide-image {
    width: 200px !important;
    height: 100%;
  }
  .co-advantage {
    margin-bottom: 179px;
  }
  .co-card-container {
    margin: 0 auto;
    width: 1460px;
    .card-item {
      margin-bottom: 38px;
      margin-right: 30px;
      padding: 33px 22px 0;
      width: 336px;
      height: 312px;
      background: #fff;
      cursor: pointer;
      box-sizing: border-box;
      box-shadow: 0px 2px 11px 2px rgba(0, 0, 0, 0.15);
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:hover {
        margin-top: -10px;
      }
    }
  }
`;

export const JoinUsImg = styled.img`
  min-width: 1440px;
  width: 100%;
  /* height: 300px; */
  cursor: pointer;
`;

export const CateWrapper = styled.div<{
  bgColor?: string;
  len: number;
  type?: number;
}>`
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#f5fafd")};
  width: calc(100% - 304px);
  display: flex;
  flex-wrap: wrap;
  .item-cate {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: ${(props) =>
      props.len
        ? props.len == 3 || props.len == 6 || props.len == 7
          ? "33.3%"
          : props.len == 4 || props.len == 5
          ? "50%"
          : "33.3%"
        : "33.3%"};

    padding: 32px 60px 20px 32px;
    padding-bottom: ${(props) => (props.len == 3 ? "32px" : "20px")};
    border-right: 1px solid #d8d8d8;
    border-bottom: ${(props) =>
      props.len <= 3 ? "none" : "1px solid #d8d8d8"};
    background: url(bg3);
  }
  .item-cate:hover {
    background: url(${(props) =>
        props.len
          ? props.len == 3
            ? props.type == 1
              ? bg3
              : bg3_w
            : props.len == 4
            ? props.type == 1
              ? bg4
              : bg4_w
            : props.len == 5
            ? props.type == 1
              ? bg6
              : bg6_w
            : props.len == 6
            ? props.type == 1
              ? bg6
              : bg6_w
            : props.len == 7
            ? props.type == 1
              ? bg7
              : bg7_w
            : bg3
          : ""})
      no-repeat center;
    background-size: cover;
  }
  .item-cate:nth-child(
      ${(props) =>
          props.len
            ? props.len == 3 || props.len == 6 || props.len == 7
              ? "3n"
              : "2n"
            : "2n"}
    ) {
    border-right: none;
  }

  .item-cate:nth-child(
      ${(props) =>
          props.len
            ? props.len == 7
              ? "n+ 4"
              : props.len == 5
              ? "n+ 3"
              : ""
            : ""}
    ) {
    width: ${(props) =>
      props.len
        ? props.len == 7
          ? "224px"
          : props.len == 5
          ? "298px"
          : "298px"
        : "298px"};
    border-bottom: none;
  }
  .item-cate:nth-child(
      ${(props) =>
          props.len ? (props.len == 7 ? "6" : props.len == 5 ? "4" : "") : ""}
    ) {
    border-right: 1px solid #d8d8d8;
  }
  .item-cate:last-child {
    border-right: none;
  }
  .cate-sub-title {
    font-size: 24px;
  }
`;

export const Join = styled.div`
  position: relative;
  .join-us-container {
    position: absolute;
    top: 50%;
    width: 1400px;
    margin-top: -72px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 50%;
    margin-left: -700px;
  }
  .title {
    margin-bottom: 20px;
    text-align: center;
    font-size: 40px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
  }
  .join-us-btn {
    margin: 0 auto;
    font-size: 30px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
  }
`;
