import BaseLayout from "@/layouts";
import { Title } from "@/styles/common-style";
import { BgImage } from "../order-platform/style";
import { DataDesc } from "./components/data-desc";
import { DataInfo } from "./components/data-info";
import { ProductInfo } from "./components/product";
import { ApplyWrapper, DataAssetsWrapper } from "./style";
import { Button } from "antd";
import { ContactUs } from "@/utils/common";

interface DataAssetsProps {}

const DataAssets: React.FC<DataAssetsProps> = () => {
  return (
    <BaseLayout>
      <DataAssetsWrapper>
        <BgImage bg={require("@/assets/images/d-center/data_assets_bg.png")} />
        <DataInfo />
        <DataDesc />
        <ProductInfo />
        <ApplyWrapper>
          <Title>立即体验橙织数字资产平台</Title>
          <Button type="primary" onClick={() => ContactUs("申请试用")}>
            申请试用
          </Button>
        </ApplyWrapper>
      </DataAssetsWrapper>
    </BaseLayout>
  );
};

export default DataAssets;
