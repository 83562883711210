import { Title } from "@/styles/common-style";
import { AtlasListHeader } from "./atlas-list/style";
import { Input, Button, Select, Row } from "antd";
import AreaSelectNew from "@/components/area";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "umi";
import Tool from "@/components/tool";
import { RightTool } from "../style";
import select from "@/assets/images/park/select.png";
import Storage from "@/utils/storage";
import {
  getQueryCompanyList,
  queryClusterList,
  queryParkList,
} from "@/api/park";
import CateiesList from "@/components/cate-list";
interface SearchHeader {}
const SearchHeader: React.FC<SearchHeader> = ({}) => {
  const [type, setType] = useState<string>("1");
  const [fullscreen, setFullscreen] = useState<boolean>(false);
  const { parkSearchInfo } = useSelector((state: any) => state.park);
  const dispatch = useDispatch();
  const [value, setValue] = useState<string>("浙江省/杭州市/临平区");
  const { Option } = Select;
  const OptionList = [
    { label: "集群", value: 1 },
    { label: "工业园区", value: 2 },
    { label: "企业", value: 3 },
  ];
  const option = [
    { label: "全部规模", value: "" },
    { label: "中小企业", value: 0 },
    { label: "规上企业", value: 1 },
  ];
  const [request, setRequest] = useState<any>({
    index: 1,
    size: 10,
  });
  useEffect(() => {
    saveInfo({});
    if (
      parkSearchInfo?.province ||
      parkSearchInfo?.city ||
      parkSearchInfo?.area
    ) {
      console.log(
        [parkSearchInfo?.province, parkSearchInfo?.city, parkSearchInfo.area]
          .filter((item) => item)
          .join(" / "),
        "123"
      );
      setValue(
        [parkSearchInfo?.province, parkSearchInfo?.city, parkSearchInfo.area]
          .filter((item) => item)
          .join(" / ")
      );
    }
  }, [parkSearchInfo]);
  const saveInfo = (values: any) => {
    Storage.set(`list-page`, 1);
    const param: any = {
      ...parkSearchInfo,
    };
    // if (request.displayType == 1) {
    //   param.clusterName = request.keyword
    // } else if (request.displayType == 2) {
    //   param.parkName = request.keyword
    // } else {
    //   param.companyName = request.keyword
    // }

    // dispatch({ type: "park/saveParkSearchInfo", payload: { ...param } })
    (param.displayType === 1
      ? queryClusterList({ ...param })
      : param.displayType === 2
      ? queryParkList({ ...param, index: param.index + 1, size: 20 })
      : param.displayType === 3
      ? getQueryCompanyList({ ...param, index: param.index + 1, size: 20 })
      : getQueryCompanyList({ ...param, index: param.index + 1, size: 20 })
    ).then((res: any) => {
      if (res.code == 1) {
        dispatch({
          type: "park/saveParkList",
          payload: {
            data: res.data,
            displayType: param.displayType,
            page: res.page,
          },
        });
        if (request.displayType === 1) {
          dispatch({
            type: "park/saveClusterList",
            payload: { data: res.data },
          });
          // if (res.data.length == 1) {
          //   dispatch({ type: "park/saveParkInfo", payload: res.data[0] })
          //   dispatch({ type: "park/savebackNumber", payload: 1 })
          //   dispatch({
          //     type: "park/saveMapCenter",
          //     payload: {
          //       lat: Number(res.data[0].latitude),
          //       lng: Number(res.data[0].longitude),
          //     },
          //   })
          // } else {
          dispatch({ type: "park/savebackNumber", payload: 0 });
          // }
        } else if (request.displayType === 2) {
          dispatch({ type: "park/savebackNumber", payload: 51 });
          dispatch({ type: "park/saveParks", payload: res.data });
        } else if (request.displayType === 3) {
          dispatch({ type: "park/saveParks", payload: res.data });
          dispatch({ type: "park/savebackNumber", payload: 52 });
        } else {
          dispatch({ type: "park/savebackNumber", payload: 52 });
          dispatch({ type: "park/saveParks", payload: res.data });
        }
      }
    });
  };
  return (
    <AtlasListHeader>
      <Row align="middle">
        <Title className="title">产业地图</Title>
        <Row>
          <div style={{ marginRight: "20px" }}>
            <Select
              defaultValue={3}
              value={request?.displayType}
              placeholder="请选择类型"
              style={{ width: 150 }}
              onChange={(value) => {
                if (value == 1) {
                  dispatch({ type: "park/savebackNumber", payload: 0 });
                } else if (value == 2) {
                  dispatch({ type: "park/savebackNumber", payload: 51 });
                } else if (value == 3) {
                  dispatch({ type: "park/savebackNumber", payload: 52 });
                } else {
                  dispatch({ type: "park/savebackNumber", payload: 52 });
                }
                parkSearchInfo.displayType = value;
                request.displayType = value;
                setRequest({ ...request });
                // dispatch({
                //   type: "park/savePre",
                //   payload: [1],
                // })
                dispatch({
                  type: "park/saveParkSearchInfo",
                  payload: { ...parkSearchInfo },
                });
              }}
            >
              {OptionList.map((option) => (
                <Option value={option.value} key={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </div>
          <div style={{ marginRight: "20px" }}>
            <AreaSelectNew
              all
              hasNoStreet
              value={value}
              placeholder={"浙江省/杭州市/临平区"}
              onChange={(value) => {
                parkSearchInfo.contact = null;
                parkSearchInfo.contactCode = null;
                parkSearchInfo.area = null;
                parkSearchInfo.areaCode = null;
                parkSearchInfo.city = null;
                parkSearchInfo.cityCode = null;
                parkSearchInfo.province = null;
                parkSearchInfo.provinceCode = null;
                value &&
                  Array.isArray(value) &&
                  value.forEach((item: any) => {
                    if (item.level == 1) {
                      parkSearchInfo.province = item.name;
                      parkSearchInfo.provinceCode = item.areaCode;
                    } else if (item.level == 2) {
                      parkSearchInfo.city = item.name;
                      parkSearchInfo.cityCode = item.areaCode;
                    } else if (item.level == 3) {
                      parkSearchInfo.area = item.name;
                      parkSearchInfo.areaCode = item.areaCode;
                    } else if (item.level == 4) {
                      parkSearchInfo.contact = item.name;
                      parkSearchInfo.contactCode = item.areaCode;
                    } else if (item.level == 5) {
                      parkSearchInfo.contact = null;
                      parkSearchInfo.contactCode = null;
                      parkSearchInfo.area = null;
                      parkSearchInfo.areaCode = null;
                      parkSearchInfo.city = null;
                      parkSearchInfo.cityCode = null;
                      parkSearchInfo.province = null;
                      parkSearchInfo.provinceCode = null;
                    }
                  });
                dispatch({
                  type: "park/saveParkSearchInfo",
                  payload: { ...parkSearchInfo },
                });
              }}
            />
          </div>
          {parkSearchInfo?.displayType == 3 && (
            <>
              <div style={{ marginRight: "20px" }}>
                <CateiesList
                  value={parkSearchInfo?.companyTypeId}
                  onChange={(value) => {
                    parkSearchInfo.companyTypeId = value.companyTypeId;
                    dispatch({
                      type: "park/saveParkSearchInfo",
                      payload: { ...parkSearchInfo },
                    });
                  }}
                />
              </div>
              <div style={{ marginRight: "20px" }}>
                <Select
                  defaultValue={parkSearchInfo?.scaleCompany}
                  placeholder="全部规模"
                  style={{ width: 150 }}
                  onChange={(value) => {
                    parkSearchInfo.scaleCompany = value;
                    dispatch({
                      type: "park/saveParkSearchInfo",
                      payload: { ...parkSearchInfo },
                    });
                  }}
                  allowClear
                >
                  {option.map((option) => (
                    <Option value={option.value} key={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </div>
            </>
          )}
          <div style={{ marginRight: "20px" }}>
            <Input
              style={{ width: 150 }}
              placeholder="请输入关键词"
              onChange={(e) => {
                if (parkSearchInfo.displayType == 1) {
                  parkSearchInfo.clusterName = e.target.value;
                } else if (parkSearchInfo.displayType == 2) {
                  parkSearchInfo.parkName = e.target.value;
                } else {
                  parkSearchInfo.companyName = e.target.value;
                }
                dispatch({
                  type: "park/saveParkSearchInfo",
                  payload: { ...parkSearchInfo },
                });
              }}
            />
          </div>
          <Button type="primary" htmlType="submit" onClick={() => saveInfo({})}>
            搜索
          </Button>
        </Row>
        {/* <Form
          layout="inline"
          form={form}
          onFinish={(values) => saveInfo(values)}
        >
          {filedData.map(
            (item: any) =>
              item && (
                <Form.Item
                  name={item.name}
                  key={item.name}
                  label={item.label}
                  rules={item.rules}
                >
                  {item.render && item.render}
                </Form.Item>
              )
          )}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              搜索
            </Button>
          </Form.Item>
        </Form> */}
      </Row>
      <RightTool>
        <Tool
          bgImage={select}
          text="切换"
          onClick={() => {
            if (type === "1") {
              setType("2");
              dispatch({
                type: "park/setShowMap",
                payload: false,
              });
            } else {
              setType("1");
              dispatch({
                type: "park/setShowMap",
                payload: true,
              });
            }
          }}
        />
        {type === "1" && (
          <Tool
            text={"全屏"}
            onClick={() => {
              // dispatch({
              //   type: "park/savebackNumber",
              //   payload: 0,
              // });
              setFullscreen(!fullscreen);
              dispatch({
                type: "park/setShowMapfullscreen",
                payload: !fullscreen,
              });
            }}
          />
        )}
      </RightTool>
    </AtlasListHeader>
  );
};

export default SearchHeader;
