import { CardWrapper } from "../../style";
import CardHeader from "./card-header";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "umi";
import SearchList from "../result-list/list1";
import { ClusterList } from "../cluster-list";
import { ParkCompanyList } from "../park-company-list";
import { ParkList } from "../park-list";
import DetaiParkList from "../result-list/list2";
import ResultList from "../result-list/index2";
interface CardWrapperProps {
  status?: string;
  val?: number;
}

const CusCard: React.FC<CardWrapperProps> = ({ val }) => {
  const [type, setType] = useState(val || 0);
  const dispatch = useDispatch();
  const [value, setValue] = useState<any>();
  const { parkInfo, backInfo } = useSelector(
    (state: { park: any }) => state.park
  );
  useEffect(() => {
    setType(backInfo);
  }, [backInfo]);
  return (
    <>
      <CardWrapper>
        <CardHeader type={type} />
        {type == 0 && <ClusterList />}
        {type == 1 && (
          <ParkCompanyList key={parkInfo?.id || 0} /> // 园区 + 企业 列表
        )}
        {type == 2 && <ParkList />}
        {/* {type == 3 && <CompanyList />} */}
        {type == 4 && <SearchList />}
        {type == 6 && <ResultList />}
        {(type + "").includes("5") && <DetaiParkList />}
      </CardWrapper>
    </>
  );
};

export default CusCard;
