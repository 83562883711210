import styled from "styled-components";
import expert_list_1 from "@/assets/images/expert/expert_list_1.png";
import { Tabs, Card, Pagination } from "antd";

const { Meta } = Card;
const { TabPane } = Tabs;
export const ExpertCenterWrapper = styled.div`
  .header-container {
    position: relative;
    width: 100%;
    min-width: 1440px;
    /* height: 400px; */
    img {
      width: 100%;
      height: 100%;
    }
    .container-info {
      width: 1200px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -128px;
      margin-left: -600px;
      .title {
        font-family: "RuiZiRuiXianNuFangHeiJian";
        font-size: 80px;
        font-weight: 400;
        line-height: 1;
        color: #ffffff;
      }
      .sub-info {
        font-size: 36px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        color: #ffffff;
        letter-spacing: 10px;
      }
      .desc {
        font-size: 18px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
        color: #ffffff;
        margin-bottom: 20px;
      }
    }
  }
  .header-search {
    .ant-input-search {
      width: 620px;
    }
    .ant-input,
    .ant-btn {
      height: 50px;
    }
    .ant-input-group-addon .ant-btn {
      width: 110px;
      font-weight: 500;
    }
    .ant-btn-lg {
      font-size: 18px;
    }
  }
`;

export const ExpertItemContainer = styled.div<{ bgImage?: string }>`
  margin: 68px auto 132px;
  width: 1200px;
  .ant-row {
    background: #141561;
    border-radius: 5px;
    align-items: center;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    padding: 38px 36px;
  }
  .img {
    /* height: 366px; */
    width: 278px;
    height: 0;
    padding-bottom: 366px;
    /* width:100%;*/
    overflow: hidden;
    background: url(${(props) => (props.bgImage ? props.bgImage : "")})
      no-repeat center;
    background-size: cover;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .expert-info {
    position: relative;
    margin-left: 30px;
    width: calc(100% - 370px);
    height: 370px;
    color: #fff;
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    .watter {
      width: 96px;
      height: 15px;
    }
    .name {
      margin-top: 22px;
      font-size: 30px;
      font-weight: 600;
    }
    .export-info-nick {
      font-size: 18px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .export-info-intro {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 7;
      overflow: hidden;
      font-size: 14px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .text-align {
      text-align: right;
    }
    .footer-img {
      position: absolute;
      width: 758px;
      bottom: 0;
    }
  }
`;

export const ExpertSenior = styled.div<{ bgImage?: string }>`
  width: 100%;
  min-width: 1440px;
  height: 700px;
  background: #fff
    url(${(props) => (props.bgImage ? props.bgImage : expert_list_1)}) no-repeat
    center;
  background-size: 100% 700px;
  .senior-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 78px 0 60px;
    width: 1200px;
  }
  .senior-centent {
    width: 766px;
  }
  .img-avatar {
    background-size: cover;
  }
  .senior-title {
    position: relative;
    margin-bottom: 12px;
    &::before {
      position: absolute;
      content: "";
      bottom: -12px;
      width: 150px;
      height: 4px;
      background-color: #141561;
    }
  }
  .senior-name {
    padding-top: 10px;
    /* margin-bottom: 10px; */
    font-size: 30px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    color: #000000;
  }
  .export-info-nick {
    font-size: 24px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .senior-info {
    height: 146px;
    margin-bottom: 30px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #666666;
  }
  .senior-img-list {
    cursor: pointer;
    display: flex;
    .senior-img {
      margin-right: 34px;
      width: 164px;
      height: 164px;
      border: 3px solid transparent;
    }
    .senior-img-active {
      border: 3px solid #1848fd;
    }
    .senior-img img {
      width: 100%;
      height: 100%;
    }
  }
`;
export const ExpertSeniorList = styled.div`
  width: 100%;
  min-width: 1200px;
  /* min-height: 1070px; */
  height: auto;
  background: #141561;
`;

export const SeniorTabs = styled(Tabs)`
  padding-top: 75px;

  .ant-tabs-nav {
    margin-bottom: 40px;
    height: 75px;
  }
  .ant-tabs-nav-list {
    width: 1200px;
    justify-content: space-between;
  }
  .ant-tabs-content-holder {
    margin: 0 auto;
    width: 1200px;
  }
  &.ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 1px solid #fff;
  }
  .ant-tabs-tab .ant-tabs-tab-btn {
    color: #fff;
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    position: relative;
    font-size: 24px;
    text-shadow: 0px 3px 4px 0px rgba(11, 191, 204, 0.7);
    &::before {
      position: absolute;
      content: "";
      width: 120px;
      height: 5px;
      left: 50%;
      margin-left: -60px;
      bottom: -20px;
      background-color: #1848fd;
    }
  }
  .ant-tabs-tabpane {
    display: flex;
    flex-wrap: wrap;
  }
  .ant-tabs-ink-bar {
    background: transparent;
  }
`;

export const SeniorTabPane = styled(TabPane)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const SeniorCard = styled(Card)`
  width: 280px;
  height: 388px;
  padding: 24px;
  margin-bottom: 35px;
  margin-right: 25px;
  box-sizing: border-box;
  background: #323373;
  border-radius: 5px;
  border: 1px solid #323373;
  &:nth-child(4n) {
    margin-right: 0;
  }
  .ant-card-cover {
    width: 234px;
    height: 234px;
    img {
      height: 100%;
    }
  }
  .ant-card-body {
    width: 234px;
    padding: 0;
    padding-top: 45px;
  }
`;
export const SeniorMeta = styled(Meta)`
  width: 100%;
  .ant-card-meta-title {
    position: absolute;
    padding: 0 10px;
    width: 140px;
    height: 42px;
    left: 0;
    bottom: 107px;
    line-height: 42px;
    background: #1848fd;
    font-size: 30px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
  }
  .ant-card-meta-description {
    width: 234px;
  }
  .info {
    margin-bottom: 10px;
    font-size: 14px;
    height: 42px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    color: #ffffff;
  }
  .icon {
    text-align: left;
  }
`;

export const ExpertPagination = styled(Pagination)`
  width: 1200px;
  text-align: center;
  .ant-pagination-item-active {
    background: #1848fd;
    border-radius: 2px;
    a {
      color: #fff;
    }
  }
`;
