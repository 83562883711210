import { NavRightWrapper } from "../style";
import RightContent from "./right-content";

interface NavRightProps {}

const NavRight: React.FC<NavRightProps> = () => {
  return (
    <NavRightWrapper>
      <RightContent />
    </NavRightWrapper>
  );
};

export default NavRight;
