import request from "@/utils/request";

///knowledgeDownLog/list 知识库下载记录 列表接口

export const getknowledgeDownList = (params: any) => {
  return request("/product/knowledgeDownLog/list", {
    method: "POST",
    requestType: "json",
    data: params,
  });
};
//创建知识库下载记录
export const createKnowledgeDownLog = (params: any) => {
  return request("/product/knowledgeDownLog/create", {
    method: "POST",
    requestType: "json",
    data: params,
  });
};

export const getKnowledgeList = (params: any) => {
  return request("/product/knowledge/moList", {
    method: "POST",
    requestType: "json",
    data: params,
  });
};

export const getKnowledgeTree = () => {
  return request(`/product/knowledgeCate/tree`, {
    method: "get",
    requestType: "json",
  });
};

//查询知识库详情

export const getKnowledgeDetail = (params: any) => {
  return request("/product/knowledge/queryMoById", {
    method: "POST",
    requestType: "json",
    data: params,
  });
};
//预览
export const getQueryPreview = (params: any) => {
  ///knowledge/queryPreview
  return request("/product/knowledge/queryPreview", {
    method: "POST",
    requestType: "json",
    data: params,
    type: "queryPreview",
  });
};

export const createOrder = (params: any) => {
  return request("/order/trade/create", {
    method: "POST",
    requestType: "json",
    data: params,
  });
};

// 获取用户openId

export const getOpenId = (code: any) => {
  return request(`/pay/wechat/openId/${code}`, {
    method: "get",
    requestType: "json",
  });
};

// 发起支付
export const createPay = (params: any) => {
  return request("/pay/pay/payment", {
    method: "POST",
    requestType: "json",
    data: params,
  });
};

//知识库 购买记录

export const getOrderList = (params: any) => {
  return request("/order/trade/pageList", {
    method: "POST",
    requestType: "json",
    data: params,
  });
};
