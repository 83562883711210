import BaseFooter from "@/layouts/base-footer";
import CusHeader from "@/layouts/cus-header";
import PersionCommon from "../components/common";
import { ContentWrapper, CusButton, Wrap } from "../style";
import EditPhoneInfo from "./edit-info";
import { Form, message } from "antd";
import type { FormInstance } from "antd/es/form";
import { useEffect, useRef, useState } from "react";
import { isPhone, replaceId } from "@/utils/common";
import _ from "lodash";
import CusCode from "@/components/cus-code";
import CusInputNumber from "@/components/cus-input-number";
import { useDispatch, useSelector } from "umi";
import { sendSms } from "@/api/login";
import { updateMobile } from "@/api/user";
interface EditPhoneProps {}
const EditPhone: React.FC<EditPhoneProps> = () => {
  const [currentId, setCurrentId] = useState<number>(0);
  const formRef: any = useRef<FormInstance>();
  const formRef2: any = useRef<FormInstance>();
  const [text] = useState<string>("获取验证码");
  let [num, setNum] = useState<number>(60);
  let timer: any = null;

  const { user } = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "user/getUser",
    });
  }, []);
  const onFinish = (values: any) => {
    updateMobile({ mobile: user?.mobile, smsCode: values.newCode }).then(
      (res: any) => {
        if (res.code === 1) {
          setCurrentId(1);
          clearInterval(timer);
          num = 60;
          setNum(num);
        }
      }
    );
  };
  const onFinish2 = (values: any) => {
    updateMobile(values).then((res: any) => {
      if (res.code === 1) {
        dispatch({
          type: "user/getUser",
        });
        setCurrentId(2);
        num = 60;
        setNum(num);
        clearInterval(timer);
      }
    });
  };
  const validator = (_: any, value: any) => {
    if (value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("验证码不能为空"));
  };
  const getCode = _.throttle(
    (type) => {
      let params = {
        mobile:
          type == 1 ? user.mobile : formRef2.current?.getFieldValue("mobile"),
        smsType: 4,
      };
      // debugger
      sendSms(params).then((res: any) => {
        if (res.code == 1) {
          message.success("验证码发送成功");
        }
      });
      clearInterval(timer);
      num = 60;
      timer = setInterval(() => {
        if (num == 0) {
          clearInterval(timer);
          num = 60;
        } else {
          num--;
        }
        setNum(num);
      }, 1000);
    },
    60000,
    {
      leading: true,
      trailing: false,
    }
  );

  const steps = [
    {
      id: 1,
      title: "验证身份",
      content: () => {
        return (
          <Form className="cus-form" onFinish={onFinish} autoComplete="off">
            <Form.Item label="您的手机号">
              <span>{replaceId(user?.mobile, 3, 4)}</span>
            </Form.Item>
            <Form.Item
              label="短信验证码"
              name="newCode"
              rules={[{ validator: validator, validateTrigger: ["blur"] }]}
            >
              <CusCode
                placeholder="请输入验证码"
                inputWidth={160}
                inputHeight={32}
                buttonWidth={81}
                disabled={num >= 60 ? false : true}
                onCodeClick={() => {
                  getCode(1);
                }}
                text={num >= 60 ? text : num + "秒"}
              />
            </Form.Item>
            <Form.Item>
              <CusButton
                type="primary"
                htmlType="submit"
                width={257}
                height={32}
              >
                下一步
              </CusButton>
            </Form.Item>
          </Form>
        );
      },
    },
    {
      id: 2,
      title: "验证新手机号",
      content: () => {
        return (
          <Form
            name="basic"
            ref={formRef2}
            className="cus-form"
            onFinish={onFinish2}
            autoComplete="off"
          >
            <Form.Item
              label="新手机号码"
              name="mobile"
              rules={[
                {
                  validator: (_: any, value: any) => {
                    if (value) {
                      if (isPhone(value)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(new Error("手机号格式不正确"));
                      }
                    } else {
                      return Promise.reject(new Error("请输入手机号"));
                    }
                    //
                  },
                  validateTrigger: ["blur"],
                },
              ]}
            >
              <CusInputNumber
                inputHeight={32}
                inputWidth={254}
                placeholder="请输入手机号码"
              />
            </Form.Item>
            <Form.Item
              label="短信验证码"
              name="smsCode"
              rules={[{ validator: validator, validateTrigger: ["blur"] }]}
            >
              <CusCode
                placeholder="请输入验证码"
                inputWidth={160}
                inputHeight={32}
                buttonWidth={81}
                disabled={num >= 60 ? false : true}
                onCodeClick={() => {
                  formRef2.current
                    ?.validateFields(["mobile"])
                    .then((values: any) => {
                      getCode(2);
                    });
                }}
                text={num >= 60 ? text : num + "秒"}
              />
            </Form.Item>
            <Form.Item>
              <CusButton
                type="primary"
                htmlType="submit"
                width={257}
                height={32}
              >
                下一步
              </CusButton>
            </Form.Item>
          </Form>
        );
      },
    },
    {
      id: 3,
      title: "完成",
      content: () => {
        return (
          <div className="container">
            <span className="title">修改成功</span>
            <img src={require("@/assets/images/persion/success.png")} />
          </div>
        );
      },
    },
  ];
  return (
    <>
      <CusHeader title="个人中心" showSearch={false}></CusHeader>
      <ContentWrapper>
        <Wrap>
          <PersionCommon></PersionCommon>
          <EditPhoneInfo steps={steps} current={currentId} />
        </Wrap>
      </ContentWrapper>
      <BaseFooter />
    </>
  );
};

export default EditPhone;
