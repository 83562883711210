export const colors = (count: number) => {
  const color = ["#5577ff", "#A8BFFF"];
  const rStart = 0x55;
  const rEnd = 0xa8;
  const gStart = 0x77;
  const gEnd = 0xbf;
  const rStep = Math.floor((rEnd - rStart) / (count - 1));
  const gStep = Math.floor((gEnd - gStart) / (count - 1));

  const res = [];
  res.push(color[0]);
  for (let i = 0; i < count - 2; i++) {
    res.push(
      `#${(rStart + (i + 1) * rStep).toString(16)}` +
        `${(gStart + (i + 1) * gStep).toString(16)}` +
        `ff`
    );
  }
  res.push(color[1]);
  return res;
};

export const fishboneTopHeight = (tree: any) => {
  const len = tree.length;

  const temp = tree
    .map((item: any) => item.childCateList || [])
    .reduce((res: any = [], item: any) => {
      res = [...res, ...item];
      return res;
    });
  const tertiaryLengthList = temp.map(
    (item: any) => item?.childCateList?.length || 0
  );

  const maxTertiaryLength = Math.max(...tertiaryLengthList);

  let topHeight = maxTertiaryLength * 30 + 100;

  topHeight = Math.max(topHeight, 200);

  return topHeight;
};
