import BaseLayout from "@/layouts";
import { ExpertCenterWrapper } from "./style";
import { Input } from "antd";
import ExpertList from "./components/expert-list";
import { getExpert, getExpertList } from "@/utils/api";
import { useEffect, useState } from "react";
interface ExpertCenterProps {}
const ExpertCenter: React.FC<ExpertCenterProps> = () => {
  const [searchList, setSearchList] = useState<any>([]);
  const [warnning, setWarnning] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const onSearch = (value: string) => {
    if (!value) {
      setWarnning(false);
      return;
    }
    const params = {
      expertName: value,
    };
    getExpertList(params).then((res: any) => {
      res.data && res.data.length === total
        ? setWarnning(false)
        : setWarnning(true);
      if (res.code === 1) {
        setSearchList(res.data);
      } else {
        setWarnning(true);
      }
    });
  };
  useEffect(() => {
    getExpert().then((res: any) => {
      if (res.code == 1) {
        const data = res.data;
        const arr = [].concat(
          data.adviser,
          data.numberCenter,
          data.scientist,
          data.trendsCenter,
          data.wearingCenter
        );
        setTotal(arr.length);
      }
    });
  }, []);
  return (
    <BaseLayout>
      <ExpertCenterWrapper>
        <div className="header-container">
          <img src={require("@/assets/images/solution/solution-bg.png")} />
          <div className="container-info">
            <div className="title">专家中心</div>
            <div className="sub-info">SOLUTION CENTER</div>
            <div className="desc">
              构建国内一流的服装行业专家服务平台,服务决策咨询,服务科技创新,帮助服装企业提高核心竞争力
            </div>
            <div className="header-search">
              <Input.Search
                size="large"
                placeholder="请输入你需要的专家"
                enterButton="搜索"
                onSearch={onSearch}
              />
            </div>
          </div>
        </div>
        <ExpertList searchList={searchList} warnning={warnning} />
      </ExpertCenterWrapper>
    </BaseLayout>
  );
};

export default ExpertCenter;
