import BaseLayout from "@/layouts";
import { EconomicWrapper } from "./style";
import { Autoplay, Navigation, EffectCoverflow } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { history } from "umi";
import "swiper/swiper-bundle.css";
import { Title } from "@/styles/common-style";
interface EconomicProps {}

const Economic: React.FC<EconomicProps> = () => {
  const slider = [
    {
      img: require("@/assets/images/economic/slider1.png"),
      path: "/economic/economic-detail/7",
    },
    {
      img: require("@/assets/images/economic/slider2.png"),
      path: "/economic/economic-detail/1",
    },
    {
      img: require("@/assets/images/economic/slider3.png"),
      path: "/economic/economic-detail/3",
    },
    {
      img: require("@/assets/images/economic/slider4.png"),
      path: "/economic/economic-detail/2",
    },
    {
      img: require("@/assets/images/economic/slider5.png"),
      path: "/economic/economic-detail/4",
    },
    {
      img: require("@/assets/images/economic/slider6.png"),
      path: "/economic/economic-detail/5",
    },
    {
      img: require("@/assets/images/economic/slider7.png"),
      path: "/economic/economic-detail/6",
    },
  ];
  return (
    <BaseLayout>
      <EconomicWrapper>
        <Title className="title">临平数字时尚产业经济监测 </Title>
        <Swiper
          autoplay
          loop
          centeredSlides
          spaceBetween={400}
          slidesPerView={"auto"}
          modules={[Autoplay, Navigation, EffectCoverflow]}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          effect={"coverflow"}
          coverflowEffect={{
            rotate: 0, // 旋转的角度
            stretch: 60, // 拉伸   图片间左右的间距和密集度
            depth: 60, // 深度   切换图片间上下的间距和密集度
            modifier: 2, // 修正值 该值越大前面的效果越明显
            slideShadows: true,
          }}
        >
          {slider &&
            slider.length > 0 &&
            slider.map((item, index) => (
              <SwiperSlide
                key={index}
                onClick={() => {
                  history.push(item.path);
                }}
              >
                <div className="silder-container">
                  <img src={item.img} className="swiper-image" alt="swiper" />
                </div>
              </SwiperSlide>
            ))}
          <div className="swiper-button-next">
            <img src={require("@/assets/images/economic/next.png")} />
          </div>
          <div className="swiper-button-prev">
            <img src={require("@/assets/images/economic/prev.png")} />
          </div>
        </Swiper>
      </EconomicWrapper>
    </BaseLayout>
  );
};

export default Economic;
