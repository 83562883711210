// 绘制覆盖物 标注点
export default class Dot extends TMap.DOMOverlay {
  constructor(options) {
    super(options);
  }
  // 初始化：获取配置参数
  onInit(options = {}) {
    Object.assign(this, { ...options });
  }
  // 创建DOM元素，返回一个Element，使用this.dom可以获取到这个元素
  createDOM() {
    let divTag = document.createElement("div");

    this.onMouseEnter = function (e) {
      this.emit("mouseenter", e.target.firstChild);
    }.bind(this);
    this.onMouseLeave = function (e) {
      this.emit("mouseleave", e.target.firstChild);
    }.bind(this);
    this.onClick = function (e) {
      let center = this.position;
      this.map.setCenter(center);
      // DOMOverlay继承自EventEmitter，可以使用emit触发事件
      this.emit("click", e.target.firstChild);
    }.bind(this);
    this.destory = () => {
      divTag.remove();
      this.emit("destory");
    };
    // let dotTag = document.createElement("div")
    divTag.className = "overlay"; // 覆盖物类名
    divTag.setAttribute("title", `${this.properties.title}`);
    divTag.innerHTML =
      this.content > 10
        ? `<div class="dot"></div>`
        : `<div class="icon-map" ><div class="text">${this.content}</div></div>`;
    divTag.style.cssText = [
      "position: absolute;",
      "top:  0px;",
      "left: 0px;",
      "z-index: 0;",
    ].join("");

    divTag.addEventListener("click", this.onClick);
    divTag.addEventListener("mouseenter", this.onMouseEnter);
    divTag.addEventListener("mouseleave", this.onMouseLeave);
    divTag.addEventListener("destory", this.onClick);
    return divTag;
  }

  // 更新DOM元素，在地图移动/缩放后执行
  updateDOM() {
    if (!this.map) {
      return;
    }
    // 经纬度坐标转容器像素坐标
    let pixel = this.map.projectToContainer(this.position);
    // 使饼图中心点对齐经纬度坐标点
    let left = pixel.getX() - this.dom.clientWidth / 2 + "px";
    let top = pixel.getY() - this.dom.clientHeight / 2 + "px";
    this.dom.style.transform = `translate(${left}, ${top})`;
  }

  // 销毁时
  onDestroy() {
    if (this.onClick) {
      this.dom.removeEventListener(this.onClick);
    }
    if (this.onMouseLeave) {
      this.dom.removeEventListener(this.onMouseLeave);
    }
    if (this.onMouseEnter) {
      this.dom.removeEventListener(this.onMouseEnter);
    }
    this.dom.removeEventListener("destory", this.onClick);
  }
}
