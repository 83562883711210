import { getParklistCompany, searchList } from "@/api/park";
import { Title } from "@/styles/common-style";
import { Divider, List, Skeleton, Button } from "antd";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "umi";
import { AddressImg, ListContainer, ListItem } from "../../style";
interface ResultListProps {
  height?: number;
  onItemClick?: (value: any) => void;
}
const SearchList: React.FC<ResultListProps> = ({
  height = 800,
  onItemClick,
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const dispatch = useDispatch();
  const { keyword, parkSearchInfo } = useSelector(
    (state: { park: any }) => state.park
  );
  const loadMoreData = () => {
    if (loading) {
      return;
    }

    setLoading(true);
    searchList({ keyword: keyword, ...parkSearchInfo }).then((res: any) => {
      if (res.code === 1) {
        if (
          res.data.clusterCount ||
          res.data.companyCount ||
          res.data.parkCount
        ) {
          let arr = [];
          if (res.data.clusterCount) {
            arr.push({
              item: {
                data: res.data.clusterVoList,
                clusterCount: res.data.clusterCount,
              },
            });
          }
          if (res.data.companyCount) {
            arr.push({
              item: {
                data: res.data.companyVoList,
                companyCount: res.data.companyCount,
              },
            });
          }
          if (res.data.parkCount) {
            arr.push({
              item: {
                data: res.data.parkVoList,
                parkCount: res.data.parkCount,
              },
            });
          }
          setData(arr);
        } else {
          setData([]);
        }

        // setTotal(res?.page?.count || 0)
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    loadMoreData();
  }, [keyword]);
  return (
    <ListContainer
      id="scrollableDiv"
      style={{
        height: height + "px",
      }}
    >
      <InfiniteScroll
        dataLength={data && data.length}
        next={loadMoreData}
        hasMore={data && data.length < 50}
        loader={<></>}
        endMessage={<></>}
        scrollableTarget="scrollableDiv"
      >
        <List
          loading={loading}
          dataSource={data}
          renderItem={(item: any, index) => {
            return (
              <List.Item key={index}>
                <ListItem className="listItems">
                  {item.item.clusterCount && (
                    <div className="list-group">
                      <Title fz={18}>
                        {"产业集群(" + item.item.clusterCount + ")"}
                      </Title>
                      <Button
                        type="link"
                        onClick={() => {
                          dispatch({
                            type: "park/savebackNumber",
                            payload: 6,
                          });
                          dispatch({
                            type: "park/saveParkList",
                            payload: null,
                          });
                          dispatch({
                            type: "park/savePre",
                            payload: [0],
                          });
                          dispatch({
                            type: "park/saveParkSearchInfo",
                            payload: {
                              ...parkSearchInfo,
                              clusterName: keyword,
                            },
                          });
                        }}
                      >
                        查看更多
                      </Button>
                    </div>
                  )}
                  {item.item.clusterCount
                    ? item.item.data.map((it: any, index: any) => (
                        <div
                          className="list-group"
                          key={index}
                          onClick={() => {
                            dispatch({
                              type: "park/savebackNumber",
                              payload: 1,
                            });
                            dispatch({
                              type: "park/saveParks",
                              payload: [it],
                            });
                            dispatch({
                              type: "park/saveParkInfo",
                              payload: it,
                            });
                            dispatch({
                              type: "park/savePre",
                              payload: [0],
                            });
                            if (it.latitude && it.longitude) {
                              dispatch({
                                type: "park/saveMapCenter",
                                payload: {
                                  lat: Number(it.latitude),
                                  lng: Number(it.longitude),
                                },
                              });
                            }
                          }}
                        >
                          <div className="left">
                            <AddressImg>{index + 1}</AddressImg>
                            <div className="container">
                              <div className="info">
                                {[it.province, it.city, it.area, it.contact]
                                  .filter((i) => i !== "")
                                  .join("") || "-"}
                              </div>
                              <div className="title">{it.clusterName}</div>
                            </div>
                          </div>
                          <div className="right">
                            <div className="detail-btn">详情</div>
                          </div>
                        </div>
                      ))
                    : ""}
                  {item.item.parkCount ? (
                    <div className="list-group">
                      <Title fz={18}>
                        {"产业园区(" + item.item.parkCount + ")"}
                      </Title>
                      <Button
                        type="link"
                        onClick={() => {
                          dispatch({
                            type: "park/savebackNumber",
                            payload: 51,
                          });
                          dispatch({
                            type: "park/savePre",
                            payload: [0],
                          });
                          dispatch({
                            type: "park/saveParkList",
                            payload: null,
                          });
                          dispatch({
                            type: "park/saveParkSearchInfo",
                            payload: { ...parkSearchInfo, parkName: keyword },
                          });
                        }}
                      >
                        查看更多
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                  {item.item.parkCount
                    ? item.item.data.map((it: any, index: any) => (
                        <div
                          className="list-group"
                          key={index}
                          onClick={() => {
                            dispatch({
                              type: "park/saveIndustrialInfo",
                              payload: it,
                            });
                            dispatch({
                              type: "park/savebackNumber",
                              payload: 2,
                            });
                            dispatch({
                              type: "park/saveParks",
                              payload: [it],
                            });
                            if (it.latitude && it.longitude) {
                              dispatch({
                                type: "park/saveMapCenter",
                                payload: {
                                  lat: Number(it.latitude),
                                  lng: Number(it.longitude),
                                },
                              });
                            }
                          }}
                        >
                          <div className="left">
                            <AddressImg>{index + 1}</AddressImg>
                            <div className="container">
                              <div className="title">{it.parkName}</div>
                              <div className="info">
                                {[it.province, it.city, it.area, it.contact]
                                  .filter((i) => i !== "")
                                  .join("") || "-"}
                              </div>
                            </div>
                          </div>
                          <div className="right">
                            {it?.parkPic && (
                              <img
                                src={it?.parkPic?.split(",")[0]}
                                alt=""
                                className="img"
                              />
                            )}
                          </div>
                        </div>
                      ))
                    : ""}
                  {item.item.companyCount ? (
                    <div className="list-group">
                      <Title fz={18}>
                        {"企业(" + item.item.companyCount + ")"}
                      </Title>
                      <Button
                        type="link"
                        onClick={() => {
                          dispatch({
                            type: "park/savebackNumber",
                            payload: 52,
                          });
                          dispatch({
                            type: "park/saveParkList",
                            payload: null,
                          });
                          dispatch({
                            type: "park/saveParkSearchInfo",
                            payload: {
                              ...parkSearchInfo,
                              companyName: keyword,
                            },
                          });
                        }}
                      >
                        查看更多
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                  {item.item.companyCount
                    ? item.item.data.map((it: any, index: any) => (
                        <div
                          className="list-group"
                          key={index}
                          onClick={() => {
                            dispatch({
                              type: "park/saveCompanyInfo",
                              payload: it,
                            });
                            // dispatch({
                            //   type: "park/saveParks",
                            //   payload: [it],
                            // })
                            dispatch({
                              type: "park/savebackNumber",
                              payload: 3,
                            });
                            if (it.latitude && it.longitude) {
                              dispatch({
                                type: "park/saveMapCenter",
                                payload: {
                                  lat: Number(it.latitude),
                                  lng: Number(it.longitude),
                                },
                              });
                            }
                          }}
                        >
                          <div className="left">
                            <AddressImg>{index + 1}</AddressImg>
                            <div className="container">
                              <div className="title">{it.companyName}</div>
                              <div className="info">
                                {[it.province, it.city, it.area, it.contact]
                                  .filter((i) => i !== "")
                                  .join("") || "-"}
                              </div>
                            </div>
                          </div>
                          <div className="right">
                            {it?.companyPic && (
                              <img
                                src={it?.companyPic?.split(",")[0]}
                                alt=""
                                className="img"
                              />
                            )}
                          </div>
                        </div>
                      ))
                    : ""}
                </ListItem>
              </List.Item>
            );
          }}
        />
      </InfiniteScroll>
    </ListContainer>
  );
};

export default SearchList;
