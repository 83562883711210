import request from "@/utils/request";

export const loginIn = (params: any) => {
  return request("/auth/chainge/token/sms", {
    method: "POST",
    requestType: "json",
    data: params,
  });
};

export const sendSms = (params: any) => {
  return request("/account/smsCode/send", {
    method: "POST",
    requestType: "json",
    data: params,
  });
};
