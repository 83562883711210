import styled from "styled-components";
export const Footer = styled.div`
  display: flex;
  padding: 50px 0 80px 0;
  min-width: 1440px;
  flex-direction: column;
  background-color: #333;
`;
export const Foot = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  font-size: 14px;
  width: 1440px;
  font-weight: 500;
  color: #fff;
  .label {
    position: relative;
    font-size: 22px;
  }
  .info .title {
    margin-top: 20px;
    font-weight: 100;
    margin-bottom: 20px;
    font-family: PingFangSC, PingFangSC-Regular !important;
  }
  .common-tilte {
    margin-left: 34px;
    display: flex;
    // width: 1000px;
  }
  .wrap {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-around;
    align-items: baseline;
    max-width: 880px;
    flex-wrap: wrap;
    .item {
      margin: 10px 46px 10px 0;
      /* width: 200px; */
      &:nth-child(5n) {
        margin-right: 0;
      }
    }
    .title {
      color: #fff;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular !important;
    }
  }
  .wrap-item {
    justify-content: flex-start;
  }
  .label::before {
    position: absolute;
    content: "";
    left: -30px;
    top: 8px;
    width: 20px;
    height: 8px;
    background: #d8d8d8;
  }

  .base-title {
    margin-bottom: 43px;
    font-size: 22px;
    font-family: Alibaba, Alibaba-Regular;
  }
  .contanct {
    margin-bottom: 14px;
  }
  .right {
    padding-left: 40px;
    border-left: 1px solid #979797;
  }
  .follow {
    margin-top: 43px;
    margin-bottom: 14px;
  }
  @media screen and (max-width: 1440px) {
    /* .wrap {
      max-width: 1020px;
    } */
  }
`;
