import styled from "styled-components";

export const MoreTrainsWrapper = styled.div``;

export const Train1Wrapper = styled.div`
  width: 100%;
  min-width: 1440px;
  padding: 84px 0 84px 0;
  background: #fff;
  .train1-list {
    margin: 0 auto;
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .item {
    width: 230px;
    height: 562px;

    border-top: none;
    position: relative;
    margin-right: 8px;
    margin-bottom: 10px;
    .img {
      width: 100%;
      height: 150px;
    }
  }
  .item-int {
    width: 230px;
    height: 412px;
    padding: 22px 15px 0 15px;
    box-sizing: border-box;
    border: 1px solid #d8d8d8;
  }
  .item-title {
    text-align: center;
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    color: #000000;
  }
  .item-info {
    margin-top: 8px;
    font-size: 18px;
    font-family: Alibaba, Alibaba-Regular;
    font-weight: 400;
    color: #666666;
    line-height: 30px;
  }

  .bg1 {
    /* background: ; */
  }
`;
export const Train2Wrapper = styled.div`
  width: 100%;
  min-width: 1440px;
  padding: 40px 0 100px 0;
  background: #fff;
  .train2-list {
    margin: 0 auto;
    width: 1200px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .train2-list-item {
      position: relative;
      width: 258px;
      height: 213px;
      margin-right: 12px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      /* justify-content: center; */
      /* padding-left: 30px;?/ */
      padding: 19px 30px 19px 30px;
      box-sizing: border-box;
    }
  }
  .item-title {
    margin-bottom: 17px;
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    color: #000000;
  }
  .item-info {
    font-size: 18px;
    font-family: Alibaba, Alibaba-Regular;
    font-weight: 400;
    color: #999999;
    line-height: 36px;
    /* line-height: 30px; */
  }
`;

export const ConsultNowWrapper = styled.div`
  cursor: pointer;
  width: 90px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #1848fd;
  font-size: 18px;
  font-family: Alibaba, Alibaba-Regular;
  font-weight: 400;
  color: #1848fd;
`;

export const Train3Wrapper = styled.div`
  width: 100%;
  min-width: 1440px;
  height: 387px;
  background-color: #edf1f8;
  .train3-list {
    margin: 0 auto;
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .train3-list-item {
      width: 210px;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
  .img {
    width: 94px;
    height: 94px;
  }
  .item-title {
    margin-top: 17px;
    margin-bottom: 6px;
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    color: #000000;
  }
  .item-info {
    font-size: 18px;
    font-family: Alibaba, Alibaba-Regular;
    font-weight: 400;
    color: #999999;
    /* line-height: 36px; */
  }
`;

export const Train4Wrapper = styled.div`
  width: 100%;
  min-width: 1440px;
  margin-bottom: 80px;
  /* height: 387px; */
  .train4-list {
    margin: 0 auto;
    width: 1200px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .train4-list-item {
      width: 390px;
      height: 166px;
      margin-right: 7px;
      margin-bottom: 20px;
      background: #ffffff;
      padding: 10px 24px 0 22px;
      box-sizing: border-box;
      box-shadow: 0px 2px 7px 2px rgba(0, 0, 0, 0.15);
    }
    .list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .img {
        width: 59px;
        height: 59px;
      }
      .item-title {
        margin-top: 17px;
        margin-bottom: 6px;
        font-size: 18px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        color: #000000;
      }
    }
    .item-info {
      font-size: 18px;
      font-family: Alibaba, Alibaba-Regular;
      font-weight: 400;
      color: #999999;
      /* line-height: 36px; */
    }
  }
`;

export const Train5 = styled.div`
  width: 100%;
  min-width: 1440px;
  margin-bottom: 80px;
  .train-sub-tilte {
    width: 1200px;
    margin: 30px auto;
    color: #333;
  }
`;
