import { Title } from "@/styles/common-style";
import { SpecificationWrapper } from "../style";

interface SpecificationProps {}

export const Specification: React.FC<SpecificationProps> = () => {
  const data = [
    {
      title: "一级（规范级）",
      icon: require("@/assets/images/maintain/icon1.png"),
      info: "指企业应具备基本的生产运作条件，满足生产基础数据的手工记录及存档。",
    },
    {
      title: "二级（标准级）",
      icon: require("@/assets/images/maintain/icon2.png"),
      info: "指企业应具备维持常规生产运作的保障岗位及标准规范，满足生产数据的电子化记录及存档。",
    },
    {
      title: "三级（信息级）",
      icon: require("@/assets/images/maintain/icon3.png"),

      info: "指企业应采用自动化技术、信息化手段对核心业务和设备等进行改造和规范，实现单一业务的数据共享。",
    },
  ];
  const data1 = [
    {
      title: "四级（集成级）",
      icon: require("@/assets/images/maintain/icon4.png"),
      info: "指企业应对装备、系统等开展集成，实现跨业务间的数据共享。",
    },
    {
      title: "五级（引领级）",
      icon: require("@/assets/images/maintain/icon5.png"),
      info: "指企业应对人员、资源、制造等进行数据挖掘分析，形成知识、模型等，实现对核心业务的精准预测和优化。",
    },
  ];
  return (
    <SpecificationWrapper>
      <Title
        fz={36}
        fw={600}
        color="#000"
        textAlign="center"
        style={{
          marginBottom: 30,
        }}
      >
        五级规范
      </Title>
      <div className="list">
        {data.map((item, index: number) => (
          <div className="list-item" key={index}>
            <div className="title-info">
              <Title className="title" fz={18} fw={600} color="#000">
                {item.title}
              </Title>
              <img src={item.icon} className="icon" />
            </div>
            <div className="info">{item.info}</div>
          </div>
        ))}
      </div>
      <div className="list list1">
        {data1.map((item, index: number) => (
          <div className="list-item" key={index}>
            <div className="title-info">
              <Title className="title" fz={18} fw={600} color="#000">
                {item.title}
              </Title>
              <img src={item.icon} className="icon" />
            </div>
            <div className="info">{item.info}</div>
          </div>
        ))}
      </div>
    </SpecificationWrapper>
  );
};
