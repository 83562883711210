import { Title } from "@/styles/common-style";
import { Rate, Button } from "antd";
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { CaretRightOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import { ContactUs, factorylist } from "@/utils/common";
import { FactoryWrapper } from "../style";
interface OrderProcessProps {}

export const OrderProcess: React.FC<OrderProcessProps> = () => {
  const [orderList, setOrderList] = useState<any>(factorylist);
  let arr = orderList.slice(0, 6);
  let arr1 = orderList.slice(5, orderList.length);
  return (
    <FactoryWrapper>
      <Title
        fz={36}
        fw={600}
        color="#000"
        textAlign="center"
        style={{ marginBottom: 48 }}
      >
        工厂资源展示
      </Title>

      <div className="order-slider">
        <div className="swiper-prev">
          <img
            src={require("@/assets/images/order-platform/arrow_left.png")}
            alt=""
          />
        </div>
        <div>
          <Swiper
            autoplay
            loop
            centeredSlides
            spaceBetween={10}
            slidesPerView={3.75}
            className="slider-comtainer"
            modules={[Autoplay, Navigation]}
            navigation={{
              nextEl: ".swiper-next",
              prevEl: ".swiper-prev",
            }}
          >
            {arr &&
              arr.length > 0 &&
              arr.map((item: any, index: number) => (
                <SwiperSlide key={index} className="order-item">
                  <div className="img-container">
                    <img src={item.logo} alt="logo" />
                  </div>
                  <div className="order-item-info">
                    <div className="order-goods">{item.companyName} </div>
                    <Rate
                      disabled
                      defaultValue={item.star}
                      className="rate-start"
                    />
                    <div className="goods-num">
                      <span>{item.type}</span>
                      <span>{item.address}</span>
                    </div>
                    <Button
                      className="order-btn"
                      onClick={() => {
                        ContactUs("邀请接单");
                      }}
                    >
                      邀请接单
                      <CaretRightOutlined style={{ color: "#1848fd" }} />
                    </Button>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
          <Swiper
            autoplay
            loop
            centeredSlides
            spaceBetween={10}
            slidesPerView={3.75}
            className="slider-comtainer"
            modules={[Autoplay, Navigation]}
            navigation={{
              nextEl: ".swiper-next",
              prevEl: ".swiper-prev",
            }}
          >
            {arr1 &&
              arr1.length > 0 &&
              arr1.map((item: any, index: number) => (
                <SwiperSlide key={index} className="order-item">
                  <div className="img-container">
                    <img src={item.logo} alt="logo" />
                  </div>
                  <div className="order-item-info">
                    <div className="order-goods">{item.companyName} </div>
                    <Rate
                      disabled
                      defaultValue={item.star}
                      className="rate-start"
                    />
                    <div className="goods-num">
                      <span>{item.type}</span>
                      <span>{item.address}</span>
                    </div>
                    <Button
                      className="order-btn"
                      onClick={() => {
                        ContactUs("申请接单");
                      }}
                    >
                      申请接单
                      <CaretRightOutlined style={{ color: "#1848fd" }} />
                    </Button>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
        <div className="swiper-next">
          <img
            src={require("@/assets/images/order-platform/arrow_right.png")}
            alt=""
          />
        </div>
      </div>
    </FactoryWrapper>
  );
};
