import { ContactUs } from "@/utils/common";
import { FlatButtonWrapper } from "./style";

interface FlatButtonProps {}
const scrollTo = (element: any) => {
  // element.scrollIntoView({ behavior: "smooth", block: "start" }) // 顶部
  // element.scrollIntoView({ behavior: "smooth", block: "end" }) // 底部
  element.scrollIntoView({ behavior: "smooth" }); // 可视区域
};

export const FlatButton: React.FC<FlatButtonProps> = () => {
  return (
    <FlatButtonWrapper>
      <div className="btn-wrap" onClick={() => ContactUs("在线咨询")}>
        <div className="icon">
          <img src={require("@/assets/images/contact_icon.png")} alt="" />
        </div>
        <div className="text">在线咨询</div>
      </div>
      <div className="btn-wrap phone-wrap">
        <div className="icon">
          <img src={require("@/assets/images/phone_icon.png")} alt="" />
        </div>
        <div className="text">客服热线</div>
        <div className="phone-num">4006-621-521</div>
      </div>
      <div className="btn-wrap collect-wrap">
        <div className="icon">
          <img src={require("@/assets/images/collect_icon.png")} alt="" />
        </div>
        <div className="text">关注我们</div>
        <div className="colllect-img">
          <img src={require("@/assets/images/scale.png")} alt="" />
        </div>
      </div>
      <div
        className="btn-wrap"
        onClick={() => {
          scrollTo(document.body);
          // window.scrollTo(0, 0)
        }}
      >
        <div className="icon">
          <img src={require("@/assets/images/back.png")} alt="" />
        </div>
        <div className="text">返回顶部</div>
      </div>
    </FlatButtonWrapper>
  );
};
