import { CaretUpFilled, CaretDownFilled } from "@ant-design/icons";
import { Row } from "antd";
import { useEffect, useState } from "react";

interface SortItemsProps {
  selector: any;
  nosort?: boolean;
  onChange?: (value: any) => void;
}
const SortItems: React.FC<SortItemsProps> = ({
  selector,
  onChange,
  nosort = false,
}) => {
  const [direction, setDirection] = useState<string | null>("");
  return (
    <Row>
      <div
        className="tag-name"
        onClick={() => {
          let d = "";
          if (direction === "1") {
            d = "2";
          } else if (direction === "2") {
            d = null;
          } else if (direction === "") {
            d = "1";
          }
          setDirection(d);
          onChange?.(d);
          // setCurrentId(selector.value)
        }}
      >
        {selector.name}
      </div>
      {!nosort && (
        <div className="carete">
          <CaretUpFilled
            style={{
              color: direction === "1" ? "#000" : "#999",
            }}
            onClick={() => {
              // setCurrentId(selector.value)
              setDirection("1");
              onChange?.("1");
            }}
          />
          <CaretDownFilled
            style={{
              color: direction === "2" ? "#000" : "#999",
            }}
            onClick={() => {
              setDirection("2");
              onChange?.("2");
            }}
          />
        </div>
      )}
    </Row>
  );
};

export default SortItems;
