import BaseFooter from "@/layouts/base-footer";
import CusHeader from "@/layouts/cus-header";
import { Title } from "@/styles/common-style";
import { history, useDispatch, useParams, useSelector } from "umi";
import { Button, Checkbox } from "antd";
import { OrderWrap } from "./style";
import no_data from "@/assets/images/knowledge/no_data.png";
import { useEffect, useState } from "react";
import { Spin, Modal } from "antd";
import {
  createOrder,
  getKnowledgeDetail,
  getQueryPreview,
} from "@/api/knowledge";
import moment from "moment";
import QRCode from "qrcode";
import { GetQueryString } from "@/utils/common";

interface OrderInfoProps {}

const OrderInfo: React.FC<OrderInfoProps> = () => {
  let i = 0;
  const params = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = useSelector((state: any) => state.user);
  const [visible, setVisible] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { knowledgeDetail } = useSelector((state: any) => state.knowledge);
  const fileReg = /^\.rar|\.zip|\.arj/i;
  const toPay = async () => {
    if (user?.id) {
      const p = {
        targetId: Number(params.id),
        downFlag: 1,
        payer: user?.id,
        incrementBusinessType: 1,
        payType: 1,
        payAmount: knowledgeDetail.salePrice,
      };
      let orderNo: any = "";

      if (window.location.href.includes("orderNo")) {
        orderNo = GetQueryString("orderNo");
      }

      //创建订单
      if (!orderNo) {
        let rst: any = await createOrder(p);
        if (rst.code == 1) {
          orderNo = rst.data.orderNo;
        }
      }
      console.log(
        process.env.LOCATION_URL + "#/h5-pay/" + params?.id + `/${orderNo}    `
      );
      if (orderNo) {
        setVisible(true);
        QRCode.toCanvas(
          document.getElementById("canvas"),
          process.env.LOCATION_URL + "#/h5-pay/" + params?.id + `/${orderNo}`,
          // "https://umi-blog-example.vercel.app/",
          function (error) {
            if (error) console.error(error);
            console.log("success!");
          }
        );
      }

      // }
      // })
    } else {
      history.push("/login");
    }
  };
  // 查询支付结果
  const getResult = () => {
    const p = {
      id: params.id,
      userId: user.id!,
    };

    getKnowledgeDetail(p).then((res: any) => {
      if (res.code == 1) {
        if (res.data.playFlag == 1) {
          history.replace("/knowledge/knowledge-detail/" + params.id);
        }
      }
    });
  };
  useEffect(() => {
    let timer: any = null;
    clearInterval(timer);
    timer = setInterval(() => {
      getResult();
    }, 30000);
    return () => {
      clearInterval(timer);
    };
  }, []);
  useEffect(() => {
    const p = {
      id: params.id,
      userId: user.id!,
    };
    if (params.id) {
      setLoading(false);
      getKnowledgeDetail(p).then((res: any) => {
        if (res.code == 1) {
          dispatch({
            type: "knowledge/saveKnowledgeDetail",
            payload: {
              ...res.data,
              totalPage: knowledgeDetail?.previewImgNum || 0,
              coverImg: res.data.coverImg
                ? res.data.coverImg
                : res.data.previewImgName
                ? res.data.previewImgPrefix +
                  res.data.previewImgName.split(",")[0]
                : null,
            },
          });
        }
      });
    }
  }, [params]);
  return (
    <>
      <CusHeader
        title={"知识图谱"}
        showSearch
        placeholder="请输入您需要的知识标题"
        search={(value) =>
          history.push({
            pathname: "/knowledge",
            state: {
              keyword: value,
            },
          })
        }
      />
      <Spin spinning={loading} tip="加载中...">
        {knowledgeDetail && (
          <OrderWrap>
            <Title fz={36} color="#333" fw={500} className="order-title">
              订单详情
            </Title>
            <div className="goods-info">
              <div className="left">
                <img
                  className="img"
                  src={knowledgeDetail.coverImg || no_data}
                />
              </div>
              <div className="right">
                <div className="right-title">{knowledgeDetail.title}</div>
                <div className="right-intro">{knowledgeDetail.remark}</div>
                <div className="info">
                  <div className="left-info">
                    <div className="download-info">
                      下载{" "}
                      {knowledgeDetail.downNum ||
                        0 + knowledgeDetail.waterSaleNum ||
                        0}
                    </div>
                    <div className="total-info">
                      总页数 {knowledgeDetail?.totalPage || 0}页
                    </div>
                    <div className="time-info">
                      {moment(knowledgeDetail.createTime || 0).format(
                        "YYYY-MM-DD HH:mm"
                      )}
                    </div>
                  </div>

                  <div className="price-info">
                    价格：
                    <span className="price">¥{knowledgeDetail.salePrice}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="pay-way">
              <div className="pay-title">支付方式</div>
              <div className="pay-way-description">
                <div
                  className="pay-card"
                  style={{ marginTop: 40, marginBottom: 27 }}
                >
                  <img
                    src={require("@/assets/images/knowledge/wx_icon.png")}
                    alt=""
                  />
                  <div className="text">微信支付</div>
                </div>
              </div>
            </div>
            <div className="pay-way">
              <div className="pay-title">提交订单</div>
              <div className="pay-amount">
                应付金额：￥{knowledgeDetail.salePrice}
              </div>
            </div>
            <div className="pay-act">
              <Button
                type="primary"
                className="pay-btn"
                onClick={() => {
                  toPay();
                }}
              >
                购买下载
              </Button>
              {/* <Checkbox type='checkbox' defaultChecked>
                我已阅读并同意
                <Agreement
                  subTitle='文档购买条款'
                  title='《文档购买条款》'
                  content={() => <></>}
                />
              </Checkbox> */}
            </div>
          </OrderWrap>
        )}
      </Spin>

      <BaseFooter />
      <Modal
        visible={visible}
        forceRender
        footer={false}
        width={300}
        onCancel={() => {
          setVisible(false);
        }}
      >
        <div
          className="wapper"
          style={{
            width: 260,
            height: 260,
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>扫一扫支付：{knowledgeDetail.salePrice || 0} 元</div>
          <canvas id="canvas" style={{ width: "100%", height: "100%" }} />
        </div>
      </Modal>
    </>
  );
};

export default OrderInfo;
