import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "umi";
import { RightContainer, RightContentWrapper } from "../../style";
import { Cates } from "./cates";
import RightHeader from "./right-header";
import SelectArea from "./SelectArea";

interface RightContentProps {}
const RightContent: React.FC<RightContentProps> = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const { parkList, parkSearchInfo } = useSelector((state: any) => state.park);
  const [CatesVisible, setCatesVisible] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [cateName, setCateName] = useState<string>("");
  const dispatch = useDispatch();
  const [currentId, setCurrentId] = useState<number>(1);
  useEffect(() => {
    if (parkList && parkList.displayType == 1) {
      setCatesVisible(false);
    }
  }, [parkList]);
  useEffect(() => {
    if (parkSearchInfo) {
      let address = [
        parkSearchInfo.province,
        parkSearchInfo.city,
        parkSearchInfo.area,
      ].filter((item) => item);
      setName(address.length == 0 ? "全国" : address[address.length - 1]);
    }
  }, [parkSearchInfo]);
  return (
    <RightContentWrapper>
      <RightHeader
        name={name}
        cateName={cateName}
        onClickAll={() => {
          setVisible(!visible);
          setCatesVisible(false);
        }}
        onSelectCate={(value) => {
          setCurrentId(value);
          if (value == 3) {
            setCatesVisible(true);
          } else {
            setCatesVisible(false);
          }
        }}
        onClickCates={() => {
          setCatesVisible(!CatesVisible);
          setVisible(false);
        }}
      />
      {visible && (
        <RightContainer>
          <SelectArea
            current={currentId}
            onClick={(value: any) => {
              setVisible(false);
              setName(value.name);
              parkSearchInfo.city = value.city;
              parkSearchInfo.cityCode = value.cityCode;
              parkSearchInfo.province = value.province;
              parkSearchInfo.provinceCode = value.provinceCode;
              parkSearchInfo.cityCode = value.cityCode;
              parkSearchInfo.area = value.area;
              parkSearchInfo.areaCode = value.areaCode;
              dispatch({
                type: "park/saveParkSearchInfo",
                payload: {
                  ...parkSearchInfo,
                },
              });
            }}
          />
        </RightContainer>
      )}
      {/* {CatesVisible && (
        <Cates
          onClick={(value) => {
            setCateName(value)
            setCatesVisible(false)
          }}
        />
      )} */}
    </RightContentWrapper>
  );
};

export default RightContent;
