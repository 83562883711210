import { history } from "umi";
import { Autoplay, Pagination, Thumbs } from "swiper";
import { HomeSliderWrapper } from "./style";
import { SwiperSlide } from "swiper/react/swiper-react";
import { base_image_oss } from "@/utils/common";
interface HomeSliderProps {}

const HomeSlider: React.FC<HomeSliderProps> = () => {
  const items = [
    {
      image: base_image_oss + "home/home-bannar-202400710.png",
      id: "1",
      path: "https://mp.weixin.qq.com/s/ActB-R_-exaov6eXmYAWkA",
    },
    {
      image: base_image_oss + "home/home-bannar-202404111.png",
      id: "-1",
      path: "https://mp.weixin.qq.com/s/esvR-7n96gg6GLs64Td7kQ",
    },
    {
      image: base_image_oss + "home/home-bannar-20240412.png",
      id: "0",
      path: "https://mp.weixin.qq.com/s/SpY8syKh1DT-SMku4jsV8Q",
    },
    {
      image: base_image_oss + "home/home-bannar-20240104-2.png",
      id: "8",
      path: "https://mp.weixin.qq.com/s/t7Oqxy0nJEFI1kCelrkttQ",
      title: (
        <>
          <div>
            <div
              style={{
                marginTop: 60,
                fontSize: 40,
                lineHeight: 1.2,
                fontFamily: "SourceHanSansCN",
              }}
            >
              2023-2024
            </div>
            <div
              style={{
                fontFamily: "SourceHanSansCN",
                fontSize: 70,
                lineHeight: 1.3,
              }}
            >
              中国服装行业科技创新白皮书
            </div>
          </div>
          <div
            style={{
              fontSize: 20,
              fontFamily: "Alibaba, Alibaba-Regular",
            }}
          >
            White Paper on Scientific and Technological Innovation of China's
            Garment Industry 2023-2024{" "}
          </div>
          <div style={{ fontFamily: "SourceHanSansCN", fontSize: 40 }}>
            中国服装科创研究院 编著
          </div>
        </>
      ),
      describe: (
        <div
          style={{
            border: "1px solid #fff",
            marginTop: 30,
            width: 300,
            height: 56,
            textAlign: "center",
            lineHeight: "56px",
            letterSpacing: "20px",
          }}
        >
          了解详情
        </div>
      ),
      titleclass: "slide-title slide-title1",
      describelass: "slide-subtitle slide-subtitle1 ",
    },
    {
      image: base_image_oss + "home/home-bannar_12.png",
      id: "2",
      path: "https://mp.weixin.qq.com/s/l01Fti4jyXbiVOObqXpzGw",
    },
    {
      image: base_image_oss + "home/home-bannar_10.png",
      id: "10",
      title: (
        <>
          <div style={{ fontSize: 80, lineHeight: 1, color: "#3360f2" }}>
            中国服装
          </div>
          <div style={{ fontSize: 80, lineHeight: 1, color: "#000" }}>
            数字化快反工厂等级认定
          </div>
        </>
      ),
      describe: (
        <div
          style={{
            textAlign: "left",
            lineHeight: "56px",
            letterSpacing: "0",
            color: "#000",
          }}
        >
          China Garment Digital Fast Response Factory Grade Certification
        </div>
      ),
      path: "/maintain",
      titleclass: "slide-title",
      describelass: "slide-subtitle",
    },
    {
      image: base_image_oss + "home/home-bannar_3.png",
      id: "3",
      titleclass: "slide-title",
      title: (
        <>
          <div
            style={{
              fontSize: 80,
              lineHeight: 1,
              marginBottom: "14px",
              textAlign: "left",
            }}
          >
            数字化促服装产业
          </div>
          <div
            style={{
              fontSize: 80,
              lineHeight: 1,
              marginBottom: "14px",
              textAlign: "left",
            }}
          >
            高质量发展
          </div>
        </>
      ),
      describelass: "slide-subtitle",
      describe: (
        <div style={{ textAlign: "left" }}>橙织助力服装产业智能化建设</div>
      ),
      path: "",
    },
  ];
  const setClassName = (data: { isActive: any; align: string }) => {
    let className = "";
    if (data.isActive) {
      if (data.align === "left") {
        className = " animated fadeInLeft";
      } else if (data.align === "right") {
        className = " animated fadeInRight";
      } else if (data.align === "center") {
        className = " animated fadeInDown";
      }
    } else {
      return "slide-title";
    }
    return className;
  };
  const navigateTo = (path: string) => {
    console.log(path.includes("https"), 'path.includes("/https"');
    if (path.includes("/trains")) {
      path && window.open(path);
    } else if (path.includes("https")) {
      window.open(path);
    } else {
      path && history.push(path);
    }
  };
  return (
    <HomeSliderWrapper
      spaceBetween={0}
      autoplay
      loop
      modules={[Autoplay, Pagination, Thumbs]}
      pagination={{ clickable: true }}
    >
      {items &&
        items.map((item) => (
          <SwiperSlide
            onClick={() => item.path && navigateTo(item.path)}
            key={item.id}
          >
            {({ isActive }) => (
              <>
                <img src={item.image} className="slider-img" />
                <div className="slide-wrap">
                  <div
                    className={`${item.titleclass}  ${setClassName({
                      align: "center",
                      isActive: isActive,
                    })}`}
                  >
                    {item.title}
                  </div>
                  <div
                    className={`${item.describelass}  ${setClassName({
                      align: "center",
                      isActive: isActive,
                    })}`}
                  >
                    {item.describe}
                  </div>
                </div>
              </>
            )}
          </SwiperSlide>
        ))}
    </HomeSliderWrapper>
  );
};

export default HomeSlider;
