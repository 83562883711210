import { Row } from "antd";
import { useEffect, useState } from "react";

import Title from "@/components/title";
import CusTab from "@/components/cus-tab";
import { ListProps } from "@/models/tab";

interface InterestsItem {
  key: string;
  title: string;
  solgan: string;
  bg: string;
  desc: string[];
}
const titleStyle = {
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "44px",
} as any;
const INTERESTS: ListProps[] = [
  { title: "战略赋能", key: "1" },
  { title: "市场赋能", key: "2" },
  { title: "服务赋能", key: "3" },
  { title: "技术赋能", key: "4" },
];
const INTERESTSLIST: InterestsItem[] = [
  {
    key: "1",
    bg: require("@/assets/images/bg1.png"),
    solgan: "STRATEGIC EMPOWERMENT",
    title: "战略赋能",
    desc: [
      "我们非常欢迎共同致力于中国时尚产业大脑建设的各界优秀企业，共同实现先进技术、数字化、精益管理等全方位的战略合作，共同提升企业技术进步的核心竞争力和战略定位，以应对日趋复杂多变的市场。",
    ],
  },
  {
    key: "2",
    bg: require("@/assets/images/bg2.png"),
    solgan: "MARKET EMPOWERMENT",
    title: "市场赋能",
    desc: ["300+直播电商机构", "2000+品牌", "10万+工厂", "62个服装产业集群"],
  },
  {
    key: "3",
    bg: require("@/assets/images/bg3.png"),
    solgan: "SERVICE EMPOWERMENT",
    title: "服务赋能",
    desc: [
      "提供数字化解决方案、行业标准应用、构建共享数字化款式库和版型库、共享人才资源服务平台和供应链金融等服务，构建技术、渠道、资源等多维度合作共享生态体系。",
    ],
  },
  {
    key: "4",
    bg: require("@/assets/images/bg4.png"),
    solgan: "TECHNOLOGY EMPOWERMENT",
    title: "技术赋能",
    desc: [
      "提供行业前沿科技的落地应用和产学研联合研发，打通科研机构、高等院校、行业协会和科技企业的协作互联，让更多的中小企业可以低成本、低风险的享受优质数字化转型技术及共建可信制造体系。",
    ],
  },
];

const CoInterests = () => {
  const [activeItem, setActiveItem] = useState<InterestsItem>();
  const defaultKey = "1";
  useEffect(() => {
    setActiveItem(INTERESTSLIST[0]);
  }, [defaultKey]);
  const onTabClick = (data: ListProps) => {
    let Item = INTERESTSLIST.filter((item) => item.key === data.key)[0];
    setActiveItem(Item);
  };
  return (
    <div className="co-interest">
      <Title text={"合作权益"} titleStyle={titleStyle} />
      <Row className="co-tab-container">
        <CusTab
          list={INTERESTS}
          onTabClick={onTabClick}
          defaultKey={defaultKey}
        />
      </Row>
      <div className="interest-item-container">
        {activeItem && (
          <div>
            <img
              src={activeItem.bg}
              className="interest-item-image"
              alt="icon"
            />
            <div className="interest-item-detail ">
              <span className="interest-item-title"> {activeItem.title}</span>
              <span className="interest-item-solgan"> {activeItem.solgan}</span>
              {activeItem.desc.map((item, index) => (
                <span className="interest-item-desc" key={index}>
                  {item}
                </span>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CoInterests;
