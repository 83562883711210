import { Title } from "@/styles/common-style";
import { TABS_ITEMS } from "@/utils/common";
import { DataInfoWrapper } from "../style";

interface DataInfoProps {}

export const DataInfo: React.FC<DataInfoProps> = () => {
  const tab = TABS_ITEMS[1];
  return (
    <DataInfoWrapper>
      {tab.item.map((it, index) => (
        <div className="card" key={index}>
          <img src={it.icon} alt="" className="icon" />
          <Title fz={24} fw={600} color="#000" textAlign="center">
            {it.title}
          </Title>
          <div className="card-intro">{it?.intro}</div>
        </div>
      ))}
    </DataInfoWrapper>
  );
};
