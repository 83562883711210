import { Avatar, Divider, List, Skeleton, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { history, ServerDetailVo } from "umi";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  ContentContainer,
  ListWrapper,
  SelectorArea,
  ServicesListWrapper,
  TitleWrapper,
} from "../style";
import SortItems from "./sort-item";
import moment from "moment";
import { payWays } from "@/utils/common";
export interface CusListVo {
  image: string;
  type: number;
  description: string;
  content: () =>
    | React.ReactNode
    | {
        children: React.ReactNode;
        props: any;
      };
}
// const ddata = Array.from({
//   length: 23,
// }).map((_, i) => ({
//   title: `服务名称 ${i}`,
//   type: 1,
//   image: "https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png",
//   description:
//     "数据是企业数字化转型的基础，数据贯穿各个环节，数据的获取与应用是数字化转型落地的基础。数据的获取与应用是数字化转型落地的基础 依托中服科创研究院在服装行业领域的积累，使数据贯穿各个环节… …",
//   content: () => {
//     return (
//       <ContentContainer>
//         <Col span={24}>交付方式：数据回显</Col>
//         <Col span={24}>
//           <Row justify='space-between'>
//             <div>服务商：杭州中服科创研究院</div>
//             <div>发布时间：2021-12-26</div>
//           </Row>
//         </Col>
//       </ContentContainer>
//     )
//   },
// }))
interface ServiceListProps {
  onItemClick?: (value: any) => void;
  sortby1?: (value: string) => void;
  sortby2?: (value: string) => void;
  sortby3?: (value: string) => void;
  serverList: ServerDetailVo[];
  loadMoreData: () => void;
  hasMore?: boolean;
}
const ServiceList: React.FC<ServiceListProps> = ({
  onItemClick,
  sortby1,
  sortby2,
  sortby3,
  loadMoreData,
  hasMore = true,
  serverList,
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ServerDetailVo[]>([]);
  useEffect(() => {
    console.log(serverList, "serverList");
    setData(serverList);
  }, [serverList]);
  // const loadMoreData = () => {
  //   if (loading) {
  //     return
  //   }
  //   const ddata = Array.from({
  //     length: 23,
  //   }).map((_, i) => ({
  //     title: `服务名称 ${i}`,
  //     type: 1,
  //     image:
  //       "https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png",
  //     description:
  //       "数据是企业数字化转型的基础，数据贯穿各个环节，数据的获取与应用是数字化转型落地的基础。数据的获取与应用是数字化转型落地的基础 依托中服科创研究院在服装行业领域的积累，使数据贯穿各个环节… …",
  //     content: () => {
  //       return (
  //         <ContentContainer>
  //           <Col span={24}>交付方式：数据回显</Col>
  //           <Col span={24}>
  //             <Row justify='space-between'>
  //               <div>服务商：杭州中服科创研究院</div>
  //               <div>发布时间：2021-12-26</div>
  //             </Row>
  //           </Col>
  //         </ContentContainer>
  //       )
  //     },
  //   }))
  //   setData([...data, ...ddata])
  //   // setLoading(true)
  //   // fetch(
  //   //   "https://randomuser.me/api/?results=10&inc=name,gender,email,nat,picture&noinfo"
  //   // )
  //   //   .then((res) => res.json())
  //   //   .then((body) => {
  //   //     setData([...data, ...body.results])
  //   //     setLoading(false)
  //   //   })
  //   //   .catch(() => {
  //   //     setLoading(false)
  //   //   })
  // }
  const getItemType = (data: any) => {
    switch (data.priceType) {
      case 2:
        return "询价";
      case 1:
        return "免费";
      case 3:
        return `${data.price}${data.priceCompany == 1 ? "元/年" : "元/次"}`;
      default:
        return "免费";
    }
  };
  useEffect(() => {
    // loadMoreData()
  }, []);
  return (
    <ServicesListWrapper>
      <SelectorArea>
        <SortItems
          key={1}
          selector={{ name: "综合排序", value: "0" }}
          nosort
          onChange={(value: any) => {
            sortby1?.(value);
          }}
        />
        <SortItems
          key={2}
          selector={{ name: "价格排序", value: "1" }}
          onChange={(value: any) => {
            sortby2?.(value);
          }}
        />
        <SortItems
          key={3}
          selector={{ name: "发布时间", value: "2" }}
          onChange={(value: any) => {
            sortby3?.(value);
          }}
        />
      </SelectorArea>
      <ListWrapper>
        <InfiniteScroll
          dataLength={data.length}
          next={loadMoreData}
          hasMore={hasMore}
          loader={<></>}
          endMessage={data.length > 10 && <Divider plain>我是有底线的</Divider>}
        >
          <List
            itemLayout="vertical"
            size="large"
            dataSource={data}
            footer={false}
            renderItem={(item: any) => (
              <List.Item
                key={item.title}
                onClick={() => {
                  onItemClick?.(item);
                  history.push("/server/server-detail/" + item.id);
                }}
                extra={
                  <img
                    width={265}
                    height={199}
                    alt="poster"
                    src={item.poster}
                  />
                }
              >
                <List.Item.Meta
                  title={
                    <TitleWrapper>
                      <div className="title">{item.serviceName}</div>
                      <div className="type">{getItemType(item)}</div>
                    </TitleWrapper>
                  }
                />
                <div className="ant-list-item-meta-description ql-editor">
                  {item.introduction}
                </div>

                {/* {item.content()} */}
                <ContentContainer>
                  <Col span={24}>
                    交付方式：
                    {
                      payWays.filter((i) => i.id == item.deliveryMethod)[0]
                        .metadataName
                    }
                  </Col>
                  <Col span={24}>
                    <Row justify="space-between">
                      {item.serviceSupplierName && (
                        <div>服务商：{item.serviceSupplierName || "-"}</div>
                      )}
                      <div>
                        发布时间：{moment(item.createTime).format("YYYY-MM-DD")}
                      </div>
                    </Row>
                  </Col>
                </ContentContainer>
              </List.Item>
            )}
          />
        </InfiniteScroll>
      </ListWrapper>
    </ServicesListWrapper>
  );
};

export default ServiceList;
