import styled from "styled-components";
import { Dropdown, Row } from "antd";
export const BaseHeaderWrapper = styled(Row)<{ isfixed: boolean }>`
  width: 100%;
  min-width: 1440px;
  height: 100%;
  position: ${(props) => (props.isfixed ? "fixed" : "relative")};
  align-items: center;
  top: 0;
  height: 95px;
  z-index: 99;
  justify-content: space-between;
  background-color: #fff;
  .base-menu {
    display: flex;
  }
  .base-menu-wrap {
    display: flex;
    margin: 0;
    padding: 0;
    max-width: 1200px;
    height: 100%;
    align-items: center;
    justify-content: space-around;
    list-style: none;
    li {
      display: flex;
      position: relative;
      font-size: 18px;
      height: 100%;
      /* flex: 1; */
      width: 132px;
      align-items: center;
      justify-content: center;
      /* padding-left: 0; */
      font-weight: 400;
      cursor: pointer;
      &:last-child {
        padding-right: 0;
      }
    }
  }

  .base-menu-item-title {
    margin-top: 10px;
    font-weight: 500;
    font-family: PingFangSC, PingFangSC-Medium;
    color: #333;
  }
  .base-menu-item-sub {
    position: relative;
    margin-top: 10px;
    font-size: 12px;
    transform: scale(0.8);
    color: #999;
  }
  .base-menu-active-item .base-menu-item-title,
  .base-menu-active-item .base-menu-item-sub {
    color: #3360f2;
  }
  .base-menu-container {
    /* display: flex;
    flex: 1;
    align-items: center;
    justify-content: center; */
    height: 92px;
    line-height: 92px;
    border-bottom: 3px solid transparent;
  }
  .base-menu-item:hover .base-menu-container {
    border-bottom: 3px solid #3360f2;
  }
  /* .base-menu-active-item::after{
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  width: 90px;
  // border-bottom: 3px solid #3360F2;
} */
  .base-menu-item-drop:hover .drop-menu {
    display: flex;
  }
  .sign-login {
    padding-right: 30px;
    .ant-btn {
      margin-right: 20px;
    }
    .tel {
      font-size: 24px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 700;
      text-align: center;
      color: #333333;
    }
  }
  @media (min-width: 1670px) and (max-width: 1870px) {
    .base-menu-wrap li {
      width: 110px;
    }
    .base-menu-wrap {
      margin-right: 2px;
    }
    .sign-login {
      padding-right: 0px;
    }
  }
  @media (min-width: 1440px) and (max-width: 1670px) {
    .base-menu-wrap li {
      width: 93px;
    }
    /* .base-menu-wrap {
      margin-right: 2px;
    } */
    .sign-login {
      padding-right: 0px;
    }
  }
  @media screen and (max-width: 1440px) {
    .base-menu-wrap li {
      width: 90px;
    }
    /* .base-menu-wrap {
      margin-right: px;
    } */
    .sign-login {
      width: 212px;
      padding-right: 0px;
    }
  }
`;

export const CusDropdown = styled(Dropdown)``;
