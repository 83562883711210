import Title from "@/components/title";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Autoplay, Thumbs, Virtual } from "swiper";
import { useEffect, useState } from "react";
import { getpartant } from "@/utils/api";

interface logoItem {
  key: string;
  logo: string;
  logo1: string;
}

const titleStyle = {
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "44px",
} as any;

const CoPartner: React.FC = () => {
  const LOGOLIST: logoItem[] = [
    {
      logo: require("@/assets/images/logo/logo1-1.png"),
      logo1: require("@/assets/images/logo/logo1-2.jpg"),
      key: "1",
    },
    {
      logo: require("@/assets/images/logo/logo2-1.jpg"),
      logo1: require("@/assets/images/logo/logo2-2.jpg"),
      key: "2",
    },
    {
      logo: require("@/assets/images/logo/logo3-1.png"),
      logo1: require("@/assets/images/logo/logo3-2.jpg"),
      key: "3",
    },
    {
      logo: require("@/assets/images/logo/logo4-1.jpg"),
      logo1: require("@/assets/images/logo/logo4-2.jpg"),
      key: "4",
    },
    {
      logo: require("@/assets/images/logo/logo5-1.jpg"),
      logo1: require("@/assets/images/logo/logo5-2.png"),
      key: "5",
    },
    {
      logo: require("@/assets/images/logo/logo6-1.jpg"),
      logo1: require("@/assets/images/logo/logo6-2.jpg"),
      key: "6",
    },
    {
      logo: require("@/assets/images/logo/logo7-1.gif"),
      logo1: require("@/assets/images/logo/logo7-2.png"),
      key: "7",
    },
    {
      logo: require("@/assets/images/logo/logo8-1.jpg"),
      logo1: require("@/assets/images/logo/logo8-2.jpg"),
      key: "8",
    },
    {
      logo: require("@/assets/images/logo/logo9-1.jpg"),
      logo1: require("@/assets/images/logo/logo9-2.png"),
      key: "9",
    },
    {
      logo: require("@/assets/images/logo/logo10-1.png"),
      logo1: require("@/assets/images/logo/logo10-2.png"),
      key: "10",
    },
    {
      logo: require("@/assets/images/logo/logo11-1.jpg"),
      logo1: require("@/assets/images/logo/logo11-2.png"),
      key: "11",
    },
    {
      logo: require("@/assets/images/logo/logo12-1.jpg"),
      logo1: require("@/assets/images/logo/logo12-2.jpg"),
      key: "12",
    },
  ];
  const [loglist, setlogList] = useState<logoItem[]>([]);
  useEffect(() => {
    getpartant({ index: 1, size: 20 }).then((res: any) => {
      if (res.data) {
        setlogList(
          res.data.map((item: any) => {
            return {
              logo: item.logUrl,
              // logo1: require('@/assets/images/logo/logo1-2.jpg'),
              key: item.id,
            };
          })
        );
      }
    });
  }, []);
  return (
    <div className="co-partner-container">
      <Title text={"合作伙伴"} titleStyle={titleStyle} />
      <div className="co-partner">
        <Swiper
          className="co-partner-swiper"
          spaceBetween={18}
          slidesPerView={7}
          autoplay
          loop
          modules={[Autoplay, Thumbs]}
        >
          {loglist &&
            loglist.length > 0 &&
            loglist.map((item) => (
              <SwiperSlide
                className="co-partner-slide"
                key={item.key}
                virtualIndex={Number(item.key)}
              >
                <>
                  <div className="co-partner-slide-wrap">
                    <img
                      src={item.logo}
                      className="co-partner-slide-image"
                      alt="icon"
                    />
                  </div>
                  {item.logo1 && (
                    <div className="co-partner-slide-wrap">
                      <img
                        src={item.logo1}
                        className="co-partner-slide-image"
                      />
                    </div>
                  )}
                </>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  );
};

export default CoPartner;
