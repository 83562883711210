import styled from "styled-components";
import info1 from "@/assets/images/home/info1.png";
import info2 from "@/assets/images/home/info2.png";
import info3 from "@/assets/images/home/info3.png";
export const RealTimeInfoWrapper = styled.div`
  margin: 76px auto 102px;
  width: 1200px;
  font-family: Alibaba, Alibaba-Regular;
  .sub-title {
    margin-bottom: 45px;
  }
  .left-col {
    padding: 24px 14px;
    width: 418px;
    height: 413px;
    box-sizing: border-box;
    background: url(${info1}) no-repeat center;
    background-size: 100%;
  }
  .right-col {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 418px);
  }
  .real-info-row {
    margin-bottom: 28px;
    // height: 564px;
  }
  .right-col-item {
    position: relative;
    margin-bottom: 8px;
    margin-left: 8px;
    width: 415px;
    height: 214px;
    padding: 20px 16px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    box-sizing: border-box;
    background-color: #f8f8f8;
    &:nth-child(2n) {
      width: 349px;
      height: 213px;
    }
    &:nth-child(2) {
      background: url(${info2}) no-repeat center;
      background-size: 100%;
    }
    &:nth-child(n + 3) {
      padding: 12px 16px;
      height: 188px;
    }
    &:nth-child(3) {
      background: url(${info3}) no-repeat center;
      background-size: 100%;
    }
  }
  .left-col-title {
    line-height: 1.2;
    margin-bottom: 25px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .left-col-info {
    margin-bottom: 30px;
    /* height: 244px; */
    font-size: 18px;
    font-family: Alibaba, Alibaba-Regular;
    font-weight: 400;
    text-align: left;
    color: #666666;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    overflow: hidden;
    text-align: left;
  }
  .info {
    font-size: 18px;
    font-weight: 400;
    color: #666;
    height: 90px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-align: left;
  }
  .info-date {
    font-size: 18px;
    font-weight: 400;
    color: #999;
  }

  .text-right {
    text-align: right;
  }
  .look-more {
    margin-top: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    font-size: 21px;
    background: #eaeaea;
    border-radius: 2px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #1848fd;
  }
  .right-col-item-title {
    line-height: 1.2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-align: left;
  }
  .right-col-item-title1 {
    /* height:90px; */
    line-height: 1.2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-align: left;
  }
  .right-col-date {
    position: absolute;
    bottom: 20px;
    right: 16px;
  }
`;
