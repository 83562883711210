import { queryClusterList } from "@/api/park";
import { List } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "umi";
import { ParkVo } from "@/models/park";
import { AddressImg, ListContainer, ListItem } from "../../style";
// import { utilsArr } from "@/utils/common"
import Storage from "@/utils/storage";
interface ResultListProps {
  height?: number;
}
const ResultList: React.FC<ResultListProps> = ({ height = 800 }) => {
  let [index, setIndex] = useState<number>(1);
  const { parkSearchInfo, keyword, parkList } = useSelector(
    (state: any) => state.park
  );
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState<number>(0);
  const [data, setData] = useState<ParkVo[]>([]);
  const dispatch = useDispatch();
  const loadMoreData = (page: any) => {
    if (loading) {
      return;
    }
    // index++;
    // setIndex(index);
    const params = {
      ...parkSearchInfo,
      index: page,
      size: 10,
      provice: keyword,
    };

    setLoading(true);
    queryClusterList(params).then((res: any) => {
      if (res.code == 1) {
        setTotal(res?.page?.count || 0);
        setData([...res.data]);
        setLoading(false);
        // if (data.length == 1) {
        //   dispatch({ type: "park/saveParkInfo", payload: res.data[0] });
        //   dispatch({ type: "park/savebackNumber", payload: 1 });
        //   dispatch({
        //     type: "park/saveMapCenter",
        //     payload: {
        //       lat: Number(res.data[0].latitude),
        //       lng: Number(res.data[0].longitude),
        //     },
        //   });
        // }
        dispatch({
          type: "park/saveClusterList",
          payload: { data: [...res.data], page: res.page },
        });
      } else {
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    index = 1;
    setIndex(Storage.get(`list-page1`) || 1);
    loadMoreData(Storage.get(`list-page1`) || 1);
  }, [parkList, parkSearchInfo]);
  return (
    <>
      <div
        style={{
          padding: "0 20px",
          fontSize: 24,
          fontWeight: 500,
          color: "#333",
          fontFamily: "PingFangSC, PingFangSC-Semibold",
        }}
      >
        {parkSearchInfo.area
          ? parkSearchInfo.area
          : parkSearchInfo.city
          ? parkSearchInfo.city
          : parkSearchInfo.province
          ? parkSearchInfo.province
          : "全国"}
        产业集群（{total}）
      </div>
      <ListContainer
        id="scrollableDiv"
        style={{
          height: height + "px",
        }}
      >
        {/* <InfiniteScroll
        dataLength={data.length}
        next={loadMoreData}
        hasMore={data.length < total}
        loader={<></>}
        endMessage={
          data.length > 10 ? <Divider plain>我是有底线的</Divider> : <></>
        }
        scrollableTarget="scrollableDiv"
      > */}
        <List
          loading={loading}
          pagination={{
            total: total,
            showSizeChanger: false,
            simple: true,
            hideOnSinglePage: true,
            onChange: (page) => {
              setIndex(page);
              Storage.set(`list-page1`, page);
              loadMoreData(page);
            },
            pageSize: 10,
          }}
          dataSource={data}
          renderItem={(item: any, index) => (
            <List.Item key={index}>
              <ListItem
                onClick={() => {
                  dispatch({
                    type: "park/saveMapCenter",
                    payload: {
                      lat: Number(item.latitude),
                      lng: Number(item.longitude),
                    },
                  });
                  dispatch({ type: "park/saveParkInfo", payload: item });
                  dispatch({ type: "park/savebackNumber", payload: 1 });
                }}
              >
                <div className="left">
                  <AddressImg>{index + 1}</AddressImg>
                  <div className="container">
                    <div className="info">
                      {[item.province, item.city, item.area, item.contact]
                        .filter((it) => it !== "")
                        .join("") || "-"}
                    </div>
                    <div className="title">{item.clusterName}</div>
                  </div>
                </div>
                <div className="right">
                  <div className="detail-btn">详情</div>
                </div>
              </ListItem>
            </List.Item>
          )}
        />
        {/* </InfiniteScroll> */}
      </ListContainer>
    </>
  );
};

export default ResultList;
