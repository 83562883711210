import { Title } from "@/styles/common-style";
import { Train2Wrapper } from "../style";
import { DoubleRightOutlined } from "@ant-design/icons";
interface Train2Props {}
export const Train2: React.FC<Train2Props> = () => {
  const train2Data = [
    {
      title: "需求诊断",
      info: ["沟通咨询需求", "共识咨询目标", " 明确交付成果"],
      bg: require("@/assets/images/more-consulting/step1.png"),
    },
    {
      title: "方案设计",
      info: ["设计企业方案", "优选企业资源", " 科学运营规划"],
      bg: require("@/assets/images/more-consulting/step2.png"),
    },
    {
      title: "培训实施",
      info: ["签订合作协议", "项目前期筹备", " 培训实施把控"],
      bg: require("@/assets/images/more-consulting/step3.png"),
    },
    {
      title: "项目结算",
      info: ["咨询效果评估", "后续发展建议"],
      bg: require("@/assets/images/more-consulting/step4.png"),
    },
  ];
  return (
    <Train2Wrapper>
      <Title
        fz={36}
        textAlign="center"
        fw={600}
        color="#000"
        style={{
          marginBottom: 56,
          fontFamily: " PingFangSC, PingFangSC-Semibold",
        }}
      >
        咨询服务流程
      </Title>
      <div className="train2-list">
        {train2Data.map((item: any, index: number) => (
          <div style={{ display: "flex", alignItems: "center" }} key={index}>
            <div
              className="train2-list-item"
              style={{
                background: `url(${item.bg}) no-repeat center`,
                backgroundSize: "100%",
              }}
            >
              <div className="item-title">{item.title}</div>
              <div className="item-info">
                {item.info.map((info: any, i: any) => (
                  <div key={i}>{info}</div>
                ))}
              </div>
            </div>
            {index != 3 && (
              <DoubleRightOutlined
                key={index + 1}
                style={{ color: "#999", fontSize: 30, margin: "0 4px" }}
              />
            )}
          </div>
        ))}
      </div>
    </Train2Wrapper>
  );
};
