import { getQueryCompanyList, queryParkList } from "@/api/park";
import { List } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "umi";
import { AddressImg, ListContainer, ListItem } from "../../style";
import Storage from "@/utils/storage";
interface ResultListProps {
  height?: number;
  onItemClick?: (value: any) => void;
}
const DetaiParkList: React.FC<ResultListProps> = ({
  height = 660,
  onItemClick,
}) => {
  let [index, setIndex] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const dispatch = useDispatch();
  const [total, setTotal] = useState<number>(0);
  const { keyword, backInfo, parkSearchInfo, parkList, pre } = useSelector(
    (state: { park: any }) => state.park
  );
  const loadMoreData = async (page: number) => {
    if (loading) {
      return;
    }
    // index++;

    // setIndex(index);
    const params = {
      ...parkSearchInfo,
      displayType: null,
      index: page,
      size: 10,
    };
    setLoading(true);
    let rst: any =
      backInfo == 52
        ? await getQueryCompanyList(params)
        : await queryParkList(params);
    if (rst.code === 1) {
      setData([...rst.data]);
      setTotal(rst?.page?.count || 0);
    }
    setLoading(false);
    (backInfo == 52
      ? getQueryCompanyList({ ...params, index: page + 1, size: 20 })
      : queryParkList({ ...params, index: page + 1, size: 20 })
    ).then((res: any) => {
      if (res.code === 1) {
        let map = new Map();
        let data = [...rst.data, ...res.data];
        for (let item of data) {
          if (!map.has(item.id)) {
            map.set(item.id, item);
          }
        }

        dispatch({
          type: "park/saveParks",
          payload: [...map.values()],
        });
      }
    });
  };

  // useEffect(() => {
  //   setData([])
  //   console.log("12", "parkSearchInfo", parkSearchInfo)
  //   if (parkList && parkList.data && parkList.displayType !== 1) {
  //     setData(parkList.data)
  //     // dispatch({
  //     //   type: "park/saveParks",
  //     //   payload: parkList.data,
  //     // })
  //     setTotal(parkList?.page?.count || 0)
  //     setIndex(1)
  //   } else {
  //     loadMoreData(1)
  //   }
  //   dispatch({
  //     type: "park/savePre",
  //     payload: [...pre, backInfo],
  //   })
  // }, [parkList])
  useEffect(() => {
    setData([]);
    console.log(total, "total", Storage.get(`list-page`));
    setIndex(Storage.get(`list-page`) || 1);
    loadMoreData(Storage.get(`list-page`) || 1);
    dispatch({
      type: "park/savePre",
      payload: [...pre, backInfo],
    });
  }, [parkList, parkSearchInfo]);
  return (
    <>
      <div
        style={{
          padding: "0 20px",
          fontSize: 24,
          fontWeight: 500,
          color: "#333",
          fontFamily: "PingFangSC, PingFangSC-Semibold",
        }}
      >
        {parkSearchInfo.area
          ? parkSearchInfo.area
          : parkSearchInfo.city
          ? parkSearchInfo.city
          : parkSearchInfo.province
          ? parkSearchInfo.province
          : "全国"}
        {backInfo == 52 ? "企业" : "产业园区"}（{total}）
      </div>
      <ListContainer
        id="scrollableDiv"
        style={{
          height: height + "px",
        }}
      >
        {/* <InfiniteScroll
        dataLength={data && data.length}
        next={loadMoreData}
        hasMore={data && data.length < total}
        loader={<></>}
        endMessage={total > 10 ? <Divider plain>我是有底线的</Divider> : <></>}
        scrollableTarget="scrollableDiv"
      > */}
        <List
          loading={loading}
          pagination={{
            total: total,
            showSizeChanger: false,
            simple: true,
            current: index,
            onChange: (page) => {
              console.log(page, page);
              setIndex(page);
              Storage.set(`list-page`, page);
              loadMoreData(page);
            },
            pageSize: 10,
          }}
          dataSource={data}
          renderItem={(item: any, index) => (
            <List.Item key={index}>
              <ListItem
                onClick={() => {
                  onItemClick?.(item);
                  if (item.companyName || item.companyPic) {
                    dispatch({ type: "park/saveCompanyInfo", payload: item });
                    dispatch({ type: "park/savebackNumber", payload: 3 });
                  } else {
                    dispatch({
                      type: "park/saveIndustrialInfo",
                      payload: item,
                    });
                    dispatch({ type: "park/savebackNumber", payload: 2 });
                  }
                  dispatch({
                    type: "park/savePre",
                    payload: [...pre, backInfo],
                  });
                  if (item.latitude && item.longitude) {
                    dispatch({
                      type: "park/saveMapCenter",
                      payload: {
                        lat: Number(item.latitude),
                        lng: Number(item.longitude),
                      },
                    });
                  }
                }}
              >
                <div className="left">
                  <AddressImg>{index + 1}</AddressImg>
                  <div className="container">
                    <div className="title">
                      {item.companyName || item.parkName}
                    </div>
                    <div className="info">
                      {[item.province, item.city, item.area, item.contact]
                        .filter((it) => it !== "")
                        .join("") || "-"}
                    </div>
                  </div>
                </div>
                <div className="right">
                  <div className="img-wrapper">
                    {(item?.companyPic || item.parkPic) && (
                      <img
                        src={
                          (item.companyPic
                            ? item?.companyPic
                            : item.parkPic && item.parkPic
                          ).split(",")[0]
                        }
                        alt=""
                        className="img"
                      />
                    )}
                  </div>
                </div>
              </ListItem>
            </List.Item>
          )}
        />
        {/* </InfiniteScroll> */}
      </ListContainer>
    </>
  );
};

export default DetaiParkList;
