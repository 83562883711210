import BaseLayout from "@/layouts";
import "@/styles/fashion-map.less";
// import LeftContainer from "./componets/left-list"
// import ParkListDetails from "./componets/park-list-details"
import { AatlasWrapper, MapWrapper, UseMapWrapper } from "./style";
import AtlasInfo from "./componets/atlas-info";

const DigitalFashionMap: React.FC = () => {
  // const useMapRef = useRef(null)
  // const dispatch = useDispatch()
  // const [showDetails, setshowDetails] = useState<boolean>()
  // const [parkList, setparkList] = useState<parkItem[]>()
  // const [parkdetails, setparkdetails] = useState<parkItem>()
  // const [visible, setVisible] = useState(true)
  // const [companData, setCompanData] = useState([])
  // const [zoom, setZoom] = useState<number>(13)

  // 获取产业园区列表
  // const getParks = () => {
  //   getParklist(params).then((res: any) => {
  //     if (res.code == 1) {
  //       setparkList(res.data)
  //       const maker = res.data.map((item: any) => {
  //         return {
  //           id: item.id,
  //           styleId: "marker",
  //           position: new TMap.LatLng(item.latitude, item.longitude),
  //           content: item.parkName,
  //         }
  //       })
  //       initMap(maker)
  //     }
  //   })
  // }

  // const locate = (map: any) => {
  //   const ipLocation = new TMap.service.IPLocation();
  //   const markers = new TMap.MultiMarker({
  //     map: map,
  //     geometries: [],
  //   });
  //   ipLocation.locate({}).then((res: any) => {
  //     const { result } = res;
  //     markers.updateGeometries([
  //       {
  //         id: 'main',
  //         position: result.location, // 将所得位置绘制在地图上
  //         styles: {
  //           default: new TMap.MarkerStyle({
  //             width: 192, // 宽度
  //             height: 72, // 高度
  //             anchor: { x: 46, y: 46 }, // 锚点位置
  //             src: require('@/assets/images/map-marker.png'), // 标注点图片url或base64地址
  //             color: '#000', // 标注点文本颜色
  //             size: 22, // 标注点文本文字大小
  //             content: '10',
  //             offset: { x: 0, y: 0 }, // 标注点文本文字基于direction方位的偏移属性
  //           }),
  //         },
  //       },
  //     ]);
  //     map.setCenter(result.location);
  //   });
  // };
  // useEffect(() => {
  //   if (mapCenter) {

  //   }
  // }, [mapCenter])
  // const getPark = (id: number) => {
  //   getParkdetail({ id }).then((res: any) => {
  //     if (res.code === 1) {
  //       setshowDetails(true)

  //       setparkdetails(res.data)
  //     }
  //   })
  // }
  return (
    <>
      <BaseLayout footer={false}>
        <AatlasWrapper>
          <AtlasInfo />
        </AatlasWrapper>
      </BaseLayout>
    </>
  );
};

export default DigitalFashionMap;
