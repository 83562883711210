import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Autoplay, Thumbs, Virtual } from "swiper";
import { useEffect, useState } from "react";
import { getpartant } from "@/utils/api";
import { Copartanter, Title } from "../style";

interface logoItem {
  key: string;
  logo: string;
  logo1: string;
}

const CoPartner: React.FC = () => {
  const [loglist, setlogList] = useState<logoItem[]>([]);
  useEffect(() => {
    getpartant({ index: 1, size: 20 }).then((res: any) => {
      if (res.data) {
        setlogList(
          res.data.map((item: any) => {
            return {
              logo: item.logUrl,
              key: item.id,
            };
          })
        );
      }
    });
  }, []);
  return (
    <Copartanter>
      <div className="co-partner-container">
        <Title style={{ textAlign: "center", marginBottom: "30px" }}>
          合作伙伴
        </Title>
        <div className="co-partner">
          <Swiper
            className="co-partner-swiper"
            spaceBetween={18}
            slidesPerView={7}
            autoplay
            loop
            modules={[Autoplay, Thumbs, Virtual]}
          >
            {loglist &&
              loglist.length > 0 &&
              loglist.map((item, index) => (
                <SwiperSlide
                  className="co-partner-slide"
                  key={index}
                  virtualIndex={Number(item.key)}
                >
                  <>
                    <div className="co-partner-slide-wrap">
                      <img src={item.logo} className="co-partner-slide-image" />
                    </div>
                    {item.logo1 && (
                      <div className="co-partner-slide-wrap">
                        <img
                          src={item.logo1}
                          className="co-partner-slide-image"
                        />
                      </div>
                    )}
                  </>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </Copartanter>
  );
};

export default CoPartner;
