import { MenuType } from "@/models/menu";
import { TABS_ITEMS } from "@/utils/common";
import { useEffect, useState } from "react";
import { history } from "umi";
// import DropMenuContent from "./drop-menu-content"
// import DropMenuItem from "./drop-menu-item"
import { DropMenuWrapper } from "./style";

interface DropMenuProps {
  showLeftMenu?: boolean;
  menus?: MenuType[];
}
const DropMenu: React.FC<DropMenuProps> = ({ showLeftMenu, menus = [] }) => {
  const [clickActive, setclickActive] = useState<string>("1");
  const [dropmenuItem, setDropmenuItem] = useState<MenuType[]>();
  useEffect(() => {
    if (showLeftMenu) {
      if (clickActive === "1") {
        setDropmenuItem(TABS_ITEMS[0].item);
      }
    } else {
      menus.length > 0 && setDropmenuItem(menus);
    }
  }, [showLeftMenu]);
  const onItemClick = (item: {
    path: any;
    label?: string;
    key: any;
    item?: MenuType[];
  }) => {
    setclickActive(item.key);
    // setDropmenuItem(item.item)
    if (item.path) {
      window.open(process.env.LOCATION_URL + "#" + item.path);
    }
  };
  return (
    showLeftMenu && (
      <DropMenuWrapper
        className={showLeftMenu ? "drop-menu" : "drop-menu menuClassName"}
      >
        <div className="menu-content">
          {TABS_ITEMS.map((item: any, index: number) => (
            <div
              className={
                item.key === clickActive
                  ? "menu-item memu-active"
                  : "menu-item "
              }
              key={index}
              onClick={() => onItemClick(item)}
            >
              <img src={item.icon} alt={item.label} className="menu-icon" />
              <span className="menu-title">{item.label}</span>
            </div>
          ))}
        </div>
        {/* {showLeftMenu && (
          <div className="left-menu">
            {TABS_ITEMS.map((item) => (
              <DropMenuItem
                item={item}
                onItemClick={() => onItemClick(item)}
                itemName={
                  clickActive === item.key ? "drop-menu-ative-item" : ""
                }
                key={item.key}
              />
            ))}
          </div>
        )}
        <div className="right-content">
          {dropmenuItem && dropmenuItem.length > 0 && (
            <DropMenuContent
              content={dropmenuItem}
              menuClass={showLeftMenu ? "" : "menu-class"}
            />
          )}
        </div> */}
      </DropMenuWrapper>
    )
  );
};

export default DropMenu;
