import { createKnowledgeDownLog, getknowledgeDownList } from "@/api/knowledge";
import { Row, List, Space, Button } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "umi";
import { DownloadListWrapper } from "../style";
import { downloadFile } from "@/utils/common";

interface pageVo {
  index: number;
  size: number;
  count: number;
  total: number;
}
const DownloadList: React.FC = () => {
  const { user } = useSelector((state: any) => state.user);
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [request, setRequest] = useState<any>({
    index: 1,
    size: 8,
    userId: user?.id,
  });
  const [page, setPage] = useState<pageVo>();
  const getCollect = () => {
    setLoading(true);
    getknowledgeDownList(request)
      .then((res: any) => {
        if (res.code === 1) {
          setData(res.data);
          setPage(res.page);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getCollect();
  }, [request]);
  return (
    <DownloadListWrapper>
      <List
        itemLayout="vertical"
        size="large"
        loading={loading}
        pagination={
          page && {
            pageSize: 8,
            total: page && page.count ? page.count : 1,
            onChange: (page) => {
              request.index = page;
              setRequest({ ...request });
              console.log(page);
            },
          }
        }
        dataSource={data}
        footer={false}
        renderItem={(item: any) => (
          <List.Item key={item.id}>
            <div className="goods-title">{item.knowledgeTitle}</div>
            <div
              className="reload-btn"
              onClick={() => {
                createKnowledgeDownLog({
                  userId: user.id!,
                  downFlag: 1,
                  knowledgeId: item?.knowledgeId!,
                }).then((res: any) => {
                  if (res.code == 1) {
                    downloadFile(
                      process.env.REACT_APP_ENV == "dev"
                        ? item.knowledgeAnnex.replace("https", "http")
                        : item.knowledgeAnnex.includes("https")
                        ? item.knowledgeAnnex
                        : item.knowledgeAnnex.replace("http", "https"),
                      item.knowledgeTitle
                    );
                  }
                });
              }}
            >
              重新下载
            </div>
          </List.Item>
        )}
      />
    </DownloadListWrapper>
  );
};

export default DownloadList;
