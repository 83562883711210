import styled from "styled-components";
import order_platform_bg from "@/assets/images/order-platform/order_platform_bg.png";
export const OrderPlatformWrapper = styled.div``;

export const BgImage = styled.div<{ bg?: string }>`
  width: 100%;
  min-width: 1440px;
  height: 400px;
  @media (min-width: 2000px) {
    & {
      height: 600px;
    }
  }
  background: url(${(props) => (props.bg ? props.bg : order_platform_bg)})
    no-repeat center;
  background-size: cover;
`;

export const OrderInfoWrapper = styled.div`
  margin: 100px auto;
  width: 1200px;
  .order-info {
    margin-bottom: 32px;
    display: flex;
    /* align-items: baseline; */
    justify-content: space-between;
  }
  .left {
    width: 480px;
    .info {
      margin-top: 34px;
      font-size: 18px;
      font-family: Alibaba, Alibaba-Regular;
      font-weight: 400;
      text-align: left;
      color: #666666;
      line-height: 36px;
    }
  }
  .right {
    width: 600px;
    height: 254px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .order-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .order-item {
      display: flex;
      align-items: center;
      width: 281px;
      height: 135px;
      padding: 22px 20px 22px 20px;
      box-sizing: border-box;
      background: #ffffff;
      border-radius: 5px;
      box-shadow: 0px 2px 7px 2px rgba(0, 0, 0, 0.17);
      img {
        width: 58px;
        height: 58px;
      }
      .order-item-info {
        margin-left: 20px;
      }

      .order-card-intro {
        margin-top: 16px;
        font-size: 18px;
        font-family: Alibaba, Alibaba-Regular;
        font-weight: 400;
        text-align: left;
        color: #666666;
      }
    }
  }
`;

//订单平台特色
export const OrderDescWrapper = styled.div`
  margin: 0 auto 90px;
  width: 1200px;
  .order-desc {
    display: flex;
    flex-wrap: wrap;
    .desc {
    }
  }
`;

export const OrderDescItem = styled.div<{ bg?: string }>`
  width: 50%;
  height: 150px;
  display: flex;
  flex-direction: column;
  padding-left: 190px;
  /* align-items: center; */
  justify-content: center;
  background: ${(props) =>
    props.bg?.indexOf("png") !== -1
      ? `url(${props.bg}) no-repeat center `
      : props.bg};
  background-size: 100%;
  .desc {
    margin-top: 4px;
    font-size: 18px;
    font-family: Alibaba, Alibaba-Regular;
    font-weight: 400;
    text-align: left;
    color: #666666;
  }
`;

export const OrderProcessWrapper = styled.div`
  margin: 0 auto 100px;
  width: 1440px;
  .process-container {
    margin: 0 auto;
    width: 1200px;
    margin-bottom: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .process-item {
    position: relative;
    width: 198px;
    &::before {
      position: absolute;
      content: "";
      right: 0;
      right: -118px;
      top: 106px;
      width: 96px;
      height: 1px;
      border: 2px dashed #979797;
    }
    &:last-child::before {
      border: none;
    }
  }

  .process-item-icon {
    width: 100%;
  }
  .process-item-desc {
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #666;
  }

  .order-slider {
    width: 1300px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .slider-comtainer {
    width: 1200px;
    margin: 0 auto;
    /* display: flex; */
    /* overflow-x: auto;
    overflow-y: hidden; */
    /* flex-wrap: nowrap;
    &::-webkit-scrollbar {
      width: 4px;
      height: 2px;
      scrollbar-arrow-color: #666;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.2);
      scrollbar-arrow-color: #666;
    }
    /*滚动条里面轨道*/
    /* &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    } */
    .order-item {
      margin-right: 15px;
      width: 320px;
      height: 135px;
      display: flex;
      align-items: center;
      background: #edf1f8;
      border-radius: 5px;
      .img-container {
        margin-right: 15px;
        width: 135px;
        height: 135px;
        background-color: #fff;
      }
      img {
        width: 135px;
        height: 135px;
      }
      .order-item-info {
        width: 185px;
        color: #666666;
        .order-goods {
          width: 130px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .order-btn {
          margin-top: 22px;
          width: 105px;
          height: 30px;
          background: #edf1f8;
          border: 1px solid #1848fd;
          border-radius: 16px;
          color: #1848fd;
        }
      }
    }
  }
  .swiper-prev,
  .swiper-next {
    width: 81px;
    height: 81px;
    img {
      width: 100%;
      height: 100%;
    }
  }
`;

export const PartnerWrapper = styled.div`
  padding: 40px 0 60px;
  background: #edf1f8;
  .sub-title {
    text-align: center;
    font-size: 18px;
    font-family: Alibaba, Alibaba-Regular;
    font-weight: 400;
    color: #333333;
  }
  .content-logo {
    width: 1200px;
    margin: 13px auto 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .icon-wrap {
      width: 150px;
      height: 113px;
    }
    .icon {
      vertical-align: middle;
      width: 100%;
    }
  }
  .download-logo {
    margin: 20px auto 0;
    width: 1200px;
    img {
      width: 100%;
    }
  }
`;
