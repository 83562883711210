import BaseFooter from "@/layouts/base-footer";
import CusHeader from "@/layouts/cus-header";
import { Title } from "@/styles/common-style";
import { Row } from "antd";
import PersionCommon from "../components/common";
import { ContentWrapper, Wrap } from "../style";
import DownloadList from "./components/list";

interface MyOrderProps {}

const MyDownload: React.FC<MyOrderProps> = () => {
  return (
    <>
      <CusHeader title="个人中心" showSearch={false}></CusHeader>
      <ContentWrapper>
        <Wrap>
          <PersionCommon />
          <Row style={{ flexDirection: "column", flex: 1 }}>
            <Title style={{ marginBottom: "12px" }}> 我的下载 </Title>
            <DownloadList />
          </Row>
        </Wrap>
      </ContentWrapper>
      <BaseFooter />
    </>
  );
};

export default MyDownload;
