import styled from "styled-components";

export const OrderListWrapper = styled.div`
  .ant-list-item {
    margin-bottom: 14px;
  }
  .nav-btn {
    margin-bottom: 30px;
  }
  .ant-radio-button-wrapper {
    margin-right: 14px;
    background: #f5fafd;
    border-radius: 4px;
    height: 40px;
    line-height: 40px;
    border: none;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    height: 0;
  }
  .ant-radio-button-wrapper-checked {
    background-color: #1848fd;
  }
  .ant-list-lg .ant-list-item {
    padding: 0;
  }
  .header {
    width: 844px;
    height: 50px;
    background: #f4f4f4;
    padding: 0 20px;
    .header-order-num {
      margin-right: 20px;
      width: 332px;
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      color: #000000;
    }
    .order-status {
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #999;
      .green {
        color: #73d13d;
      }
      .yellow {
        color: #faad14;
      }
    }
  }
  .content-info {
    padding: 20px 0;
    border: 1px solid #d8d8d8;
    border-top: none;
  }
  .left {
    .img {
      margin: 8px;
      margin-top: 0;
      width: 100px;
      height: 100px;
    }
    .order-title {
      width: 166px;
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #666666;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      overflow: hidden;
    }
    .order {
      margin-left: 20px;
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #666666;
    }
  }
  .right {
    padding: 20px;
    width: 302px;
    .goods-price {
      max-width: 142px;
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      color: #333333;
    }
    .pay {
      cursor: pointer;
      width: 100px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #ffffff;
      border: 1px solid #1848fd;
      border-radius: 2px;
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #1848fd;
    }
  }
  .ant-pagination {
    text-align: center;
    .ant-pagination-item-active {
      background-color: #1890ff;
      a {
        color: #fff;
      }
    }
  }
`;
