import { CusInput } from "@/styles/common-style";
import { useState } from "react";

import { CusInputWrapper } from "./style";

interface CusInputNumberProps {
  disabled?: boolean;
  width?: number; // 整个验证码框的宽度
  inputWidth?: number;
  inputHeight?: number;
  onChange?: (value: string) => void;
  placeholder?: string;
}
const CusInputNumber: React.FC<CusInputNumberProps> = ({
  inputWidth = 240,
  inputHeight = 54,
  width = 370,
  disabled,
  onChange,
  placeholder = "",
}) => {
  const [value, setValue] = useState("");
  return (
    <CusInputWrapper width={width}>
      <CusInput
        disabled={disabled}
        placeholder={placeholder ? placeholder : "请输入"}
        width={inputWidth}
        height={inputHeight}
        value={value}
        onChange={(e) => {
          setValue(e.target.value.replace(/[^\d]/g, ""));
          onChange?.(e.target.value.replace(/[^\d]/g, ""));
        }}
      />
    </CusInputWrapper>
  );
};

export default CusInputNumber;
