import { getSubTreeById } from "@/api/park";
import Tool from "@/components/tool";
import { Title } from "@/styles/common-style";
import eventBus from "@/utils/events";
import { Row } from "antd";
import * as echarts from "echarts";
import { useEffect, useRef, useState } from "react";
import { AtlasRightWrapper } from "../../style";

interface AtlasRightProps {}

const AtlasRight: React.FC<AtlasRightProps> = () => {
  const [fullscreen, setFullscreen] = useState<boolean>(false);
  const [path, setPath] = useState<[]>();

  const chartRef: any = useRef(null);
  const [isSelected, setIsSelected] = useState(false);

  function convertData(data: any) {
    const children = data.childCateList.map((item: any) => {
      return {
        name: item.name,
        children: item.childCateList.map((item: any) => convertData(item)),
      };
    });
    return {
      name: data.name,
      children,
    };
  }

  function handleSelectNodeChange(id: number, path: []) {
    getSubTreeById(id).then((res) => {
      const tree = convertData(res.data);
      setChart(tree);
      setPath(path);
    });
  }
  // handleSelectNodeChange(115)

  useEffect(() => {
    //监听事件总线
    eventBus.on("select-fishbone-tree-node", handleSelectNodeChange);

    return () => {
      //移出事件总线
      eventBus.removeListener(
        "select-fishbone-tree-node",
        handleSelectNodeChange
      );
    };
  }, []);

  function setChart(data: any) {
    let chartInstance = echarts.init(chartRef.current);
    let option: any = {
      tooltip: {
        trigger: "item",
        triggerOn: "mousemove",
      },
      series: [
        {
          type: "tree",
          data: [data],
          top: "1%",
          left: "15%",
          bottom: "1%",
          right: "20%",
          symbolSize: 7,
          label: {
            position: "left",
            verticalAlign: "middle",
            align: "right",
            fontSize: 12,

            formatter: function (item: any) {
              const res = echarts.format.truncateText(
                item.name,
                60,
                "14px Microsoft Yahei",
                "…",
                {}
              );
              console.log("res", item.name, res);
              return res;
            },
          },
          lineStyle: {
            // 设置线条的style等
            normal: {
              color: "#5577ff", // 折线线条颜色:红色
            },
          },
          leaves: {
            label: {
              position: "right",
              verticalAlign: "middle",
              align: "left",
            },
          },
          emphasis: {
            focus: "descendant",
          },
          expandAndCollapse: true,
          animationDuration: 550,
          animationDurationUpdate: 750,
        },
      ],
    };
    setIsSelected(true);
    chartInstance.setOption(option);
    chartInstance.resize();
  }

  return (
    <AtlasRightWrapper fullscreen={fullscreen}>
      <Row align="middle" justify="space-between">
        <Title className="title">链路节点放大器</Title>
        <Tool
          onClick={() => {
            setFullscreen(!fullscreen);
            document.body.style.overflow = !fullscreen ? "hidden" : "auto";
          }}
        />
      </Row>
      {path && path.length > 0 && (
        <Row style={{ marginLeft: 50, fontSize: 14 }}>
          {["产业链图谱 ", ...(path || [])]?.join("  >  ")}
        </Row>
      )}
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: fullscreen ? "calc(100vh - 300px)" : "calc(100% - 100px)",
        }}
        align="middle"
      >
        {!isSelected && (
          <span style={{ fontSize: 20, marginTop: fullscreen ? -300 : 0 }}>
            {" "}
            请选择节点
          </span>
        )}
        <div
          ref={chartRef}
          style={{
            height: isSelected ? "400px" : 0,
            visibility: isSelected ? "visible" : "hidden",
            width: isSelected ? "400px" : 0,
          }}
        ></div>
      </Row>
    </AtlasRightWrapper>
  );
};

export default AtlasRight;
