import { payWays } from "@/utils/common";
import { List, Col, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ContentContainer, ListWrapper, TitleWrapper } from "../../style";
import { history, useSelector } from "umi";
import { getCollectlist } from "@/api/persion";
export interface CusListVo {
  image: string;
  type: number;
  description: string;
}
interface CusListProps {}
const getItemType = (data: any) => {
  switch (data.priceType) {
    case 2:
      return "询价";
    case 1:
      return "免费";
    case 3:
      return `${data.price}${data.priceCompany == 1 ? "元/年" : "元/次"}`;
    default:
      return "免费";
  }
};
interface pageVo {
  index: number;
  size: number;
  count: number;
  total: number;
}
const CusList: React.FC<CusListProps> = () => {
  const { user } = useSelector((state: any) => state.user);
  const [page, setPage] = useState<pageVo>();
  const [loading, setLoading] = useState<boolean>(false);
  const [request, setRequest] = useState<any>({
    index: 1,
    size: 4,
    accountId: user?.id,
  });
  const [dataSource, setDataSource] = useState<any>([]);
  const getCollect = () => {
    setLoading(true);

    getCollectlist(request)
      .then((res: any) => {
        if (res.code === 1) {
          setDataSource(res.data);
          setPage(res.page);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getCollect();
  }, [request]);
  return (
    <ListWrapper>
      <List
        itemLayout="vertical"
        size="large"
        loading={loading}
        pagination={
          dataSource && dataSource.length > 0
            ? {
                onChange: (page, pageSize) => {
                  request.index = page;
                  request.size = pageSize;
                  setRequest({ ...request });
                },
                pageSize: 4,
                total: page && page.count ? page.count : 1,
              }
            : false
        }
        dataSource={dataSource}
        footer={false}
        renderItem={(item: any, index: number) => (
          <List.Item
            key={item.title}
            onClick={() => {
              // onItemClick?.(item)
              history.replace("/server/server-detail/" + item?.id);
            }}
            extra={
              <img width={265} height={199} alt="poster" src={item.poster} />
            }
          >
            <List.Item.Meta
              title={
                <TitleWrapper>
                  <div className="title">
                    {item.serviceName || "我是默认的"}
                  </div>
                  <div className="type">{getItemType(item)}</div>
                </TitleWrapper>
              }
            />
            <div
              className="ant-list-item-meta-description ql-editor"
              dangerouslySetInnerHTML={{ __html: item.brief }}
            ></div>

            {/* {item.content()} */}
            <ContentContainer>
              <Col span={24}>
                交付方式：
                {item.deliveryMethod
                  ? payWays.filter((i) => i.id == item.deliveryMethod)[0]
                      .metadataName
                  : "-"}
              </Col>
              <Col span={24}>
                <Row justify="space-between">
                  {item?.serviceSupplierName && (
                    <div>服务商：{item?.serviceSupplierName || "-"}</div>
                  )}
                  <div>
                    发布时间：
                    {item.createTime
                      ? moment(item.createTime).format("YYYY-MM-DD")
                      : "-"}
                  </div>
                </Row>
              </Col>
            </ContentContainer>
          </List.Item>
        )}
      />
    </ListWrapper>
  );
};

export default CusList;
