export default function getSearch(searchStr: string) {
  const obj: any = {};
  const searchIndex = searchStr.indexOf("?");
  const searchKeyValue = searchStr.slice(searchIndex + 1).split("&");
  for (let i = 0; i < searchKeyValue.length; i++) {
    const key = searchKeyValue[i].split("=")[0];
    const value = searchKeyValue[i].split("=")[1];
    obj[key as string] = decodeURIComponent(value); //进行编码
  }
  return obj;
}
