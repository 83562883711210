import { Title } from "@/styles/common-style";
import { Train4Wrapper } from "../style";
import { ConsultNow } from "./consult-now";

interface train4Props {}
export const Train4: React.FC<train4Props> = () => {
  const train4Data = [
    {
      title: "产品赋能",
      info: ["以极致产品赢得用户", "以数字科技武装产品"],
      bg: require("@/assets/images/more-consulting/img1.png"),
    },
    {
      title: "数字赋能",
      info: ["数字体系建立与落地", "管理者的数字领导力"],
      bg: require("@/assets/images/more-consulting/img2.png"),
    },
    {
      title: "资本赋能",
      info: ["用蜕变吸引资本", "用资本加速蜕变"],
      bg: require("@/assets/images/more-consulting/img3.png"),
    },
    {
      title: "营销赋能",
      info: ["获取流量密码 占据消费", "智引导市场趋势"],
      bg: require("@/assets/images/more-consulting/img4.png"),
    },
    {
      title: "供应链管理咨询",
      info: ["突破自我认知 图片创新思维", "图片领导能力"],
      bg: require("@/assets/images/more-consulting/img5.png"),
    },
    {
      title: "商业模式赋能",
      info: ["核心竞争力定位 企业战略定位 ", "盈利模式定位"],
      bg: require("@/assets/images/more-consulting/img6.png"),
    },
  ];
  return (
    <Train4Wrapper>
      <Title
        fz={36}
        textAlign="center"
        fw={600}
        color="#000"
        style={{
          marginBottom: 60,
          fontFamily: "PingFangSC, PingFangSC-Semibold",
        }}
      >
        管理者成长赋能体系
      </Title>
      <div className="train4-list">
        {train4Data.map((item: any, index: number) => (
          <div className="train4-list-item" key={index}>
            <div className="list-item">
              <div className="item-title">{item.title}</div>
              <img src={item.bg} alt="" className="img" />
            </div>
            <div className="item-info">
              {item.info.map((info: any, i: number) => (
                <div key={i}>{info}</div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <ConsultNow
        name={"咨询"}
        style={{
          margin: "46px auto 0",
          position: "relative",
          width: 250,
          height: 48,
          backgroundColor: "#1848fd",
          color: "#fff",
        }}
      />
    </Train4Wrapper>
  );
};
