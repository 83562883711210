import styled from "styled-components";
export const CusStepWrapper = styled.div<{ width?: number; height?: number }>`
  .steps {
    margin-bottom: 62px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .step {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: baseline;
    width: 72px;
  }
  .step:before {
    content: "";
    position: absolute;
    right: -82px;
    top: 15px;
    width: 90px;
    height: 2px;
    background-color: #d8d8d8;
  }
  .step:last-child:before {
    background-color: transparent;
  }
  .step-index {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 6px;
    width: ${(props) => (props.width ? props.width + "px" : "30px")};
    height: ${(props) => (props.height ? props.height + "px" : "30px")};
    background: #d8d8d8;
    border-radius: 50%;
    font-size: 12px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #fff;
  }
  .step-title {
    font-size: 12px;
    color: #999999;
  }
  .step-active {
    .step-index {
      background: #007aff;
    }
    .step-title {
      color: #007aff;
    }
  }
  .ant-form {
    margin-top: 60px;
    width: 312px;
  }
  .ant-form-item-label {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    width: 90px;
  }
  .container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .title {
      margin-right: 10px;
      font-size: 24px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #333333;
    }
    img {
      width: 15px;
      height: 15px;
    }
  }
`;
