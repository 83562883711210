import BaseLayout from "@/layouts";
import { Partner } from "../order-platform/components/partner";
import { BgImage } from "../order-platform/style";
import { CapacityInfo } from "./components/capacity-info";
import { OrderProcess } from "./components/factory-info";
import { OrderDesc } from "./components/order-desc";
import { CapacityCenterWrapper } from "./style";
interface CapacityCenterProps {}
const CapacityCenter: React.FC<CapacityCenterProps> = () => {
  return (
    <BaseLayout>
      <CapacityCenterWrapper>
        <BgImage bg={require("@/assets/images/d-center/capacity-center.png")} />
        <CapacityInfo />
        <OrderDesc />
        <OrderProcess />
        <Partner
          url={require("@/assets/images/order-platform/capacity-dowload.png")}
        />
      </CapacityCenterWrapper>
    </BaseLayout>
  );
};

export default CapacityCenter;
