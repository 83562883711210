import request from "@/utils/request";

export const getCollectlist = (params: any) => {
  return request("/account/serviceCollect/pageServiceList", {
    method: "post",
    requestType: "json",
    data: params,
  });
};

///order/mixOrder/pageList
export const getOrderList = (params: any) => {
  return request("/order/mixOrder/pageList ", {
    method: "post",
    requestType: "json",
    data: params,
  });
};
