import { ContactUs } from "@/utils/common";
import { CSSProperties } from "react";
import { ConsultNowWrapper } from "../style";

interface ConsultNowProps {
  style?: CSSProperties;
  name: string;
  title?: string;
}
export const ConsultNow: React.FC<ConsultNowProps> = ({
  style,
  name,
  title = "立即预约",
}) => {
  return (
    <ConsultNowWrapper style={{ ...style }} onClick={() => ContactUs(name)}>
      {title}
    </ConsultNowWrapper>
  );
};
