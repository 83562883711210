import styled from "styled-components";

export const ServerDetailContainer = styled.div`
  width: 100%;
  min-width: 1440px;
  .server-content {
    margin: 0 auto 170px;
    width: 1200px;
    .title {
      margin-bottom: 30px;
      font-size: 36px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #000000;
    }
    .html {
      img {
        width: 100%;
      }
      font-size: 24px;
      font-family: Alibaba, Alibaba-Regular;
      font-weight: 400;
      text-align: left;
      color: #666666;
    }
  }
`;

export const HeaderInfoWrapper = styled.div`
  margin-bottom: 48px;
  padding-top: 42px;
  .card-wrapper {
    width: 1200px;
    padding: 30px;
    margin: 0 auto;
    display: flex;
    background-color: #f5fafd;
    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 150px;
      background-color: #fff;
      .img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .right {
      padding-left: 20px;
      padding-right: 20px;
      width: 1000px;
      .right-title {
        font-size: 24px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        color: #333333;
      }
      .right-intro {
        margin-top: 10px;
        margin-bottom: 20px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #666666;
      }
    }
    .info {
      margin-bottom: 21px;
      display: flex;
      align-items: center;
      color: #999999;
      .download-info {
        margin-right: 84px;
      }
      .total-info {
        margin-right: 148px;
      }
    }
    .price-info {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #999999;
      .price {
        font-size: 30px;
        color: #333;
      }
    }
    .price-btn {
      cursor: pointer;
      width: 130px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      background: #1848fd;
      border-radius: 2px;
      font-size: 24px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      color: #ffffff;
    }
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 38px auto 101px;
  width: 1200px;
  height: 190px;
  background: #f4f4f4;
  border-radius: 2px;
  .titlte {
    height: 42px;
    font-size: 30px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    color: #333333;
  }
  .info {
    margin: 3px 0 13px;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #666666;
  }
  .price-btn {
    width: 140px;
    height: 50px;
    line-height: 50px;
    background: #1848fd;
    border-radius: 2px;
    font-size: 24px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
  }
`;

export const ContentInfoWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  width: 1200px;
  height: 1200px;
  .content-wrapper {
    display: block;
    /* justify-content: center;
    flex-direction: column; */
    height: 1200px;
    overflow: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 4px;
      height: 2px;
      scrollbar-arrow-color: #666;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.2);
      scrollbar-arrow-color: #666;
    }
    /*滚动条里面轨道*/
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }
  }
  .pdf_document {
    /* width: 1200px; */
    .react-pdf__Page__canvas {
      margin: 0 auto;
      max-width: 100%;
    }
  }
  .ant-list-items {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .pdf-img {
    /* margin-left: 300px; */
    max-width: 100%;
    /* height: 100%; */
  }
`;
export const ContentInfoFooter = styled.div<{ show: boolean }>`
  position: fixed;
  left: 50%;
  bottom: 44px;
  width: 1196px;
  height: 120px;
  padding: 22px;
  margin-left: -598px;
  box-sizing: border-box;
  display: ${(props) => (props.show ? "flex" : "none")};
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 0px 5px 1px rgba(26, 23, 23, 0.15);
  .price-btn {
    width: 140px;
    height: 50px;
    line-height: 50px;
    background: #1848fd;
    border-radius: 2px;
    font-size: 24px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
  }
  .left {
    display: flex;
    align-items: center;
    img {
      width: 42px;
      height: 49px;
    }
  }
  .pdf-info {
    margin-left: 23px;
    .title {
      margin-bottom: 8px;
      font-size: 24px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      color: #333333;
    }
    .price-info {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #999999;
    }
    .price {
      font-size: 30px;
      color: #333;
    }
  }
`;
