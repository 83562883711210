import request from "@/utils/request";

//创建认证
export const createAuth = (params: any) => {
  return request("/account/accountAudit/create", {
    method: "POST",
    requestType: "json",
    data: params,
  });
};

// 查询用户最新认证详情

export const getUserAuthedInfo = (id: any) => {
  return request(`/account/accountAudit/queryByAccountId/${id}`, {
    method: "get",
    requestType: "json",
  });
};

export const createCertificate = (params: any) => {
  return request("/account/accountAudit/createCertificate", {
    method: "get",
    requestType: "blob",
    params,
    headers: { "Content-Type": "application/pdf" },
  });
};
