// server_detail

import BaseFooter from "@/layouts/base-footer";
import CusHeader from "@/layouts/cus-header";
import { useEffect, useRef, useState } from "react";
import { history } from "umi";
import { useDispatch, useParams, useSelector } from "umi";
import Info from "./components/header-info";
import { ContentInfoFooter, ServerDetailContainer } from "./style";
import { FooterInfo } from "./components/footer-info";
import { ContentInfo } from "./components/content-info";
import { Spin } from "antd";
import {
  createKnowledgeDownLog,
  createOrder,
  getKnowledgeDetail,
  getQueryPreview,
} from "@/api/knowledge";
import { downloadFile } from "@/utils/common";
interface ServerDetailProps {}
const ServerDetail: React.FC<ServerDetailProps> = () => {
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.user);
  const { knowledgeDetail } = useSelector((state: any) => state.knowledge);
  const [loading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const fileReg = /^\.rar|\.zip|\.arj｜\.png|\.jpg|\.gif/i;
  const useHeaderRef = useRef<any>();
  const usefooterRef = useRef<any>();
  const payTo = (value: string) => {
    if (value == "2") {
      if (user?.id) {
        history.push("/goods-order/" + params.id);
      } else {
        history.push("/login");
      }
    } else if (value == "3") {
      createKnowledgeDownLog({
        userId: user.id!,
        downFlag: 1,
        knowledgeId: knowledgeDetail?.id!,
      }).then((res: any) => {
        if (res.code == 1) {
          downloadFile(
            process.env.REACT_APP_ENV == "dev"
              ? knowledgeDetail.annex.replace("https", "http")
              : knowledgeDetail.annex.includes("https")
              ? knowledgeDetail.annex
              : knowledgeDetail.annex.replace("http", "https"),
            knowledgeDetail.title
          );
        }
      });
    } else if (value == "1") {
      const param = {
        targetId: Number(params.id),
        downFlag: 0,
        payer: user?.id!!,
        incrementBusinessType: 1,
        payType: 1,
        payAmount: knowledgeDetail.salePrice,
      };
      createOrder(param).then((res: any) => {
        if (res.code == 1) {
          downloadFile(
            process.env.REACT_APP_ENV == "dev"
              ? knowledgeDetail.annex.replace("https", "http")
              : knowledgeDetail.annex.includes("https")
              ? knowledgeDetail.annex
              : knowledgeDetail.annex.replace("http", "https"),
            knowledgeDetail.title
          );
        }
      });
    }
  };
  useEffect(() => {
    const p = {
      id: params.id,
      userId: user?.id,
    };
    if (params.id) {
      setLoading(true);
      getKnowledgeDetail(p)
        .then(async (res: any) => {
          if (res.code == 1) {
            const param = {
              previewPage: res.data.lookPage,
              previewUrl: res.data.annex,
              knowledgeId: params.id,
              playFlag: res.data.playFlag || 0,
              knowledgeId: params.id,
            };

            if (!fileReg.test(res.data.annex)) {
              getQueryPreview(param)
                .then((rst: any) => {
                  if (rst.code === 1) {
                    let previewImgPrefix: string = rst.data.previewImgPrefix;
                    let imageBytes: any = rst.data.previewImgName
                      ? rst.data.previewImgName
                          .split(",")
                          .map((item: any) => previewImgPrefix + item)
                      : null;
                    dispatch({
                      type: "knowledge/saveKnowledgeDetail",
                      payload: {
                        ...res.data,
                        ...rst.data,
                        imageBytes,
                        coverImg: res.data.coverImg
                          ? res.data.coverImg
                          : previewImgPrefix +
                            rst.data.previewImgName.split(",")[0],
                        nofile: imageBytes ? false : true,
                      },
                    });
                  } else {
                    dispatch({
                      type: "knowledge/saveKnowledgeDetail",
                      payload: {
                        ...res.data,
                        nofile: true,
                        totalPage: res.data?.previewImgNum || 0,
                      },
                    });
                  }

                  setLoading(false);
                })
                .catch((err) => {
                  setLoading(false);
                  dispatch({
                    type: "knowledge/saveKnowledgeDetail",
                    payload: {
                      ...res.data,
                      nofile: true,
                      totalPage: res.data?.previewImgNum || 0,
                    },
                  });
                });
            } else {
              setLoading(false);
              dispatch({
                type: "knowledge/saveKnowledgeDetail",
                payload: {
                  ...res.data,
                  nofile: true,
                  totalPage: res.data?.previewImgNum || 0,
                },
              });
            }

            // let data =
            //   rst && rst.data ? { ...res.data, ...rst.data } : { ...res.data }
            // dispatch({
            //   type: "knowledge/saveKnowledgeDetail",
            //   payload: data,
            // })
          }
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  }, [params.id]);
  useEffect(() => {
    window.addEventListener("scroll", (e: any) => {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (useHeaderRef.current && usefooterRef.current) {
        let header = useHeaderRef.current;
        let footer = usefooterRef.current;
        let firstH = header.offsetHeight + 100;
        let end = header.offsetHeight + footer.offsetTop + 100;
        if (scrollTop > firstH) {
          console.log(1);
          setShow(true);
        } else {
          setShow(false);
        }
        if (scrollTop > end) {
          setShow(false);
        }
      }
    });
    return () => {
      window.removeEventListener("scroll", () => {});
    };
  }, []);
  return (
    <>
      <CusHeader
        title={"知识图谱"}
        showSearch
        placeholder="请输入您需要的知识标题"
        search={(value) =>
          history.push({
            pathname: "/knowledge",
            state: {
              keyword: value,
            },
          })
        }
      />
      <Spin spinning={loading} tip="加载中...">
        <ServerDetailContainer>
          <div ref={useHeaderRef}>
            <Info payFunc={payTo} />
          </div>
          <div ref={usefooterRef}>
            <ContentInfo url={knowledgeDetail?.annex} />
          </div>
          <ContentInfoFooter show={show}>
            {knowledgeDetail &&
              knowledgeDetail?.salePrice > 0 &&
              knowledgeDetail.playFlag !== 1 && (
                <div className="left">
                  <img
                    src={require("@/assets/images/knowledge/file_icon.png")}
                    alt=""
                  />
                  <div className="pdf-info">
                    <div className="title">{knowledgeDetail?.title}</div>
                    {knowledgeDetail?.salePrice && (
                      <div className="price-info">
                        价格：
                        <span className="price">
                          ¥{knowledgeDetail?.salePrice || 0}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )}
            <div
              className="price-btn"
              onClick={() =>
                payTo?.(
                  knowledgeDetail && knowledgeDetail?.salePrice > 0
                    ? knowledgeDetail.playFlag !== 1
                      ? "2"
                      : "3"
                    : "1"
                )
              }
            >
              {knowledgeDetail && knowledgeDetail?.salePrice > 0
                ? knowledgeDetail.playFlag !== 1
                  ? "购买下载"
                  : "下载"
                : "免费下载"}
            </div>
          </ContentInfoFooter>
          <FooterInfo payFunc={payTo} />
        </ServerDetailContainer>
      </Spin>
      <BaseFooter />
    </>
  );
};

export default ServerDetail;
