export const ContentText = () => {
  return (
    <div className="content-wrapper">
      <div className="content-text">
        <p>
          当您注册时，请您认真阅读本协议，审阅并接受或不接受本协议（未成年人应在法定监护人陪同下审阅）。
        </p>
        <p>
          若您已经注册为本网站用户，即表示您已充分阅读、理解本协议，并自愿与本网站订立本协议，且您自愿受本协议的条款约束。本网站有权随时变更本协议并在本网站上予以公告。经修订的条款一经在本网站的公布后，立即自动生效。如您不同意相关变更，必须停止使用本网站。本协议内容包括协议正文及所有橙织已公布的各类规则。所有规则为本协议不可分割的一部分，与本协议正文具有同等法律效力。一旦您继续使用本网站，则表示您已接受并自愿遵守经修订后的条款。
        </p>
        <p>
          当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容，并与橙织达成一致，成为橙织数字时尚产业创新平台的“用户”。阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序。
        </p>
        <p> 第一条 相关定义及解释</p>
        <p>
          1、“本网站或橙织数字时尚产业创新平台”：指由杭州中服科创研究院有限公司（以下简称：橙织、橙织APP、中国数字时尚产业创新平台、本站、本网站）合法所有、自主运营和管理、提供技术支持和服务的，域名为https://dfib.cnatii.com/的网站及其子网站。
        </p>
        <p>
          2、用户：在橙织数字时尚产业创新平台网上注册成为会员的法人、自然人、个体工商户及其他组织，包含雇主和服务商。
        </p>
        <p>
          3、服务商：是指在橙织数字时尚产业创新平台网上参加竞标、销售服务、出售应用等“售卖”操作的用户。
        </p>
        <p>
          4、雇主：是指在橙织数字时尚产业创新平台网上发布需求或购买增值服务等“购买”操作的用户。
        </p>
        <p>第二条 用户注册条件</p>
        <p>
          1、只有符合下列条件之一的自然人、法人或非法人组织才能申请成为本网站用户，使用本网站的服务：
        </p>
        <p>A、年满十八岁，并具有民事权利能力和民事行为能力的自然人；</p>
        <p>B、无民事行为能力人或限制民事行为能力人应经过其监护人的书面同意；</p>
        <p>
          C、根据中国法律、法规、行政规章成立并合法存在的机关、企事业单位、企业法人、社团组织、及其他等能够依法以自己的名义从事民事活动的组织。
        </p>
        <p>
          D非任何国家、国际组织或者地域实施的贸易限制、制裁或其他法律、规则限制的对象。
        </p>
        <p>
          不符合上述条件的自然人、法人或非法人组织，其与本网站之间的协议自始无效，本网站一经发现，有权立即停止对该用户的服务，并追究其使用本网站服务的一切法律责任。
        </p>
        <p>2、用户需要提供明确的联系地址和联系电话，并提供真实姓名或名称。</p>
        <p>
          3、本网站用户须承诺遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性。
        </p>
        <p> 第三条 用户的权利和义务</p>
        <p>
          1、用户有权根据本协议及本网站发布的相关规则，利用本网站发布需求信息、查询其他用户公开信息、参与需求投标，参加本网站的有关活动及有权享受本网站提供的其他有关资讯及信息服务。
        </p>
        <p>
          2、用户须自行设置并保护自己的用户账号和密码，且须对在用户账号密码下发生的所有活动（包括但不限于发布需求信息、网上点击同意各类协议、规则、参与需求投标等）承担责任。用户有权根据需要更改登录和账户提现密码。非因本网站的过错导致的任何损失由用户自行承担，该过错包括但不限于：不按照交易提示操作，未及时进行交易操作，遗忘或泄漏密码，密码被他人破解，用户使用的计算机被他人侵入。账户因用户主动泄露或因用户遭受他人攻击、诈骗等行为导致的损失及后果，橙织并不承担责任，用户应通过司法、行政等救济途径向侵权行为人追偿。
        </p>
        <p>
          3、用户应当向本网站提供真实准确的注册信息，包括但不限于真实姓名或名称、身份证号或统一社会信用代码、联系电话、地址、邮政编码等。保证本网站可以通过上述联系方式与用户进行联系。同时，用户也应当在相关资料实际变更时及时更新有关注册资料。
        </p>
        <p>
          4、用户在本网站注册的账号名称，不得有下列情形，否则橙织可拒绝该用户的注册申请：
        </p>
        <p> （1）违反宪法或法律法规规定的；</p>
        <p> （2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
        <p> （3）损害国家荣誉和利益的，损害公共利益的；</p>
        <p> （4）煽动民族仇恨、民族歧视，破坏民族团结的；</p>
        <p> （5）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
        <p> （6）散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
        <p>（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
        <p> （8）侮辱或者诽谤他人，侵害他人合法权益的；</p>
        <p> （9）含有法律、行政法规禁止的其他内容的。</p>
        <p>
          5、用户不得以虚假信息骗取账号名称注册，或其账号头像、简介等注册信息存在违法和不良信息。
        </p>
        <p> 6、用户不得冒用他人信息注册账号名称。</p>
        <p>
          7、用户不得以任何形式擅自转让或授权他人使用自己在本网站的用户帐号（实名认证通过后，不能进行变更）。
        </p>
        <p>8、用户确保在本网站上发布的需求信息真实、准确、无误导性。</p>
        <p>
          9、用户在本网站上发布需求或相关产品信息，不得违反国家法律、法规、行政规章的规定，不得侵犯他人知识产权或其他合法权益，不得违背社会公共利益或公共道德，不得违反橙织的相关规定。
        </p>
        <p>
          10、用户在本网站交易中应当遵守诚实信用原则，不得以干预或操纵发布需求等不正当竞争方式扰乱网上交易秩序，不得从事与网上交易无关的不当行为。
        </p>
        <p>
          11、用户不得采取不正当手段（包括但不限于虚假需求、互换好评等方式）提高自身或他人信用度，或采用不正当手段恶意评价其他用户，降低其他用户信用度。
        </p>
        <p>
          12、用户不得违反《银行卡业务管理办法》使用银行卡；用户不得利用信用卡套取现金（以下简称套现）；用户不得盗刷他人银行卡；用户不得明知或应知他人可能盗刷银行卡而与对方进行交易。
        </p>
        <p>
          13、用户承诺自己在使用本网站实施的所有行为遵守法律、法规、行政规章和本网站的相关规定以及各种社会公共利益或公共道德。如有违反导致任何法律后果的发生，用户将以自己的名义独立承担相应的法律责任。
        </p>
        <p>
          14、本网站仅为居间平台，不对用户之间进行的交易做任何保证。用户在本网站交易过程中如与其他用户产生纠纷，可以请求本网站从中予以协调处理。用户如发现其他用户有违法或违反本协议的行为，可以向本网站举报。
        </p>
        <p>
          15、除橙织另有规定外，用户应当自行承担因交易产生的相关费用，并依法纳税。
        </p>
        <p>
          16、未经本网站书面允许，用户不得将本网站的任何资料以及本网站在交易平台上所展示的任何信息作商业性利用（包括但不限于以复制、修改、翻译等形式制作衍生作品、分发或公开展示）。
        </p>
        <p> 17、用户不得使用以下方式登录网站或破坏网站所提供的服务：</p>
        <p>
          A、以任何机器人软件、蜘蛛软件、爬虫软件、刷屏软件或其它自动方式访问或登录本网站；
        </p>
        <p>
          B、通过任何方式对本网站内部结构造成或可能造成不合理或不合比例的重大负荷的行为；
        </p>
        <p>
          C、通过任何方式干扰或试图干扰本网站的正常工作或本网站上进行的任何活动。
        </p>
        <p>
          18、用户同意接收来自本网站的信息，包括但不限于活动信息、交易信息、促销信息等。
        </p>
        <p> 第四条　橙织的权利和义务</p>
        <p>
          1、橙织数字时尚产业创新平台仅为用户提供一个信息交流平台，是雇主发布需求和服务商提供解决方案的一个交易市场，对交易双方均不加以监视或控制。
        </p>
        <p>
          2、橙织数字时尚产业创新平台有义务在现有技术水平的基础上努力确保整个网上交流平台的正常运行，尽力避免服务中断或将中断时间限制在最短时间内，保证用户网上交流活动的顺利进行。
        </p>
        <p>
          3、橙织数字时尚产业创新平台有义务对用户在注册使用本网站信息平台中所遇到的与交易或注册有关的咨询及时作出回复。　
        </p>
        <p>
          4、橙织数字时尚产业创新平台有权基于普通人的判断对用户的注册资料进行形式审查，对存疑的注册资料，橙织有权发出通知询问用户并要求用户做出解释、改正。但本条款不构成橙织对用户提供资料的真实性作出保证。
        </p>
        <p>
          5、用户因在本网站网上交易与其他用户产生纠纷的，用户将纠纷告知橙织数字时尚产业创新平台，或橙织数字时尚产业创新平台知悉纠纷情况的，经审核后，橙织数字时尚产业创新平台有权通过电子邮件及电话联系向纠纷双方了解纠纷情况，并将所了解的情况通过电子邮件互相通知对方；用户通过司法机关依照法定程序要求本网站提供相关资料的，橙织数字时尚产业创新平台有权将用户在本网站的注册信息、交易信息及其他在本网站平台上发布的任何信息提供给司法机关。
        </p>
        <p>
          6、因网上信息平台的特殊性，橙织不承担对所有用户的交易行为以及与交易有关的其他事项进行事先审查的义务，但如发生以下情形，橙织有权无需征得用户的同意便采取限制用户的活动、向用户核实有关资料、发出警告通知、暂时中止、无限期中止及拒绝向该用户提供服务的措施：
        </p>
        <p>
          A、用户以非自然人名义进行认证之后认证主体自行注销或者经有权机关吊销或撤销的；
        </p>
        <p> B、用户违反本协议或因被举报而被橙织认定为违反本协议的相关规则；</p>
        <p>
          C、用户被其他用户或其他第三方举报存在违法交易行为，且其他用户或其他第三方提供初步证据，而橙织无法联系到该用户进行核实的；
        </p>
        <p>
          D、其他用户或其他第三方通知本网站，认为某个用户或具体交易事项存在违法或不当行为，并提供相关证据。橙织数字时尚产业创新平台以普通非专业人员的知识水平标准对相关内容进行判别，有理由认为这些内容或行为可能对他方或本网站造成财务损失或承担法律责任。
        </p>
        <p>
          7、根据国家法律、法规、行政规章规定、本协议的内容和本网站所掌握的事实依据，橙织数字时尚产业创新平台有合理理由认为该用户存在违法或违反本协议行为以及在本网站交易平台上的其他不当行为，橙织数字时尚产业创新平台有权无需征得用户的同意在本网站交易平台及所在网站上以网络发布形式公布该用户的违法行为，并有权随时对该用户作出删除其在本网站的相关信息、终止服务提供等处理。
        </p>
        <p>
          8、橙织数字时尚产业创新平台依据本协议细则，可以冻结、使用、先行赔付、退款、处置用户缴存并冻结在本网站账户内的资金。因违规而被封号的用户账户中的资金在按照规定进行处置后尚有余额的，该用户可申请提现。
        </p>
        <p>
          9、橙织数字时尚产业创新平台有权在不通知用户及无需征得用户同意的前提下，删除或采取其他限制性措施处理下列信息：包括但不限于以规避费用为目的、以炒作信用为目的、存在欺诈等恶意或虚假内容、与网上交易无关或不是以交易为目的、存在恶意竞价或其他试图扰乱正常交易秩序因素、违反公共利益或可能严重损害本网站和其他用户合法利益的信息。
        </p>
        <p> 第五条 服务的中断和终止</p>
        <p>
          1、橙织数字时尚产业创新平台可自行全权决定以任何理由(包括但不限于橙织认为用户已违反本协议及相关规则的字面意义和精神，或用户在超过180日内未登录本网站等)终止对用户的服务，并有权在两年内保存用户在本网站的全部资料（包括但不限于用户信息、产品信息、交易信息等）。同时橙织数字时尚产业创新平台可自行全权决定，在发出通知或不发出通知的情况下，随时停止提供全部或部分服务。服务终止后，橙织数字时尚产业创新平台没有义务为用户保留原账户中或与之相关的任何信息，或转发任何未曾阅读或发送的信息给用户或第三方。
        </p>
        <p>
          2、若用户申请终止本网站服务，需经橙织数字时尚产业创新平台审核同意，方可解除与本网站的协议关系，但橙织仍保留下列权利：
        </p>
        <p>
          A、橙织数字时尚产业创新平台有权在法律、法规、行政规章或橙织相关规则规定的时间内保留该用户的资料，包括但不限于以前的用户资料、交易记录等；
        </p>
        <p>
          B、若终止服务之前，该用户在本网站交易平台上存在违法行为或违反本协议的行为，橙织数字时尚产业创新平台仍可在终止服务后行使本协议所规定的权利。
        </p>
        <p> 3、用户存在下列情行之一，本网站可以终止向该用户提供服务：</p>
        <p>
          A、在用户违反本协议及相关规则规定时，橙织有权终止向该用户提供服务。橙织数字时尚产业创新平台将在中断服务时通知用户。但该用户在被橙织数字时尚产业创新平台终止提供服务后，再一次直接或间接或以他人名义注册为本网站用户的，橙织有权再次单方面终止为该用户提供服务
        </p>
        <p>
          B、橙织数字时尚产业创新平台发现用户注册资料中主要内容是虚假的，有权随时终止为该用户提供服务；
        </p>
        <p> C、本协议终止或更新时，用户未确认新的协议的；</p>
        <p> D、其它橙织数字时尚产业创新平台认为需终止服务的情况。</p>
        <p>
          4、本协议终止后，对于用户在本协议存续期间产生的交易订单，橙织可通知交易相对方并根据交易相对方的意愿决定是否关闭该等交易订单；如交易相对方要求继续履行的，则用户应当就该等交易订单继续履行本协议及交易订单的约定，并承担因此产生的任何损失或增加的任何费用。
        </p>
        <p> 第六条　橙织数字时尚产业创新平台的责任范围</p>
        <p> 当用户接受该协议时，用户应当明确了解并同意：</p>
        <p>
          1、橙织数字时尚产业创新平台不能随时预见到任何技术上的问题或其他困难。该等困难可能会导致数据损失或其他服务中断。橙织是在现有技术基础上提供的服务。橙织数字时尚产业创新平台不保证以下事项：
        </p>
        <p> A、本网站将符合所有用户的要求；</p>
        <p> B、本网站不受干扰、能够及时提供、安全可靠或免于出错；</p>
        <p> C、通过本网站获得的交易信息是正确或可靠的。</p>
        <p>
          2、经由本网站下载或取得任何资料，由用户自行考虑、衡量并且自负风险，因下载任何资料而导致用户电脑系统的任何损坏或资料流失，用户应负完全责任。希望用户在使用本网站时，小心谨慎并运用常识。
        </p>
        <p>
          3、用户经由本网站取得的建议和资讯，无论其形式或表现，绝不构成本协议未明示规定的任何保证。
        </p>
        <p>
          4、基于以下原因而造成的利润、商誉、使用、资料损失或其它无形损失，橙织不承担任何直接、间接、附带、特别、衍生性或惩罚性赔偿（即使橙织已被告知前款赔偿的可能性）：
        </p>
        <p> A、本网站的使用或无法使用；</p>
        <p> B、用户的传输或资料遭到未获授权的存取或变更；</p>
        <p> C、本网站中任何第三方之声明或行为；</p>
        <p> D、本网站在服务交易中为用户提供交易机会，推荐交易方；</p>
        <p> E、本网站其它相关事宜。</p>
        <p>
          5、本网站只是为用户提供一个服务交易的平台，对于用户所发布的需求的合法性、真实性及其品质，以及用户履行交易的能力等，橙织一律不负任何担保责任。
        </p>
        <p>
          6、本网站提供与其它互联网上的网站或资源的链接，用户可能会因此连结至其它运营商经营的网站，但不表示橙织数字时尚产业创新平台对上述运营商构成任何连带责任或保证关系。其它运营商经营的网站均由各经营者自行负责，不属于本网站控制及负责范围之内。对于存在或来源于此类网站或资源的任何内容、广告、物品或其它资料，橙织数字时尚产业创新平台亦不予保证或负责。因使用或依赖任何此类网站或资源发布的或经由此类网站或资源获得的任何内容、物品或服务所产生的任何损害或损失，橙织不负任何直接或间接的责任。
        </p>
        <p> 第七条　知识产权</p>
        <p>
          1、本网站及本网站所使用的任何相关软件、程序、内容，包括但不限于作品、图片、档案、资料、网站构架、网站版面的安排、网页设计、经由本网站或广告商向用户呈现的广告或资讯，均由橙织数字时尚产业创新平台或其它权利人依法享有相应的知识产权，包括但不限于著作权、商标权、专利权或其它专属权利等，受到相关法律的保护。未经橙织数字时尚产业创新平台或权利人明示授权，用户保证不修改、出租、出借、出售、散布本网站及本网站所使用的上述任何资料和资源，或根据上述资料和资源制作成任何种类产品。
        </p>
        <p>
          2、橙织数字时尚产业创新平台授予用户不可转移及非专属的使用权，使用户可以通过单机计算机使用本网站的目标代码（以下简称“软件”），但用户不得且不得允许任何第三方复制、修改、创作衍生作品、进行还原工程、反向组译，或以其它方式破译或试图破译源代码，或出售、转让“软件”或对“软件”进行再授权，或以其它方式移转“软件”之任何权利。用户同意不以任何方式修改“软件”，或使用修改后的“软件”。
        </p>
        <p>3、用户不得经由非本网站所提供的界面使用本网站。 第八条　隐私权</p>
        <p> 1、信息使用</p>
        <p>
          A、除本协议另有约定的，未经用户许可橙织不会向任何人出售或出借用户的个人或法人信息；
        </p>
        <p>
          B、橙织数字时尚产业创新平台亦不允许任何用户以任何手段收集、编辑、出售或者无偿传播其他用户的个人或法人信息。任何用户如从事上述活动，一经发现，橙织有权立即终止与该用户的服务协议，查封其账号，构成刑事犯罪的，橙织有权移送公安机关或其他相关机构处置。
        </p>
        <p> 2、信息披露 用户的个人或法人信息将在下述情况下部分或全部被披露：</p>
        <p> A、经用户同意，向第三方披露；</p>
        <p>
          B、用户在使用本网站过程中涉及到知识产权类纠纷，有他方主张权利的，本网站在审核主张方提交的资料后认为披露用户信息有助于纠纷解决的；
        </p>
        <p>
          C、根据法律的有关规定，或者行政、司法机关的要求，向第三方或者行政、司法机关披露；
        </p>
        <p>
          D、若用户出现违反中国有关法律或者本网站规定的情况，需要向第三方披露；
        </p>
        <p>
          E、为提供用户所要求的产品和服务，而必须和第三方分享用户的个人或法人信息；
        </p>
        <p>
          F、为保护用户或橙织数字时尚产业创新平台关联方的合法权益，橙织有权将用户的个人信息用于预防、发现、调查以下事项：欺诈、危害安全、违法或违反与橙织数字时尚产业创新平台或橙织数字时尚产业创新平台关联方协议、政策或规则的行为；
        </p>
        <p>
          G、在遵循隐私权保护以及其他相应的保密安全措施的前提下，橙织有权将用户的个人信息提供给相关合作方，并授权相关合作方根据橙织的指令处理相关信息；
        </p>
        <p>
          H、其它橙织数字时尚产业创新平台根据法律或者网站规定认为合适的披露。
        </p>
        <p> 若您不同意以上内容，请立即停止使用橙织平台服务。</p>
        <p>
          用户或者第三方申请本网站披露其他用户信息的，本网站有权视实际情况要求申请人出具申请书，申请书内容应包含申请披露的信息范围、用途及使用承诺等内容。
        </p>
        <p> 3、信息安全</p>
        <p>
          A、在使用本网站服务进行网上交易时，请用户妥善保护自己的个人或法人信息，仅在必要的情形下向他人提供;
        </p>
        <p>
          B、如果用户发现自己的个人或法人信息泄密，尤其是用户账户或“支付账户管理”账户及密码发生泄露，请用户立即联络本网站客服，以便橙织配合采取相应措施。
        </p>
        <p> 第九条　不可抗力</p>
        <p>
          因不可抗力或者其他意外事件，使得本协议的履行不可能、不必要或者无意义的，双方均不承担责任。本协议所称之不可抗力意指不能预见、不能避免并不能克服的客观情况，包括但不限于战争、台风、水灾、火灾、雷击或地震、罢工、暴动、法定疾病、黑客攻击、网络病毒、电信部门技术管制、政府行为或任何其它自然或人为造成的灾难等客观情况。
        </p>
        <p> 第十条　保密</p>
        <p>
          用户保证在使用本网站过程中所获悉的属于橙织及他方的且无法自公开渠道获得的文件及资料（包括但不限于商业秘密、公司计划、运营活动、财务信息、技术信息、经营信息及其他商业秘密）予以保密。未经该资料和文件的原提供方同意，用户不得向第三方泄露该商业秘密的全部或者部分内容。但法律、法规、行政规章另有规定或者双方另有约定的除外。
        </p>
        <p> 第十一条　通知与送达</p>
        <p>
          1、您在注册成为橙织数字时尚产业创新平台用户，并接受橙织数字时尚产业创新平台服务时，您应该向橙织提供真实有效的联系方式（包括您的电子邮件地址、联系电话、联系地址等），对于联系方式发生变更的，您有义务及时更新有关信息，并保持可被联系的状态。
        </p>
        <p>
          2、橙织数字时尚产业创新平台通过上述联系方式向用户发出通知，其中以电子的方式发出的书面通知，包括但不限于在橙织公告，向用户提供的联系电话发送手机短信，向用户提供的电子邮件地址发送电子邮件，向您的账号发送系统消息以及站内信信息，在发送成功后即视为送达；以纸质载体发出的书面通知，按照提供联系地址交邮后的第五个自然日即视为送达。
        </p>
        <p>
          3、用户应当保证所提供的联系方式是准确、有效的，并进行实时更新。如果因提供的联系方式不确切，或不及时告知变更后的联系方式，使通知或其他法律文书无法送达或未及时送达，由用户自行承担由此可能产生的法律后果。
        </p>
        <p>第十二条　交易纠纷解决方式</p>
        <p>
          1、本协议及其规则的有效性、履行和与本协议及其规则效力有关的所有事宜，将受中华人民共和国法律管辖，任何争议仅适用中华人民共和国法律。
        </p>
        <p>
          2、您在橙织数字时尚产业创新平台交易过程中与其他用户发生争议的，您或其他用户中任何一方均有权选择以下途径解决：
        </p>
        <p> （1）与争议相对方自主协商；</p>
        <p>（2）使用橙织数字时尚产业创新平台提供的争议调处服务；</p>
        <p>（3）请求消费者协会或者其他依法成立的调解组织调解；</p>
        <p> （4）向有关行政部门投诉；</p>
        <p>（5）根据与争议相对方达成的仲裁协议（如有）提请仲裁机构仲裁；</p>
        <p>（6）向人民法院提起诉讼。</p>
        <p>
          3、橙织数字时尚产业创新平台有权受理并调处用户之间因交易服务产生的纠纷，同时有权单方面独立判断其他用户对服务商的举报及索偿是否成立，若判断索偿成立，则橙织数字时尚产业创新平台有权划拨服务商已支付的担保金或交纳的保证金以及账户余额进行相应偿付。橙织数字时尚产业创新平台没有使用自用资金进行偿付的义务，但若进行了该等支付，服务商应及时赔偿橙织数字时尚产业创新平台的全部损失，否则橙织有权通过前述方式抵减相应资金或权益，如仍无法弥补损失，则橙织保留继续追偿的权利。因橙织数字时尚产业创新平台非司法机关，您完全理解并承认，橙织数字时尚产业创新平台对证据的鉴别能力及对纠纷的处理能力有限，受理交易纠纷完全是基于服务商之委托，不保证处理结果符合服务商的期望，橙织有权决定是否参与争议的调处。
          如用户对调处决定不满意，用户仍有权采取其他争议处理途径解决争议，但通过其他争议处理途径未取得终局决定前，用户仍应先履行调处决定。
        </p>
        <p>第十三条　完整协议</p>
        <p>
          本协议由本协议条款与《橙织用户服务协议》《隐私政策》等网站公示的各项规则组成，相关名词可互相引用参照，如有不同理解，以本协议条款为准。
          开发者对本协议理解和认同，开发者即对本协议所有组成部分的内容理解并认同，一旦开发者使用本网站服务，甲乙双方即受本协议所有组成部分的约束。
        </p>
        <p> 第十四条　法律适用、管辖及其他</p>
        <p>
          1、本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如法律无相关规定的，参照商业惯例及/或行业惯例。
        </p>
        <p>
          2、您因使用橙织数字时尚产业创新平台所产生及与橙织数字时尚产业创新平台有关的争议，由橙织与您协商解决。协商不成时，任何一方均可向被告所在地人民法院提起诉讼。
        </p>
        <p>
          3、本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。
        </p>
        <p>
          4、橙织数字时尚产业创新平台对本服务协议包括基于本服务协议制定的各项规则拥有最终解释权。
        </p>
        <p>5、本协议自服务商在橙织注册时生效。</p>
      </div>
    </div>
  );
};
