import { getFishboneTree } from "@/api/park";
import { useEffect, useRef, useState } from "react";

import eventBus from "@/utils/events";
import { colors, fishboneTopHeight } from "./fishbone-chart-utils";
import "./fishbone-chart.css";
interface IArrowProps {
  text: string;
  rectangleHeight: number;
  rectangleWidth: number;
  arrowHeight: number;
  arrowWidth: number;
  background: string;
}
const Arrow = (props: IArrowProps) => {
  const {
    text,
    rectangleHeight,
    rectangleWidth,
    arrowHeight,
    arrowWidth,
    background,
  } = props;

  return (
    <div
      style={{
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className="rectangle"
        style={{
          height: `${rectangleHeight}px`,
          width: `${rectangleWidth}px`,
          background,
          verticalAlign: "middle",
          textAlign: "center",
        }}
      >
        <span
          style={{
            height: `${rectangleHeight / 2}px`,
            lineHeight: `${rectangleHeight}px`,
            color: "#fff",
            fontSize: `${rectangleHeight / 2 - 6}px`,
          }}
        >
          {text}
        </span>
      </div>
      <div
        className="arrow"
        style={{
          marginLeft: -1,
          display: "inline-block",
          verticalAlign: "middle",
          borderLeftWidth: arrowWidth,
          borderRightWidth: 0,
          borderTopWidth: `${arrowWidth * 2}px`,
          borderBottomWidth: `${arrowWidth * 2}px`,
          width: 0,
          height: 0,
          borderWidth: `${arrowHeight / 2}px 0px ${
            arrowHeight / 2
          }px ${arrowWidth}px`,
          borderColor: `transparent`,
          borderLeftColor: background,
          borderStyle: "solid",
        }}
      ></div>
    </div>
  );
};

const Top = (props: any) => {
  const { list, topHeight, path } = props;

  return (
    <div
      style={{
        marginRight: 120,
      }}
    >
      {list.map((item: any, index: number) => (
        <Secondary
          key={index}
          path={path}
          topHeight={topHeight}
          position="top"
          style={{ float: "right" }}
          {...item}
        />
      ))}
    </div>
  );
};
const Bottom = (props: any) => {
  const { list, topHeight, path } = props;
  return (
    <div
      style={{
        marginRight: 120,
      }}
    >
      {list.map((item: any, index: number) => (
        <Secondary
          topHeight={topHeight}
          key={index}
          path={path}
          position="bottom"
          style={{ float: "right" }}
          {...item}
        />
      ))}
    </div>
  );
};

const Tertiary = (props: any) => {
  const { list, position, topHeight, path } = props;

  const transform = `skew(${position === "bottom" ? 20 : -20}deg)`;
  const transformOrigin = position === "top" ? "bottom" : "bottom";

  return (
    <div
      style={{
        height: topHeight,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
      }}
    >
      {list &&
        list.length > 0 &&
        list.map((item: any, index: string) => (
          <div
            key={index}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              transform,
              transformOrigin,
            }}
          >
            <div
              style={{
                height: 25,
                lineHeight: "25px",
                width: 81,
                background: item.isHaveSub
                  ? "rgb(256,215,68)"
                  : "rgb(241,241,241)",
                borderRadius: 15,
                cursor: "pointer",
                textAlign: "center",
                marginRight: 22,
                color: "rgb(136,136,136)",
              }}
              className="name"
              onClick={() => {
                eventBus.emit("select-fishbone-tree-node", item.id, [
                  ...path,
                  item.name,
                ]);
              }}
            >
              {item.name}
              {item.count > 0 && <span>({item.count})</span>}
            </div>
            {!!item.isHaveSub && (
              <div
                style={{
                  marginLeft: -15,
                  height: 15,
                  width: 15,
                  border: "1px solid rgb(136,136,136)",
                  borderRadius: 8,
                  fontSize: 8,
                  textAlign: "center",
                  display: "flex",
                  marginRight: 2,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>+</span>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};
const Secondary = (props: any) => {
  const { name, childCateList, position, topHeight, path } = props;
  const transform = `skew(${position === "top" ? 20 : -20}deg)`;
  const textTransform = `skew(${position === "bottom" ? 20 : -20}deg)`;
  const transformOrigin = position === "top" ? "bottom" : "top";
  const secondaryHeight = 55;
  return (
    <div
      style={{
        float: "right",
        width: 140,
        marginLeft: 20,
        transform,
        transformOrigin,
        borderRight: "1px solid rgb(221,221,221)",
      }}
    >
      {position == "top" && (
        <Tertiary
          list={childCateList}
          position={position}
          path={[...path, name]}
          topHeight={topHeight - secondaryHeight}
        />
      )}
      <div
        style={{
          height: secondaryHeight,
          lineHeight: "55px",
          fontSize: `20px`,
          color: "rgb(85,119,255)",
          width: 140,
          background: "rgb(232,239,255)",
          textAlign: "center",
          verticalAlign: "middle",
        }}
      >
        <div
          className="name"
          style={{ transform: textTransform, transformOrigin }}
        >
          {name}
        </div>
      </div>
      {position == "bottom" && (
        <Tertiary
          list={childCateList}
          position={position}
          path={[...path, name]}
          topHeight={topHeight - secondaryHeight}
        />
      )}
    </div>
  );
};

const Primary = (props: any) => {
  const { name, childCateList, loading, color, index, topHeight } = props;
  const childCount = childCateList?.length || 0;
  const width = Math.max(((childCount + 1) >> 1) * 160 + 90 + 60, 300);

  const topList = [],
    bottomList = [];
  for (let i = 0; i < childCount; i++) {
    if (i % 2 == 0) {
      topList.push(childCateList[i]);
    } else {
      bottomList.push(childCateList[i]);
    }
  }

  return (
    <div
      style={{
        visibility: loading ? "hidden" : "visible",
        display: "inline-flex",
        flexDirection: "column",
        marginRight: -60,
        zIndex: 100 - index,
      }}
    >
      <div style={{ height: 100 }}></div>
      <div style={{ height: topHeight }}>
        <Top list={topList} topHeight={topHeight} path={[name]}></Top>
      </div>
      <Arrow
        text={name}
        rectangleHeight={80}
        rectangleWidth={width}
        arrowHeight={160}
        arrowWidth={90}
        background={color}
      />
      <div style={{ height: topHeight }}>
        <Bottom list={bottomList} topHeight={topHeight} path={[name]}></Bottom>
      </div>
      <div style={{ height: 100 }}></div>
    </div>
  );
};

export default function fishboneChart(props: any) {
  const { fullscreen } = props;

  const [tree, setTree] = useState([1]);

  const [topHeight, setTopHeight] = useState(0);

  const [scale, setScale] = useState(1);

  const [loading, setLoading] = useState(true);

  const first = useRef(true);

  const handleSetZoom = async () => {
    const containerHeight = parseInt(
      getComputedStyle(document.getElementById("scroll-container")!).height
    );

    if (tree.length === 1 && tree[0] == 1) {
      getFishboneTree().then((res) => {
        setTree(res.data);
        const height = fishboneTopHeight(res.data);
        const chartHeight = height * 2 + 160;
        let scale = (containerHeight / chartHeight) * 0.9;
        setTopHeight(height);
        setScale(scale);
      });
    } else {
      const height = fishboneTopHeight(tree);
      const chartHeight = height * 2 + 160;
      let scale = (containerHeight / chartHeight) * 0.9;
      setTopHeight(height);
      setScale(scale);
    }
  };

  useEffect(() => {
    handleSetZoom();
  }, [fullscreen]);

  const [flag, setFlag] = useState(false);

  const cursorPosition: any = useRef({});
  const scrollPosition: any = useRef({});

  const handleMouseDown = (event: any) => {
    let dom = document.getElementById("scroll-container")!;

    scrollPosition.current.scrollLeft = dom.scrollLeft;
    scrollPosition.current.scrollTop = dom.scrollTop;
    cursorPosition.current.pageX = event.pageX;
    cursorPosition.current.pageY = event.pageY;
    setFlag(true);
  };
  //鼠标移动
  const handleMouseMove = (event: any) => {
    event.preventDefault();
    let dom = document.getElementById("scroll-container")!;

    if (flag) {
      const pageX = event.pageX;
      const pageY = event.pageY;

      let X = pageX - cursorPosition.current.pageX;
      let Y = pageY - cursorPosition.current.pageY;

      dom.scrollLeft = scrollPosition.current.scrollLeft - X;
      dom.scrollTop = scrollPosition.current.scrollTop - Y;
    }
  };
  //鼠标释放
  const handleMouseUp = (event: any) => {
    setFlag(false);
  };

  const len = tree.length;
  const color = colors(len);

  const setAlignCenter = () => {
    const dom = document.getElementById("scroll-container")!;
    console.log("dom", dom);
    if (!dom) {
      return;
    }

    let delay = 0;
    if (first.current) {
      delay = 1200;
    }
    setLoading(true);
    setTimeout(() => {
      dom.scrollLeft = 10000;
      dom.scrollTop = 10000;
      dom.scrollLeft = dom.scrollLeft / 2;
      dom.scrollTop = dom.scrollTop / 2;
      setLoading(false);
      first.current = false;
    }, delay);
  };

  useEffect(() => {
    setAlignCenter();
  }, [fullscreen]);

  return (
    <div
      onMouseLeave={handleMouseUp}
      onMouseDown={handleMouseDown}
      onMouseMoveCapture={handleMouseMove}
      onMouseUp={handleMouseUp}
      id="scroll-container"
      style={{
        height: fullscreen ? "calc(100vh - 240px)" : "calc(100% - 187px)",
        width: "calc(100% - 60px)",
        overflow: "scroll",
        margin: 30,
        boxSizing: "content-box",
      }}
    >
      <div
        style={{
          flexWrap: "nowrap",
          display: "inline-flex",
          margin: "50px 200px",
          zoom: fullscreen ? 0.8 : Math.max(scale, 0.5),
        }}
      >
        <div style={{ width: 200 }}></div>
        {tree.map((item: any, index: number) => {
          return (
            <Primary
              loading={loading}
              key={index}
              color={color[index]}
              index={index}
              topHeight={topHeight}
              {...item}
            />
          );
        })}
        <div style={{ width: 100 }}></div>
      </div>
    </div>
  );
}
