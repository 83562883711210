import { Title } from "@/styles/common-style";
import { TABS_ITEMS } from "@/utils/common";
import { CapacityInfoWrapper } from "../style";
import { Button, Modal } from "antd";
import { useState } from "react";
interface CapacityInfoProps {}
export const CapacityInfo: React.FC<CapacityInfoProps> = () => {
  const tabs = TABS_ITEMS[2];
  const [visible, setvisible] = useState<boolean>(false);
  const [tab, setTab] = useState<any>();
  return (
    <CapacityInfoWrapper>
      {tabs.item.map((it: any, index) => (
        <div
          className="card"
          key={index}
          onClick={() => {
            it && it.img && setvisible(true);
          }}
        >
          <div className="title-info">
            <Title fz={24} fw={600} color="#000">
              {it.title}
            </Title>
            <img src={it.icon} alt={it.icon} />
          </div>
          <div className="intro">{it.intro}</div>
          <Button type="primary">去找工厂</Button>
        </div>
      ))}
      {visible && (
        <Modal
          width={400}
          title="扫一扫进入了解详情"
          style={{ zIndex: 999999999999999 }}
          visible={visible}
          onCancel={() => setvisible(false)}
          footer={false}
        >
          <img
            src={require("@/assets/images/factory.jpg")}
            className="modal-img"
            alt="二维码"
          />
        </Modal>
      )}
    </CapacityInfoWrapper>
  );
};
