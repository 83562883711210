import { Title } from "@/styles/common-style";
import { TABS } from "@/utils/common";
import { useState } from "react";
import MarketInfo from "./market-item";
import { MarketTabPane, MarketTabs, MarketWrapper } from "./style";

interface MarketProps {}
const Market: React.FC<MarketProps> = () => {
  const [tabs, setTabs] = useState<any>(TABS);
  const [currentItem, setCurrentItem] = useState<any>(TABS[0]);
  return (
    <MarketWrapper>
      <Title textAlign="center" className="market-title">
        科技“服装”高融合 跨行业跨领域赋能
      </Title>
      <MarketTabs
        defaultActiveKey="0"
        centered
        onChange={(key) => {
          setCurrentItem(tabs[key]);
        }}
      >
        {tabs &&
          tabs.map((tab: any, index: any) => (
            <MarketTabPane
              tab={
                <Title className="market-tab-title" fz={18}>
                  {tab.title}
                </Title>
              }
              key={index}
            >
              <MarketInfo data={currentItem} />
            </MarketTabPane>
          ))}
      </MarketTabs>
    </MarketWrapper>
  );
};

export default Market;
