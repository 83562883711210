import styled from "styled-components";

export const H5Wrapper = styled.div`
  position: relative;
  margin: 20px 20px 0;
  background: #fff;
  .info-title {
    margin-bottom: 6px;
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    color: #000000;
  }
  .html {
    height: calc(100% - 150px);
    padding-bottom: 100px;
    overflow: auto;
    &.ql-editor img {
      height: auto;
    }
  }
  .concat-wrap {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    background: #fff;
  }
  .concat {
    width: 100%;
    height: 60px;
    background: #326aff;
    border-radius: 7px;
  }
  .h5-form {
    .ant-form-item-label label {
      font-size: 18px;
    }
    @media (max-width: 575px) {
      &.ant-form .ant-form-item .ant-form-item-label {
        flex: 0 0 50%;
        width: 100px !important;
        padding: 0;
        font-size: 18px;
      }
      &.ant-form .ant-form-item .ant-form-item-control {
        flex: 0 0 50%;
      }
      &.ant-form .ant-form-item.text .ant-form-item-label {
        flex: 0 0 0;
      }
      &.ant-form .ant-form-item.multiple .ant-form-item-control,
      &.ant-form .ant-form-item.submit .ant-form-item-control,
      &.ant-form .ant-form-item.text .ant-form-item-control {
        flex: 0 0 100%;
      }
    }
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      .ant-checkbox-wrapper {
        margin-bottom: 2px;
        margin-top: 2px;
      }
      .ant-checkbox-wrapper span:last-child {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #666666;
      }
    }
  }
`;

export const H5Content = styled.div`
  margin-top: 0;
  .rv-cell {
    flex-direction: column;
  }
  .rv-field .rv-field__label {
    margin-bottom: 8px;
    width: 100%;
  }
`;
