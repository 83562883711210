import { Title } from "@/styles/common-style";
import { useEffect, useState } from "react";
import { CaseInfoWrapper, TabsCover } from "./style";

interface CaseInfoProps {}
const CaseInfo: React.FC<CaseInfoProps> = () => {
  const [tabs] = useState<any>([
    {
      title: "企业服务案例",
      img: require("@/assets/images/home/case_icon_1.png"),
      bgImage: require("@/assets/images/home/case_bg_1.png"),
      info: () => {
        return (
          <>
            <div className="case-intro">
              <Title color="#fff" fz={18} className="info-title">
                创新平台为时尚企业数字化快反转型升级提供赋能驱动。
              </Title>
              <div className="info">
                助力<span className="case-info-orange">100+</span>
                时尚企业实现数字化转型；
              </div>
              <div className="info">
                帮助<span className="case-info-orange">100+</span>
                时尚企业实现技术咨询顶层规划；
              </div>
              <div className="info">
                服务<span className="case-info-orange">1000+</span>
                时尚企业为其提供数字化咨询服务；
              </div>
              <div className="info">
                链接<span className="case-info-orange">5000+</span>
                时尚企业为其提供数字化咨询服务；
              </div>
            </div>
          </>
        );
      },
      items: [
        { img: require("@/assets/images/home/case/logo1.jpg") },
        { img: require("@/assets/images/home/case/logo2.jpg") },
        { img: require("@/assets/images/home/case/logo3.jpg") },
        { img: require("@/assets/images/home/case/logo4.jpg") },
        { img: require("@/assets/images/home/case/logo5.jpg") },
        { img: require("@/assets/images/home/case/logo6.jpg") },
        { img: require("@/assets/images/home/case/logo7.jpg") },
        { img: require("@/assets/images/home/case/logo8.jpg") },
        { img: require("@/assets/images/home/case/logo9.jpg") },
        { img: require("@/assets/images/home/case/logo10.jpg") },
        { img: require("@/assets/images/home/case/logo11.jpg") },
        { img: require("@/assets/images/home/case/logo12.jpg") },
      ],
    },
    {
      title: "行业服务案例",
      img: require("@/assets/images/home/case_icon_2.png"),
      bgImage: require("@/assets/images/home/case_bg_2.png"),
      info: () => {
        return (
          <>
            <div className="case-intro">
              <Title color="#fff" fz={18} className="info-title">
                创新平台为支持时尚行业的发展输出资源、标准、技术及专业人才。
              </Title>
              <div className="info">
                <span>
                  线下开展
                  <span className="case-info-orange">60+</span>
                  场次培训服务
                </span>
                <span>
                  培养
                  <span className="case-info-orange">2000+</span>
                  人才
                </span>
              </div>
              <div className="info">
                <span>
                  线上开展
                  <span className="case-info-orange">30+</span>
                  期培训服务
                </span>
                <span>
                  培养
                  <span className="case-info-orange">50000+</span>
                  人才
                </span>
              </div>
              <div className="info">
                线下组织
                <span className="case-info-orange">10+</span>
                场交流分享活动
              </div>
              <div className="info">
                线上组织
                <span className="case-info-orange">100+</span>
                场沙龙座谈活动
              </div>
            </div>
          </>
        );
      },
      items: [
        { img: require("@/assets/images/home/case/logo1.png") },
        { img: require("@/assets/images/home/case/logo2.png") },
        { img: require("@/assets/images/home/case/logo3.png") },
        { img: require("@/assets/images/home/case/logo4.png") },
        { img: require("@/assets/images/home/case/logo5.png") },
        { img: require("@/assets/images/home/case/logo6.png") },
        { img: require("@/assets/images/home/case/logo7.png") },
        { img: require("@/assets/images/home/case/logo8.png") },
      ],
    },
    {
      title: "科技服务案例",
      img: require("@/assets/images/home/case_icon_2.png"),
      bgImage: require("@/assets/images/home/case_bg_3.png"),
      info: () => {
        return (
          <>
            <div className="case-intro">
              <Title color="#fff" fz={18} className="info-title">
                联合时尚行业顶尖科研机构及院校，共同分析行业科技创新发展情况及总结未来新趋势，为相关部门及企业提供参考。
              </Title>
              <div className="info">
                发布
                <span className="case-info-orange">10+</span>
                项时尚行业标准体系
              </div>
              <div className="info">
                荣获
                <span className="case-info-orange">20+</span>
                项行业资质及荣誉
              </div>
              <div className="info">
                合作
                <span className="case-info-orange">20+</span>
                所国内外时尚行业科研院校及机构
              </div>
            </div>
          </>
        );
      },
      items: [
        { img: require("@/assets/images/home/case/logo7.jpg") },
        { img: require("@/assets/images/home/case/logo8.jpg") },
        { img: require("@/assets/images/home/case/logo9.jpg") },
        { img: require("@/assets/images/home/case/logo10.jpg") },
        { img: require("@/assets/images/home/case/logo11.jpg") },
        { img: require("@/assets/images/home/case/logo12.jpg") },
      ],
    },
    {
      title: "政府服务案例",
      img: require("@/assets/images/home/case_icon_2.png"),
      bgImage: require("@/assets/images/home/case_bg_4.png"),
      info: () => {
        return (
          <>
            <div className="case-intro">
              <Title color="#fff" fz={18} className="info-title">
                创新平台助力政府精准引领区域的时尚产业发展。
              </Title>
              <div
                className="info "
                style={{ marginTop: 30, fontSize: 18, lineHeight: "25px" }}
              >
                杭州、温州、宁波、海宁、柯桥、长垣、赣州、深圳、上海、辛集、甘孜
              </div>
            </div>
          </>
        );
      },
    },
  ]);
  const [currBgImage, setCurrBgImage] = useState<string>("");
  const [current, setCurrent] = useState<any>(tabs[0]);
  let [currentId, setCurrentId] = useState<any>(0);
  let timer: any = null;
  useEffect(() => {
    clearInterval(timer);
    timer = setInterval(() => {
      if (currentId == tabs.length - 1) {
        currentId = 0;
        setCurrentId(currentId);
      } else {
        currentId++;
        setCurrentId(currentId);
      }
      setCurrent(tabs[currentId]);
      setCurrBgImage(tabs[currentId].bgImage);
    }, 3000);
    return () => {
      clearInterval(timer);
    };
  }, [currentId]);
  return (
    <CaseInfoWrapper bgImage={currBgImage}>
      <Title textAlign="center">经典案例</Title>
      <Title
        textAlign="center"
        fz={18}
        color="#333"
        className="sub-title"
        fw={400}
      >
        探究学习行业先进方案，创新行业发展新动能
      </Title>
      <div className="case-info-content">
        <div className="left-content">
          <Title fz={24} color="#fff" className="title">
            {current.title}
          </Title>
          {current.info && current.info()}
          <div className="case-list">
            {current.items &&
              current.items.map((item: any, index: number) => (
                <div
                  style={{ backgroundColor: "#fff" }}
                  key={index}
                  className="item-img"
                >
                  <img src={item.img} className="img" />
                </div>
              ))}
          </div>
        </div>
        <div className="right-content">
          {tabs &&
            tabs.map((tab: any, index: any) => (
              <TabsCover
                key={index}
                className={
                  currentId == index
                    ? "tab-content-active tab-content"
                    : "tab-content"
                }
                onClick={() => {
                  clearInterval(timer);
                  setCurrBgImage(tab.bgImage);
                  setCurrent(tab);
                  setCurrentId(index);
                }}
              >
                <Title fz={18} color="#fff" className="tab-content-title">
                  {tab.title}
                </Title>
              </TabsCover>
            ))}
        </div>
      </div>
    </CaseInfoWrapper>
  );
};

export default CaseInfo;
