import { Title } from "@/styles/common-style";
import { TABS_ITEMS } from "@/utils/common";
import { useState } from "react";
import { Row, Col, Modal } from "antd";
import { OrderInfoWrapper } from "../style";

interface OrderInfoProps {}
export const OrderInfo: React.FC<OrderInfoProps> = () => {
  const [tabs] = useState(TABS_ITEMS[0]);
  const [visible, setvisible] = useState<boolean>(false);
  const [tab, setTab] = useState<any>();
  return (
    <OrderInfoWrapper>
      <div className="order-info">
        <div className="left">
          <Title fz={36} fw={600} color="#000">
            关于订单平台
          </Title>
          <div className="info">
            汇集产业联优质制造资源，基于中国服装科创研究院大数据可信制造管理体系，构建数字化服装产业订单交易平台，为产业链上下游提供高效精准的订单匹配服务、以实现订单的全流程可视化追溯。
          </div>
        </div>
        <div className="right">
          <img
            src={require("@/assets/images/order-platform/order_info_bg.png")}
            alt=""
          />
        </div>
      </div>
      <div className="order-list">
        {tabs.item.map((item: any, index) => (
          <div
            className="order-item"
            key={index}
            onClick={() => {
              setTab(item.img);
              item && item.img && setvisible(true);
            }}
          >
            {item?.icon && <img src={item?.icon} />}
            <div className="order-item-info">
              <Title fz={24} fw={600} color="#000" className="order-card-title">
                {item?.title}
              </Title>
              {item?.intro && (
                <div className="order-card-intro">{item?.intro}</div>
              )}
            </div>
          </div>
        ))}
      </div>
      {tab && visible && (
        <Modal
          width={400}
          title="扫一扫进入了解详情"
          style={{ zIndex: 999999999999999 }}
          visible={visible}
          onCancel={() => setvisible(false)}
          footer={false}
        >
          <img src={tab} className="modal-img" alt="二维码" />
        </Modal>
      )}
    </OrderInfoWrapper>
  );
};
