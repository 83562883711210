import { Title } from "@/styles/common-style";
import { useState } from "react";
import {
  CasedemoWrapper,
  CusCaseCard,
  CusCasePane,
  CusCaseTabs,
} from "../style";
import { history } from "umi";
import { Row, Button } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import { NavigationWithAuth } from "@/utils/common";
interface CasedemoProps {}
const Casedemo: React.FC<CasedemoProps> = () => {
  const [currentId, setCurrentId] = useState<string>("1");
  const Tabs = [
    {
      title: (
        <>
          <div>上海之禾</div>
          <div> 女装智能工厂项目</div>
        </>
      ),
      id: "2",
      img: require("@/assets/images/solution/logo1.png"),
      key: "1",
      list: [
        {
          icon: require("@/assets/images/solution/icon_2.png"),
          title: "提高生产效率",
        },
        {
          icon: require("@/assets/images/solution/icon_3.png"),
          title: "缩短交货周期",
        },
        {
          icon: require("@/assets/images/solution/icon_5.png"),
          title: "提升产品合格率",
        },
        {
          icon: require("@/assets/images/solution/icon_1.png"),
          title: "提升产线平衡率",
        },
      ],
      point: `1.计划：信息使用EXCEL人工导入 ，依赖个人的技能保障工作质量; ,
      2.产前准备工作量大;,
      3.IE标准工时系统数据孤立;, 
      4.车缝品质问题不易快速控制，工序生产平衡不好控制；现场报工、数据统计全部靠人工，效率低、易出错、不及时;
     `,
      // 5.车缝品质问题不易快速控制,工序生产平衡不好控制；现场报工、数据统计全部靠人工，效率低、易出错、不及时;6.质检手工报表过多，录入工作量大；
      plan: `
      1.数据标准体系建立： 建立集团工序、工价、工艺的统一标准;,
      2. 智能工厂系统集成：集成现有业务系统，吊挂采取全控模式对接，搭建智能制造数字化生产管理平台的人力资源管理模块、制造执行MES模块、打通智能制造业务流程，实现柔性快反生产模式;,
      3. 智能工厂物联网设备集成：通过中服工业物联网平台集成智能吊挂、裁床、工业缝纫机，实现设备实时监控、操作指令实时下发。`,
    },
    {
      title: (
        <>
          <div>浙江红袖</div>
          <div> 女装智能工厂项目</div>
        </>
      ),
      img: require("@/assets/images/solution/logo2.png"),
      key: "2",
      id: "6",
      list: [
        {
          icon: require("@/assets/images/solution/icon_1.png"),
          title: "数据标准化",
        },
        {
          icon: require("@/assets/images/solution/icon_2.png"),
          title: "缩短交货周期",
        },
        {
          icon: require("@/assets/images/solution/icon_3.png"),
          title: "提升数据准确格率",
        },
        {
          icon: require("@/assets/images/solution/icon_5.png"),
          title: "提升生产效率",
        },
      ],
      point: `1.生产不透明，管控混乱，无法应对市场发展;,2.数据没有标准化管理，导致无法及时现场数据采集分析;,3.产线不平衡`,
      plan: `1.建立标准数据体系，打通现有业务系统，实现柔性快反生产模式,,
        2.搭建数字化生产管理平台：通过管理从产前准备，到裁剪、缝制、后道、包装的整个流程，帮助红袖实现从传统制造企业到数字化生产企业的转变。,
        3.搭建智能仓储管理系统，物料、库位统一编码化管理，从仓库的出入库到盘点补货等流程进行标准化管理；
        `,
    },
    {
      title: (
        <>
          <div>歌力思集团</div>
          <div> 深圳智能仓储项目</div>
        </>
      ),
      img: require("@/assets/images/solution/logo3.png"),
      key: "3",
      id: "4",
      list: [
        {
          icon: require("@/assets/images/solution/icon_4.png"),
          title: "减少操作员工",
        },
        {
          icon: require("@/assets/images/solution/icon_2.png"),
          title: "提升存储容量",
        },
        {
          icon: require("@/assets/images/solution/icon_1.png"),
          title: "提升作业和库存准确率",
        },
        {
          icon: require("@/assets/images/solution/icon_1.png"),
          title: "提升拣选效率",
        },
      ],
      point: `1.物料、产品、仓储库位缺少标识，出入库信息依靠手工记录，查找困难;,2.库存情况无法实时获取，难以合理安排采购、发货等工作;,3.生产单元物料消耗，无法保证及时配送信息化建设：研、产、供、销信息化建设不均衡;,4.信息化孤岛严重，信息流未打通;
      `,

      plan: `1.规范管理体系建立： 规范仓储标准化作业流程，实现作业有序化;,
        2.规范管理体系建立： 规范仓储标准化作业流程，实现作业有序化;,
        3.建立数字化仓储管理系统：无缝集成，减少人员录入和等待；条码化作业，减少纸单浪费，加速作业流转；策略指引作业，多作业模式组合管理，提升作业效率;
        `,
    },
    {
      title: (
        <>
          <div>南山智尚</div>
          <div> 西服智能工厂项目</div>
        </>
      ),
      id: "5",
      img: require("@/assets/images/solution/logo4.png"),
      key: "4",
      list: [
        {
          icon: require("@/assets/images/solution/icon_4.png"),
          title: "提升协同效率",
        },
        {
          icon: require("@/assets/images/solution/icon_1.png"),
          title: "排产效率提升",
        },
        {
          icon: require("@/assets/images/solution/icon_3.png"),
          title: "缩短交货周期",
        },
        {
          icon: require("@/assets/images/solution/icon_5.png"),
          title: "提升生产效率",
        },
      ],
      point: `1.现场生产不平衡;,2.生产过程不透明，无法实时跟进生产情况;,3.信息系统未打通，各自独立运行，订单信息无法及时给到生产;,4.生产现状无法满足未来定制化业务需求;
      `,

      plan: `1.建立精益管理体系，规范现场员工操作，确定实际生产工时，合理生产排位;,
        2.建立部件工艺管理系统，沉淀企业工艺数据库，为未来定制业务快速匹配工艺做支撑;,
        3.搭建智能仓储管理系统，物料、库位统一编码化管理，从仓库的出入库到盘点补货等流程进行标准化管理；
        `,
    },
  ];
  return (
    <CasedemoWrapper>
      <Row align="middle" justify="space-between">
        <Title>经典解决方案案例</Title>
        <Button type="link" onClick={() => history.push("/case-center")}>
          查看更多
          <DoubleRightOutlined />
        </Button>
      </Row>
      <CusCaseTabs
        type="card"
        activeKey={currentId}
        animated
        onChange={(key: string) => setCurrentId(key)}
      >
        {Tabs &&
          Tabs.map((tab: any) => (
            <CusCasePane
              tab={
                <CusCaseCard>
                  <img src={tab.img} />
                </CusCaseCard>
              }
              key={tab.key}
            >
              <Row
                onClick={() => {
                  NavigationWithAuth(
                    "https://dfib.cnatii.com/#/case/case-detail/" + tab.id,
                    "cus"
                  );
                  // window.open(
                  //   "https://dfib.cnatii.com/#/case/case-detail/" + tab.id
                  // );
                }}
              >
                <div className="left-tab">
                  <Title className="left-title">{tab.title}</Title>
                  <div className="left-list">
                    {tab.list &&
                      tab.list.map((item: any, index: any) => (
                        <div key={index} className="list-item">
                          <img src={item.icon} className="left-list-icon" />
                          <div className="left-list-title">{item.title}</div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="right-tab">
                  <div className="cate-wrap mab36">
                    <div className="cate-title">痛点</div>
                    <div className="cate-sub ">
                      {tab.point &&
                        tab.point
                          .split(",")
                          .map((it: any, key: any) => (
                            <div key={key}>{it}</div>
                          ))}
                    </div>
                  </div>
                  <div className="cate-wrap">
                    <div className="cate-title">实施方案</div>
                    <div className="cate-sub">
                      {tab.plan &&
                        tab.plan
                          .split(",")
                          .map((it: any, key: any) => (
                            <div key={key}>{it}</div>
                          ))}
                    </div>
                  </div>
                </div>
              </Row>
            </CusCasePane>
          ))}
      </CusCaseTabs>
    </CasedemoWrapper>
  );
};

export default Casedemo;
