import { getParklistCompany, getQueryCompanyList } from "@/api/park";
import { Divider, List, Skeleton } from "antd";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "umi";
import { AddressImg, ListContainer, ListItem } from "../../style";
interface ResultListProps {
  height?: number;
  onItemClick?: (value: any) => void;
}
const DetaiList: React.FC<ResultListProps> = ({
  height = 340,
  onItemClick,
}) => {
  let [index, setIndex] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [total, setTotal] = useState<number>(0);
  const { pre, industrialInfo, parkSearchInfo } = useSelector(
    (state: { park: any }) => state.park
  );
  const loadMoreData = () => {
    if (loading) {
      return;
    }
    index++;
    setIndex(index);
    const params = {
      ...parkSearchInfo,
      index: index,
      size: 10,
      parkId: industrialInfo?.id,
    };
    setLoading(true);
    getQueryCompanyList(params).then((res: any) => {
      if (res.code === 1) {
        setData(res.data);
        setTotal(res?.page?.count || 0);
        dispatch({
          type: "park/saveParks",
          payload: [...res.data],
        });
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    setIndex(1);
    if (data.length > 0) {
    } else {
      loadMoreData();
    }
  }, []);
  return (
    <ListContainer
      id="scrollableDiv"
      style={{
        height: height + "px",
      }}
    >
      <InfiniteScroll
        dataLength={data && data.length}
        next={loadMoreData}
        hasMore={data && data.length < total}
        loader={<></>}
        endMessage={total > 10 ? <Divider plain>我是有底线的</Divider> : <></>}
        scrollableTarget="scrollableDiv"
      >
        <List
          loading={loading}
          dataSource={data}
          renderItem={(item: any, index) => (
            <List.Item key={index}>
              <ListItem
                className="detail-info-item"
                onClick={() => {
                  onItemClick?.(item);
                  dispatch({ type: "park/saveCompanyInfo", payload: item });
                  dispatch({ type: "park/savebackNumber", payload: 3 });
                  dispatch({
                    type: "park/savePre",
                    payload: [...pre, 3],
                  });
                  dispatch({
                    type: "park/saveMapCenter",
                    payload: {
                      lat: Number(item.latitude),
                      lng: Number(item.longitude),
                    },
                  });
                }}
              >
                <div className="left">
                  <img
                    src={item?.companyPic?.split(",")[0]}
                    alt=""
                    className="img"
                  />
                  {/* <AddressImg>{index + 1}</AddressImg> */}
                </div>
                <div className="right">
                  <div className="container">
                    <div className="title">{item.companyName}</div>
                    <div className="info">
                      {[item.province, item.city, item.area, item.contact]
                        .filter((it) => it !== "")
                        .join("") || "-"}
                    </div>
                  </div>
                </div>
              </ListItem>
            </List.Item>
          )}
        />
      </InfiniteScroll>
    </ListContainer>
  );
};

export default DetaiList;
