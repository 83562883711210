import BaseLayout from "@/layouts";
import Plans from "./components/Plans";
import { Join, SolutionWrapper } from "./style";
import Casedemo from "./components/case";
import { CusButton } from "../persion/style";
import { ContactUs } from "@/utils/common";
import { JoinUs } from "../server/server-center/components/join";
interface SolutionProps {}
export const Solution: React.FC<SolutionProps> = () => {
  return (
    <BaseLayout>
      <SolutionWrapper>
        <div className="header-container">
          <img src={require("@/assets/images/solution/solution-bg.png")} />
          <div className="container-info">
            <div className="title">解决方案中心</div>
            <div className="sub-info">SOLUTION CENTER</div>
            <div className="desc">
              探究行业共性需求 构造普适而实用的解决方案
            </div>
            <CusButton
              type="primary"
              width={210}
              height={60}
              onClick={() => {
                ContactUs("解决方案");
              }}
              className="cus-btn"
            >
              立即咨询
            </CusButton>
          </div>
        </div>
        <Plans></Plans>
        <Casedemo />
        <JoinUs title="更多专业定制化解决方案，欢迎垂询" btnText="联系我们" />
      </SolutionWrapper>
    </BaseLayout>
  );
};

export default Solution;
