import BaseLayout from "@/layouts";
import { useSelector } from "umi";
import CaseInfo from "./components/cases";
import Expert from "./components/expert";
import HeaderList from "./components/policy-list";
import HomeSlider from "./components/home-slider";
import Market from "./components/market";
import ProductService from "./components/product-server";
import RealTimeInfo from "./components/real-time";
import ServerPart from "./components/server";
import Solutions from "./components/solutions";
import { BaseHomeWrapper } from "./style";
import { Bannar } from "./components/bannar";
import HeaderVideo from "./components/header-video";

interface BaseHomeProps {}

const BaseHome: React.FC<BaseHomeProps> = () => {
  const isLinpin = useSelector((state: any) => state.home.isLinpin);
  return (
    <BaseLayout>
      <BaseHomeWrapper>
        <HomeSlider />
        {<HeaderVideo isLinpin={isLinpin} />}
        {<HeaderList isLinpin={isLinpin} />}
        <ProductService />
        {isLinpin && <ServerPart />}
        <Solutions />
        <Bannar />
        <Market />
        <CaseInfo />
        <Expert />
        <RealTimeInfo />
      </BaseHomeWrapper>
    </BaseLayout>
  );
};

export default BaseHome;
