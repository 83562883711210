import styled from "styled-components";
import { OrderProcessWrapper } from "../order-platform/style";

export const CapacityCenterWrapper = styled.div``;

export const CapacityInfoWrapper = styled.div`
  width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 106px auto;
  .card {
    width: 280px;
    height: 320px;
    padding: 38px 26px 40px 38px;
    box-sizing: border-box;
    background: #edf1f8;
    border-radius: 5px;
  }
  .title-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 13px;
    img {
      width: 50px;
      height: 50px;
    }
  }
  .intro {
    font-size: 18px;
    font-family: Alibaba, Alibaba-Regular;
    font-weight: 400;
    text-align: left;
    color: #666666;
  }
  .ant-btn {
    margin-top: 106px;
    width: 100px;
    height: 40px;
    background: #3460f2;
    border-radius: 2px;
    box-shadow: 0px 2px 7px 2px rgba(27, 71, 215, 0.3);
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
  }
`;

export const FactoryWrapper = styled(OrderProcessWrapper)`
  .slider-comtainer {
    margin-bottom: 20px;
  }
  .swiper-next,
  .swiper-prev {
    img {
      width: 81px;
      height: 81px;
    }
  }
  .order-goods {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .rate-start {
    margin-bottom: 10px;
    font-size: 13px;
  }
  /* .rate-start .anticon svg {
    width: 13px;
    height: 13px;
  } */
  .order-item-info {
    padding-right: 10px;
  }
  .goods-num {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #666666;
  }
  .order-btn {
    margin-top: 14px !important;
  }
`;
