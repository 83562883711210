import { Row, Col } from "antd";
import BaseLayout from "@/layouts";
import { useState } from "react";

import "@/styles/ecolgica.less";
import Title from "@/components/title";
import CoInterests from "./components/co-interests";
import CoProcess from "./components/co-process";
import CoPartner from "./components/co-partner";
import CoAdvantage from "./components/co-advantage";
import ContactUs from "./components/co-contact";

interface CardNode {
  node: any;
  key: string;
}
interface CooperationType {
  key: string;
  img_default: string;
  img_selected: string;
  title: string;
  desc: string;
}
// 生态合作
const EcologicalCooperation: React.FC = () => {
  const [aticeKey, setAticeKey] = useState<string>("1");
  const [typeKey, settypeKey] = useState<string>("1");
  const cardNode: CardNode[] = [
    {
      node: (
        <>
          <span> 中国服装智能制造技术创新战略联盟</span> &raquo;
        </>
      ),
      key: "1",
    },
    {
      node: (
        <>
          <span> 中国服装数字化转型创新中心</span> &raquo;
        </>
      ),
      key: "2",
    },
  ];
  const COOPERATION_TYPES: CooperationType[] = [
    {
      key: "1",
      img_default: require("@/assets/images/soft-serve.png"),
      img_selected: require("@/assets/images/soft-serve-selected.png"),
      title: "软件服务商",
      desc: "开发和销售软件的公司，为特定行业提供软件支持。",
    },
    {
      key: "2",
      img_default: require("@/assets/images/hardware-serve.png"),
      img_selected: require("@/assets/images/hardware-serve-selected.png"),
      title: "硬件服务商",
      desc: "生产和销售专业硬件的公司，侧重于智能制造方面。",
    },
    {
      key: "3",
      img_default: require("@/assets/images/solve-serve.png"),
      img_selected: require("@/assets/images/solve-serve-selected.png"),
      title: "解决方案服务商",
      desc:
        "提供技术、服务、方案的团队，应用领域包 括安全生产、节能减排、质量管控、供应链 管理、生产制造、仓储物流、运维服务。",
    },
    {
      key: "4",
      img_default: require("@/assets/images/supply-serve.png"),
      img_selected: require("@/assets/images/supply-serve-selected.png"),
      title: "供应链金融服务商",
      desc: "融资、租赁、小微贷款。",
    },
    {
      key: "5",
      img_default: require("@/assets/images/other-serve.png"),
      img_selected: require("@/assets/images/other-serve-selected.png"),
      title: "其他",
      desc: "法律、知识产权等。",
    },
  ];
  const cardItemClick = (item: CardNode) => {
    setAticeKey(item.key);
  };
  const itemClick = (item: CooperationType) => {
    settypeKey(item.key);
  };

  return (
    <BaseLayout>
      <div className="ecolgica">
        <Row
          align="middle"
          justify="center"
          className="bg-wrap-title animated fadeInDown"
        >
          <Title
            text="共建中国服装数字时尚产业生态"
            titleStyle={{
              fontSize: "80px",
              fontWeight: 400,
              color: "#fff",
              fontFamily:
                "BigruixianBlackGBV1.0, BigruixianBlackGBV1.0-Regular",
            }}
          ></Title>
        </Row>
        <Row align="middle" justify="center" className="animated fadeInDown">
          <Title
            text="立即成为生态伙伴，共同助力中国服装数字时尚产业"
            titleStyle={{ fontSize: "26px", fontWeight: 600, color: "#fff" }}
          ></Title>
        </Row>
      </div>
      <div className="ecolgica-card">
        <Row justify="space-between" align="middle">
          {cardNode.map((item) => (
            <Col
              key={item.key}
              onClick={() => cardItemClick(item)}
              className={aticeKey === item.key ? "ecolgica-card-active" : ""}
            >
              {item.node}
            </Col>
          ))}
        </Row>
      </div>
      <Row justify="center" align="middle" className="cooperation-title">
        <Title text="合作类型" />
      </Row>
      <div className="cooperation-types">
        {COOPERATION_TYPES.map((item) => (
          <div
            onClick={() => {
              itemClick(item);
            }}
            className={
              typeKey === item.key
                ? "cooperation-item cooperation-item-active"
                : "cooperation-item"
            }
            key={item.key}
          >
            <img
              className={
                typeKey === item.key
                  ? "cooperation-item-image cooperation-item-active-image  animated slideInRight"
                  : "cooperation-item-image"
              }
              src={typeKey === item.key ? item.img_selected : item.img_default}
            />
            {typeKey === item.key ? (
              <div className="cooperation-item-info  animated slideInRight">
                <div className="cooperation-item-wrap ">
                  <span className="cooperation-item-selected-title">
                    {item.title}
                  </span>
                  <div className="cooperation-item-desc">{item.desc}</div>
                </div>
              </div>
            ) : (
              <div className="cooperation-item-info">
                <span className="cooperation-item-title">{item.title}</span>
              </div>
            )}
          </div>
        ))}
      </div>
      <CoInterests />
      <CoProcess />
      <CoPartner />
      <CoAdvantage />
      <ContactUs />
    </BaseLayout>
  );
};

export default EcologicalCooperation;
