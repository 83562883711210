import Layout from "@/layouts";
import { Row, Col } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "umi";

import { getRealInfodetail } from "@/utils/api";
import { RealInfo } from "@/models/home";

import "@/styles/real-detail.less";

interface Params {
  id: string;
}
const RealTimeDetail = () => {
  const params = useParams<Params>();
  const [detail, setdetail] = useState<RealInfo>();
  const getRealInfo = () => {
    getRealInfodetail(params).then((res: any) => {
      if (res.code === 1) {
        setdetail(res.data);
      }
    });
  };
  useEffect(() => {
    window.scroll(0, 0);
    getRealInfo();
  }, [params]);
  return (
    <Layout>
      {detail && (
        <Row className="real-detail">
          <Col className="real-detail-title">{detail.title || ""}</Col>
          <Col className="real-detail-time">
            发布时间： {detail.releaseTime || ""}
          </Col>
          <div
            className="real-detail-content ql-editor"
            dangerouslySetInnerHTML={{ __html: detail.detail || "" }}
          ></div>
        </Row>
      )}
    </Layout>
  );
};

export default RealTimeDetail;
