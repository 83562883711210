import Layout from "@/layouts";
import { Row, Col } from "antd";
import { useEffect, useState } from "react";
import { useParams, useLocation } from "umi";

import { getRealInfodetail, getRealPolicydetail } from "@/utils/api";
import { RealInfo } from "@/models/home";

import "@/styles/real-detail.less";
import qs from "@umijs/deps/compiled/express/qs";
import getSearch from "@/utils/getSearch";

interface Params {
  id: string;
}
interface State {
  isProlicy?: boolean;
}
interface Loc {
  state: State;
  [x: string]: any;
}
const PolicyDetail = () => {
  const params = useParams<Params>();
  const location = useLocation<Loc>();
  const [detail, setdetail] = useState<RealInfo>();
  const getRealInfo = () => {
    const { isProlicy } = getSearch(location.search);
    console.log(isProlicy, params);
    isProlicy === "true"
      ? getRealPolicydetail(params).then((res: any) => {
          if (res.code === 1) {
            setdetail(res.data);
          }
        })
      : getRealInfodetail(params).then((res: any) => {
          if (res.code === 1) {
            setdetail(res.data);
          }
        });
  };
  useEffect(() => {
    window.scroll(0, 0);
    getRealInfo();
  }, [params]);
  useEffect(() => {
    getRealInfo();
  }, []);
  return (
    <Layout>
      {detail && (
        <Row className="real-detail">
          <Col className="real-detail-title">{detail.title || ""}</Col>
          <Col className="real-detail-time">
            发布时间： {detail.releaseTime || ""}
          </Col>
          <div
            className="real-detail-content ql-editor"
            dangerouslySetInnerHTML={{ __html: detail.detail || "" }}
          ></div>
        </Row>
      )}
    </Layout>
  );
};

export default PolicyDetail;
