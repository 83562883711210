import { Title } from "@/styles/common-style";
import { useSelector } from "umi";

interface ServerContentProps {}
const ServerContent: React.FC<ServerContentProps> = ({}) => {
  const { serverDetail } = useSelector((state: any) => state.server);
  return (
    <div className="server-content">
      <Title className="title">服务介绍 </Title>
      {serverDetail && (
        <div
          className="html ql-editor"
          dangerouslySetInnerHTML={{ __html: serverDetail?.brief }}
        ></div>
      )}
    </div>
  );
};

export default ServerContent;
