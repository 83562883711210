import { Effect, Reducer } from "umi";
export interface RealInfo {
  data?: any;
  id: number;
  title: string;
  releaseTime: string;
  poster?: string;
  introduction: string;
  top?: number;
  sort?: number;
  detail?: string;
  createTime?: string;
  updateTime?: string;
}

export interface HomeSliceState {
  isLinpin: boolean;
}

interface HomeSliceType {
  namespace: "home";
  state: HomeSliceState;
  effects: {
    saveState: Effect;
  };
  reducers: {
    save: Reducer<HomeSliceState>;
  };
}

const HomeSlice: HomeSliceType = {
  // 跟着类型写就完事儿
  namespace: "home",
  state: {
    isLinpin: false,
  },
  effects: {
    *saveState({ payload }: any, { call, put }: any) {
      try {
        yield put({
          type: "save",
          payload: {
            isLinpin: payload === "1" ? false : true,
          },
        });
      } catch (error) {}
    },
  },
  reducers: {
    save(state: any, { payload }: any) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default HomeSlice;
