import { FullscreenWrapper } from "./style";
import fullscreen from "@/assets/images/park/fullScreen.png";
interface FullscreenProps {
  text?: string;
  bgImage?: string;
  onClick?: () => void;
}
export default ({ text, bgImage = fullscreen, onClick }: FullscreenProps) => {
  return (
    <FullscreenWrapper
      className="tool"
      bgImage={bgImage}
      onClick={() => onClick && onClick()}
    >
      <i className="icon"></i>
      {text && <span className="text"> {text}</span>}
    </FullscreenWrapper>
  );
};
