import { useEffect, useLayoutEffect, useState } from "react";
import { Button, Row } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { H5PayWrapper } from "./style";
import { useParams, Helmet } from "umi";
// import * as AdaPay from "@/assets/js/adaPay/adaPay.min.js"
// import { Helmet, HelmetProvider } from 'react-helmet-async';
import { GetQueryString, goAuth, is_weixin, wx_appid } from "@/utils/common";
import { createPay, getKnowledgeDetail, getOpenId } from "@/api/knowledge";
// import VConsole from "vconsole"
// import "./h5-pay.less"
interface H5PayProps {}
const H5Pay: React.FC<H5PayProps> = () => {
  const params = useParams<{ id: string; orderNo: string }>();
  const [openId, setOpenId] = useState<string>("");
  const [orderNo, setOrderNo] = useState<string>(params?.orderNo);
  const [knowledgeDetail, setKnowledgeDetail] = useState<any>({});
  let [code, setCode] = useState<string | null>(
    window.location.href.includes("code") ? GetQueryString("code") : ""
  );

  let WeixinJSBridge = (window as any).WeixinJSBridge;
  useLayoutEffect(() => {
    document.title = "订单确认";
  });
  const getAuthor = async (code: any) => {
    getOpenId(code).then((res: any) => {
      if (res.code == 1) {
        setOpenId(res.data);
      }
    });
  };
  useEffect(() => {
    // new VConsole()
    // if (is_weixin()) {
    if (!code) {
      goAuth();
      setCode(GetQueryString("code"));
    } else {
      getAuthor(code);
    }
    // wx.config({
    //   debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    //   appId: wx_appid, // 必填，公众号的唯一标识
    //   timestamp: "", // 必填，生成签名的时间戳
    //   nonceStr: "", // 必填，生成签名的随机串
    //   signature: "", // 必填，签名
    //   jsApiList: ["chooseWXPay"], // 必填，需要使用的JS接口列表
    // })
    // //通过ready接口处理成功验证
    // wx.ready(function () {
    //   // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
    // })
    // //通过error接口处理失败验证
    // wx.error(function (res) {
    //   // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
    // })
    // }
  }, [code]);
  const goPay = () => {
    const p = {
      orderNo: orderNo,
      businessType: 3,
      wxPubOpenId: openId,
      payChannelCode: "wx_pub",
      goodsTitle: knowledgeDetail?.title,
      goodsDesc: knowledgeDetail?.remark
        ? knowledgeDetail.remark
        : knowledgeDetail?.title + "购买",
      deviceType: "pc",
    };
    // alert("我要去支付啦")

    // console.log({ AdaPay })
    createPay(p).then((res: any) => {
      if (res.code == 1) {
        // const charge = { ...res.data }
        // alert(JSON.stringify(res.data))
        // wx.chooseWXPay({
        //   timestamp: res.data.timeStamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
        //   nonceStr: res.data.nonceStr, // 支付签名随机串，不长于 32 位
        //   package: res.data.packageStr, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        //   signType: res.data.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
        //   paySign: res.data.paySign, // 支付签名
        //   success: function (res) {
        //     // 支付成功后的回调函数
        //     alert(JSON.stringify(res))
        //   },
        // })
        function onBridgeReady() {
          WeixinJSBridge.invoke(
            "getBrandWCPayRequest",
            {
              appId: wx_appid, //公众号名称，由商户传入
              timeStamp: res.data.timeStamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
              nonceStr: res.data.nonceStr, // 支付签名随机串，不长于 32 位
              package: res.data.packageStr, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
              signType: res.data.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
              paySign: res.data.paySign, // 支付签名
            },
            function (res) {
              if (res.err_msg == "get_brand_wcpay_request:ok") {
                // alert(JSON.stringify(res))
              } // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回    ok，但并不保证它绝对可靠。
            }
          );
        }
        if (typeof WeixinJSBridge == "undefined") {
          if (document.addEventListener) {
            document.addEventListener(
              "WeixinJSBridgeReady",
              onBridgeReady,
              false
            );
          } else if ((document as any).attachEvent) {
            (document as any).attachEvent("WeixinJSBridgeReady", onBridgeReady);
            (document as any).attachEvent(
              "onWeixinJSBridgeReady",
              onBridgeReady
            );
          }
        } else {
          onBridgeReady();
        }
      }
    });
  };
  useEffect(() => {
    const p = {
      id: params.id,
      // accountId: params?.userId || 0,
    };
    if (params.id) {
      getKnowledgeDetail(p).then((res: any) => {
        if (res.code == 1) {
          setKnowledgeDetail(res.data);
        }
      });
    }
  }, [params]);
  return (
    <>
      <Helmet>
        <title>订单确认</title>
      </Helmet>
      {is_weixin() ? (
        <H5PayWrapper className="h5-pay">
          <div className="h5-content">
            <div className="order-title common">
              {knowledgeDetail.title || "测试标题"}
            </div>
            <div className="info-content">
              <div className="title mab20">价格明细</div>
              <Row align="middle" justify="space-between" className="mab20">
                <div className="tag-label">总价</div>
                <div className="tag-label">
                  ¥{knowledgeDetail.salePrice || 0}
                </div>
              </Row>
              <Row align="middle" justify="space-between" className="mab20">
                <div className="tag-label">支付方式</div>
                <div className="tag-label">
                  微信支付 <RightOutlined style={{ fontSize: 12 }} />
                </div>
              </Row>
              <Row align="middle" justify="space-between" className="mab20">
                <div className="tag-mount">合计：</div>
                <div className="tag-mount">
                  ¥
                  {knowledgeDetail?.salePrice ||
                    0 - (knowledgeDetail?.discountPrice || 0)}
                </div>
              </Row>
            </div>
          </div>
          <div className="h5-footer">
            <div className="footer-content">
              <div className="amount-info">
                <span className="amount">
                  合计: ¥
                  {knowledgeDetail?.salePrice ||
                    0 - (knowledgeDetail?.discountPrice || 0)}
                </span>
                <span className="sales">
                  已优惠{knowledgeDetail.discountPrice || 0}元
                </span>
              </div>
              <Button
                className="pay-btn"
                type="primary"
                onClick={() => {
                  goPay();
                  // wx.chooseWXPay({
                  //   timestamp: 0, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
                  //   nonceStr: "", // 支付签名随机串，不长于 32 位
                  //   package: "", // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                  //   signType: "", // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
                  //   paySign: "", // 支付签名
                  //   success: function (res) {
                  //     // 支付成功后的回调函数
                  //   },
                  // })
                }}
              >
                去支付
              </Button>
            </div>
          </div>
        </H5PayWrapper>
      ) : (
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          请在微信中打开
        </div>
      )}
    </>
  );
};

export default H5Pay;
