export default class Storage {
  /** 设置storage */
  static set(key: string, obj: any, outtime?: number) {
    const ctime = Date.now() / 1000; // 获取存数据的时间
    const exp = outtime || 24 * 60 * 60; // outtime 是 秒为单位的过期时间
    const outObj = {
      // 时间和数据
      outime: ctime + exp,
      data: obj,
    };
    localStorage.setItem(key, JSON.stringify(outObj));
  }
  /** 获取storage */
  static get(key: string) {
    const dataString = window.localStorage.getItem(key) || "";
    // 初始化没有数据
    if ([null, "", undefined].includes(dataString)) {
      return null;
    }
    const data = JSON.parse(dataString);
    // 判断过期时间 和获取数据的时间对比 大于过期时间说明超时
    if (data.outime >= Date.now() / 1000) {
      return data.data;
    }
    return null;
  }
  /** 清空某个字段缓存 */
  static clear(key: string) {
    window.localStorage.removeItem(key);
  }
}
