import BaseLayout from "@/layouts";
import { BgImage } from "../order-platform/style";
import { Benefits } from "./components/Benefits";
import { Diagnosis } from "./components/Diagnosis";
import { Process } from "./components/process";
import { Specification } from "./components/specification";
import { MaintainWrapper } from "./style";

interface MaintainProps {}

const Maintain: React.FC<MaintainProps> = () => {
  return (
    <BaseLayout>
      <MaintainWrapper>
        <BgImage bg={require("@/assets/images/maintain/maintain_bg.png")} />
        <Specification />
        <Benefits />
        <Diagnosis />
        <Process />
      </MaintainWrapper>
    </BaseLayout>
  );
};

export default Maintain;
