import styled from "styled-components";
import { SeniorCard, SeniorMeta } from "@/pages/expert/style";

export const ExpertWrapper = styled.div`
  width: 1200px;
  margin: 0 auto 88px;
  margin-bottom: 111px;
  .sub-title {
    margin-bottom: 40px;
    margin-bottom: 48px;
    font-family: Alibaba, Alibaba-Regular;
  }
  .senior {
    display: flex;
  }
  .senior-right {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    .ant-card-cover img {
      width: 144px;
      height: 144px;
    }
  }
  .senior-list {
    .header-nav {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 62px;
      padding: 18px 31px 18px 23px;
      box-sizing: border-box;
      background: #eaeaea;
      border-radius: 2px;
    }
    .header-nav-items {
      cursor: pointer;
      display: flex;
      align-items: center;
      .header-nav-title {
        margin-right: 58px;
        color: #666666;
      }
      .header-nav--active-title {
        color: #1848fd;
      }
    }
    .senior-more {
      cursor: pointer;
    }
  }
  .senior-info-list {
    margin-top: 15px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
`;

export const Chief = styled(SeniorCard)`
  margin-right: 16px;
  width: 340px;
  height: 525px;
  padding: 23px;
  .ant-card-cover {
    margin: 0 auto;
    width: 280px;
    height: 280px;
  }
  .meta-title {
    position: absolute;
    left: 0;
    font-size: 24px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    color: #ffffff;
    width: 143px;
    height: 41px;
    line-height: 41px;
    bottom: 200px;
    text-align: center;
    background-color: #1848fd;
  }
  .name {
    font-size: 24px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    color: #ffffff;
    line-height: 33px;
  }
  .info {
    height: auto;

    margin-top: 15px;
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    -webkit-line-clamp: 4;
  }
  .ant-card-meta-description {
    width: 300px;
  }
  .info-title {
    width: 290px;
  }
  .ant-card-body {
    width: 100%;
  }
`;
export const SeniorChief = styled(SeniorCard)`
  margin-right: 16px;
  margin-bottom: 16px;
  display: flex;
  width: 413px;
  height: 255px;
  padding: 16px;
  box-sizing: border-box;
  background-color: #e6eaff;
  border: none;
  &:nth-child(2n) {
    margin-right: 0;
  }
  &:nth-child(2) {
    background-color: #f8f8f8;
  }
  &:nth-child(3) {
    background-color: #f6f9ff;
  }
  &:last-child {
    background-color: #d8d8e7;
  }
  .ant-card-body {
    padding: 0;
  }
  .ant-card-cover {
    margin-right: 20px;
    width: 144px;
    height: 144px;
  }
  .meta-title {
    position: absolute;
    left: 0;
    top: 150px;
    width: 87px;
    height: 30px;
    line-height: 30px;
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    background: #141561;
  }
  .name {
    margin-bottom: 10px;
    font-size: 24px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    color: #000000;
  }
  .info {
    width: 210px;
    height: auto;
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #666666;
    -webkit-line-clamp: 6;
  }
`;
export const ChiefMeta = styled(SeniorMeta)``;

export const ExportSeniorCard = styled(SeniorCard)`
  background: #f6f9ff;
  border: 1px solid #f6f9ff;
  .info {
    color: #666666;
  }
  margin-bottom: 20px;
`;
export const ExportSeniorMeta = styled(SeniorMeta)``;
