import { CSSProperties } from "react";

interface TitleProps {
    text:string;
    titleStyle?:CSSProperties;
}
const style = {
    fontSize: '52px',
    fontWeight: 600,
    color:' #000',
}
const Title:React.FC<TitleProps> = ({text, titleStyle}) =>{
     return <span style={{...style,...titleStyle}}>{text}</span>
}

export default Title