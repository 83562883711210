import request from "@/utils/request";

export const getUserInfo = (id: any) => {
  return request(`/account/userInfo/queryById/${id}`, {
    method: "get",
    requestType: "json",
  });
};

export const updateUserInfo = (params: any) => {
  return request("/account/userInfo/update", {
    method: "put",
    requestType: "json",
    data: params,
  });
};

export const updateMobile = (params: any) => {
  return request("/account/userInfo/updateMobile", {
    method: "put",
    requestType: "json",
    data: params,
  });
};
//account/userInfo/update

export const getUserbankCardInfo = (id: any) => {
  return request(`/account/bankCardInfo/queryLatestByAccountId/${id}`, {
    method: "get",
    requestType: "json",
  });
};

//

export const getUserPay = (params: any) => {
  return request(`/order/productOrder/pay`, {
    method: "post",
    requestType: "json",
    data: params,
  });
};
