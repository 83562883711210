import styled from "styled-components";

import { Upload } from "antd";

export const CusUplpad = styled(Upload)<{
  url?: string;
  width?: number;
  height?: number;
}>`
  .ant-upload.ant-upload-select-picture-card {
    width: ${(props) => (props.width ? props.width + "px" : "104px")};
    height: ${(props) => (props.height ? props.height + "px" : "104px")};
    background-image: url(${(props) => (props.url ? props.url : "")});
    background-color: #f5fafd;
    background-size: 100% 100%;
    border: ${(props) =>
      props.url ? "1px dotted  #d8d8d8" : "1px solid #d8d8d8"};
    background-repeat: no-repeat;
  }

  .ant-upload-list-picture-card-container {
    width: ${(props) => (props.width ? props.width + "px" : "104px")};
    height: ${(props) => (props.height ? props.height + "px" : "104px")};
  }
  .ant-upload-list-picture-card .ant-upload-list-item {
    padding: 0;
  }
`;
