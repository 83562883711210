import { Title } from "@/styles/common-style";
import { Train1Wrapper } from "../style";
import { ConsultNow } from "./consult-now";

interface Train1Props {}
export const Train1: React.FC<Train1Props> = () => {
  const train1Data = [
    {
      title: "数字化战略咨询",
      info: "战略咨询、业务咨询、技术咨询的融合，快速迭代、直接连接市场及产业链上下游的轻量级咨询，以数据和数字化技术为核心，绘制一张数字化转型的蓝图。",
      bg: require("@/assets/images/more-consulting/bg1.png"),
    },
    {
      title: "商业模式定位",
      info: "协助企业正确定位并规划企业战略，及时跟进战略执行，体检和重整，确保企业规避风险与把握机会，高效开创顾客保障业务潜力实现。",
      bg: require("@/assets/images/more-consulting/bg2.png"),
    },
    {
      title: "品牌定位咨询",
      info: "帮助企业通过计划、组织、指挥、协调、控制、激励等活动 , 对价值链增值过程进行有效管理。",
      bg: require("@/assets/images/more-consulting/bg3.png"),
    },
    {
      title: "着装顾问",
      info: "为品牌提供全面的品牌策划与品牌设计服务，以更高的服务标准，全面提升品牌营销能力。",
      bg: require("@/assets/images/more-consulting/bg4.png"),
    },
    {
      title: "供应链管理咨询",
      info: "有效帮助企业制定供应链计划，降低库存和运作成本，优化采购、物流和供应商管理，实现企业销售增长。",
      bg: require("@/assets/images/more-consulting/bg5.png"),
    },
  ];
  return (
    <Train1Wrapper>
      <Title
        fz={36}
        textAlign="center"
        fw={600}
        color="#000"
        style={{
          marginBottom: 56,
          fontFamily: " PingFangSC, PingFangSC-Semibold",
        }}
      >
        咨询服务内容
      </Title>
      <div className="train1-list">
        {train1Data.map((item: any, index: number) => {
          return (
            <div className="item" key={index}>
              <img src={item.bg} alt="" className="img" />
              <div className="item-int">
                <div className="item-title">{item.title}</div>
                <div className="item-info">{item.info}</div>
                <ConsultNow
                  name={item.title}
                  style={{
                    position: "absolute",
                    width: "110px",
                    height: "40px",
                    background: " #1848fd",
                    left: "50%",
                    marginLeft: "-55px",
                    bottom: 28,
                    color: "#fff",
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </Train1Wrapper>
  );
};
