import { Menu } from "antd";
import { useEffect, useState } from "react";
import { history, useDispatch } from "umi";
import { CaretDownOutlined } from "@ant-design/icons";
import { CusDropdown } from "../base-header/style";
import { HeaderTitleWrapper } from "./style";
import { GetIsLinpin, SetIsLinpin } from "@/utils/common";
interface HeaderTitleProps {
  hasSelect?: boolean;
}
export const HeaderTitle: React.FC<HeaderTitleProps> = ({
  hasSelect = true,
}) => {
  const [menuCurrent, setMenuCurrent] = useState<string>("1");
  const [title, setTitle] = useState<string>("服装工业互联网平台");
  const [content, setContent] = useState<string>(
    "Garment Industry Internet Platform"
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setMenuCurrent(GetIsLinpin() ? "2" : "1");
    dispatch({
      type: "home/saveState",
      payload: GetIsLinpin() ? "2" : "1",
    });
  }, [GetIsLinpin()]);
  const menu = (
    <Menu
      onClick={({ item, key, keyPath, domEvent }) => {
        setMenuCurrent(key);
        dispatch({
          type: "home/saveState",
          payload: key,
        });
        SetIsLinpin(key === "1" ? false : true);

        // setTitle(
        //   key == "1" ? "中国数字时尚产业创新平台" : "临平数字时尚产业创新平台"
        // );
        // setContent(
        //   key == "1"
        //     ? "zhongguoshuzishishangchangye"
        //     : "linpingshuzishishangchangye"
        // )
      }}
      items={[
        {
          key: "1",
          label: <a target="_blank">全国站</a>,
        },
        {
          key: "2",
          label: <a target="_blank">临平站</a>,
        },
      ]}
    />
  );
  return (
    <HeaderTitleWrapper onClick={() => history.push("/")}>
      <h1 className="base-left-title">
        <img
          src={require("@/assets/images/dfib_logo.png")}
          alt="logo"
          className="base-logo"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginTop: "5px",
            marginLeft: "5px",
            width:
              window.navigator.userAgent.indexOf("Chrome") != -1
                ? "201px"
                : "201px",
          }}
        >
          <div
            style={{
              fontSize: "18px",
              display: "flex",
              width: "201px",
              alignItems: "center",
              justifyContent: "space-between",
              fontFamily: "AlibabaPuHuiTiBold",
            }}
          >
            {title.split("").map((item: any, index: number) => (
              <span key={index}>{item}</span>
            ))}
          </div>
          <div
            style={{
              marginTop: "5px",
              fontSize: "12px",
              display: "flex",
              width: "201px",
              alignItems: "center",
              justifyContent: "space-between",
              color: "#666",
              // fontFamily: "AlibabaPuHuiTiBold",
            }}
          >
            {content.split(" ").map((item: any, index: number) => (
              <span key={index}>{item}</span>
            ))}
          </div>
        </div>
      </h1>
      {hasSelect && (
        <CusDropdown overlay={menu} overlayClassName="overlay-title">
          <a onClick={(e) => e.preventDefault()}>
            {menuCurrent == "1" ? "全国站" : "临平站"} <CaretDownOutlined />
          </a>
        </CusDropdown>
      )}
    </HeaderTitleWrapper>
  );
};
