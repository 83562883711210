import styled from "styled-components";

export const H5PayWrapper = styled.div<{ h?: number }>`
  position: relative;
  max-width: 750px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  height: 100vh;
  background: #f6f7fb;
  .common {
    padding: 0 15px;
  }
  .h5-content {
    background-color: #fff;
  }
  .order-title {
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e4e4e4;
  }
  .mab20 {
    margin-bottom: 20px;
  }
  .tag-label {
    font-size: 16;
    color: #333;
    font-weight: 400;
    font-family: PingFangSC, PingFangSC-Regular;
  }
  .tag-mount {
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
  }
  .info-content {
    padding: 12px 15px 14px;
    .title {
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      color: #252525;
    }
  }
  .h5-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 94px;
    background-color: #fff;
    border-top: 1px solid #d8d8d8;
    padding: 0 15px;
    /* .amount-info {
      float: left;
    } */
    .footer-content {
      width: 100%;
      height: 94px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .pay-btn {
      /* float: right; */
      width: 112px;
      height: 44px;
      background: #1848fd;
      border-radius: 2px;
    }
    .amount {
      font-size: 20px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
    }
    .sales {
      padding-left: 8px;
      font-size: 12px;
      color: #ff3b30;
      font-weight: 400;
      font-family: PingFangSC, PingFangSC-Regular;
    }
  }
`;
