import BaseFooter from "@/layouts/base-footer";
import CusHeader from "@/layouts/cus-header";
import { Title } from "@/pages/server/server-center/style";

import PersionCommon from "../components/common";
import { ContentWrapper, Wrap } from "../style";
import CusList from "./components/list";
interface MyCollectProps {}

const MyCollect: React.FC<MyCollectProps> = () => {
  return (
    <>
      <CusHeader title="个人中心" showSearch={false}></CusHeader>
      <ContentWrapper>
        <Wrap>
          <PersionCommon></PersionCommon>
          <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <Title style={{ marginBottom: "20px" }}> 我的收藏 </Title>
            {/* // <EditInfo data={list} /> */}
            <CusList
            // onChange={(value) => {
            //   request.index = value
            //   setRequest({ ...request })
            // }}
            />
          </div>
        </Wrap>
      </ContentWrapper>
      <BaseFooter />
    </>
  );
};

export default MyCollect;
