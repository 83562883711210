import { Title } from "@/styles/common-style";
import { useState } from "react";
import { SolutionWrapper } from "./style";
import { history } from "umi";
interface SolutionsProps {}

const Solutions: React.FC<SolutionsProps> = (props) => {
  const SOLUTIONS = [
    {
      bgImage: require("@/assets/images/marketing-bg.png"),
      icon: require("@/assets/images/marketing-icon.png"),
      title: "企业服务",
      id: "1",
      way: "解决方案",
      show: true,
      path: "/solution",
      detail_way:
        "以数据驱动企业内各环节的高效协同，打通上下游间的物流和信息流，帮助企业降低运营成本，提升管理水平，依托丰富的全产业链资源及实战专家团队，快速助力企业数字化转型升级。",
    },
    {
      bgImage: require("@/assets/images/customized-bg.png"),
      icon: require("@/assets/images/customized-icon.png"),
      title: "行业服务",
      id: "2",
      path: "/solution",
      show: true,
      way: "解决方案",
      detail_way:
        "总结当下行业痛点，积极探寻突破方向，通过整合优质的市场资源，为行业发展的转型升级输出资源、标准、技术及专业人才",
    },
    {
      bgImage: require("@/assets/images/industrial-park-bg.png"),
      icon: require("@/assets/images/industrial-park-icon.png"),
      title: "政府服务",
      id: "3",
      show: true,
      path: "/solution",
      way: "解决方案",
      detail_way:
        "产业大数据服务平台，聚焦产业数据集成，助力政府测有效把控当地产业发展态势，促进技术融合、产业融合、数据融合，推进决策科学化、治理精准化、服务高效化，构建区域内良性产业发展生态链。",
    },
    {
      bgImage: require("@/assets/images/factory-bg.png"),
      icon: require("@/assets/images/factory-icon.png"),
      title: "科技成果转化服务",
      id: "4",
      show: true,
      path: "/solution",
      way: "解决方案",
      detail_way:
        "深化开展与高校及科研机构的产、学、研合作项目落地，及研究成果的输出转化，从而引领全行业的数字化快反转型升级",
    },
  ];
  const [ativeCard, setativeCard] = useState<string>();
  const [list, setlist] = useState(SOLUTIONS);
  return (
    <SolutionWrapper>
      <Title textAlign="center">解决方案</Title>
      <Title
        className="solutions-intro"
        fz={18}
        fw={400}
        color="#333"
        textAlign="center"
      >
        探究行业共性需求 构造普适而实用的解决方案
      </Title>
      <div className="solutions-list">
        {list.map((item, index) => (
          <div
            className="solutions-item"
            key={index}
            onMouseEnter={() => {
              setativeCard(item.id);
            }}
            onClick={() => {
              item.path && history.push(item.path);
            }}
          >
            <img src={item.bgImage} className="solutions-item-img" />
            <div className="solutions-item-wrap">
              {ativeCard !== item.id ? (
                <>
                  <img
                    src={item.icon}
                    className="solutions-item-icon animated flipInY"
                  />
                  <span className="solutions-item-title">{item.title}</span>
                  <span className="solutions-item-way">{item.way}</span>
                </>
              ) : (
                <div className="solutions-item-card animated flipInY ">
                  <div className="solutions-item-card-title">{item.title}</div>
                  <span className="solutions-item-card-way">{item.way}</span>
                  <div className="solutions-item-card-detail">
                    {item.detail_way}
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </SolutionWrapper>
  );
};

export default Solutions;
