import map_marker_light from "@/assets/images/map-marker-light.png"; //@/assets/images/map-marker.png
import map_marker from "@/assets/images/map-marker.png"; //
import addr from "@/assets/images/park/addr.png";
import icon_address from "@/assets/images/park/icon_address.png";
import { Tabs } from "antd";
import styled from "styled-components";

const { TabPane } = Tabs;
export const AatlasWrapper = styled.div`
  /* height: calc(100vh - 95px); */
  min-width: 1440px;
  padding-top: 50px;
  padding-bottom: 50px;
  height: 100%;
  background-color: #f4f4f4;
`;
export const MapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 980px;
`;
export const UseMapWrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: 666;
  .compass-circle,
  .rotate-panel,
  .tmap-scale-control,
  .tmap-zoom-control,
  a img,
  .logo-text {
    display: none;
  }
  .info_card {
    cursor: pointer;
    display: inline-block;
    /* margin: 50px auto; */
    position: absolute;
    width: 200px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(${map_marker_light}) no-repeat center;
    background-size: 100%;
  }

  .info_card .title {
    padding: 10px;
    width: 200px;
    height: 80px;
    line-height: 40px;
    font-weight: 500;
    font-size: 18px;
    box-sizing: border-box;
    text-align: center;
  }
  .info_card-mark {
    background-image: url(${map_marker});
    color: #fff;
  }

  /* .info_card .title .close_img {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    background-color: #ffffff;
  }
  .info_card .title .close_img .min {
    width: 0;
    height: 0;
    font-size: 0;
    overflow: hidden;
    position: absolute;
    border-width: 10px;
  }

  .info_card .title .close_img .top_img {
    border-style: solid dashed dashed;
    border-color: #000000 transparent transparent transparent;
    top: -2px;
  }

  .info_card .title .close_img .right_img {
    border-style: solid dashed dashed;
    border-color: transparent #000000 transparent transparent;
    left: 2px;
  }

  .info_card .title .close_img .bottom_img {
    border-style: solid dashed dashed;
    border-color: transparent transparent #000000 transparent;
    top: 2px;
  }

  .info_card .title .close_img .left_img {
    border-style: solid dashed dashed;
    border-color: transparent transparent transparent #000000;
    left: -2px;
  } */

  /* .info_card span.cancle {
    width: 0;
    height: 0;
    font-size: 0;
    overflow: hidden;
    position: absolute;
  }

  .info_card span.bot {
    border-width: 20px;
    border-style: solid dashed dashed;
    border-color: #ffffff transparent transparent;
    left: 80px;
    bottom: -40px;
  } */
  .info_card span.top {
    border-width: 20px;
    border-style: solid dashed dashed;
    border-color: #c7c9c8 transparent transparent;
    left: 80px;
    bottom: -33px;
  }
  .info_card .content {
    margin-top: 10px;
    margin-left: 10px;
  }
`;

export const NavLeftWrapper = styled.div`
  position: absolute;
  top: 0;
  z-index: 999;
  width: 405px;
  height: 980px;
  padding-top: 40px;
  padding-left: 10px;
  box-sizing: border-box;
  background-color: transparent;
`;
export const CardWrapper = styled.div`
  margin-top: 10px;
  height: 880px;
  padding: 20px 0;
  box-sizing: border-box;
  background-color: #fff;
`;
export const CardHeaderWrapper = styled.div``;

export const CityHeader = styled.div`
  padding: 0 20px;
  margin-bottom: 20px;
  .card-header-city {
    margin-top: 24px;
    font-size: 24px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    color: #333333;
  }
  .card-header-name {
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #333333;
  }
  .card-header-info {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    color: #666666;
  }
`;
//地址图片特殊处理
export const AddressImg = styled.span`
  width: 25px;
  height: 26px;
  display: flex;
  font-size: 12px;
  color: #fff;
  font-family: PingFangSC, PingFangSC-Regular;
  align-items: center;
  justify-content: center;
  background: url(${icon_address}) no-repeat center;
  background-size: 100%;
`;
export const ListContainer = styled.div`
  overflow: auto;
  padding: 0 20px;
  &::-webkit-scrollbar {
    width: 4px;
    height: 2px;
    scrollbar-arrow-color: #666;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
    scrollbar-arrow-color: #666;
  }
  /*滚动条里面轨道*/
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
  }
  .ant-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const ListItem = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  .left {
    display: flex;
    .container {
      flex: 1;
      margin-left: 6px;
      .title {
        width: 200px;
        font-size: 18px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        color: #333333;
      }
      .info {
        font-size: 18px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #666666;
      }
    }
  }
  .right {
    margin-left: 48px;
    width: 72px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    .detail-btn {
      width: 72px;
      height: 72px;
      display: flex;
      justify-content: center;
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #1848fd;
    }
    /* background: #d8d8d8; */
    border-radius: 2px;
    .img-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 72px;
      height: 72px;
      /* background-color: #d8d8d8; */
    }
    .img {
      width: 72px;
      max-height: 100%;
      /* height: 72px; */
    }
  }

  &.detail-info-item {
    display: flex;
    .left {
      width: 72px;
      height: 72px;
      .img {
        width: 72px;
        height: 72px;
      }
    }
    .right {
      flex: 1;
      margin-left: 6px;
      .title {
        width: 260px;
        font-size: 18px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        color: #333333;
      }
      .info {
        font-size: 18px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #666666;
      }
    }
  }
  .img-wrapper {
    width: 72px;
    height: 72px;
    .poster {
      width: 72px;
      height: 72px;
    }
  }
  .content-info {
    margin-left: 14px;
    flex: 1;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #666666;
  }
  &.listItems {
    flex-direction: column;
  }
  .list-group {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    margin-bottom: 10px;
  }
`;

export const ParkWrapper = styled.div`
  padding: 0 20px;
  margin-bottom: 20px;
  .card-back {
  }
  .card-header-park {
    margin-top: 24px;
    word-break: break-all;
    font-size: 24px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    color: #333333;
  }
  .img-poster1 {
    margin: 24px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 284px;
    height: 284px;
    /* background: #d8d8d8; */
    img {
      width: 284px;
      /* height: 284px; */
    }
  }
  .card-header-address {
    margin-bottom: 10px;
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #333333;
  }
  .card-header-info {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #666666;
  }
`;

// tab 切换

export const CusTabs = styled(Tabs)`
  .ant-tabs-nav-list {
    margin: 0 auto;
    width: 300px;
    .ant-tabs-tab {
      flex: 1;
      align-items: center;
      justify-content: center;
      .ant-tabs-tab-btn {
        font-size: 18px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        color: #666666;
      }
    }
    .ant-tabs-tab-active .ant-tabs-tab-btn {
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      text-align: center;
      color: #1848fd;
      position: relative;
      ::before {
        position: absolute;
        content: "";
        width: 96px;
        height: 4px;
        background: #1848fd;
        left: 50%;
        bottom: -10px;
        margin-left: -48px;
      }
    }
  }
  .ant-tabs-nav .ant-tabs-ink-bar {
    background-color: transparent;
  }
`;
export const CusTabPane = styled(TabPane)``;

export const ParDetail = styled.div`
  padding: 0 20px;
  margin-bottom: 20px;
  .card-park-name {
    margin-top: 24px;
    position: relative;
    padding-left: 28px;
    font-size: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    color: #333333;
    ::before {
      position: absolute;
      content: "";
      left: 0;
      width: 25px;
      height: 25px;
      background: url(${addr}) no-repeat center;
      background-size: 100%;
    }
  }
  .card-header-info {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    padding-left: 28px;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #666666;
  }
  .img-poster1 {
    margin: 24px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 284px;
    height: 284px;
    background: #d8d8d8;
    img {
      width: 284px;
      /* height: 284px; */
    }
  }
`;

// 右侧导航工具栏
export const NavRightWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99999;
  width: 680px;
  height: auto;
`;

export const RightContentWrapper = styled.div`
  margin-top: 40px;
  margin-right: 20px;
`;

export const RightHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 660px;
  height: 50px;
  background-color: #fff;
  .ant-btn {
    border: none;
  }
  .ant-btn-link {
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #666666;
  }
  .ant-select:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: transparent;
    box-shadow: none;
  }
  .ant-select-selection-item {
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #666666;
  }
  .anticon.anticon-down {
    font-size: 12px;
    color: #666666;
  }
  .select-area span:first-child {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .select {
    flex: 1;
  }
  .tool {
    width: 100px;
  }
`;

export const RightContainer = styled.div`
  margin-top: 10px;
  margin-right: 20px;
  width: 680px;
  min-height: 400px;
  max-height: 800px;
  background-color: #fff;
`;

export const AtlasInfoWrapper = styled.div`
  * {
    user-select: none;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1440px;
  height: 650px;
  margin: 0 auto 38px;
  .title {
    padding: 30px;
    padding-left: 50px;
    font-size: 24px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    color: #000000;
    &::before {
      position: absolute;
      content: "";
      left: 30px;
      top: 50%;
      margin-top: -15px;
      width: 6px;
      height: 30px;
      background: #1848fd;
    }
  }
`;

export const AtlasRelationWrapper = styled.div<{ fullscreen: boolean }>`
  width: ${(props) => (props.fullscreen ? "100%" : "970px")};
  height: 100%;
  position: ${(props) => (props.fullscreen ? "absolute" : "relative")};
  top: ${(props) => (props.fullscreen ? "95px" : 0)};
  z-index: ${(props) => (props.fullscreen ? 999999 : 0)};
  left: ${(props) => (props.fullscreen ? 0 : "")};
  // display: flex;
  // justify-content: center;
  // align-items: center;
  background-color: #fff;
  .ant-row {
    padding-right: 20px;
  }
  .name {
    padding: 0 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const AtlasRightWrapper = styled.div<{ fullscreen: boolean }>`
  width: ${(props) => (props.fullscreen ? "100%" : "460px")};
  height: 100%;
  position: ${(props) => (props.fullscreen ? "absolute" : "relative")};
  top: ${(props) => (props.fullscreen ? "95px" : 0)};
  background-color: #fff;
  left: ${(props) => (props.fullscreen ? 0 : "")};
  z-index: ${(props) => (props.fullscreen ? 999 : 0)};
  .ant-row {
    padding-right: 20px;
  }
`;

export const SelectAreaWrapper = styled.div`
  padding: 10px;
`;

export const SelectTabs = styled(Tabs)`
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    /* color: #1848fd; */
  }
`;
export const SelectTabPane = styled(TabPane)``;

export const AreaContent = styled.div`
  height: 600px;
  overflow: auto;
  .ant-btn-link {
    color: #1848fd;
  }
  .ant-row .provice {
    width: 100px;
  }
  .child-link {
    width: 300px;
  }
`;

export const MapContainer = styled.div<{ fullscreen?: boolean }>`
  margin: 0 auto;
  width: ${(props) => (props.fullscreen ? "100%" : "1440px")};
  background-color: #fff;
  position: ${(props) => (props.fullscreen ? "absolute" : "relative")};
  top: ${(props) => (props.fullscreen ? "95px" : 0)};
`;

export const AtlasWrapper = styled.div`
  background-color: #f4f4f4;
`;

export const RightTool = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .tool {
    margin-right: 10px;
  }
`;

export const CatesContent = styled.div`
  margin-top: 10px;
  height: 600px;
  overflow: auto;
  background-color: #fff;
  .ant-collapse-header {
    background-color: #fff;
  }
  .item-row {
    flex-wrap: wrap;
    margin-right: 10px;
  }
`;
