import { CusButton } from "@/styles/common-style";
import { useState } from "react";
import { AgreementModal } from "./style";
interface AgreementProps {
  onChange?: (value: any) => void;
  title: string;
  subTitle?: string;
  content?: () =>
    | React.ReactNode
    | {
        children: React.ReactNode;
        props: any;
      };
}

const Agreement: React.FC<AgreementProps> = ({
  content,
  title,
  subTitle,
  onChange,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
    return;
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <a
        onClick={(e) => {
          e.preventDefault();
          showModal();
        }}
      >
        {title}
      </a>
      <AgreementModal
        title={subTitle || title}
        width={1200}
        centered
        visible={isModalVisible}
        onOk={handleOk}
        footer={[
          <CusButton
            type="primary"
            width={370}
            height={54}
            key="button"
            style={{
              fontSize: "18px",
            }}
            onClick={handleOk}
          >
            同意并继续
          </CusButton>,
        ]}
        onCancel={handleCancel}
      >
        {content && content?.()}
      </AgreementModal>
    </>
  );
};

export default Agreement;
