import { Title } from "@/styles/common-style";
import { CaseInfoWrapper } from "../style";

interface CaseInfoProps {
  data: any;
}

const CaseInfo: React.FC<CaseInfoProps> = ({ data }) => {
  return (
    <CaseInfoWrapper>
      <div className="case-intro">
        <Title className="case-title">方案介绍</Title>
        <div className="case-intro-content">
          <img
            src={require("@/assets/images/case/case-detail-bg1.png")}
            alt="icon"
            className="case-img"
          />
          <div className="right-content">
            <div className="right-content-item mab26">
              <div className="right-content-item-title">背景</div>
              <div className="right-content-item-intro">
                {data.caseBackdrop}
              </div>
            </div>
            <div className="right-content-item">
              <div className="right-content-item-title">痛点</div>
              <div
                className="right-content-item-intro ql-editor"
                dangerouslySetInnerHTML={{ __html: data.caseDemand }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="case-solute">
        <Title className="case-title"> 解决方案</Title>
        <div
          className="case-content ql-editor"
          dangerouslySetInnerHTML={{
            __html: data.caseDetails,
          }}
        ></div>
      </div>
    </CaseInfoWrapper>
  );
};

export default CaseInfo;
