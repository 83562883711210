import { CSSProperties, useRef, useState } from 'react';
import './index.less';
export interface TabProps {
  label: string; //头部文字
  key: string | number; // id 每项id
}
interface CusTabbarProps {
  defaultkey?: string | number;
  dataSource: TabProps[];
  tabstyle?: CSSProperties;
  tabItemStyle?: CSSProperties;
  bottomBorder?: CSSProperties;
  onTabItemClick: (item: TabProps) => void; // Tab项点击事件
}
interface TabActiveLineProps {
  style: CSSProperties;
  offsetLeft: number;
  clientWidth: number;
}
const CusTabbar: React.FC<CusTabbarProps> = ({
  defaultkey = '1',
  dataSource,
  onTabItemClick,
  tabstyle,
  tabItemStyle,
}) => {
  const [activeItemKey, setactiveItemKey] = useState<string | number>(
    defaultkey || '',
  );
  const cusTabActiveLine = useRef<TabActiveLineProps | any>();
  const wrapRef = useRef<any>();
  const onItemClick = (item: TabProps) => {
    onTabItemClick && onTabItemClick(item);
    setactiveItemKey(item.key);
    if (wrapRef.current) {
      if (cusTabActiveLine.current) {
        cusTabActiveLine.current.style.left =
          (Number(item.key) - 1) *
            (wrapRef.current.clientWidth / dataSource.length) +
          'px';
      }
    }
  };
  return (
    <div className="cus-tab-wrap" style={{ borderBottom: ' 1px solid #666' }}>
      <div className="cus-tab-header">
        <ul className="cus-tab-header-wrap" style={tabstyle} ref={wrapRef}>
          {dataSource &&
            dataSource.map((item) => (
              <li
                key={item.key}
                style={tabItemStyle}
                className={
                  activeItemKey == item.key
                    ? 'cus-tab-item-active cus-tab-item'
                    : 'cus-tab-item'
                }
                onClick={() => onItemClick(item)}
              >
                {item.label}
              </li>
            ))}
        </ul>
        <div className="cus-tab-active-line" ref={cusTabActiveLine}></div>
      </div>
    </div>
  );
};

export default CusTabbar;
