import BaseLayout from "@/layouts";

import "@/styles/data-wrap.less";

const DataCockpit: React.FC = () => {
  return (
    <BaseLayout>
      <div className="data-wrap">
        <iframe src="https://workspace.easyv.cloud/shareScreen/eyJzY3JlZW5JZCI6ODA4MjQ1fQ==?timeStamp=1801d60bb4a"></iframe>
      </div>
    </BaseLayout>
  );
};

export default DataCockpit;
